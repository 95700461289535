import React, { useRef, useState } from 'react';

import Logo from '../landingPage/assets/LogoWithText.svg';
import { useHistory } from 'react-router-dom';

import UserPool from './AWS/UserPool';
import { GetConfirmRegisteredUserUrl } from '../routes';
import axios from 'axios';
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from './CognitoUserPoolSignin';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { PATHS } from '../landingPage/components/Navbar/Navbar';
import classes from '../styles/Login.module.css';
import Eye from '../landingPage/assets/login/passwordEye.svg';
import EyeHide from '../landingPage/assets/login/passwordEyeHide.svg';

function CognitoUserPoolSignup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const recaptchaRef = useRef();
  const passwordConfirmRef = useRef();
  const history = useHistory();
  const [passwordInput, setPasswordInput] = useState('password');

  const onSuccessfulSignupCompleted = async (data) => {
    data.authProvider = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    const url = GetConfirmRegisteredUserUrl();
    const payloadData = data;

    try {
      const resp = await axios.post(url, payloadData);
      return resp;
    } catch (err) {
      console.log('onSuccessfulSignupCompleted to ' + url + ' failed');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    recaptchaRef.current.reset();
    let captchaToken = await recaptchaRef.current.executeAsync();

    // try {
    //   await axios.post(GetCaptchaVerificationUrl(), { captchaToken });
    // } catch (error) {
    //   return toast.error('Captcha Verification Failed!');
    // }

    UserPool.signUp(emailRef.current.value, passwordRef.current.value, [], null, async (err, data) => {
      if (err) {
        console.log('err == ', err);
        toast.error(err.message);
      } else {
        await onSuccessfulSignupCompleted(data);
        toast.success('Signup success! Please login');
        history.push('/cguplogin');
        console.log('data == ');
        console.log(data);
      }
    });
  };

  return (
    <>
      <div className={classes.LoginContainer}>
        <div className={classes.leftContainer}>
          <img className={classes.LoginLogo} src={Logo} alt='' />
          <div className={`${classes.formContainer} ${classes.toggleIn}`}>
            <div className={classes.form}>
              <div className={classes.title}>Create An Account</div>
              <div className={classes.Inputs}>
                <div>
                  <label htmlFor='email'>Work Email</label>
                  <div className={classes.Input}>
                    <input className={classes.inputBox} ref={emailRef} type='email' name='email' id='email' placeholder='Email' />
                  </div>
                </div>
                <div>
                  <label htmlFor='password'>Password</label>
                  <div className={classes.PasswordInput}>
                    <input
                      className={classes.inputBox}
                      type={passwordInput}
                      ref={passwordRef}
                      name='password'
                      id='password'
                      placeholder='Password'
                    />
                    {passwordInput === 'password' ? (
                      <img className={classes.eye} src={Eye} onClick={() => setPasswordInput('text')} />
                    ) : (
                      <img className={classes.eye} src={EyeHide} onClick={() => setPasswordInput('password')} />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor='confirmPassword'>Confirm Password</label>
                  <div className={classes.PasswordInput}>
                    <input
                      className={classes.inputBox}
                      type={passwordInput}
                      name='confirmPassword'
                      id='confirmPassword'
                      ref={passwordConfirmRef}
                      placeholder='Confirm Password'
                    />
                    {passwordInput === 'password' ? (
                      <img className={classes.eye} src={Eye} onClick={() => setPasswordInput('text')} />
                    ) : (
                      <img className={classes.eye} src={EyeHide} onClick={() => setPasswordInput('password')} />
                    )}
                  </div>
                </div>
                <div className={classes.helperLine}>
                  Already Have An Account?{' '}
                  <span className={classes.hint} onClick={() => history.push(PATHS.LOGIN)}>
                    Sign In Here
                  </span>
                </div>
                <div className='new-btn-secondary' onClick={onSubmit} style={{ height: '3vw', fontSize: '1.1vw' }}>
                  Sign Up
                </div>
                <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CognitoUserPoolSignup;
