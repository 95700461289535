import "../../../styles/ContextBar.css"
import {
    ContextBarGetNewDropDownField,
    ContextBarGetNewTextInputField,
    ContextBarGetNewCustomWidgetField,
    FIELD_APPENDING_TYPE,
    FIELD_VALUES, UI_FIELDS_DEFINITION,
    CreateContextBarUiHandler, setContextBarDefinitionTypeAsResourceLink
} from "../../ContextBarHandlerBuilder";
import {Button, Card, Dropdown, DropdownButton, Form, ListGroup} from "react-bootstrap";
import {createRef} from "react";
import React, { useState } from 'react';
import {GetWidgetCloseButtonIconPath} from "../../GeneralResourceIconsList";
import uniqid from "uniqid";

const ROUTE_NAME_KEY = "routename"
const HTTP_METHOD_TYPE_KEY = "httpmethodtype"


const CREDENTIALS_STORAGE_TYPE = [
    "YES",
    "NO"
]

const uiFieldsDefinition = [

    ContextBarGetNewDropDownField(
        "Store ?", "is_generate_and_store_credentials",
        FIELD_APPENDING_TYPE.NO_APPENDING,
        CREDENTIALS_STORAGE_TYPE, FIELD_VALUES.EMPTRY_STR
    ),

]

const contextBarDefinition = {
    [UI_FIELDS_DEFINITION]: uiFieldsDefinition,
}


export const Rds_linked_to_Secrets_Manager = (contextBarStateStore, setContextBarStateStore) => {

    setContextBarDefinitionTypeAsResourceLink(contextBarDefinition)

    contextBarDefinition.contextBarStateStore = contextBarStateStore
    contextBarDefinition.setContextBarStateStore = setContextBarStateStore

    const formContent = CreateContextBarUiHandler(contextBarDefinition)

    return (
        <div>
            <h6> <b> RDS - Secrets Manager Relation </b> </h6>
            <Card>
            <p>  <b> Create Credentials and Store in Secrets Manager ? </b>  </p>
            {formContent}
            </Card>
        </div>
    )
}


//
//