import CostManagerPage from "../CostManager/CostManagerPage";
import React, {useState} from "react";
import { getResourceWatcherUrl} from "../../routes";
import axios from "axios";
import {GetUserLoginCredentials, GetUserLoginToken} from "../UserCredentials";
import {Button, Table} from "react-bootstrap";
import { useLayoutEffect } from 'react';
import "../../styles/ResourecWatcher.css"
import refershIcon from "../../images/refersh.png"
import timerIcon from "../../images/timer.png"

const ResourceWatcherPage = () => {
    
    useLayoutEffect(() => {
        getDatabtclicked()
      }, []);

    const GetLoginCredentials = () => {
        let userCreds = GetUserLoginCredentials()[0]
        return {
            displayName: userCreds.displayName,
            email: userCreds.email,
            phoneNumber: userCreds.phoneNumber,
            photoURL: userCreds.photoURL,
            providerId: userCreds.providerId,
            uid: userCreds.uid,
            "user_oauth_token": GetUserLoginToken()
        }
    }

    const get_request_list = () => {
        return (
            [
                {
                    "request_type": "get_light_sail_outliers",
                    "request_params": ""
                },
                {
                    "request_type": "get_ec2_outliers",
                    "request_params": ""
                }
            ]
        )
    }

    const getPayload = () => {
        return {
            "action": "handleRequest", // don't change this -> used by websocket api gateway
            "concerto_user_credentials": GetLoginCredentials(),
            "msg_type": "get_light_sail_outliers",
            "params": { }
        }
    }

    const [resourceStatusList, setResourceStatusList] = useState({})

    let ws = null

    let setupWebSocketConnectionInit = false

    const setupWebSocketConnection = () => {
        if (setupWebSocketConnectionInit) {
            return
        }
        ws = new WebSocket(getResourceWatcherUrl())
        //console.log("new WebSocket(getResourceWatcherUrl()) ", getResourceWatcherUrl())

        ws.onopen = function(e) {
            console.log("Connection Opened")
            ws.send(JSON.stringify(getPayload('{"pi": "3.14"}')));
        };

        ws.onmessage = function(event) {
            let responseStr =  event.data
            let responseJsonObj = JSON.parse(responseStr);
            setResourceStatusList(responseJsonObj)
            //console.log("Got message from server ", event.data)
        };

        ws.onclose = function(event) {
            console.log("Connection Closed")
        }

        ws.onerror = function(error) {
            console.log("Connection error", error)
        };
        setupWebSocketConnectionInit = true
    }

    const getResourceStatusList = (RSL) => {
        // console.log("getResourceStatusList #################====> ")
        // console.log(typeof(RSL))
        // console.log(RSL)

        let rslCheck = RSL["lightsail_result_info_list"]

        // console.log("rslCheck", rslCheck)

        let rsl = RSL
        // if (rsl.lightsail_result_info_list) {
        //     console.log("if (rsl.lightsail_result_info_list) {")
        // } else {
        //     console.log("else of if (rsl.lightsail_result_info_list) {")
        // }
        if (!(rsl && rsl.lightsail_result_info_list)) {
            // console.log("getResourceStatusList XXXXXXXXXXXXXXXXX ")
            return (
                <>
                <Table className="resourecWatcher">
                <thead>

                <tr>
                    <th>Instance Name</th>
                    <th>Instance Id</th>
                    {/* <th>Account Id</th> */}{/* account id column*/}
                    <th>Status Name</th>
                    <th>Status Code</th>
                    <th>Health Status</th>
                    <th>Health Check Time</th>
                </tr>
                </thead>
            </Table>
   
            <div className="resourecWatcherRefersh">
                <img src={timerIcon} /><br/>
                <h3>Data Expired</h3>
                <p>Refresh the table to get updated</p>
                <button type="button"
                    className="btn btn-outline-primary"
                    onClick={getDatabtclicked}>
                        <img src={refershIcon} />
                        {"Refresh"}
                </button>
            </div>
                </>
            )
        }

        let rows = rsl.lightsail_result_info_list.map((item, i) => {
            return (
            <tr key={i} style={{ background: (item.health_status!="HEALTHY") ? "#FFEFF3" : ""}}>
                <td>{item.lightsail_instance_name}</td>
                <td>{item.lightsail_instance_id}</td>
                {/* <td>{item.lightsail_instance_id}</td> */} {/* account id column*/}
                <td>{item.status_name}</td>
                <td>{item.status_code}</td>
                <td style={{ color: (item.health_status==="HEALTHY") ? "#53A258" : "#FF003D"}}>{item.health_status}</td>
                <td>{item.creation_time_stamp}</td>
            </tr>
            )
        });

        const getRows = () => {
            return (<>
                {rows}
            </>)
        }
        // console.log("getResourceStatusList YYYYYYYYYYYYYYYYYYYY ")
/*
        {
            "account_id": "508066222061",
            "lightsail_instance_name": "Ubuntu-3",
            "lightsail_instance_id": "i-05026049b92767142",
            "status_code": "16",
            "status_name": "running",
            "health_status": "HEALTHY",
            "creation_time_stamp": "2022-06-28 18:10:06.272952968 +0530 IST"
 */
        return (
            <Table className="resourecWatcher">
                <thead>

                <tr>
                    <th>Instance Name</th>
                    <th>Instance Id</th>
                    {/* <th>Account Id</th> */} {/* account id column*/}
                    <th>Status Name</th>
                    <th>Status Code</th>
                    <th>Health Status</th>
                    <th>Health Check Time</th>
                </tr>
                </thead>

                <tbody>
                {getRows()}
                </tbody>
            </Table>
        )
    }

    // const connectbtclicked = (e) => {
    //     console.log("connectbtclicked")
    //     setupWebSocketConnection()
    //     //ws = new WebSocket(getResourceWatcherUrl())
    // }
    //
    // function disConnectbtclicked() {
    //     console.log("disConnectbtclicked")
    //     ws.close()
    // }

    function getDatabtclicked() {
        setupWebSocketConnection()
        // console.log("getDatabtclicked")
        // console.log(`ws.send(JSON.stringify(getPayload('{"pi": "3.14"}')));`)
        //
    }

    let getThat = () => {
        // console.log("=====> getThat")
        return (
            <></>
        )
    }

    return (
        <>
            {getResourceStatusList(resourceStatusList)}
            {/* <button type="button"
                    className="btn btn-outline-primary my-float"
                    onClick={getDatabtclicked}>
                {"Get Data"}
            </button> */}

            {/*{getThat(resourceStatusList)}*/}
        </>
    )
}

export default ResourceWatcherPage;
//
//