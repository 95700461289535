import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Select, { components } from 'react-select';
import uniqid from 'uniqid';
import { CSVLink } from 'react-csv';
import { Table, Spin, Collapse } from 'antd';
import moment, { MomentTimezone } from 'moment';
import { uniqBy, isEmpty, groupBy, cloneDeep } from 'lodash';

import { GetAllAccountsURL, GetSecurityViolationsListUrl, getSecurityViolationsTypeDefinitionsUrl } from '../../routes';
import { GetLoginCredentialsAsJsonObject, GetUserLoginCredentials, GetUserLoginToken } from '../UserCredentials';
import SeverityRenderer from './severityRenderer';
import ViolatedCategories from './violatedCategoriesRenderer';

import arrowDownIcon from '../../images/arrow-down.png';
import strokeIcon from '../../images/stroke.png';
import aws_logo from '../../images/Amazon_Web_Services_Logo.svg';

import '../../styles/SecurityAdvisor.css';
import { customDropdownStyles } from './styleUtils';

import { LoadingOutlined } from '@ant-design/icons';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownIcon} />
    </components.DropdownIndicator>
  );
};

const defaultModalState = {
  type: 'resolve' || 'details',
  show: false,
  data: {},
};

const SECURITY_VIOLATIONS_URL = 'https://epcmfsz4sl.execute-api.ap-south-1.amazonaws.com/dev/get_security_violations_list';

const SecurityAdvisorPage = () => {
  const [showGrid, setShowGrid] = useState(false);
  const [securityViolations, setSecurityViolations] = useState([]);
  const [accounts, setAccounts] = useState({});
  const [accountsMap, setAccountsMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [accountChosen, setAccountChosen] = useState({});
  const [violationsTypeDefinition, setViolationsTypeDefinition] = useState([]);
  const [modalState, setModalState] = useState(Object.assign({}, defaultModalState));
  const isMounted = useRef(false);

  const GET_ALL_ACCOUNTS_API = GetAllAccountsURL();

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    return {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };
  };

  const getSecurityViolationsPayload = (userCredentials, command_args_as_str, commandToExecute, commmandArgs) => {
    const command_args_as_str_base64 = Buffer.from(JSON.stringify(command_args_as_str)).toString('base64');
    return {
      concerto_user_credentials: userCredentials,
      command_to_execute: commandToExecute,
      command_args: commmandArgs,
      // "command_args_as_str": "ewp9"
      //"command_args_as_str": command_args_as_str_base64
    };
  };

  const retrieveSecurityViolationsTypeDefinitions = async (requestParams) => {
    let userCreds = GetUserLoginCredentials()[0];

    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = getSecurityViolationsTypeDefinitionsUrl();
    const payload = {};

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200) {
        return;
      }
      if (typeof resp.data === 'object' && resp.data.message !== 'Internal server error')
        setViolationsTypeDefinition(resp.data?.aws_security_violation_type_definitions);
      else setViolationsTypeDefinition([]);
      return resp.data;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    }
  };

  const retrieveSecurityViolations = async (requestParams) => {
    let al = [];
    let mp = {};

    for (var i = 0; i < accountChosen.length; i++) {
      let k = accountChosen[i].accountID.toString();
      console.log(typeof k);
      //k.toString()
      al.push(k);
      //console.log(xs[i]);
    }

    mp.accountsList = al;
    let commandsToExecute = [];
    commandsToExecute.push('get_violations_list_for_accounts');

    let cmp = {};
    cmp.commands = commandsToExecute;

    let userCredentials = GetLoginCredentialsAsJsonObject(); // GetLoginCredentials()

    const url = GetSecurityViolationsListUrl();
    const payload = getSecurityViolationsPayload(userCredentials, requestParams, cmp, mp);

    setIsLoading(true);
    try {
      const resp = await axios.post(url, payload);
      console.log(resp);

      if (resp.status != 200) {
        return;
      }

      if (typeof resp.data === 'object' && resp.data.message !== 'Internal server error') {
        let data =
          resp.data.violationRecordsList.map((violationsByAcc) => violationsByAcc.security_violations_list).flatMap((list) => list) || [];
        setSecurityViolations(data);
      } else setSecurityViolations([]);

      return resp;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    } finally {
      setIsLoading(false);
    }
  };

  const OnSearchSecViolations = () => {
    if (isEmpty(accountChosen)) return;
    setShowGrid(true);
    retrieveSecurityViolations({});
    retrieveSecurityViolationsTypeDefinitions({});
  };

  const getAllAccountsRequestPayload = () => {
    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), // GetLoginCredentials(),
      command_to_execute: 'get_account_ids',
    };
    return payload;
  };

  const retrieveAccounts = async () => {
    const url = GET_ALL_ACCOUNTS_API;
    const payload = getAllAccountsRequestPayload();

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200) {
        return;
      }
      if (typeof resp.data === 'object' && resp.data.message !== 'Internal server error') {
        setAccounts(resp.data);
      } else {
        setAccounts({});
      }
      return resp.data;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    }
  };

  useEffect(() => {
    isMounted.current = true;
    retrieveAccounts();
  }, []);

  const renderCell = (value, hasTooltip) => {
    if (hasTooltip)
      return (
        <div className='clr-blk-202020 fw-500 text-ellipsis cursr-pntr' title={value}>
          {value}
        </div>
      );
    else return <div className='flex just-cont-center clr-blk-202020 fw-500 text-ellipsis'>{value}</div>;
  };

  const defaultColDefs = (data) => {
    return [
      {
        title: 'Issue',
        dataIndex: 'violation_message_string',
        width: 250,
        render: (text, row, index) => (
          <div className='clr-blk-202020 fw-500 text-ellipsis cursr-pntr' title={text}>
            {text}
          </div>
        ),
      },
      {
        title: 'Resource Type',
        width: 205,
        dataIndex: 'resource_type',
        render: (text, row, index) => renderCell(row?.violations_params_Obj?.resource_type, false),
      },
      {
        title: 'Severity',
        width: 90,
        dataIndex: 'severity_level',
        render: (text, row, index) => (
          <div className='flex just-cont-center'>
            <SeverityRenderer severity={text} />
          </div>
        ),
      },
      {
        title: 'Affected Resources',
        width: 89,
        dataIndex: 'affectedResourcces',
        render: (text, row, index) => {
          const count = data.reduce(
            (counter, obj) => (obj.violation_message_string === row.violation_message_string ? (counter += 1) : counter),
            0
          ); // 6
          return <div className='flex just-cont-center fw-600 clr-gry40'>{count}</div>;
        },
      },
      {
        title: 'Violated Categories',
        width: 100,
        dataIndex: 'violations_params_Obj.violation_categories',
        render: (text, row, index) => (
          <div className='flex just-cont-center'>
            <ViolatedCategories categories={row?.violations_params_Obj?.violation_categories} />
          </div>
        ),
      },
      {
        title: 'Action',
        dataIndex: '',
        width: 170,
        render: (text, row, index) => {
          return (
            <div className='flex fs-14 fw-700 clr-blu'>
              <div className='p-l-r-20 cursr-pntr' onClick={() => onActionsClick('resolve', row)}>
                Resolve
              </div>
              <div className=' cursr-pntr' onClick={() => onActionsClick('details', row)}>
                Details
              </div>
            </div>
          );
        },
      },
    ];
  };

  const columnsForViolationDetails = (violation) => {
    let paramCols = [];
    let cols = [
      {
        title: 'Resource ID',
        dataIndex: 'violation_message_string',
        render: (text, row, index) => renderCell(row?.violations_params_Obj?.account_id, false),
      },
      {
        title: 'Region',
        dataIndex: 'resource_type',
        render: (text, row, index) => renderCell('', false),
      },
    ];
    if (violation?.secondary_details.length) {
      paramCols = violation?.secondary_details.map((param) => {
        let keyValueArray = param.split(':');
        let key = keyValueArray[0];
        return {
          title: key,
          dataIndex: '',
          render: (text, row, index) => renderCell(row?.violations_params_Obj?.[keyValueArray[0].trim()], true),
        };
      });
      cols = [...cols, ...paramCols];
    }

    return cols;
  };

  const columnsForSecurityViolationsGrid = (data) => defaultColDefs(data);

  const exportToCsv = () => {
    const rows = parseViolationParams(securityViolations);
  };

  const onRefineSearchBtnClick = () => {
    setAccountChosen({});
    setShowGrid(false);
  };

  const getCloudServiceLogo = (accountType) => {
    let imgSrc = aws_logo;
    return <img alt='' height='50' src={imgSrc} />;
  };

  const onAccountSelection = (options) => {
    if (options && options.length === 0) {
      setAccountsMap({});
      setAccountChosen(options);
      return;
    }
    const accountsMapClone = Object.assign({}, accountsMap);
    options.forEach((option) => {
      if (accountsMapClone[option?.accountID] == true) {
        return;
      }
      accountsMapClone[option?.accountID] = true;
    });
    setAccountChosen(options);
    setAccountsMap(accountsMapClone);
  };

  const closeModal = () => {
    setModalState({ ...modalState, show: false });
  };

  const onActionsClick = (type, violation) => {
    setModalState({ show: true, type, data: violation });
  };

  const getAccountsDropdown = () => {
    const accountIDs =
      accounts?.account_ids?.map((accId, i) => {
        return { id: i, accountID: accId };
      }) || [];

    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Select Account</div>
        <Select
          getOptionLabel={(option) => option.accountID}
          getOptionValue={(option) => option.accountID}
          onChange={(e) => {
            onAccountSelection(e);
          }}
          options={accountIDs}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          name='Service'
          placeholder='Select'
          value={isEmpty(accountChosen) ? null : accountChosen}
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  const getSearchButton = () => {
    return (
      <div
        className={`rdfn-search-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 ${
          isEmpty(accountChosen) ? 'disabled' : 'cursr-pntr'
        }`}
        onClick={OnSearchSecViolations}
      >
        <div className='txt-white p-l-r-10'>SEARCH</div>
      </div>
    );
  };

  const getAccountChooser = () => {
    return (
      <Row>
        <Col xs lg='2'></Col>
        <Col xs lg='8'>
          <div className='brdr-gry flex just-cont-center a-i-c p-t-b-20'>
            <div className=''>
              <div className='flex just-cont-sb p-t-b-10'>
                <div className='flex a-i-c'>Security Advisor</div>
                <div className='btn-hvr'>{getSearchButton()}</div>
              </div>
              <div className='p-t-b-10'>{getAccountsDropdown()}</div>
            </div>
          </div>
        </Col>
        <Col xs lg='2'></Col>
      </Row>
    );
  };

  const getGoBackBtn = () => {
    return (
      <div className='flex a-i-c clr-blu p-l-r-20 cursr-pntr'>
        <div className='m-r-10'>
          <i className='bi bi-arrow-left-short'></i>
        </div>
        <div>GO BACK</div>
      </div>
    );
  };

  const getExportToCsvButton = () => {
    const rows = parseViolationParams(securityViolations);
    const headers = [
      { label: 'Acc No', key: 'violations_params_Obj.account_id' },
      { label: 'Issue', key: 'violation_message_string' },
      { label: 'Resource Type', key: 'violations_params_Obj.resource_type' },
      { label: 'Severity', key: 'severity_level' },
      { label: 'Vioalated Categories', key: 'violations_params_Obj.violation_categories' },
      { label: 'Additional Info', key: 'secondary_details' },
    ];
    return (
      <CSVLink data={rows} headers={headers}>
        <div className='export-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 cursr-pntr m-r-20' onClick={() => {}}>
          <div className='p-l-r-10'>EXPORT EXCEL</div>
        </div>
      </CSVLink>
    );
  };

  const getRefineSearchButton = () => {
    return (
      <div className='rdfn-search-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 cursr-pntr' onClick={onRefineSearchBtnClick}>
        <div className='txt-white'>
          <img src={strokeIcon} />
        </div>
        <div className='txt-white p-l-r-10'>REFINE SEARCH</div>
      </div>
    );
  };

  const parseViolationParams = (array) => {
    const primary_details = ['account_id', 'cloud_provider', 'resource_type', 'violation_categories'];
    if (array && array.length) {
      const parsedArr = array.map((violations) => {
        if (violations) {
          violations.violations_params_Obj = JSON.parse(violations?.violations_params_json_string || '{}');
          violations.secondary_details = Object.entries(violations?.violations_params_Obj)
            .map((keyValuePair) => {
              const [key, value] = keyValuePair;
              if (!primary_details.includes(key)) return `${key} : ${value}`;
            })
            .filter((addnlDetail) => addnlDetail);
          return violations;
        }
      });
      return parsedArr;
    } else return [];
  };

  const getTableTitle = (accountId) => {
    return (
      <div className='m-l-r-10 p-l-r-20 p-t-b-10 flex just-cont-sb '>
        <div className='flex'>
          <div>{getCloudServiceLogo()}</div>
          <div className='flex a-i-c p-l-r-5 fw-500 fs-16 txt-black'>devacc : {accountId}</div>
        </div>
        <div className='flex a-i-c p-l-r-5 fs-12 color-ash'>
          Last reviewed on : <span className='fw-600 p-l-r-5'>{moment().format('DD/MM/YYYY , HH:mm z')}</span>
        </div>
      </div>
    );
  };

  const getSecurityViolationsTable = (data) => {
    const rows = data;
    const cols = columnsForSecurityViolationsGrid(rows);
    const violotionsUniqByInstanceId = uniqBy(rows, 'violation_message_string');
    return (
      <div className='securityViolationsTable'>
        <Table
          columns={cols}
          dataSource={violotionsUniqByInstanceId}
          rowClassName={(record, index) => (index % 2 === 0 ? '' : 'bg-blue-grad')}
          tableLayout='fixed'
          bordered={false}
          pagination={false}
          loading={{ spinning: isLoading, indicator: antIcon }}
          rowKey={(record) => uniqid()}
        />
      </div>
    );
  };

  const getViolationDetailsTable = () => {
    const violation = modalState?.data;
    const rows = securityViolations.filter(
      (record) =>
        record.violations_params_Obj.account_id === violation.violations_params_Obj.account_id &&
        record.violation_message_string === violation.violation_message_string
    );
    const cols = columnsForViolationDetails(violation);
    return (
      <div className='securityViolationsTable'>
        <Table
          columns={cols}
          dataSource={rows}
          rowClassName={(record, index) => (index % 2 === 0 ? '' : 'bg-blue-grad')}
          tableLayout='fixed'
          bordered={false}
          pagination={false}
          rowKey={(record) => uniqid()}
        />
      </div>
    );
  };

  const renderSecurityViolationsData = () => {
    const parsedData = parseViolationParams(securityViolations) || [];
    const groupedByAccMap = groupBy(parsedData, (violation) => violation?.violations_params_Obj?.account_id);
    const groupedByAcc = [];
    for (const [key, value] of Object.entries(accountsMap)) {
      groupedByAcc.push({ accountId: key, violations: groupedByAccMap?.[key] || [] });
    }

    return (
      <Row>
        <div>
          <div className='flex just-cont-sb m-b-15'>
            <div
              className='flex a-i-c'
              onClick={() => {
                setShowGrid(false);
              }}
            >
              {getGoBackBtn()}
            </div>
            <div className='flex just-cont-sb'>
              {getExportToCsvButton()}
              {getRefineSearchButton()}
            </div>
          </div>
          <div className='collapse-panel'>
            <Collapse defaultActiveKey={['1']}>
              {groupedByAcc && groupedByAcc.length
                ? groupedByAcc.map((acc, index) => {
                    return (
                      <CollapsePanel header={<div>{getTableTitle(acc?.accountId)}</div>} key={(index + 1).toString()}>
                        {getSecurityViolationsTable(acc?.violations)}
                      </CollapsePanel>
                    );
                  })
                : null}
            </Collapse>
          </div>
        </div>
      </Row>
    );
  };

  const renderModalTitle = () => {
    const violation = modalState?.data;
    return (
      <div className='flex just-cont-sb bg-blue-grad w-100per p-20 fs-17 '>
        <div className='flex clr-gry40'>
          <span className=''>Issue: </span>
          <span className='p-l-r-5 fw-700'>{violation?.violation_message_string}</span>
          <span className='flex a-i-c p-l-r-10'>
            <SeverityRenderer severity={violation?.severity_level} />
          </span>
        </div>
        <div>
          <span className='cursr-pntr' onClick={closeModal}>
            <i className='bi bi-x-lg'></i>
          </span>
        </div>
      </div>
    );
  };

  const renderAdditionalResources = (solutionsMoreInfo) => {
    return (
      <div style={{ display: 'grid' }}>
        {solutionsMoreInfo.map((link, i) => (
          <a key={i} href={link} target='_blank'>
            {link}
          </a>
        ))}
      </div>
    );
  };

  const renderViolationResolution = () => {
    if (!violationsTypeDefinition) return <></>;

    const violation = modalState?.data;
    const violationInfo = violationsTypeDefinition.find((type) => type.ViolationType === violation.ViolationMessageType);
    const additionalResources = violationInfo?.SolutionMoreInfo.split(' ');
    return (
      <div className='fs-18 fw-500'>
        <div className='p-b-10 p-l-r-10'>
          <div className='flex a-i-c'>
            <div className='sqr-20p severity-high brdr-rad-50per flex a-i-c'>
              <i class='bi bi-exclamation flex m-auto clr-white'></i>
            </div>
            <div className=' p-l-r-10'>Impact</div>
          </div>
          <div className='clr-gry40 fs-14'>{violationInfo?.ProblemInfo}</div>
        </div>
        <div className='p-t-b-10 m-t-b-10 p-l-r-10 bg-blue brdr-rad-8p'>
          <div className='flex a-i-c'>
            <div className='sqr-20p bg-grn brdr-rad-50per flex a-i-c'>
              <i class='bi bi-exclamation m-auto clr-white flex'></i>
            </div>
            <div className='p-l-r-10'>Resolution</div>
          </div>
          <div className='clr-blk-202020 fs-14 mt-2'>{violationInfo?.SolutionInfo}</div>
          <div className='p-b-10 m-t-b-10 p-l-r-10'>
            <div className='fs-14'>{additionalResources?.length ? renderAdditionalResources(additionalResources) : null}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderViolationsDetails = () => {
    return <div>{getViolationDetailsTable()}</div>;
  };

  return (
    <>
      <Container>
        {!showGrid ? getAccountChooser() : null}
        {showGrid ? renderSecurityViolationsData() : null}
      </Container>

      <Modal size='lg' show={modalState?.show} onHide={closeModal}>
        <Modal.Header className='p-0'>{renderModalTitle()}</Modal.Header>
        <Modal.Body>{modalState.type == 'resolve' ? renderViolationResolution() : renderViolationsDetails()}</Modal.Body>
      </Modal>
    </>
  );
};

export default SecurityAdvisorPage;
