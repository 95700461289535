

import { createStore } from 'redux';
import {reducer} from "./reducer";
import * as actions from "./ActionsList";

const store = createStore(reducer)

const getStore = () => { return store }

export const DispatchAction = (action, thePayload) => {
    if (thePayload && thePayload.what) {
        getStore().dispatch({
            type: action,
            payload: thePayload
        })
        return
    }
    console.log("store.js DispatchAction FAILED check " + "if (thePayload && thePayload.what)")
}

export const Subscribe = (listener) => {
    return getStore().subscribe(listener)
}

export const GetState = () => {
    return getStore().getState()
}

//