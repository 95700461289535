import React from 'react';
import '../../styles/LeftMenu.css';
import { CompanyCopywright } from '../CommonFunctions';

function LeftMenu({ menuOptions = [], selectedOption, setSelectedOption }) {
  const getCompanyAndLogo = () => {
    return CompanyCopywright();
  };

  const getOptions = () => {
    return (
      <ul className='group-options'>
        {menuOptions.map(({ value, label }) => (
          <li
            className={selectedOption === value ? 'selected-group-option' : ''}
            onClick={() => {
              setSelectedOption(value);
            }}
          >
            {label}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className='left-menu-links'>
        {getOptions()}
        {getCompanyAndLogo()}
      </div>
    </>
  );
}

export default LeftMenu;
