import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { GetMenuBar } from './components/MenubarComponent';
import { GetResourceEditorCanvas } from './components/ResourceEditorCanvas';
import ContextBar from './components/ContextBar';
import './styles/ResourceMaker.css';
import { GetNewHierarchyDiagramMaker } from './components/HierarchyDiagramMaker';
import { GetNewConnectionDiagramMaker } from './components/ConnectionDiagramMaker';
import { SidebarItem, DiagramDropdown, DiagramDropdownItem } from './styles/ResourceMakerPage';
import ComingSoon from './images/comingsoon.svg';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import { CONCERTO_HTTP_OK } from './CommonConsts';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBrand } from './components/MenubarComponent';
import UserProfile from './components/UserProfile';
import { GetState, Subscribe } from './components/statemanagement/store';
import {
  CONTEXT_BAR_CLOSE_BUTTON_CLICKED,
  RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS,
  RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS,
} from './components/statemanagement/ActionsList';
import CostManagerPage from './components/CostManager/CostManagerPage';
import AdvancedSearch from './components/AdvancedSearch';
import SecurityAdvisorPage from './components/SecurityAdvisor/securityAdvisor';

import ResourceWatcherPage from './components/ResourceWatcher/ResourceWatcherPage';
import { startCase } from 'lodash';
import AccordionTest from './components/NestedAccordion/ResourceBrowser';
import DisasterRecoveryPage from './components/DisasterRecoveryPage/DisasterRecoveryPage';

const ResourceMakerPage = () => {
  const [showSideBar, setShowSideBar] = useState(true);

  const [showContextBar, setShowContextBar] = useState(false);
  const [expandContextBar, setExpandContextBar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const onStoreStateChanged_G = () => {
    //console.log(currentAction)
    /* console.log("ResourceMaker.js: onStoreStateChanged_G")*/
    let state = GetState();

    if (state.currentAction.type === RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS) {
      setShowContextBar(Math.random());
    } else if (state.currentAction.type === CONTEXT_BAR_CLOSE_BUTTON_CLICKED) {
      setShowContextBar(false);
    }
  };
  const unsubscribe = Subscribe(onStoreStateChanged_G);

  const [activeTab, setActiveTab] = useState('cost-manager');

  const [scrolledDown, setScrolledDown] = useState(false);

  //  Functions to handle Tab Switching
  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  const onHierarchyDiagramClick = () => {
    //function
    let makeDiagramFunction = GetNewHierarchyDiagramMaker();
    makeDiagramFunction();
  };

  const onConnectionDiagramClick = () => {
    //function
    let makeDiagramFunction = GetNewConnectionDiagramMaker();
    makeDiagramFunction();
  };

  function openNav() {
    setShowSideBar(!showSideBar);
  }

  function getComingSoon() {
    return <SecurityAdvisorPage />;
    return (
      <div className='text-center'>
        <img alt='' height='200' src={ComingSoon} />
        <div className='row'>
          <h2>
            <b>We're building this feature</b>
          </h2>
        </div>
        <div className='row'>
          <h2>We will be launching this feature very soon. Stay tuned! for release 0.2 </h2>
        </div>
      </div>
    );
  }

  function getComingSoon_2() {
    return (
      <div className='text-center'>
        <img alt='' height='200' src={ComingSoon} />
        <div className='row'>
          <h2>
            <b>We're building this feature</b>
          </h2>
        </div>
        <div className='row'>
          <h2>We will be launching this feature very soon. Stay tuned! for release 0.5 </h2>
        </div>
      </div>
    );
  }

  function getCloudResourceBrowser() {
    return (
      <div>
        <div className='resource-page d-flex'>
          <div className='canvas'>
            <AccordionTest />
          </div>
          {/* <div className='canvas'>{GetResourceEditorCanvas()}</div> */}
          <div className={`sidebar context ${showContextBar ? 'open' : ''} ${showContextBar && expandContextBar ? 'expanded' : ''}`}>
            <ContextBar expanded={expandContextBar} toggleExpandView={() => setExpandContextBar((p) => !p)} />
          </div>
        </div>
      </div>
    );
  }

  function getCostManager() {
    return <CostManagerPage />;
  }

  function getAdvancedSearch() {
    return <AdvancedSearch />;
  }

  function getResourceWatcher() {
    // return <ResourecWatcherComponent />
    return <ResourceWatcherPage />;
  }

  function getDisasterRecovery() {
    return <DisasterRecoveryPage />;
  }

  const onExportJSONClick = async () => {
    try {
      let httpUrl = 'https://run.mocky.io/v3/dde86bb5-f496-49e8-a76e-c4b99a5ba180';

      const resourceData = [];
      console.log('onExportJSONClick => ' + httpUrl);
      const resp = await axios.post(httpUrl, resourceData);

      if (resp.status !== CONCERTO_HTTP_OK) {
        return;
      }

      let FileSaver = require('file-saver');
      let blob = new Blob([JSON.stringify(resp.data.data.msg)], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'data.json');
      // console.log(zip_content_binary_array)
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  function renderSwitch(params) {
    switch (params) {
      case 'resource-browser':
        return getCloudResourceBrowser();
      case 'cost-manager':
        return getCostManager();
      case 'security-advisor':
        return getComingSoon();
      case 'advanced-search':
        return getAdvancedSearch();
      case 'resource-maker':
        return getComingSoon_2();
      case 'resource-watcher':
        return getResourceWatcher();
      case 'disaster-recovery':
        return getDisasterRecovery();

      default:
        return <div>Not available</div>;
    }
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // const handleScroll = () => {
  //   setScrolledDown(window.pageYOffset > 40);
  // };

  const getTabs = () => {
    const disabledTabs = String(process.env.REACT_APP_DISABLED_TABS).split(',');
    const tabs = ['cost-manager', 'security-advisor', 'resource-browser', 'resource-watcher', 'disaster-recovery'];
    const filteredTabs = tabs.filter((tab) => !disabledTabs.includes(tab));

    const firstTabs = filteredTabs.slice(0, 3);
    const remainingTabs = filteredTabs.slice(3);

    const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
    };

    const handleTabClick = (tab) => {
      if (!disabledTabs.includes(tab)) {
        handleTab(tab);
        setShowDropdown(false);
      }
    };

    return (
      <ul className='nav d-flex justify-content-center flex-nowrap'>
        {firstTabs.map((eachTab) => (
          <li
            key={eachTab}
            className={`p2 ${activeTab === eachTab ? 'active' : ''}`}
            onClick={disabledTabs.includes(eachTab) ? () => {} : () => handleTab(eachTab)}
            style={disabledTabs.includes(eachTab) ? { cursor: 'no-drop' } : {}}
          >
            {startCase(eachTab)}
          </li>
        ))}
        {remainingTabs.length > 0 && (
          <li className='nav-item dropdown' onClick={toggleDropdown}>
            <div className='dropdown-toggle'>More</div>
            {showDropdown && (
              <div className='dropdown-menu' style={{ top: '3.4rem', left: '0.81rem', display: 'initial' }}>
                {remainingTabs.map((eachTab) => (
                  <div
                    key={eachTab}
                    className={`dropdown-item ${activeTab === eachTab ? 'active' : ''}`}
                    onClick={() => handleTabClick(eachTab)}
                    style={disabledTabs.includes(eachTab) ? { cursor: 'no-drop' } : activeTab === eachTab ? { background: '#39A2DB' } : {}}
                  >
                    {startCase(eachTab)}
                  </div>
                ))}
              </div>
            )}
          </li>
        )}
      </ul>
    );
  };

  const getStickyTopTabs = () => {
    if (scrolledDown) {
      return (
        <Navbar className='sticky-top' bg='light' variant='light'>
          <Container>
            <Nav.Link as={Link} to={'/home'}>
              {getBrand()}
            </Nav.Link>
            {getTabs()}
            <span>
              <UserProfile />
            </span>
          </Container>
        </Navbar>
      );
    } else {
      return getTabs();
    }
  };

  return (
    <div>
      {GetMenuBar('user_logged_in', getStickyTopTabs)}
      <div className='outlet'>{renderSwitch(activeTab)}</div>
    </div>
  );
};

export default ResourceMakerPage;
