import React from "react";
import { Button } from "react-bootstrap";
// import Success from "../images/success.png";
import Failure from "../images/failure.png";
import { GetMenuBar } from "./MenubarComponent";

function PaymentsFailure() {
  return (
    <>
      {GetMenuBar()}
      <div className="payment-status failure text-center">
        <img src={Failure} />
        <h2>Oops! seems like your payment has failed.</h2>
        <p>
          Please try again to complete the payment. Any amount deducted from
          your account will be refunded back in 7-8 working days
        </p>
        <div className="buttons d-flex flex-column">
          <Button
            className="custom-button filled-button"
            variant="primary"
            type=""
            // onClick={addKeyTolist}
          >
            Retry Payment
          </Button>
          <Button
            className="custom-button white-button"
            variant="primary"
            type=""
            // onClick={addKeyTolist}
          >
            Back to plans
          </Button>
        </div>
      </div>
    </>
  );
}

export default PaymentsFailure;
