import React from 'react';
import './Terms.css';

function Terms() {
  return (
    <div className='legal-container'>
      <p className='heading'>Terms of Service</p>
      <p className='sub-heading'>Last Update to Terms of Service: April 1, 2023</p>
      <p className='text'>
      The policies below are applicable to the BluefinWare network of websites, including the bluefinware.io 
      website (including any versions optimized for viewing on a wireless or tablet device); all email 
      newsletters published or distributed by BluefinWare; all apps published by BluefinWare; 
      and all other interactive features, services, and communications provided by BluefinWare, 
      however accessed and/or used, that are operated by us, made available by us, 
      or produced and maintained by BluefinWare. (Collectively “BluefinWare” or “we”, “us”, or “our”)(“Services”).
      BY USING OUR SERVICES, YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THESE TERMS OF SERVICE. 
      IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT USE THE SERVICES AND EXIT IMMEDIATELY. 
      WE RESERVE THE RIGHT TO MODIFY OR AMEND THESE TERMS OF SERVICE FROM TIME TO TIME WITHOUT NOTICE. 
      YOUR CONTINUED USE OF OUR SERVICES FOLLOWING THE POSTING OF CHANGES TO THESE TERMS WILL MEAN YOU 
      ACCEPT THOSE CHANGES. UNLESS WE PROVIDE YOU WITH SPECIFIC NOTICE, NO CHANGES TO OUR TERMS OF USE WILL 
      APPLY RETROACTIVELY. For certain of our Services, you will be required to execute our subscription agreement.
      This is a legal agreement between you (“you” or “user”) and BluefinWare that states the material terms and conditions that govern your use of the Services. This agreement, together with all updates, supplements, additional terms, and all of BluefinWare rules and policies collectively constitute this “Agreement” between you and BluefinWare. BY ACCESSING THE SERVICES, YOU AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF SERVICE AND CONDITIONS OF USE STATED HEREIN, PLEASE IMMEDIATELY LEAVE THIS WEBSITE.
      </p>
      <ol>
      <li>Site Access License. </li>
      <p className='text'>
      BluefinWare grants you a limited, revocable, non-exclusive, non-transferable license to access and 
      make use of the Services or their content. This license does not include any resale or commercial 
      use of the Services or their contents; any collection and use of any product listings, descriptions, 
      or prices; any derivative use of the Services or their contents; any downloading or copying of account 
      information for the benefit of another merchant; or any use of data mining, robots, or similar data 
      gathering and extraction tools. The Services and/or any portion of the Services may not be reproduced, 
      sold, resold, visited or otherwise exploited for any commercial purpose without BluefinWare express 
      written consent. You will not use any meta tags or any other “hidden text” utilizing our name or 
      trademarks without our express written consent. Additionally, you agree that you will not: (i) take 
      any action that imposes, or may impose in our sole discretion an unreasonable or disproportionately 
      large load on our infrastructure; (ii) interfere or attempt to interfere with the proper working of 
      the website or any activities conducted as part of the Services; or (iii) bypass any measures we may 
      use to prevent or restrict access to the Services. Any unauthorized use automatically terminates the 
      permissions and/or licenses granted by us to you.
      </p>
      <li>Copyright and Ownership.</li>
      <p className='text'>
        All of the content featured or displayed on the Services, including without limitation text, 
        graphics, photographs, images, moving images, sound, and illustrations (“Content”), is owned by 
        BluefinWare, its licensors, vendors, agents and/or its Content providers. All elements of the 
        Services, including without limitation the general design and the Content, are protected by trade 
        dress, copyright, moral rights, trademark and other laws relating to intellectual property rights. 
        The Services may only be used for the intended purpose for which such Services are being made 
        available. Except as permitted by copyright law, You may not modify any of the materials and you 
        may not copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative 
        works from, transfer or sell any information or work contained on the Services. Except as 
        authorized under the copyright laws, you are responsible for obtaining permission before reusing 
        any copyrighted material that is available on the Services. You shall comply with all applicable 
        domestic and international laws, statutes, ordinances and regulations regarding your use of the 
        Services. The Services, its Content and all related rights shall remain the exclusive property 
        of BluefinWare or its licensors unless otherwise expressly agreed. You will not remove any 
        copyright, trademark or other proprietary notices from material found on these Services.
      </p>
      <li>Trademarks/No Endorsement. </li>
      <p className='text'>All trademarks, service marks and trade names of BluefinWare used herein 
      (including but not limited to: BluefinWare name, BluefinWare corporate logo, the Services name, 
      the Services design, and any logos) (collectively “Marks”) are trademarks or registered trademarks of 
      BluefinWare or its affiliates, partners, vendors or licensors. You may not use, copy, reproduce, 
      republish, upload, post, transmit, distribute, or modify BluefinWare trademarks in any way, 
      including in advertising or publicity pertaining to distribution of materials on the Services, 
      without BluefinWare prior written consent. You shall not use BluefinWare name or any language, 
      pictures or symbols which could, in BluefinWare judgment, imply BluefinWare endorsement in any 
      (i) written or oral advertising or presentation, or (ii) brochure, newsletter, book, or other 
      written material of whatever nature, without prior written consent.
      </p>
      <li>Account Registration and Security. </li>
      <p className='text'>
      You understand that you may need to create an account to have access to all of the parts of 
      the Services. In consideration of your use of the Services, you will: (a) provide true, accurate, 
      current and complete information about yourself and your business as prompted by the Services 
      registration or subscription page (such information being the “Registration Data”) and (b) 
      maintain and promptly update the Registration Data to keep it true, accurate, current and 
      complete. If you provide any information that is untrue, inaccurate, not current or incomplete, 
      or BluefinWare has reasonable grounds to suspect that such information is untrue, inaccurate, not 
      current or incomplete, BluefinWare has the right to suspend or terminate your account and refuse any 
      and all current or future use of the Services (or any portion thereof). You are entirely responsible 
      for the security and confidentiality of your password and account. Furthermore, you are entirely 
      responsible for any and all activities that occur under your account. You will not share your account 
      information or your user name and password with any third party or permit any third party to logon to 
      the Services using your account information. You agree to immediately notify us of any unauthorized 
      use of your account or any other breach of security of which you become aware. You are responsible for 
      taking precautions and providing security measures best suited for your situation and intended use of 
      the Services. We have the right to provide user billing, account, Content or use records, and related 
      information under certain circumstances (such as in response to legal responsibility, lawful process, 
      orders, subpoenas, or warrants, or to protect our rights, customers or business).Please note that 
      anyone able to provide your personally identifiable information will be able to access your account 
      so you should take reasonable steps to protect this information.
      </p>
      <li>Solicited Submission Policy. </li>
      <p className='text'>
      Where BluefinWare has specifically invited or requested submissions or comments, BluefinWare encourages 
      you to submit content (e.g. comments to blog posts, participation in communities, tips, etc.) to 
      BluefinWare that they have created for consideration in connection with the Site (“User Submissions”). 
      User Submissions remains the intellectual property of the individual user. By posting content on our 
      Site, you expressly grant BluefinWare a non-exclusive, perpetual, irrevocable, royalty-free, fully 
      paid-up worldwide, fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, 
      create derivative works from, distribute, transmit, perform and display such content and your name, 
      voice, and/or likeness as contained in your User Submission, in whole or in part, and in any form 
      throughout the world in any media or technology, whether now known or hereafter discovered, including 
      all promotion, advertising, marketing, merchandising, publicity and any other ancillary uses thereof, 
      and including the unfettered right to sublicense such rights, in perpetuity throughout the universe. 
      Any such User Submissions are deemed non-confidential and BluefinWare shall be under no obligation 
      to maintain the confidentiality of any information, in whatever form, contained in any User Submission.
      </p>
      <li>Advertising.</li>
      <p className='text'>
      BluefinWare does not make or permit to be made any advertising or public announcement concerning the 
      relationship between the Parties without the prior written consent of the other Party.
      </p>
      <li>Third Party Links.</li>
      <p className='text'>
      From time to time, the Services may contain links to web sites that are not owned, operated or 
      controlled by BluefinWare or its affiliates. All such links are provided solely as a convenience 
      to you. If you use these links, you will leave the Services. Neither we nor any of our respective 
      affiliates are responsible for any content, materials or other information located on or accessible 
      from any other web site. Neither we nor any of our respective affiliates endorse, guarantee, or make 
      any representations or warranties regarding any other web sites, or any content, materials or other 
      information located or accessible from any other web sites, or the results that you may obtain from 
      using any other web sites. If you decide to access any other web sites linked to or from this Services, 
      you do so entirely at your own risk. Transactional Partners. In some cases we partner with another 
      company to co-promote their services within our Services. In these cases, you are transacting directly 
      with the other party. On those pages, the transactional partners brand is clearly visible and their 
      terms of service are posted. When using these partner pages, you are bound by partner terms of service 
      in addition to remaining bound by BluefinWare Terms of Service. When there is a conflict between these 
      Terms of Service and the partner’s terms of service, their terms of service will prevail.
      </p>
      <li>Inappropriate Material.</li>
      <p className='text'>
      You are prohibited from posting or sending any unlawful, threatening, defamatory, libellous, obscene, 
      pornographic or profane material or any material that could constitute or encourage conduct that 
      would be considered a criminal offense or give rise to civil liability, or otherwise violate any law. 
      In addition to any remedies that we may have at law or in equity, if we determine, in our sole 
      discretion, that you have violated or are likely to violate the foregoing prohibitions or the rules 
      in our commenting FAQs linked to here, we may take any action we deem necessary to cure or prevent 
      the violation, including without limitation, banning you from leaving comments or participating in 
      our forums and communities and/or the immediate removal of the related materials from the Services at 
      any time without notice. We will fully cooperate with any law enforcement authorities or court order 
      or subpoena requesting or directing us to disclose the identity of anyone posting such materials.
      </p>
      <li>Fees.</li>
      <p className='text'>
      For all charges or deposits for any events, products and services sold on or through the Services, 
      BluefinWare or its vendors or agents will bill your bank card or alternative payment method offered 
      by BluefinWare. When you provide bank card information, account numbers or other information necessary 
      to facilitate payment to us or our vendors, you represent to us that you are the authorized user of 
      the bank card that is used to pay for the products and services. In the event legal action is 
      necessary to collect on balances due, you agree to reimburse BluefinWare and its vendors or agents 
      for all expenses incurred to recover sums due, including attorneys’ fees and other legal expenses. 
      You are responsible for purchase of, and payment of charges for, all Internet access services and 
      telecommunications services needed for use of the Services. You understand that we will hold and 
      store such bank card or payment information to facilitate payment and deposit, damage reimbursement, 
      and other liability purposes.
      </p>
      <li>Access and Interference.</li>
      <p className='text'>
      You agree that you will not use any robot, spider, scraper or other automated means to access the 
      Services for any purpose without our express written permission. Additionally, you agree that you 
      will not: (i) take any action that imposes, or may impose in our sole discretion an unreasonable or 
      disproportionately large load on our infrastructure; (ii) interfere or attempt to interfere with the 
      proper working of the site or any activities conducted on the Services; or (iii) bypass any measures 
      we may use to prevent or restrict access to the Services. Right to Takedown Content. Except as 
      disclosed in our Privacy Policy, we will not monitor, edit, or disclose the contents of a user’s 
      e-mail unless required in the course of normal maintenance of the Services and its systems or 
      unless required to do so by law or in the good-faith belief that such action is necessary to: 
      (1) comply with the law or comply with legal process served on BluefinWare or the Services; 
      (2) protect and defend the rights or property of BluefinWare, the Services, or the users of the 
      Services; or (3) act in an emergency to protect the personal safety of our guests, the Services, 
      or the public. Users shall remain solely responsible for the content of their messages and BluefinWare 
      shall have no obligation to pre-screen any such content. However, we shall have the right in our sole 
      discretion to edit, refuse to post or remove any material submitted to or posted on the Services at 
      any time without notice. Without limiting the foregoing, we shall have the right to remove any material
      that we find to be in violation of the provisions hereof or otherwise objectionable, and the 
      additional right to deny any user who fails to conform to any provision of these Terms of 
      Service access to the Services. 
      </p>
      <li>Representations and Warranties. </li>
      <p className='text'>
      You represent that You are over the age of 18, have the right and authority to enter into this 
      Agreement, are fully able and competent to satisfy the terms, conditions, and obligations herein, 
      and Your use of the Services and Services is and will be in compliance with all applicable laws. 
      You have read, understood, agree with, and will abide by the terms of this agreement.
      </p>
      <li>DISCLAIMERS. </li>
      <p className='text'>
      YOUR USE OF THE SERVICES IS AT YOUR RISK. THE INFORMATION, MATERIALS AND SERVICES PROVIDED ON OR 
      THROUGH THE SERVICES ARE PROVIDED “AS IS” WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES 
      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY OR NON-INFRINGEMENT OF INTELLECTUAL 
      PROPERTY. NEITHER BluefinWare, NOR ANY OF ITS AFFILIATES WARRANT THE ACCURACY OR COMPLETENESS OF THE 
      INFORMATION, MATERIALS OR SERVICES PROVIDED ON OR THROUGH THE SERVICES. THE INFORMATION, MATERIALS AND 
      SERVICES PROVIDED ON OR THROUGH THE SERVICES MAY BE OUT OF DATE, AND NEITHER BluefinWare, NOR ANY OF 
      ITS AFFILIATES MAKES ANY COMMITMENT OR ASSUMES ANY DUTY TO UPDATE SUCH INFORMATION, MATERIALS OR 
      SERVICES. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW. 
      PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR 
      WRITTEN, OBTAINED FROM BluefinWare OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.  
      </p>
      <li>LIMITATIONS OF LIABILITY. </li>
      <p className='text'>
      BluefinWare DOES NOT ASSUME ANY RESPONSIBILITY, OR WILL BE LIABLE, FOR ANY DAMAGES TO, OR ANY 
      VIRUSES THAT MAY INFECT YOUR COMPUTER, TELECOMMUNICATION EQUIPMENT, OR OTHER PROPERTY CAUSED BY OR 
      ARISING FROM YOUR ACCESS TO, USE OF, OR BROWSING THIS SERVICES, OR YOUR DOWNLOADING OF ANY INFORMATION 
      OR MATERIALS FROM THIS SERVICES. IN NO EVENT WILL BluefinWare, OR ANY OF ITS OFFICERS, DIRECTORS, 
      EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, NOR ANY PARTY INVOLVED IN THE 
      CREATION, PRODUCTION OR TRANSMISSION OF THE SERVICES, BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, 
      SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT IMITATION, THOSE RESULTING 
      FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE, INABILITY TO USE, OR THE 
      RESULTS OF USE OF THE SERVICES, OR THE MATERIALS, INFORMATION OR SERVICES CONTAINED ON ANY OR ALL OF 
      THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT 
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO THE 
      EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS.IN THE EVENT OF ANY 
      PROBLEM WITH THE SERVICES OR ANY CONTENT, YOU AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING THE SERVICES.
      IN NO EVENT SHALL BluefinWare TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER 
      IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE GREATER OF (A) 
      TWENTY FIVE DOLLARS (US $25.00) OR (B) THE VALUE OF THE SERVICES PURCHASED OR SUBSCRIBED BY YOU ON THE 
      SERVICES.
      </p>
      <li>Indemnity</li>
      <p className='text'>
      You agree to defend, indemnify and hold BluefinWare and any affiliated company or individual harmless 
      from any and all liabilities, costs, and expenses, including reasonable attorneys’ fees, related to or 
      in connection with (i) the use of the Services or the Internet or your placement or transmission of any 
      message or information on this Services by you or your authorized users; (ii) your violation of any 
      term of this Agreement, including without limitation, your breach of any of the representations and 
      warranties above; (iii) your violation of any third party right, including without limitation any 
      right of privacy, publicity rights or Intellectual Property Rights; (iv) your violation of any law, 
      rule or regulation of the United States or any other country; (v) any claim or damages that arise as a 
      result of any User Submission that you provide to BluefinWare; or (vi) any other party’s access and 
      use of the Services with your unique username, password or other appropriate security code.
      </p>
      <li>Release.</li>
      <p className='text'>
      In the event that you have a dispute with one or more other users of the Services, you 
      release BluefinWare (and our officers, directors, agents, subsidiaries, joint ventures and employees) 
      from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown,
      suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such 
      disputes.
      </p>
      <li>Termination.</li>
      <p className='text'>
      You or we may suspend or terminate your account or your use of this Service at any time, for any 
      reason or for no reason. You are personally liable for any orders placed or charges incurred through 
      your account prior to termination. We may also block your access to our Services in the event that 
      (a) you breach these Terms of Service; (b) we are unable to verify or authenticate any information you 
      provide to us; or (c) we believe that your actions may cause financial loss or legal liability for you, 
      our users or us.
      </p>
      <li>Force Majeure.</li>
      <p className='text'>
      Neither BluefinWare nor you shall be responsible for damages or for delays or failures in performance 
      resulting from acts or occurrences beyond their reasonable control, including, without limitation: 
      fire, lightning, explosion, power surge or failure, water, acts of God, war, revolution, civil 
      commotion or acts of civil or military authorities or public enemies: any law, order, regulation, 
      ordinance, or requirement of any government or legal body or any representative of any such government 
      or legal body; or labour unrest, including without limitation, strikes, slowdowns, picketing, or 
      boycotts; inability to secure raw materials, transportation facilities, fuel or energy shortages, 
      or acts or omissions of other common carriers.
      </p>
      <li>Privacy.</li>
      <p className='text'>
      Data collection and use, including data collection and use of personally identifiable information 
      is governed by BluefinWare Privacy Policy which is incorporated into and is a part of this Agreement. 
      A printed version of these Terms of Service will be admissible in judicial and administrative 
      proceedings based upon or relating to these Terms of Service to the same extent and subject to 
      the same conditions as other business documents and records originally generated and maintained 
      in printed form. We do not guarantee continuous, uninterrupted or secure access to our Services, 
      and operation of the Services may be interfered with by numerous factors outside of our control. 
      If any provision of these Terms of Service is held to be invalid or unenforceable, such provision 
      shall be struck and the remaining provisions shall be enforced. You agree that these Terms of Service 
      and all incorporated agreements may be automatically assigned by BluefinWare in our sole discretion. 
      Headings are for reference purposes only and in no way define, limit, construe or describe the scope 
      or extent of such section. Our failure to act with respect to a breach by you or others does not waive 
      our right to act with respect to subsequent or similar breaches. These Terms of Service set forth the 
      entire understanding and agreement between us with respect to the subject matter hereof. All sections 
      which by their context ought to survive this agreement shall survive any termination or expiration of 
      this Agreement.
      </p>
      <li>Entire Agreement.</li>
      <p className='text'>
      These terms and conditions are the entire agreement between the user and BluefinWare and supersede 
      any prior understandings or agreements (written or oral).
      </p>
      <li>Additional Assistance.</li>
      <p className='text'>
      If you do not understand any of the foregoing Terms and Conditions or if you have any questions 
      or comments, we invite you to contact our Support Department by email at support@bluefinware.io .
      </p>
      <li>Copyright Notice.</li>
      <p className='text'>
      All Site design, graphics, text selections, arrangements, 
      and all software are Copyright © 2023, BluefinWare, or its licensors. ALL RIGHTS RESERVED.
      </p>
      </ol>
    </div>
  );
}

export default Terms;
