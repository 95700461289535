import React, { useState } from 'react';
import { Collapse } from 'antd';
import '../../styles/NestedAccordion.css';
import { DispatchAction } from '../statemanagement/store';
import * as actions from '../statemanagement/ActionsList';
import GridView from '../../images/gridView.svg';
import ListView from '../../images/listView.svg';

const { Panel } = Collapse;

const NestedAccordionItem = ({ child }) => {
  const [viewType, setViewType] = useState('gridType');

  const handleNodeClick = (node) => {
    DispatchAction(actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS, {
      what: actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS,
      node_data: node,
    });
  };

  const handleGridView = (e) => {
    e.stopPropagation();
    setViewType('gridType');
  };

  const handleListView = (e) => {
    e.stopPropagation();
    setViewType('listType');
  };

  return (
    <div>
      {child.children?.length > 0 ? (
        <NestedAccordion
          type='child'
          isLeafNodeHead={child.children?.[0]?.nodeId ? true : false}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>{child.title}</div>
              {child.children?.[0]?.nodeId ? (
                <div className='accordion-tile-container'>
                  <img alt='' src={GridView} onClick={handleGridView} />
                  <img alt='' src={ListView} onClick={handleListView} />
                </div>
              ) : null}
            </div>
          }
          content={child.content}
          children={child.children}
          viewType={viewType}
        />
      ) : (
        <div onClick={() => handleNodeClick(child)} className='leaf-node-container'>
          <div className='leaf-node-icon'>
            <img src={child?.imgPath} alt='' />
          </div>
          <div>{viewType === 'gridType' ? child.title : `${child.title} ${child.nodeType}`}</div>
        </div>
      )}
    </div>
  );
};

const NestedAccordion = ({ title, children: visibleItems = [], type = 'parent', isLeafNodeHead, viewType }) => {
  return (
    <Collapse bordered={true} className={type === 'parent' ? 'parent-accordion' : 'child-accordion'}>
      <Panel
        header={title}
        forceRender={true}
        className={`accordion-title ${isLeafNodeHead ? 'leafNodeHead' : ''} ${viewType}`}
        bordered={false}
      >
        {visibleItems &&
          visibleItems.map((child, i) => {
            return <NestedAccordionItem child={child} />;
          })}
      </Panel>
    </Collapse>
  );
};

export default NestedAccordion;
