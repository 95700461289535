import AWS_RESOURCE_ICONS, {AWS_RESOURCE_ICONS_BASE_PATH} from "./AWSResourcePickerIconAcess"

const GetAllAWSIconPaths = () => {
    let allIconResources = []
    for (var i = 0; i<AWS_RESOURCE_ICONS.length; i++) {
        let iconResource = AWS_RESOURCE_ICONS[i]
        let path = iconResource["path"]
        let isEnabled = iconResource["enabled"]
        if (isEnabled === "true") {
            allIconResources.push(path)
        }
    }
    return allIconResources
}



export const GetAllAwsResourceData = () => {
    let allIconResources = []
    for (var i = 0; i<AWS_RESOURCE_ICONS.length; i++) {
        let iconResource = AWS_RESOURCE_ICONS[i]
        let isEnabled = iconResource["enabled"]
        if (isEnabled === "true") {
            allIconResources.push(iconResource)
        }
        iconResource["full_http_path"] = AWS_RESOURCE_ICONS_BASE_PATH()+iconResource["path"]
    }
    return allIconResources
}
export default  GetAllAWSIconPaths;
//