import React, { useState } from 'react';
import classes from './Carousel.module.css'; // Import your CSS module

const CarouselComponent = ({slides}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={classes.carouselContainer}>
         <div className={classes.carouselDots}>
        {[...Array(slides.length)].map((_, index) => (
          <div
            key={index}
            className={`${classes.dot} ${currentSlide === index ? classes.activeDot : ''}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
      <div className={classes.carouselContent} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
       {slides.map((eachSlide,i)=> <div className={`${classes.carouselSlide} ${currentSlide === i ? classes.active : ''}`}>
         {eachSlide}
        </div>)}
      </div>
     
    </div>
  );
};

export default CarouselComponent;
