import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import axios from 'axios';
import { GetUserLoginCredentials, GetUserLoginToken } from '../../UserCredentials';
import { GetCaptchaVerificationUrl, GetCostPlannerUrl, GetEc2InstanceCostDetailsUrl } from '../../../routes';
import UserProfile from '../../UserProfile';
import uniqid from 'uniqid';
import { isEmpty, uniqWith } from 'lodash';
import Ec2CostParamsChooser, { GetEc2ParamsSelected } from '../Ec2CostParamsChooser';

import arrowDownIcon from '../../../images/arrow-down.png';
import strokeIcon from '../../../images/stroke.png';
import 'antd/dist/antd.css';
import { Nav, Navbar } from 'react-bootstrap';
import { getBrand, getNavBarTheme } from '../../MenubarComponent';
import { Link } from 'react-router-dom';
import { Table, Drawer } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import '../../../styles/spaces.css';
import { customDropdownStyles } from '../styleUtils';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownIcon} />
    </components.DropdownIndicator>
  );
};

const CostPlanner = () => {
  const recaptchaRef = useRef();

  const RESOURCE_TYPE_DROP_DOWN = 'RESOURCE_TYPE_DROP_DOWN_VAL';

  let [awsSelectionOptionsInfo, setAwsSelectionOptionsInfo] = useState({});
  let [isShowServiceParams, setIsShowServiceParams] = useState({ isServiceSelected: false });
  let [isShowCostPricing, setIsShowCostPricing] = useState(false);
  let [computedPricingInfo, setComputedPricingInfo] = useState({});
  const [costParamsChooserKey, setCostParamschooserKey] = useState(Date.now());
  const [ec2CostParams, setEC2CostParams] = useState({
    checkBoxMap: {},
    instancesTypeMap: {},
    ramTypesMap: {},
    vcpuTypesMap: {},
    regionTypesMap: {},
    neworkPerformanceTypesMap: {},
    regionChosen: { name: '' },
    resourceCategoryChosen: { name: '' },
    serviceChosen: { name: '' },
  });

  const getComputePricingPayload = (userCredentials, command_args_as_str) => {
    const command_args_as_str_base64 = Buffer.from(JSON.stringify(command_args_as_str)).toString('base64');
    return {
      concerto_user_credentials: userCredentials,
      command_to_execute: 'get_resource_params_options',
      command_args: {},
      command_args_as_str: command_args_as_str_base64,
    };
  };

  const computePricing = async (paramsSelectedForPricing) => {
    let userCreds = GetUserLoginCredentials()[0] || {};

    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = GetEc2InstanceCostDetailsUrl();
    const payload = getComputePricingPayload(userCredentials, paramsSelectedForPricing);

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200) {
        return;
      }
      if (typeof resp.data === 'object' && resp.data.message !== 'Internal server error') setComputedPricingInfo(resp.data);
      else setComputedPricingInfo({});
      return resp.data;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    }
  };

  const handleComputePricingButtonClick = async () => {
    recaptchaRef.current.reset();
    let captchaToken = await recaptchaRef.current.executeAsync();

    try {
      await axios.post(GetCaptchaVerificationUrl(), { captchaToken });
    } catch (error) {
      return toast.error('Captcha Verification Failed!');
    }

    console.log('handleComputePricingButtonClick');
    const { instancesTypeMap, ramTypesMap, vcpuTypesMap, neworkPerformanceTypesMap, regionTypesMap } = ec2CostParams;
    let paramsSelected = {
      service_type: 'ec2_instance',
      instances_types: instancesTypeMap,
      ram_types: ramTypesMap,
      vcpu_types: vcpuTypesMap,
      network_performance_types: neworkPerformanceTypesMap,
      regions_types: regionTypesMap,
    };

    console.log(paramsSelected);
    computePricing(paramsSelected);
    setIsShowCostPricing(true);
  };
  const onRefineSearchBtnClick = () => {
    resetSelectionsForPricing();
    setIsShowCostPricing(false);
  };

  const exportToCsv = () => {
    const rows = sortPricingInfoByInstanceType(Object.values(computedPricingInfo));
    const cols = exportColumnsForComputedPrincingInfoGrid(rows);
    const excel = new Excel();
    excel
      .addSheet('Pricing Info')
      .addColumns(cols)
      .addDataSource(rows, {
        str2Percent: true,
      })
      .saveAs('export.xlsx');
  };

  const resetSelectionsForPricing = () => {
    const resetObj = { name: '' };
    setEC2CostParams((p) => ({ ...p, resourceCategoryChosen: resetObj, serviceChosen: resetObj, regionTypesMap: {} }));
    setCostParamschooserKey(Date.now());
  };

  const getPayload = (userCredentials) => {
    return {
      concerto_user_credentials: userCredentials,
      command_to_execute: 'get_resources_info',
      command_args: {},
    };
  };

  useEffect(() => {
    fetchDataForSelection();
  }, []);

  const fetchDataForSelection = async () => {
    let userCreds = GetUserLoginCredentials()[0] || {};
    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = GetCostPlannerUrl();
    const payload = getPayload(userCredentials);

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200 || resp?.data?.message === 'Internal server error') {
        return;
      }
      setAwsSelectionOptionsInfo(resp.data);
      return resp.data;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    }
  };

  const onResourceCategorySelected = (item, option) => {
    let categoryName = option.ServiceCategory;
    const resourceCategoryChosen = { name: categoryName };
    setEC2CostParams((p) => {
      checkIsServiceSelected({ ...p, resourceCategoryChosen });
      return { ...p, resourceCategoryChosen };
    });
  };

  const onServiceSelected = (item, option) => {
    let serviceName = option.ServiceName;
    const serviceChosen = { name: serviceName };
    setEC2CostParams((p) => {
      checkIsServiceSelected({ ...p, serviceChosen });
      return { ...p, serviceChosen };
    });
  };

  const getChosenServiceName = () => {
    return ec2CostParams?.serviceChosen?.name;
  };

  const onRegionSelected = (item, options) => {
    let regionTypesMap = {},
      regionChosen;
    if (options && options.length === 0) {
      setEC2CostParams((p) => {
        checkIsServiceSelected({ ...p, regionTypesMap });
        return { ...p, regionTypesMap };
      });
      return;
    }
    options.forEach((option) => {
      let regionName = option.RegionCode;
      regionChosen = { name: regionName };
      regionTypesMap[regionName] = true;
    });
    setEC2CostParams((p) => {
      checkIsServiceSelected({ ...p, regionChosen, regionTypesMap });
      return { ...p, regionChosen, regionTypesMap };
    });
  };

  const checkIsServiceSelected = (cp) => {
    console.log({ cp });
    if (cp.resourceCategoryChosen.name != '' && cp.regionChosen.name != '' && cp.serviceChosen.name != '') {
      setIsShowServiceParams({ isServiceSelected: true });
    } else {
      // console.log("service Params can't be shown")
    }
  };

  const getResourceTypeDropDown = (tmpAwsSelectionOptionsInfo) => {
    let resourceCategories = tmpAwsSelectionOptionsInfo?.aws_resource_categories || [];
    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Resource Type</div>
        <Select
          getOptionLabel={(option) => option.ServiceCategory}
          getOptionValue={(option) => option.ServiceCategory}
          onChange={(e) => {
            onResourceCategorySelected(RESOURCE_TYPE_DROP_DOWN, e);
          }}
          options={resourceCategories}
          name='Resource Type'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  const getServiceTypeDropDown = (tmpAwsSelectionOptionsInfo) => {
    let serviceCategories = tmpAwsSelectionOptionsInfo?.aws_services || [];

    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Service</div>
        <Select
          getOptionLabel={(option) => option.ServiceName}
          getOptionValue={(option) => option.ServiceName}
          onChange={(e) => {
            onServiceSelected(RESOURCE_TYPE_DROP_DOWN, e);
          }}
          options={serviceCategories}
          name='Service'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  const getRegionsDropDown = (tmpAwsSelectionOptionsInfo) => {
    let regions = tmpAwsSelectionOptionsInfo?.aws_regions || [];

    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Regions</div>
        <Select
          getOptionLabel={(option) => option.RegionName + ' ' + option.RegionCode}
          getOptionValue={(option) => option.RegionCode}
          onChange={(e) => {
            onRegionSelected(RESOURCE_TYPE_DROP_DOWN, e);
          }}
          options={regions}
          className='basic-multi-select'
          classNamePrefix='select'
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          name='Regions'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  const getComputePricingButton = () => {
    return (
      <Button
        variant='primary'
        onClick={handleComputePricingButtonClick}
        disabled={
          isEmpty(ec2CostParams.regionTypesMap) || !ec2CostParams.resourceCategoryChosen?.name || !ec2CostParams.serviceChosen?.name
        }
      >
        Compute Pricing
      </Button>
    );
  };

  const serviceUiHandlerMap = {
    Ec2: <Ec2CostParamsChooser key={costParamsChooserKey} ec2CostParams={ec2CostParams} setEC2CostParams={setEC2CostParams} />,
  };

  const getServiceParamsChooserContent = () => {
    return serviceUiHandlerMap[getChosenServiceName()];
  };

  const getRefineSearchButton = () => {
    return (
      <div className='rdfn-search-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 cursr-pntr' onClick={onRefineSearchBtnClick}>
        <div className='txt-white'>
          <img src={strokeIcon} />
        </div>
        <div className='txt-white p-l-r-10'>REFINE SEARCH</div>
      </div>
    );
  };

  const getExportToCsvButton = () => {
    return (
      <div className='export-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 cursr-pntr m-r-20' onClick={exportToCsv}>
        <div className='p-l-r-10'>EXPORT EXCEL</div>
      </div>
    );
  };

  const getGoBackBtn = () => {
    return (
      <div className='flex a-i-c clr-blu p-l-r-20 cursr-pntr'>
        <div className='m-r-10'>
          <i className='bi bi-arrow-left-short'></i>
        </div>
        <div>GO BACK</div>
      </div>
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderCell = (value) => {
    return (
      <div className='flex just-cont-center' style={{ color: '#202020' }}>
        {value}
      </div>
    );
  };

  const renderYearWisePrice = (data, idx, key, forExport) => {
    return {
      title: forExport
        ? `${capitalizeFirstLetter(data.offering_class)} ${capitalizeFirstLetter(data.purchase_option)}`
        : () => (
            <div>
              <div className='text-capitalize'>{data.offering_class}</div>
              <div className='text-capitalize'>{data.purchase_option}</div>
            </div>
          ),
      dataIndex: key,
      className: 'border-rt',
      render: (text, row, index) => {
        const price_set = uniqWith(
          row[key],
          (a, b) => a.offering_class === b.offering_class && a.purchase_option === b.purchase_option
        ).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        const price = parseFloat(price_set?.[idx]?.price).toFixed(4);
        return (
          <div className='flex h-100per' style={{ fontWeight: 600, color: '#666666' }}>
            {price}
          </div>
        );
      },
    };
  };

  const sortColByPrice = (rowData, key) => {
    const list = uniqWith(
      rowData[0]?.[key],
      (a, b) => a.offering_class === b.offering_class && a.purchase_option === b.purchase_option
    ).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    return list;
  };

  const onCellFunc = (key) => {
    return (row, index) => {
      return { rowSpan: row[key] };
    };
  };

  const defaultColDefs = (rowData, forExport) => {
    const year_1_price_list_children = sortColByPrice(rowData, 'year_1_price_list');
    const year_3_price_list_children = sortColByPrice(rowData, 'year_3_price_list');

    return [
      {
        title: 'Instance Type',
        dataIndex: 'instance_type',
        width: 160,
        className: 'border-rt',
        fixed: 'left',
        shouldCellUpdate: (record, prevRecord) => false,
        render: (text, row, index) => renderCell(text),
        onCell: onCellFunc('instanceTypeRowSpan'),
      },
      {
        title: 'VCPU',
        dataIndex: 'vcpu',
        className: 'border-rt',
        fixed: 'left',
        render: (text, row, index) => renderCell(text),
      },
      {
        title: 'RAM',
        dataIndex: 'memory',
        className: 'border-rt',
        fixed: 'left',
        render: (text, row, index) => renderCell(text),
      },
      {
        title: 'Region',
        dataIndex: 'region',
        className: 'border-rt',
        fixed: 'left',
        render: (text, row, index) => renderCell(text),
      },
      {
        title: 'On Demand Price',
        dataIndex: 'ondemand_price',
        className: 'border-rt',
        render: (text, row, index) => renderCell(parseFloat(text).toFixed(4)),
      },
      {
        title: 'Reserve 1 Yr',
        dataIndex: 'year_1_price_list',
        className: 'border-rt',
        children: year_1_price_list_children.map((child, idx) => {
          return renderYearWisePrice(child, idx, 'year_1_price_list', forExport);
        }),
      },
      {
        title: 'Reserve 3 Yr',
        dataIndex: 'year_3_price_list',
        children: year_3_price_list_children.map((child, idx) => {
          return renderYearWisePrice(child, idx, 'year_3_price_list', forExport);
        }),
      },
    ];
  };

  const columnsForComputedPrincingInfoGrid = (rowData) => defaultColDefs(rowData, false);

  const exportColumnsForComputedPrincingInfoGrid = (rowData) => defaultColDefs(rowData, true);

  const sortPricingInfoByInstanceType = (array) => {
    let instanceTypeMap = [];
    let vcpuMap = [];
    let memoryMap = [];
    if (array && array.length) {
      array.sort((a, b) => {
        if (a.vcpu === b.vcpu) {
          if (a.instance_type === b.instance_type) {
            return a.region < b.region ? -1 : 1;
          } else {
            return a.instance_type < b.instance_type ? -1 : 1;
          }
        } else {
          return a.vcpu < b.vcpu ? -1 : 1;
        }
      });
      array.forEach((i) => {
        if (instanceTypeMap.includes(i.instance_type)) i.instanceTypeRowSpan = 0;
        else {
          i.instanceTypeRowSpan = array.filter((obj) => obj.instance_type === i.instance_type).length;
          instanceTypeMap.push(i.instance_type);
        }
        if (vcpuMap.includes(i.instance_type)) i.vcpuRowSpan = 0;
        else {
          i.vcpuRowSpan = array.filter((obj) => obj.instance_type === i.instance_type).length;
          vcpuMap.push(i.instance_type);
        }
        if (memoryMap.includes(i.instance_type)) i.memoryRowSpan = 0;
        else {
          i.memoryRowSpan = array.filter((obj) => obj.instance_type === i.instance_type).length;
          memoryMap.push(i.instance_type);
        }
        i.year_1_price_list = i.year_1_price_list.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      });
      return array;
    } else return [];
  };

  const getComputedPricingTable = () => {
    const rows = sortPricingInfoByInstanceType(Object.values(computedPricingInfo));
    const cols = columnsForComputedPrincingInfoGrid(rows);
    return (
      <div className='pricingTable'>
        <Table
          columns={cols}
          dataSource={rows}
          rowClassName={(record, index) => (index % 2 === 0 ? '' : 'bg-blue-grad')}
          // tableLayout='fixed'
          scroll={{ x: 'max-content' }}
          pagination={false}
          rowKey={(record) => uniqid()}
        />
      </div>
    );
  };

  return (
    <div className='cost-planner' key={costParamsChooserKey}>
      <div className='cost-planner-title mb-3'>Cost Calculator</div>
      <div className='m-b-20'>
        <div className='grid grid-rpt-3fr grid-gap-25p'>
          {getResourceTypeDropDown(awsSelectionOptionsInfo)}
          {getServiceTypeDropDown(awsSelectionOptionsInfo)}
        </div>

        <br />
        <br />
        <div className='flex'>{getRegionsDropDown(awsSelectionOptionsInfo)}</div>

        <br />
        <br />
        {isShowServiceParams.isServiceSelected ? (
          <>
            {getServiceParamsChooserContent()}
            <br />
            <br />
          </>
        ) : (
          <></>
        )}

        {getComputePricingButton()}
      </div>
      <Drawer
        title={
          <div style={{ paddingLeft: '25%', paddingRight: '25%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)' }}>
            <div className='flex just-cont-sb'>
              <Navbar className='bg-white' bg={getNavBarTheme()} variant={getNavBarTheme()}>
                <Nav.Link as={Link} to={'/home'}>
                  {getBrand()}
                </Nav.Link>
              </Navbar>
              <div className='flex'>
                <UserProfile />
              </div>
            </div>
          </div>
        }
        placement={'bottom'}
        closable={false}
        headerStyle={{ height: '80px', paddingLeft: '0px', paddingRight: '0px' }}
        onClose={() => {
          setIsShowCostPricing(false);
        }}
        visible={isShowCostPricing}
        height={'100vh'}
        zIndex={1060}
      >
        <div className='m-b-20 p-l-r-50' style={{ height: '100%' }}>
          <div className='flex just-cont-sb m-b-15'>
            <div
              className='flex a-i-c'
              onClick={() => {
                setIsShowCostPricing(false);
              }}
            >
              {getGoBackBtn()}
            </div>
            <div className='flex just-cont-sb'>
              {getExportToCsvButton()}
              {getRefineSearchButton()}
            </div>
          </div>
          {Object.values(computedPricingInfo).length ? getComputedPricingTable() : null}
        </div>
      </Drawer>
      <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
    </div>
  );
};

export default CostPlanner;
//
