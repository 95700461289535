import React from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import * as actions from './statemanagement/ActionsList';
import { DispatchAction, GetState, Subscribe } from './statemanagement/store';
import uniqid from 'uniqid';

import '../styles/ResourceEditorCanvas.css';
import {
  LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR,
  LINK_BUTTON_SELECTED_IN_SIDEBAR,
  RESOURCE_CONTEXT_INFO_SAVED_BY_USER,
  RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS,
  RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER,
} from './statemanagement/ActionsList'; // contains .diagram-component CSS
import ResourceCanvasContent from './ResourceCanvasContent.js';

const getGoJSLicenseKey = () => {
  return process.env.REACT_APP_GOJS_LICENSE;
};
const GOJS_LICENSE_KEY = getGoJSLicenseKey();

// export default ResourceEditorCanvas;
export const CREATE_RESOURCE_NODE = 'create_resource_node';
export const LINK_NODE_START = 'link_node_start';
export const LINK_NODE_STOP = 'link_node_stop';
export const RESOURCE_CONTEXT_INFO_KEY_TAG = 'resource_context_info_values';
export const NODE_LINK_SAVED_INFO = 'nodes_link_saved_info';
export const NODE_HINT_KEY_TAG = 'node_hint';

export const COMPUTE_GROUP = 'compute';
export const STORAGE_GROUP = 'storage';
export const DATABASE_GROUP = 'database';
export const NETWORKING_AND_CONTENT_DELIVERY = 'networkingandcontentdelivery';
export const SECURITY_IDENTITY_AND_COMPLIANCE_GROUP = 'security_identity_and_compliance';

export const SUBGROUP_1 = 'subgroup_1';
export const FULL_HTTP_PATH = 'full_http_path';
export const PROVIDER_AWS = 'aws';

export const LAMBDA = 'lambda';
export const EC2 = 'ec2';
export const RDS = 'rds';
export const S3 = 's3';
export const DYNAMODB = 'dynamodb';
export const APIGATEWAY = 'apigateway';
export const SECRETS_MANAGER = 'secrets_manager';

var diagramInternalHandles = {};

const getUniqueId = () => {
  return uniqid();
};

let isNodeLinkTrackingInProgress_G = false;
let nodeLinkPair_G = { from: null, to: null };

const clearNodeLinkPairInfo = () => {
  stopNodeLinkTracking();
  nodeLinkPair_G = { from: null, to: null };
};

const setFromNodeInLinkPair = (nodeObj) => {
  nodeLinkPair_G.from = nodeObj;
};

const setToNodeInLinkPair = (nodeObj) => {
  nodeLinkPair_G.to = nodeObj;
};

const isNodeLinkTrackingInProgress = () => {
  return isNodeLinkTrackingInProgress_G === true;
};

const startNodeLinkTracking = () => {
  isNodeLinkTrackingInProgress_G = true;
};

const stopNodeLinkTracking = () => {
  isNodeLinkTrackingInProgress_G = false;
};

const updateContextInfoForNode = (nodeData, savedParams) => {
  let nodeID = nodeData.key;
  // console.log(nodeID)
  updateNodeData(nodeID, RESOURCE_CONTEXT_INFO_KEY_TAG, savedParams);
};

const updateNodeData = (nodeID, dataKey, dataValue) => {
  for (let i = 0; i < getModel().nodeDataArray.length; i++) {
    let tmpNodeData = getModel().nodeDataArray[i];
    let tmpNodeID = tmpNodeData.key;
    if (tmpNodeID === nodeID) {
      getModel().nodeDataArray[i][dataKey] = dataValue;
    }
    console.log(getModel().nodeDataArray[i]);
  }
};

const updateNodesLinkData = (nodesLinkInfo, savedParams) => {
  let fromNodeID = nodesLinkInfo.from;
  let toNodeID = nodesLinkInfo.to;

  for (let i = 0; i < getAllNodeLinksDataFromGraph().length; i++) {
    let currentLinkInfo = getAllNodeLinksDataFromGraph()[i];
    if (currentLinkInfo.from == fromNodeID && currentLinkInfo.to == toNodeID) {
      currentLinkInfo[NODE_LINK_SAVED_INFO] = savedParams;
    }
  }
};

const onStoreStateChanged = () => {
  let state = GetState();

  // console.log("ResourceEditorCanvas.js const onStoreStateChanged = () =>")
  // console.log(state.currentAction.type)

  if (state.currentAction.type === LINK_BUTTON_SELECTED_IN_SIDEBAR) {
    startNodeLinkTracking();
  }

  if (state.currentAction.type === LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR) {
    stopNodeLinkTracking();
    clearNodeLinkPairInfo();
  }

  if (state.currentAction.type === RESOURCE_CONTEXT_INFO_SAVED_BY_USER) {
    console.log('onStoreStatechanged state.currentAction.type === RESOURCE_CONTEXT_INFO_SAVED_BY_USER');
    const savedInfo = state.contextBarSavedInfo.payload.saved_context_info;

    let savedParams = savedInfo.saved_params;
    let nodeData = savedInfo.node_info.node_data;
    updateContextInfoForNode(nodeData, savedParams);
  }

  if (state.currentAction.type === RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER) {
    console.log('ResourceEditorCanvas.js onStoreStateChanged state.currentAction.type === RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER');
    const savedInfo = state.contextBarSavedInfo.payload.saved_context_info;

    let savedParams = savedInfo.saved_params;
    let nodesLinkData = savedInfo.nodes_link_info;
    updateNodesLinkData(nodesLinkData.node_link_data, savedParams);
  }
};

const unsubscribe = Subscribe(onStoreStateChanged); // this is for redux

const getDiagramHandle = () => {
  return diagramInternalHandles['diagram'];
};

const getModel = () => {
  return getDiagramHandle().model;
};

export const GetNodesListData = () => {
  // console.log(getModel().linkDataArray)
  return getModel().nodeDataArray;
};

const getAllNodeDataFromGraph = () => {
  return getModel().nodeDataArray;
};

const getAllNodeLinksDataFromGraph = () => {
  return getModel().linkDataArray;
};

const NODES_DATA_TAG = 'nodes_data_info';
const LINKS_DATA_TAG = 'node_links_data_info';

const createGraphSnapShotData = (nodeData, linkData) => {
  return {
    [NODES_DATA_TAG]: nodeData,
    [LINKS_DATA_TAG]: linkData,
  };
};

export const GetResourcesGraphSnapshotSerialized = () => {
  let allNodesData = getAllNodeDataFromGraph();
  let allLinksData = getAllNodeLinksDataFromGraph();
  return createGraphSnapShotData(allNodesData, allLinksData);
};

const setDiagramHandle = (diagramHandle) => {
  diagramInternalHandles['diagram'] = diagramHandle;
};

const onSelectionChange = (part) => {
  console.log('const onSelectionChange = (part) => {');
  // console.log(part)
  // console.log(part)
  // console.log(part.data.key)
};

/*
// Look for addNodeAndLink function,
// https://github.com/NorthwoodsSoftware/GoJS/blob/master/samples/stateChart.html

        // create a link data from the old node data to the new node data
        var linkdata = {
          from: model.getKeyForNodeData(fromData),  // or just: fromData.id
          to: model.getKeyForNodeData(toData),
          text: "transition"
        };
        // and add the link data to the model
        model.addLinkData(linkdata);

        // select the new Node
        var newnode = diagram.findNodeForData(toData);
        diagram.select(newnode);

        diagram.commitTransaction("Add State");

        // if the new node is off-screen, scroll the diagram to show the new node
        diagram.scrollToRect(newnode.actualBounds);
 */
const createLinkBetweenFromAndToNodes = () => {
  const from = nodeLinkPair_G.from;
  const to = nodeLinkPair_G.to;
  console.log('#### Creating link between nodes');
  console.log(from.key);
  console.log(to.key);

  let diagram = getDiagramHandle();
  let model = diagram.model;

  var linkdata = {
    from: from.key, // or just: fromData.id
    to: to.key,
    text: 'transition',
  };

  model.addLinkData(linkdata);
  clearNodeLinkPairInfo();
};

const handleLinkSelection = (e, obj) => {
  const from = nodeLinkPair_G.from;
  const to = nodeLinkPair_G.to;

  if (from === null && to === null) {
    console.log("###### Setting 'from' node for linking  ");
    console.log(obj);
    setFromNodeInLinkPair(obj);
    return;
  }

  if (from !== null && to === null) {
    console.log("#### Setting 'to' node for linking  ");
    console.log(obj);
    setToNodeInLinkPair(obj);
    createLinkBetweenFromAndToNodes();
    return;
  }

  if (from === null && to !== null) {
    console.log('Invalid Link from <=> to nodes selection state (from === null && to !== null) ');
    return;
  }

  if (from !== null && to !== null) {
    console.log('Invalid Link from <=> to nodes selection state (from !== null && to !== null) ');
    return;
  }
};

const onNodeClicked = (e, obj) => {
  console.log('const onNodeClicked = (e, obj) => {');
  // console.log(obj.data)

  /*
    if (isNodeLinkTrackingInProgress()) { // handle this locally within ResourceCanvasEditor
        handleLinkSelection(e, obj)
        return
    }

    // Not a local event broadcast to whole UI
    DispatchAction(actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS, {
        "what": actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS,
        "node_data": obj.data
    })
   */

  DispatchAction(actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS, {
    what: actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS,
    node_data: obj.data,
  });
};

export const GetNodeData = (nodeID) => {
  let allNodesData = getAllNodeDataFromGraph();
  for (let i = 0; i < allNodesData.length; i++) {
    let tmpNodeData = allNodesData[i];
    // console.log("comparing "+nodeID+" against "+tmpNodeData.key)
    if (nodeID === tmpNodeData.key) {
      return tmpNodeData;
    }
  }
  return null;
};

const onNodeLinkClicked = (e, obj) => {
  let nodesLinkData = obj.data;

  let fromNodeID = nodesLinkData.from;
  let toNodeID = nodesLinkData.to;

  let fromNodeData = GetNodeData(fromNodeID);
  let toNodeData = GetNodeData(toNodeID);
  //
  // console.log("%%%%%%%%%%")
  // console.log(fromNodeData)

  // Not a local event broadcast to whole UI
  DispatchAction(actions.RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS, {
    what: actions.RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS,
    node_link_data: nodesLinkData,
    from_node_data: fromNodeData,
    to_node_data: toNodeData,
  });
};

const getGridLayout = ($) => {
  return $(go.GridLayout, { comparer: go.GridLayout.smartComparer });
};

const getTreeLayout = ($) => {
  return $(go.TreeLayout, { comparer: go.LayoutVertex.smartComparer });
};

const getCircularLayout = ($) => {
  return $(go.CircularLayout, {
    radius: 10, // minimum radius
    spacing: 0, // circular nodes will touch each other
    nodeDiameterFormula: go.CircularLayout.Circular, // assume nodes are circular
    startAngle: 270, // first node will be at top
  });
};

// https://gojs.net/latest/intro/layouts.html
const getLayout = ($) => {
  return $(
    go.TreeLayout, // the layout for the entire diagram
    {
      angle: 90,
      arrangement: go.TreeLayout.ArrangementHorizontal,
      isRealtime: false,
    }
  );
};

const getNewDiagramInstance = ($) => {
  return $(go.Diagram, {
    'undoManager.isEnabled': true, // must be set to allow for model change listening
    'grid.visible': false,

    // https://gojs.net/latest/intro/layouts.html
    layout: getLayout($),

    'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
    model: $(go.GraphLinksModel, {
      // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      linkKeyProperty: 'key',
    }),
  });
};

const getNewPicturewithBottomTextNodeTemplate = ($) => {
  return $(
    go.Node,
    'Vertical',
    new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),

    $(go.Picture, { margin: 1, width: 48, height: 48 }, new go.Binding('source')),

    $(go.TextBlock, { margin: 1, editable: true }, new go.Binding('text').makeTwoWay()),

    {
      click: function (e, obj) {
        onNodeClicked(e, obj);
      },
      selectionChanged: function (part) {
        onSelectionChange(part);
      },
    }
  );
};

const setLicenseKey = (isEvaluation = true) => {
  if (!isEvaluation) {
    go.Diagram.licenseKey = GOJS_LICENSE_KEY;
  }
};

function getImage(nodeKey) {
  return getIconPath(nodeKey);
}

function getNodeTemplate($) {
  return $(
    go.Node,
    'Auto',
    $(
      /* go.Shape, "Rectangle", { fill: null, stroke: "gray", strokeWidth: 2 }) */
      go.Shape,
      'Rectangle',
      { fill: '#F0F0F0', stroke: null, strokeWidth: 0 } /*,  new go.Binding("fill", "key") */
    ),
    $(
      go.Panel,
      'Horizontal',
      $(
        go.Picture,
        {
          name: 'Picture',
          desiredSize: new go.Size(32, 32),
          margin: new go.Margin(6, 8, 6, 10),
        },
        new go.Binding('source', 'key', getImage)
      ),
      $(go.TextBlock, { font: 'Bold 18px Sans-Serif', margin: 4 }, new go.Binding('text', 'key'))
    ),
    {
      click: function (e, obj) {
        onNodeClicked(e, obj);
      },
      selectionChanged: function (part) {
        onSelectionChange(part);
      },
    }
  );
}

function getLinkTemplate($) {
  return $(go.Link, { routing: go.Link.Orthogonal, corner: 10 }, $(go.Shape, { strokeWidth: 2 }), $(go.Shape, { toArrow: 'OpenTriangle' }));
}

function getGroupTemplate($) {
  return $(
    go.Group,
    'Auto',
    {
      // define the group's internal layout
      layout: $(go.TreeLayout, {
        angle: 90,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        isRealtime: false,
      }),
      // the group begins unexpanded;
      // upon expansion, a Diagram Listener will generate contents for the group
      isSubGraphExpanded: false,
      // when a group is expanded, if it contains no parts, generate a subGraph inside of it
      subGraphExpandedChanged: (group) => {
        if (group.memberParts.count === 0) {
          // randomGroup(group.data.key);
        }
      },
    },
    $(go.Shape, 'Rectangle', { fill: null, stroke: 'gray', strokeWidth: 2 }),
    $(
      go.Panel,
      'Vertical',
      { defaultAlignment: go.Spot.Left, margin: 4 },
      $(
        go.Panel,
        'Horizontal',
        { defaultAlignment: go.Spot.Top },
        // the SubGraphExpanderButton is a panel that functions as a button to expand or collapse the subGraph
        $('SubGraphExpanderButton'),
        $(
          go.Panel,
          'Horizontal',

          $(
            go.Picture,
            {
              name: 'Picture',
              desiredSize: new go.Size(32, 32),
              margin: new go.Margin(6, 8, 6, 10),
            },
            new go.Binding('source', 'key', getImage)
          )
        ),
        $(go.TextBlock, { font: 'Bold 18px Sans-Serif', margin: 4 }, new go.Binding('text', 'key'))
      ),
      // create a placeholder to represent the area where the contents of the group are
      $(go.Placeholder, { padding: new go.Margin(0, 10) })
    ) // end Vertical Panel
  ); // end Group
}

let diagramHandle = null;

function initDiagram() {
  const $ = go.GraphObject.make;
  setLicenseKey(false);
  const diagram = getNewDiagramInstance($);
  diagram.nodeTemplate = getNodeTemplate($);
  diagram.linkTemplate = getLinkTemplate($);
  diagram.groupTemplate = getGroupTemplate($);
  setDiagramHandle(diagram);

  diagramHandle = diagram;

  return diagram;
}

/**
 * This function handles any changes to the GoJS model.
 */
function handleModelChange(changes) {
  console.log('function handleModelChange(changes) ');
  // console.log('changes', changes)
}

const ResourceEditorCanvas = () => {
  return (
    <>
      <ReactDiagram initDiagram={initDiagram} divClassName='diagram-component' onModelChange={handleModelChange} />
      {/* <ResourceCanvasContent /> */}
    </>
  );
};

var iconPathMap = {};

const addToIconPathMap = (nodeId, imagePath) => {
  iconPathMap[nodeId] = imagePath;
};

const getIconPath = (nodeKey) => {
  if (nodeKey in iconPathMap) return iconPathMap[nodeKey];
  return 'https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png';
};

export const getMainDiagramHandle = () => {
  return diagramHandle;
};

export const ShowNodes = (diagram, nodesInfo) => {
  diagram.startTransaction('addGroupContents');

  nodesInfo.map((n) => {
    var nodeId = n.nodeId;
    var imgPath = n.imagePath;

    addToIconPathMap(nodeId, imgPath);
    diagram.model.addNodeData({
      key: nodeId,
      isGroup: n.isGroupNode,
      group: n.parent,
      nodeContextInfo: n.nodeContextInfo,
      imgPath: getImage(nodeId),
    });

    // console.log(n.nodeId);
  });

  diagram.commitTransaction('addGroupContents');
};

export const GetResourceEditorCanvas = () => {
  return ResourceEditorCanvas();
};

const addNewNode = (newId, nodeName, msg) => {
  let nodeData = getNewNodeData(newId, nodeName, msg);
  getDiagramHandle().model.addNodeData(nodeData);
};

const getNewNodeData = (nodeId, nodeName, msg) => {
  return {
    key: nodeId,
    text: nodeName,
    color: 'black',
    loc: '0 0',
    source: msg[FULL_HTTP_PATH],
    node_hint: msg,
  };
};

const getSubgroup_1 = (msg) => {
  return msg.resource_details[SUBGROUP_1];
};

const getNewNodeNameAndId = (subGroup_1) => {
  const newId = getUniqueId();
  let nodeName = subGroup_1 + '_' + newId;
  return [newId, nodeName];
};

const handleCreateResourceNode = (msg) => {
  const [newId, nodeName] = getNewNodeNameAndId(getSubgroup_1(msg));
  addNewNode(newId, nodeName, msg);
};

// Used by external components
// External components will send message to the gojs diagram Canvas
export const sendMessageToDiagramComponent = (msg) => {
  switch (msg.message_type) {
    case CREATE_RESOURCE_NODE:
      handleCreateResourceNode(msg);
      break;

    default:
      break;
  }
};

export const sendMessageCreateImmutableResourceNode = (msg) => {
  const [newId, nodeName] = [getUniqueId(), msg.nodeName];
  addNewNode(newId, nodeName, msg);
};

export const GetNodeStatesSnapshot = () => {};

const getNewGroupNodeData = (nodeId, nodeName, msg) => {
  return {
    key: nodeId,
    text: nodeName,
    color: 'green',
    isGroup: true,
    // loc: '0 0',
    source: msg[FULL_HTTP_PATH],
    node_hint: msg,
  };
};

const addNewGroupNode = (newId, nodeName, msg) => {
  let nodeData = getNewGroupNodeData(newId, nodeName, msg);
  // console.log("## GROUP NODE DATA ###")
  // console.log(nodeData)
  getDiagramHandle().model.addNodeData(nodeData);
};

export const CreateGroupNode = (msg) => {
  const [newId, nodeName] = [getUniqueId(), msg.nodeName];
  addNewGroupNode(newId, nodeName, msg);
  return newId;
};

export const CreateGroupNodeWithParams = (nodeId, nodeName, msg) => {
  addNewGroupNode(nodeId, nodeName, msg);
  return nodeId;
};

const getnewGroupNodeWithinAnotherGroup = (childNodeId, childNodeName, childNodeMsg, parentGroupNodeId, isChildNodeDataMutable) => {
  return {
    key: childNodeId,
    text: childNodeName,
    color: 'green',
    // isGroup: true,
    source: childNodeMsg[FULL_HTTP_PATH],
    node_hint: childNodeMsg,
    is_node_data_mutable: isChildNodeDataMutable,
    group: parentGroupNodeId,
  };
};

export const GroupNodeWithinAnotherGroup = (
  childNodeId = 'auto',
  childNodeName,
  childNodeMsg,
  parentGroupNodeId,
  isChildNodeDataMutable
) => {
  if (childNodeId === 'auto') {
    childNodeId = getUniqueId();
  }

  let newNode = getnewGroupNodeWithinAnotherGroup(childNodeId, childNodeName, childNodeMsg, parentGroupNodeId, isChildNodeDataMutable);
  addNewGroupNode(childNodeId, childNodeName, childNodeMsg);
  return childNodeId;
};

const getNewNodeWithinGroupData = (nodeId, nodeName, msg, groupNodeId, isNodeDataMutable) => {
  return {
    key: nodeId,
    text: nodeName,
    color: 'black',
    group: groupNodeId,
    // loc: '0 0',
    source: msg[FULL_HTTP_PATH],
    node_hint: msg,
    is_node_data_mutable: isNodeDataMutable,
  };
};

const getNewGroupNodeWithinGroupData = (nodeId, nodeName, msg, groupNodeId, isNodeDataMutable) => {
  return {
    key: nodeId,
    text: nodeName,
    color: 'black',
    group: groupNodeId,
    isGroup: true,
    source: msg[FULL_HTTP_PATH],
    node_hint: msg,
    is_node_data_mutable: isNodeDataMutable,
  };
};

const addNewGroupNodeWithinGroup = (newId, nodeName, msg, groupNodeId, isNodeDataMutable = false) => {
  let nodeData = getNewGroupNodeWithinGroupData(newId, nodeName, msg, groupNodeId, isNodeDataMutable);
  // console.log(nodeData)
  getDiagramHandle().model.addNodeData(nodeData);
};

export const sendMessageCreateImmutableResourceNodeGroupInsideGroup = (msg, groupNodeId) => {
  // console.log("sendMessageCreateImmutableResourceNodeInsideGroup groupNodeId " + groupNodeId)
  const [newId, nodeName] = [getUniqueId(), msg.nodeName];
  addNewGroupNodeWithinGroup(newId, nodeName, msg, groupNodeId);
  return newId;
};

const addNewNodeWithinGroup = (newId, nodeName, msg, groupNodeId, isNodeDataMutable = false) => {
  let nodeData = getNewNodeWithinGroupData(newId, nodeName, msg, groupNodeId, isNodeDataMutable);
  // console.log(nodeData)
  getDiagramHandle().model.addNodeData(nodeData);
};

export const sendMessageCreateImmutableResourceNodeInsideGroup = (msg, groupNodeId) => {
  // console.log("sendMessageCreateImmutableResourceNodeInsideGroup groupNodeId " + groupNodeId)
  const [newId, nodeName] = [getUniqueId(), msg.nodeName];
  addNewNodeWithinGroup(newId, nodeName, msg, groupNodeId);
  return newId;
};
