import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import { GetMenuBar } from "./MenubarComponent";
import Download from "../images/download.png";
import "../styles/Settings/billingPlans.css"
function BillingsPlans() {
  return (
    <>
      { GetMenuBar() }
      <Container className="small  mt-5">
        {/* <Row>
          <Col xs lg="3">
            <LeftMenu />
          </Col>
          <Col xs lg="9"> */}
            <section className="bill-section" style={{maxWidth:"600px",margin:"0 auto 40px auto"}}>
              <div className="billing">
                <div className="payment-due due">
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Plan : </label>
                        <span> PRO</span>
                      </div>
                      <a href="#">Need Help?</a>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <div className="form-group">
                      <label>Payment Date :</label>
                      <span> 27 January 2022</span>
                    </div>
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Transaction ID :</label>
                        <span> 33242328687879</span>
                      </div>
                      <a href="#"><img src={Download}/>Download Incoice</a>
                    </div>
                  </div>
              </div>
              <div className="billing">
                <div className="payment-due due">
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Plan : </label>
                        <span> PRO</span>
                      </div>
                      <a href="#">Need Help?</a>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <div className="form-group">
                      <label>Payment Date :</label>
                      <span> 27 January 2022</span>
                    </div>
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Transaction ID :</label>
                        <span> 33242328687879</span>
                      </div>
                      <a href="#"><img src={Download}/>Download Incoice</a>
                    </div>
                  </div>
              </div>
              <div className="billing">
                <div className="payment-due due">
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Plan : </label>
                        <span> PRO</span>
                      </div>
                      <a href="#">Need Help?</a>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <div className="form-group">
                      <label>Payment Date :</label>
                      <span> 27 January 2022</span>
                    </div>
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Transaction ID :</label>
                        <span> 33242328687879</span>
                      </div>
                      <a href="#"><img src={Download}/>Download Incoice</a>
                    </div>
                  </div>
              </div>
              <div className="billing">
                <div className="payment-due due">
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Plan : </label>
                        <span> PRO</span>
                      </div>
                      <a href="#">Need Help?</a>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <div className="form-group">
                      <label>Payment Date :</label>
                      <span> 27 January 2022</span>
                    </div>
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Transaction ID :</label>
                        <span> 33242328687879</span>
                      </div>
                      <a href="#"><img src={Download}/>Download Incoice</a>
                    </div>
                  </div>
              </div>
            </section>
          {/* </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default BillingsPlans;
