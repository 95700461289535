import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId,start,end,frameWidth,frameHeight}) => (
  <div className="video-responsive">
    <iframe
      width={frameWidth}
      height={frameHeight}
      src={`https://www.youtube.com/embed/${embedId}?start=${start};end=${end};`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  start : PropTypes.number,
  end : PropTypes.number,
  frameWidth : PropTypes.number,
  frameHeight : PropTypes.number
};

YoutubeEmbed.defaultProps = {
    start : 0,
    end : Number.MAX_SAFE_INTEGER,
    frameWidth : 299,
    frameHeight : 234
};

export default YoutubeEmbed;