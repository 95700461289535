export const resourceNodeSelectedInEditorCanvasReducer = (state, action) => {
  return {
    ...state,
    currentAction: action,
    contextBarInfo: {
      desc: 'resourceNodeSelectedInEditorCanvasReducer',
      payload: action.payload,
    },
  };
};

export const resourceNodesLinkSelectedInEditorCanvasReducer = (state, action) => {
  console.log('resourceNodesLinkSelectedInEditorCanvasReducer');
  console.log(action);
  return {
    ...state,
    currentAction: action,
    contextBarInfo: {
      desc: 'resourceNodesLinkSelectedInEditorCanvasReducer',
      payload: action.payload,
    },
  };
};

export const closeIconClickedInContextBarReduced = (state, action) => {
  return {
    ...state,
    currentAction: action,
  };
};
//
