// https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/instance


import {
    ContextBarGetNewDropDownField, ContextBarGetNewTextInputField,
    CreateContextBarUiHandler, FIELD_APPENDING_TYPE,
    FIELD_IDENTIFIER_TAG, FIELD_TYPE, FIELD_VALUE, FIELD_VALUES,
    GetNewFieldDefinition, setContextBarDefinitionTypeAsResourceLink,
    UI_FIELDS_DEFINITION
} from "../ContextBarHandlerBuilder";
import {GET_INSTANCE_TYPES, GET_OS_TYPES} from "./EC2Util";
import {GET_RDS_ENGINE_UI_LIST_ITEMS, GET_RDS_INSTANCE_TYPES_LIST} from "./RdsUtil";


const RDS_TYPES_LIST = GET_RDS_ENGINE_UI_LIST_ITEMS()

const RDS_INSTANCE_TYPES_LIST = GET_RDS_INSTANCE_TYPES_LIST()

const uiFieldsDefinition = [
    ContextBarGetNewTextInputField(
        "Name for Secrets Manager", "secrets_manager_name",
        FIELD_APPENDING_TYPE.NO_APPENDING,
        "Secrets Manager name No spaces", FIELD_VALUES.EMPTRY_STR
    ),

]

const contextBarDefinition = {
    [UI_FIELDS_DEFINITION]: uiFieldsDefinition,
}

export const SecretsManagerContextBarHandler = () =>  {

    const formContent = CreateContextBarUiHandler(contextBarDefinition)

    return (
        <div>
            <h6> <b> AWS Secrets Manager </b> </h6>
            {formContent}
        </div>
    )
}

//
//
