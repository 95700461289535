import React from 'react';
import Chart from 'react-apexcharts';

const MultiLineChart = ({ data }) => {
  const chartData = {
    series: Object.keys(data).map((accountData) => ({
      name: accountData,
      data: data[accountData].map(({ value }) => value),
    })),
    options: {
      chart: {
        type: 'line',
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },

      xaxis: {
        categories: Object.values(data)?.[0]?.map(({ date }) => date),
        labels: {
          style: {
            fontSize: '11px',
          },
        },
      },

      stroke: {
        width: 2,
      },
    },
  };
  return <Chart options={chartData.options} series={chartData.series} type='line' height={350} />;
};

export default MultiLineChart;
