import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LeftMenu from '../LeftMenu';
import { GetMenuBar } from '../MenubarComponent';
import '../../styles/ProductConfiguration.css';
import Schedules from './Schedules/Schedules';

function ProductConfiguration() {
  const [activePage, setActivePage] = useState('');

  const getActivePage = () => (activePage === 'Schedules' ? <Schedules /> : <div>Coming Soon</div>);

  return (
    <>
      {GetMenuBar('user_logged_in')}

      <Container className='small mt-5'>
        <Row>
          <Col cs lg='3'>
            <LeftMenu />
          </Col>
          <Col xs lg='9'>
            <div className='title'>
              <span onClick={() => setActivePage('')} style={{ cursor: 'pointer' }}>
                Product Configuration
              </span>
              {activePage ? ' > ' + activePage : ''}{' '}
            </div>

            {!activePage ? (
              <div className='navigation-buttons'>
                <div onClick={() => setActivePage('Schedules')}>
                  <div>Schedules</div>
                  <div>{'>'}</div>
                </div>
                <div onClick={() => setActivePage('Assets')}>
                  <div>Assets</div>
                  <div>{'>'}</div>
                </div>
              </div>
            ) : (
              getActivePage()
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductConfiguration;
