import React from "react";
import * as go from 'gojs';
import { ReactDiagram } from "gojs-react";

function ResourceCanvasContent() {
  const $ = go.GraphObject.make;

  var iconPathMap = {};

  const addToIconPathMap = (nodeId, imagePath) => {
    iconPathMap[nodeId] = imagePath;
  };

  const getIconPath = (nodeId) => {
    return iconPathMap[nodeId];
  };

  const hasIconPath = (nodeId) => {
    return nodeId in iconPathMap;
  };

  function getImage(nodeKey) {
    console.log(getIconPath(nodeKey));

    if (hasIconPath(nodeKey)) {
      return getIconPath(nodeKey);
    }

    return "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png";
  }

  const getNewGoJsDiagram = () => {
    return $(
      go.Diagram,
      "myDiagramDiv", // Diagram refers to its DIV HTML element by id
      {
        layout: $(
          go.TreeLayout, // the layout for the entire diagram
          {
            angle: 90,
            arrangement: go.TreeLayout.ArrangementHorizontal,
            isRealtime: false,
          }
        ),
      }
    );
  };

  function getNodeTemplate() {
    return $(
      go.Node,
      "Auto",
      $(
        go.Shape,
        "Rectangle",
        { stroke: null, strokeWidth: 0 },
        new go.Binding("fill", "key")
      ),
      $(
        go.Panel,
        "Horizontal",
        $(
          go.Picture,
          {
            name: "Picture",
            desiredSize: new go.Size(32, 32),
            margin: new go.Margin(6, 8, 6, 10),
          },
          new go.Binding("source", "key", getImage)
        )
      )
    );
  }

  function getGroupTemplate() {
    return $(
      go.Group,
      "Auto",
      {
        // define the group's internal layout
        layout: $(go.TreeLayout, {
          angle: 90,
          arrangement: go.TreeLayout.ArrangementHorizontal,
          isRealtime: false,
        }),
        // the group begins unexpanded;
        // upon expansion, a Diagram Listener will generate contents for the group
        isSubGraphExpanded: false,
        // when a group is expanded, if it contains no parts, generate a subGraph inside of it
        subGraphExpandedChanged: (group) => {
          if (group.memberParts.count === 0) {
            // randomGroup(group.data.key);
          }
        },
      },
      $(go.Shape, "Rectangle", { fill: null, stroke: "gray", strokeWidth: 2 }),
      $(
        go.Panel,
        "Vertical",
        { defaultAlignment: go.Spot.Left, margin: 4 },
        $(
          go.Panel,
          "Horizontal",
          { defaultAlignment: go.Spot.Top },
          // the SubGraphExpanderButton is a panel that functions as a button to expand or collapse the subGraph
          $("SubGraphExpanderButton"),
          $(
            go.Panel,
            "Horizontal",

            $(
              go.Picture,
              {
                name: "Picture",
                desiredSize: new go.Size(32, 32),
                margin: new go.Margin(6, 8, 6, 10),
              },
              new go.Binding("source", "key", getImage)
            )
          ),
          $(
            go.TextBlock,
            { font: "Bold 18px Sans-Serif", margin: 4 },
            new go.Binding("text", "key")
          )
        ),
        // create a placeholder to represent the area where the contents of the group are
        $(go.Placeholder, { padding: new go.Margin(0, 10) })
      ) // end Vertical Panel
    ); // end Group
  }

  function getLinkTemplate() {
    return $(
      go.Link,
      { routing: go.Link.Orthogonal, corner: 10 },
      $(go.Shape, { strokeWidth: 2 }),
      $(go.Shape, { toArrow: "OpenTriangle" })
    );
  }

  function init() {
    go.Diagram.licenseKey = "73f944e5b26231b700ca0d2b113f69ee1bb37b3a9e811ff25e5741f0ef5f68102a9bed7b59db8f90d0ad4ea91d7ad2c9dacd397c9745553db362d18b43e4d6fcb7377ab411584189a40021c29bf87af4f87025f3c1bd74f0d27fd9a2f9b8c5c90ceef38618cb1cab2a790532497ab04ab6";

    go.Diagram = getNewGoJsDiagram();
    go.Diagram.nodeTemplate = getNodeTemplate();
    go.Diagram.linkTemplate = getLinkTemplate();
    go.Diagram.groupTemplate = getGroupTemplate();
    getNodeData02();
  }

  function getNodeData02() {
    go.Diagram.startTransaction("addGroupContents");

    var nodesList = [
      {
        nodeId: "aws account 2783492232",
        isGroupNode: true,
        parent: "",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png",
      },
      {
        nodeId: "vpc01",
        isGroupNode: true,
        parent: "aws account 2783492232",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeCommit_64.png",
      },
      {
        nodeId: "vpc02",
        isGroupNode: true,
        parent: "aws account 2783492232",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png",
      },
      {
        nodeId: "ec2main",
        isGroupNode: false,
        parent: "vpc01",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.png",
      },
      {
        nodeId: "ec2red",
        isGroupNode: false,
        parent: "vpc01",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DynamoDB_64.png",
      },
      {
        nodeId: "ec2blue",
        isGroupNode: false,
        parent: "vpc02",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Neptune_64.png",
      },
      {
        nodeId: "ecgreen",
        isGroupNode: false,
        parent: "vpc02",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.png",
      },
      {
        nodeId: "ec2yellow",
        isGroupNode: false,
        parent: "vpc02",
        imagePath:
          "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Timestream_64%405x.png",
      },
    ];

    nodesList.map((n) => {
      var nodeId = n.nodeId;
      var imgPath = n.imagePath;

      addToIconPathMap(nodeId, imgPath);
      go.Diagram.model.addNodeData({
        key: nodeId,
        isGroup: n.isGroupNode,
        group: n.parent,
      });

      console.log(n.nodeId);
    });

    go.Diagram.commitTransaction("addGroupContents");
  }

  window.addEventListener("DOMContentLoaded", init);

  return (
    <div>
      {/* <div
        id="myDiagramDiv"
        style="height: 600px; width: 100%; border: 1px solid black; position: relative; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); cursor: auto;"
      >
        <canvas
          tabindex="0"
          width="1555"
          height="897"
          style="position: absolute; top: 0px; left: 0px; z-index: 2; user-select: none; touch-action: none; width: 1037px; height: 598px; cursor: auto;"
        >
          This text is displayed if your browser does not support the Canvas
          HTML element.
        </canvas>
        <div style="position: absolute; overflow: auto; width: 1037px; height: 598px; z-index: 1;">
          <div style="position: absolute; width: 1px; height: 1px;"></div>
        </div>
      </div> */}
      <ReactDiagram
          initDiagram={ResourceCanvasContent}
          divClassName='diagram-component'
          // onModelChange={handleModelChange}
      />
    </div>
  );
}

export default ResourceCanvasContent;
