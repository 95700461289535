import React, { useState, useRef } from 'react';
import LeftMenu from './LeftMenu.js';
import { Col, Container, Row } from 'react-bootstrap';

import '../../styles/CostManager.css';
import ResourceComparision from './ResourceComparision/ResourceComparision.js';
import RightArrow from '../../images/RightArrow.svg';
import { Breadcrumb } from 'antd';
import InfraAsCode from './InfraAsCode/InfraAsCode.js';

const DISASTER_RECOVERY_MENU_OPTIONS = [
  { label: 'Resource Comparision', value: 'resource-comparision' },
  { label: 'Infra as Code', value: 'infra-as-code' },
];

const DisasterRecoveryPage = () => {
  const [menuOption, setSelectedMenuOption] = useState(DISASTER_RECOVERY_MENU_OPTIONS[0]?.value);
  const [showMenu, setShowMenu] = useState(true);

  const getComponentToRender = () => {
    switch (menuOption) {
      case 'resource-comparision':
        return <ResourceComparision showMenu={showMenu} setShowMenu={setShowMenu} />;
      case 'infra-as-code':
        return <InfraAsCode />;

      default:
        break;
    }
  };
  return (
    <>
      <Container
        className={`${!showMenu ? 'mt-5' : 'mt-5'} my-profile-page`}
        style={!showMenu ? { maxWidth: '100%', marginTop: '0px !important' } : {}}
      >
        {!showMenu ? (
          <Breadcrumb separator='>'>
            <Breadcrumb.Item onClick={() => setShowMenu((p) => !p)}>Resource comparison</Breadcrumb.Item>
            <Breadcrumb.Item className='bread-crumb-active'>Result</Breadcrumb.Item>
          </Breadcrumb>
        ) : null}

        <Row>
          {showMenu ? (
            <Col xs lg='3'>
              <LeftMenu
                menuOptions={DISASTER_RECOVERY_MENU_OPTIONS}
                selectedOption={menuOption}
                setSelectedOption={setSelectedMenuOption}
              />
            </Col>
          ) : null}
          <Col xs lg={showMenu ? '9' : '12'}>
            {getComponentToRender()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DisasterRecoveryPage;
