import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Modal } from 'react-bootstrap';
import LeftMenu from '../LeftMenu';
import { GetMenuBar } from '../MenubarComponent';
import AddressInput from '../Address/AddressInput';
import {
  GetCognitoUserPoolEmailPasswordAuthTokenInfo,
  GetCognitoUserTokenCredentials,
  GetUserLoginCredentials,
  GetUserLoginCredentialsV2,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from '../UserCredentials';
import { GetAckSetUrl, GetMyProfileUrl, GetResourcesOverviewUiCacheUrl, GetRegionsForAwsKeysUrl } from '../../routes';
import axios from 'axios';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import KeyIcon from '../../images/key.png';
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from '../CognitoUserPoolSignin';
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from '../Login';
import Select, { components } from 'react-select';
import arrowDownIcon from '../../images/arrow-down.png';
import { customDropdownStyles } from '../CostManager/styleUtils';
import { uniqueId } from 'lodash';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownIcon} />
    </components.DropdownIndicator>
  );
};

// let countryVar = "India";
function AwsAccessKeys() {
  const initialKeys = [
    {
      FriendlyAlias: 'production',
      AccountId: 'AccId001',
      AWS_ACCESS_KEY_ID: 'AccessKeyId001',
      AWS_SECRET_ACCESS_KEY: 'SecretAccessKeyId001',
      DefaultRegion: 'US',
    },
    {
      name: 'data-science',
      AWS_ACCOUNT_ID: 'AccId002',
      AWS_ACCESS_KEY_ID: 'AccessKeyId002',
      AWS_SECRET_ACCESS_KEY: 'SecretAccessKeyId002',
      DEFAULT_REGION: 'CANADA',
    },
    {
      name: 'development',
      AWS_ACCOUNT_ID: 'AccId003',
      AWS_ACCESS_KEY_ID: 'AccessKeyId003',
      AWS_SECRET_ACCESS_KEY: 'SecretAccessKeyId003',
      DEFAULT_REGION: 'INDIA',
    },
  ];

  const [keys, setKeys] = useState([]);
  const [regions, setRegions] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);

  const addKeyTolist = () => {};

  const addEditText = () => {};

  const [addkey, setAddKeys] = useState({
    name: '',
    AWS_ACCOUNT_ID: '',
    AWS_ACCESS_KEY_ID: '',
    AWS_SECRET_ACCESS_KEY: '',
    DEFAULT_REGION: '',
  });

  const getAccessKeyDataRead = () => {
    currKey['data_op'] = 'read';
    return currKey;
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = '';

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info = GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getAwsKeysReadPayload = () => {
    let keysReadPayload = {
      concerto_user_credentials: GetLoginCredentials(),
      ack_data: getAccessKeyDataRead(),
    };

    return keysReadPayload;
  };

  const fetchRegionsForAwsKeys = async () => {
    let userCreds = GetUserLoginCredentials()[0];

    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = GetRegionsForAwsKeysUrl();
    const payload = getPayload(userCredentials);

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200 || resp?.data?.message === 'Internal server error') {
        return;
      }
      setRegions(resp.data.aws_regions_list);
      return resp.data.aws_regions_list;
    } catch (err) {
      console.log('fetchDataForSelection to ' + url + ' failed');
    }
  };

  const getAwsKeys = async () => {
    setKeys([]);

    // console.log("+++++++++= getawskeys function ")
    const url = GetAckSetUrl();
    const payload = getAwsKeysReadPayload();

    // console.log("####################  getAwsKeysReadPayload()   #####3232########################")
    // console.log(url)
    // console.log(payload)

    try {
      const resp = await axios.post(url, payload);
      let respData = [];
      Object.keys(resp.data).map((key, index) => {
        respData[index] = resp.data[key];
      });

      setKeys(respData);

      return resp;
    } catch (err) {
      console.log('getAwsKeys to ' + url + ' failed.');
    }
  };

  useEffect(() => {
    // setSelectedRegion(regionList[0])
    getAwsKeys();
    fetchRegionsForAwsKeys();
  }, []);

  const [currKey, setAddKey] = useState({
    name: '',
    AWS_ACCOUNT_ID: '',
    AWS_ACCESS_KEY_ID: '',
    AWS_SECRET_ACCESS_KEY: '',
    DEFAULT_REGION: '',
    REGIONS: [],

    // FriendlyAlias: "",
    // AccountId: "",
    // AWS_ACCESS_KEY_ID: "",
    // AWS_SECRET_ACCESS_KEY: "",
    // DEFAULT_REGION: "",
  });

  // Modal handling
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isAddMode, setIsAddMode] = useState(false);

  const handleEdit = (i) => {
    // i === index
    setAddKey({
      name: keys[i].FriendlyAlias,
      AWS_ACCOUNT_ID: keys[i].AccountId,
      AWS_ACCESS_KEY_ID: keys[i].AWS_ACCESS_KEY_ID,
      AWS_SECRET_ACCESS_KEY: keys[i].AWS_SECRET_ACCESS_KEY,
      DEFAULT_REGION: keys[i].DefaultRegion,
      REGIONS: keys[i]?.Regions,
    });
    setIsAddMode(false);
    handleShow();
  };

  const handleDelete = (i) => {
    const getDeleteAccessKeyData = () => {
      const data = {
        name: keys[i].FriendlyAlias,
        AWS_ACCOUNT_ID: keys[i].AccountId,
        AWS_ACCESS_KEY_ID: keys[i].AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: keys[i].AWS_SECRET_ACCESS_KEY,
        DEFAULT_REGION: keys[i].DefaultRegion,
        REGIONS: keys[i]?.Regions,
        data_op: 'delete',
      };
      return data;
    };

    const getDeletePayload = () => {
      return {
        concerto_user_credentials: GetLoginCredentials(),
        ack_data: getDeleteAccessKeyData(),
      };
    };

    const submitDelete = async (event) => {
      const url = GetAckSetUrl();
      const payload = getDeletePayload();

      try {
        const resp = await axios.post(url, payload);
        console.log(resp);
        if (resp.status === 200) {
          getAwsKeys();
        }
        return resp;
      } catch (err) {
        console.log('submitDelete to ' + url + ' failed');
      }
    };

    submitDelete();
  };

  const handleAdd = () => {
    setAddKey({
      name: '',
      AWS_ACCOUNT_ID: '',
      AWS_ACCESS_KEY_ID: '',
      AWS_SECRET_ACCESS_KEY: '',
      DEFAULT_REGION: '',
      REGIONS: [],
    });
    setIsAddMode(true);
    handleShow();
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAddKey({
      ...currKey,
      [evt.target.name]: value,
    });
    //console.log("currKey", currKey);
  };

  const getAccessKeyData = () => {
    currKey['data_op'] = 'update';
    return currKey;
  };

  const getPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      ack_data: getAccessKeyData(),
    };
  };

  // UPDATE / ADD
  const submitKey = async (event) => {
    // event.preventDefault();
    const url = GetAckSetUrl();

    console.log('################### onAckSetDataSaveRequest >>>>>> ');
    console.log(url);
    console.log('getPayload()', getPayload());
    try {
      const resp = await axios.post(url, getPayload());
      console.log(resp);
      if (resp.status === 200) {
        handleClose();
        getAwsKeys();
      }
      //   return resp
    } catch (err) {
      console.log('onAckSetDataSaveRequest to ' + url + ' failed');
    }
  };

  const onDefaultRegionSelected = (event) => {
    console.log('onDefaultRegionSelected ==> event', event);
    setDefaultAwsRegion(event);

    setAddKey({
      ...currKey,
      DEFAULT_REGION: event,
    });
  };

  let defaultAwsRegion = 'ap-south-1';

  const getDefaultAwsRegion = () => {
    if (currKey.DEFAULT_REGION === '') {
      return 'Select Region';
    }
    return currKey.DEFAULT_REGION;
  };
  const setDefaultAwsRegion = (defaultRegion) => {
    defaultAwsRegion = defaultRegion;
  };

  const onRegionSelected = (options) => {
    setRegionsSelected(options);
    setAddKey({
      ...currKey,
      REGIONS: options.map((i) => i.RegionCode) || [],
    });
  };

  const getRegionsDropDown = () => {
    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Regions</div>
        <Select
          getOptionLabel={(option) => option.RegionName + ' ' + option.RegionCode}
          getOptionValue={(option) => option.RegionCode}
          onChange={(e) => {
            onRegionSelected(e);
          }}
          options={regions}
          className='basic-multi-select'
          classNamePrefix='select'
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          name='Regions_Aws_Keys'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  const getKeysElements = () => {
    if (!keys) return <div></div>;

    if (keys.length < 1) return <div></div>;

    const getKeyItem = (key, index) => {
      if (!key) return <div key={uniqueId()}> </div>;

      if (!key.AccountId || !key.DefaultRegion || !key.FriendlyAlias) {
        return <div key={uniqueId()}> </div>;
      }

      return (
        <div className='single-key d-flex' key={uniqueId()}>
          <div className='image'>
            <img src={KeyIcon} />
          </div>
          <div className='key-detail'>
            <span className='bold text-capitalize'>{key?.FriendlyAlias}</span>
            <span>{key?.AccountId}</span>
            <span>{key?.DefaultRegion}</span>
          </div>
          <div className='events ml-auto'>
            <button className='update' onClick={() => handleEdit(index)}>
              Edit
            </button>
            <button className='delete' onClick={() => handleDelete(index)}>
              Delete
            </button>
          </div>
        </div>
      );
    };

    return <div key={uniqueId()}>{keys.map((key, index) => getKeyItem(key, index))}</div>;
  };

  return (
    <div>
      {GetMenuBar('user_logged_in')}

      <Container className='small mt-5'>
        <Row>
          <Col xs lg='3'>
            <LeftMenu />
          </Col>

          <Col xs lg='9'>
            <div className='addKey'>
              <div className='access-key-title d-flex justify-content-between'>
                <h3>AWS Access Keys</h3>
                <button className='add-key ml-auto' onClick={handleAdd}>
                  + ADD NEW
                </button>
              </div>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>AWS Access Keys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='add-key-form'>
                    <Form>
                      <Form.Group>
                        <Form.Control type='text' name='name' value={currKey.name} onChange={handleChange} placeholder='Enter Name' />
                        <Form.Text className='text-muted'></Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          name='AWS_ACCOUNT_ID'
                          value={currKey.AWS_ACCOUNT_ID}
                          onChange={handleChange}
                          placeholder='Enter Account Id'
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          name='AWS_ACCESS_KEY_ID'
                          value={currKey.AWS_ACCESS_KEY_ID}
                          onChange={handleChange}
                          placeholder='Enter Access Key Id'
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          name='AWS_SECRET_ACCESS_KEY'
                          value={currKey.AWS_SECRET_ACCESS_KEY}
                          onChange={handleChange}
                          placeholder='Enter Secret Access Key'
                        />
                      </Form.Group>
                      <br />
                      <div className='m-b-5'>{getRegionsDropDown()}</div>
                      <p>Default Region</p>
                      <DropdownButton
                        alignRight
                        title={getDefaultAwsRegion()}
                        id='dropdown-menu-align-right'
                        onSelect={onDefaultRegionSelected}
                        defaultValue={getDefaultAwsRegion()}
                      >
                        {regionList.map((region, index) => (
                          <Dropdown.Item eventKey={region.code} key={uniqueId()}>
                            {' '}
                            {region.name}{' '}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <Button style={{ float: 'right' }} variant='primary' onClick={submitKey}>
                        {isAddMode === true ? 'Add' : 'Edit'}
                      </Button>
                    </Form>
                  </div>
                </Modal.Body>
              </Modal>

              <div className='access-keys-page'>
                <div className='access-key-details'>{getKeysElements()}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const regionList = [
  {
    code: 'us-east-2',
    name: 'US East (Ohio)',
  },
  {
    code: 'us-east-1',
    name: 'US East (N. Virginia)',
  },
  {
    code: 'us-west-1',
    name: 'US West (N. California)',
  },
  {
    code: 'us-west-2',
    name: 'US West (Oregon)',
  },
  {
    code: 'af-south-1',
    name: 'Africa (Cape Town)',
  },
  {
    code: 'ap-east-1',
    name: 'Asia Pacific (Hong Kong)',
  },
  {
    code: 'ap-southeast-3',
    name: 'Asia Pacific (Jakarta)',
  },
  {
    code: 'ap-south-1',
    name: 'Asia Pacific (Mumbai)',
  },
  {
    code: 'ap-northeast-3',
    name: 'Asia Pacific (Osaka)',
  },
  {
    code: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
  },
  {
    code: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
  },
  {
    code: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
  },
  {
    code: 'ap-northeast-1',
    name: 'Asia Pacific (Tokyo)',
  },
  {
    code: 'ca-central-1',
    name: 'Canada (Central)',
  },
  {
    code: 'eu-central-1',
    name: 'Europe (Frankfurt)',
  },
  {
    code: 'eu-west-1',
    name: 'Europe (Ireland)',
  },
  {
    code: 'eu-west-2',
    name: 'Europe (London)',
  },
  {
    code: 'eu-south-1',
    name: 'Europe (Milan)',
  },
  {
    code: 'eu-west-3',
    name: 'Europe (Paris)',
  },
  {
    code: 'eu-north-1',
    name: 'Europe (Stockholm)',
  },
  {
    code: 'me-south-1',
    name: 'Middle East (Bahrain)',
  },
  {
    code: 'sa-east-1	South',
    name: 'America (São Paulo)',
  },
];

export default AwsAccessKeys;
