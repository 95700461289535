


export const linkButtonSelectedInSidebarReducer = (state, action) => {
    console.log("linkButtonSelectedInSidebarReducer")
    console.log(action)
    return {
        ...state,
        currentAction: action,
        sideBarInfo: {
            desc: "linkButtonSelectedInSidebarReducer",
            payload: action.payload
        }
    }
}

export const linkActionCancelButtonSelectedInSidebarReducer = (state, action) => {
    console.log("linkActionCancelButtonSelectedInSidebarReducer")
    console.log(action)
    return {
        ...state,
        currentAction: action,
        sideBarInfo: {
            desc: "linkActionCancelButtonSelectedInSidebarReducer",
            payload: action.payload
        }
    }
}
