import React from 'react';
import Chart from 'react-apexcharts';

function roundDecimal(value) {
  return typeof value === 'number' ? value.toFixed(2) : 0;
}

const FORMATTER_FUNCTIONS = {
  roundDecimal,
};

const LineChart = ({ data, seriesArray, timeStampArray, formatterFunction }) => {
  const options = {
    chart: {
      type: 'line',
    },
    series: seriesArray || [
      {
        name: 'Value',
        data: data.map(({ value }) => value || 0),
      },
    ],
    xaxis: {
      type: 'datetime',
      categories: timeStampArray || data.map(({ date }) => date),
    },
    stroke: {
      width: 2,
    },
    yaxis: {
      labels: FORMATTER_FUNCTIONS[formatterFunction]
        ? {
            formatter: FORMATTER_FUNCTIONS[formatterFunction],
          }
        : {},
    },
  };

  return <Chart options={options} series={options.series} type='line' height='250px' />;
};

export default LineChart;
