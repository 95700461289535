import React from "react";
import { Button } from "react-bootstrap";
import Success from "../images/success.png";
// import Failure from "../images/failure.png";
import { GetMenuBar } from "./MenubarComponent";

function PaymentsSuccess() {
  return (
    <>
      {GetMenuBar()}
      <div className="payment-status success text-center">
        <img src={Success} />
        <h2>Payment Successful</h2>
        <p>
          Congratulations! You have successfully subscribed to the pro plan.
        </p>
        <div className="buttons d-flex flex-column">
          <Button
            className="custom-button filled-button"
            variant="primary"
            type=""
            // onClick={addKeyTolist}
          >
            Go to Drawingboard
          </Button>
          <Button
            className="custom-button white-button"
            variant="primary"
            type=""
            // onClick={addKeyTolist}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}

export default PaymentsSuccess;
