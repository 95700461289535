export const LINE_CHART_MOCK_DATA = {
  111124716966: [
    {
      date: '2022-09-30',
      value: 0,
    },
    {
      date: '2022-10-31',
      value: 0,
    },
    {
      date: '2022-11-30',
      value: 0,
    },
    {
      date: '2022-12-31',
      value: 0,
    },
    {
      date: '2023-01-31',
      value: 0.16,
    },
    {
      date: '2023-02-28',
      value: 0.45,
    },
  ],
  111166222061: [
    {
      date: '2022-09-30',
      value: 0,
    },
    {
      date: '2022-10-31',
      value: 0,
    },
    {
      date: '2022-11-30',
      value: 0,
    },
    {
      date: '2022-12-31',
      value: 0,
    },
    {
      date: '2023-01-31',
      value: 0.29,
    },
    {
      date: '2023-02-28',
      value: 0.54,
    },
  ],
  111153660299: [
    {
      date: '2022-09-30',
      value: 619.89,
    },
    {
      date: '2022-10-31',
      value: 600.07,
    },
    {
      date: '2022-11-30',
      value: 597.67,
    },
    {
      date: '2022-12-31',
      value: 603.08,
    },
    {
      date: '2023-01-31',
      value: 658.32,
    },
    {
      date: '2023-02-28',
      value: 636.88,
    },
  ],
};
