import React, { useCallback, useEffect } from 'react';
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';

import { MarkerType } from 'reactflow';

const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

const OverviewFlow = ({ currentNodeInfo, mappedSqsNodes }) => {

  const initialNodes = [
    {
      id: '1',
      data: {
        label: 
        <>
        <div>
          <img
            style={{
              height: '2vw',
              borderRadius: '0.4vw',
            }}
            src='https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64.png'
            alt=''
          />
        </div>
        <div>{currentNodeInfo?.node_data.nodeId}</div>
      </> 
      },
      position: { x: 400, y: 300 },
      style: {
        background: '#D6D5E6',
        color: '#333',
        border: '1px solid #222138',
        width: 180,
      },
    },
    ...mappedSqsNodes.map((eachSqsNode, idx) => {
      const resourceName = eachSqsNode?.nodeContextInfo?.Attributes?.QueueArn?.split(':').pop();
      return {
        id: (idx + 2).toString(),
        data: {
          label: (
            <>
              <div>
                <img
                  style={{
                    height: '2vw',
                    borderRadius: '0.4vw',
                  }}
                  src={eachSqsNode.iconUrl}
                  alt=''
                />
              </div>
              <div>{resourceName}</div>
            </>
          ),
        },
        position: { x: 300, y: (idx+1) * 100 },
      };
    }),
  ];

  const initialEdges = [
    { id: 'e1-1', source: '2', target: '1',
    markerEnd: {
      type: MarkerType.Arrow,
      width: 40,
      height: 40,
    },
    animated: true,
    label: 'SQS'

  },
    ...mappedSqsNodes.map((eachSqsNode, idx) => {
      return {
        id: (idx + 2).toString(),
        source: (idx + 2).toString(),
        target: '1',
        markerEnd: {
          type: MarkerType.Arrow,
          width: 40,
          height: 40,
        },
        animated: true,
        label: 'SQS'

      };
    }),
  ];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  useEffect(()=>{
    setNodes(initialNodes);
    setEdges(initialEdges);
  },[mappedSqsNodes]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onInit={onInit}
      fitView
      attributionPosition='top-right'
    >
      <Controls />
      <Background color='#aaa' gap={16} />
    </ReactFlow>
  );
};

export default OverviewFlow;
