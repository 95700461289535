import {RDS} from "../ResourceEditorCanvas";

export const GET_RDS_ENGINE_UI_LIST_ITEMS = () => {

    let engine_ui_list = []

    for (let i=0; i<RDS_ENGINE_LIST.length; i++) {
        let engine_info = RDS_ENGINE_LIST[i]
        if(engine_info.is_enabled === "false")
            continue
        let e = engine_info.engine + " " + engine_info.engine_version
        engine_ui_list.push(e)
    }

    return engine_ui_list
}


export const GET_RDS_INSTANCE_TYPES_LIST = () => {
    return [
        "db.t3.micro"
    ]
}

export const RDS_ENGINE_LIST = [
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.12",
        "is_enabled": "true"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.03.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.03.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.03.4",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.4",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.5",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.6",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.7",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.8",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.04.9",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.05.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.06.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.4",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.5",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.6",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.07.7",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.08.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.08.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.08.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.08.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.09.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.09.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.09.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.09.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.10.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "5.7.mysql_aurora.2.10.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-mysql",
        "engine_version": "8.0.mysql_aurora.3.01.0",
        "is_enabled": "false"
    },
    {
        "engine": "docdb",
        "engine_version": "3.6.0",
        "is_enabled": "true"
    },
    {
        "engine": "docdb",
        "engine_version": "4.0.0",
        "is_enabled": "true"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.2.1",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.2.2",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.3.0",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.4.0",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.4.1",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.4.2",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.5.0",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.0.5.1",
        "is_enabled": "false"
    },
    {
        "engine": "neptune",
        "engine_version": "1.1.0.0",
        "is_enabled": "true"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.11",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.12",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.13",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.14",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.14",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.16",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.17",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "10.18",
        "is_enabled": "true"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.6",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.7",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.8",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.9",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.11",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.12",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "11.13",
        "is_enabled": "true"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "12.4",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "12.6",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "12.7",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "12.8",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "13.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora-postgresql",
        "engine_version": "13.4",
        "is_enabled": "true"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.2.32",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.2.37",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.2.39",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.2.40",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.2.41",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.3.23",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.3.28",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.3.31",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.3.32",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.4.13",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.4.18",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.4.21",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.4.22",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.5.12",
        "is_enabled": "false"
    },
    {
        "engine": "mariadb",
        "engine_version": "10.5.13",
        "is_enabled": "true"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.35",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.37",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.39",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.40",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.41",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.43",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.44",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.46",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.48",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.49",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.6.51",
        "is_enabled": "true"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.16",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.17",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.19",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.21",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.22",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.23",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.24",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.25",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.26",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.28",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.30",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.31",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.33",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.34",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "5.7.36",
        "is_enabled": "true"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.11",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.13",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.15",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.16",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.17",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.19",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.20",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.21",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.23",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.25",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.26",
        "is_enabled": "false"
    },
    {
        "engine": "mysql",
        "engine_version": "8.0.27",
        "is_enabled": "true"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v2",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v3",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v4",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v5",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v6",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v7",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v8",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v9",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v10",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v11",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v12",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v13",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v14",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v15",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v16",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v17",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v18",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v19",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v20",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v21",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v22",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v23",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v24",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.1.0.2.v25",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2018-10.rur-2018-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2019-01.rur-2019-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2019-04.rur-2019-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2019-07.rur-2019-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2019-10.rur-2019-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2020-01.rur-2020-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2020-04.rur-2020-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2020-07.rur-2020-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2020-10.rur-2020-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2021-01.rur-2021-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "12.2.0.1.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2019-07.rur-2019-07.r1",
        "is_enabled": "true"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2019-10.rur-2019-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2020-01.rur-2020-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2020-04.rur-2020-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2020-07.rur-2020-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2020-10.rur-2020-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2021-01.rur-2021-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2021-01.rur-2021-01.r2",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee",
        "engine_version": "19.0.0.0.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee-cdb",
        "engine_version": "19.0.0.0.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee-cdb",
        "engine_version": "19.0.0.0.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-ee-cdb",
        "engine_version": "19.0.0.0.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "true"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v2",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v3",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v4",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v5",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v6",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v7",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v8",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v9",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v10",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v11",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v12",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v13",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v14",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v15",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v16",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v17",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v18",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v19",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v20",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v21",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v22",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v23",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v24",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.1.0.2.v25",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2018-10.rur-2018-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2019-01.rur-2019-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2019-04.rur-2019-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2019-07.rur-2019-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2019-10.rur-2019-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2020-01.rur-2020-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2020-04.rur-2020-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2020-07.rur-2020-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2020-10.rur-2020-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2021-01.rur-2021-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "12.2.0.1.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2019-07.rur-2019-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2019-10.rur-2019-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2020-01.rur-2020-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2020-04.rur-2020-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2020-07.rur-2020-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2020-10.rur-2020-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2021-01.rur-2021-01.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2021-01.rur-2021-01.r2",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2",
        "engine_version": "19.0.0.0.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2-cdb",
        "engine_version": "19.0.0.0.ru-2021-04.rur-2021-04.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2-cdb",
        "engine_version": "19.0.0.0.ru-2021-07.rur-2021-07.r1",
        "is_enabled": "false"
    },
    {
        "engine": "oracle-se2-cdb",
        "engine_version": "19.0.0.0.ru-2021-10.rur-2021-10.r1",
        "is_enabled": "true"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.10a",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.10a",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.10a",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.10a",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.17.9",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.5",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.5.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.19.6",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.20.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.20.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.21.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.4",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.22.5",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.23.0",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.23.1",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.23.2",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.23.3",
        "is_enabled": "false"
    },
    {
        "engine": "aurora",
        "engine_version": "5.6.mysql_aurora.1.23.4",
        "is_enabled": "true"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.1",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.2",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.3",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.5",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.6",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.8",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.9",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.10",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.11",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.12",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.14",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.15",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.16",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.17",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.18",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.19",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.20",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.21",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.22",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "9.6.23",
        "is_enabled": "true"
    },
    {
        "engine": "postgres",
        "engine_version": "10.1",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.3",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.4",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.5",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.6",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.7",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.9",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.10",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.11",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.12",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.13",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.14",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.15",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.16",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.17",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "10.18",
        "is_enabled": "true"
    },
    {
        "engine": "postgres",
        "engine_version": "11.1",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.2",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.4",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.5",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.6",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.7",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.8",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.9",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.10",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.11",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.12",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "11.13",
        "is_enabled": "true"
    },
    {
        "engine": "postgres",
        "engine_version": "12.2",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.3",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.4",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.5",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.6",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.7",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "12.8",
        "is_enabled": "true"
    },
    {
        "engine": "postgres",
        "engine_version": "13.1",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "13.2",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "13.3",
        "is_enabled": "false"
    },
    {
        "engine": "postgres",
        "engine_version": "13.4",
        "is_enabled": "true"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "12.00.5571.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "12.00.6293.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "12.00.6329.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "13.00.4422.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "13.00.5598.27.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "13.00.5820.21.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "13.00.5850.14.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "13.00.5882.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "14.00.3281.6.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "14.00.3294.2.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "14.00.3356.20.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "14.00.3381.3.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "14.00.3401.7.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "15.00.4043.16.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "15.00.4073.23.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ee",
        "engine_version": "15.00.4153.1.v1",
        "is_enabled": "true"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "12.00.5571.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "12.00.6293.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "12.00.6329.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "13.00.4422.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "13.00.5598.27.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "13.00.5820.21.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "13.00.5850.14.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "13.00.5882.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "14.00.3281.6.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "14.00.3294.2.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "14.00.3356.20.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "14.00.3381.3.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "14.00.3401.7.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "15.00.4043.16.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "15.00.4073.23.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-ex",
        "engine_version": "15.00.4153.1.v1",
        "is_enabled": "true"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "12.00.5571.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "12.00.6293.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "12.00.6329.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "13.00.4422.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "13.00.5598.27.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "13.00.5820.21.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "13.00.5850.14.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "13.00.5882.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "14.00.3281.6.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "14.00.3294.2.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "14.00.3356.20.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "14.00.3381.3.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "14.00.3401.7.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "15.00.4043.16.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "15.00.4073.23.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-se",
        "engine_version": "15.00.4153.1.v1",
        "is_enabled": "true"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "12.00.5571.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "12.00.6293.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "12.00.6329.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "13.00.4422.0.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "13.00.5598.27.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "13.00.5820.21.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "13.00.5850.14.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "13.00.5882.1.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "14.00.3281.6.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "14.00.3294.2.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "14.00.3356.20.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "14.00.3381.3.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "14.00.3401.7.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "15.00.4043.16.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "15.00.4073.23.v1",
        "is_enabled": "false"
    },
    {
        "engine": "sqlserver-web",
        "engine_version": "15.00.4153.1.v1",
        "is_enabled": "true"
    }
]