
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";

export const VpnGatewayContextShowInfoBarHandler = () => {

    let resourceProperties = [
        "VpnGatewayId",
        "State",
        "Type",
        "AmazonSideAsn",
        "VpcAttachments"
    ]

    const displayFields = [  ]
    const title = "Vpn Gateway"

    for (let i=0 ; i<resourceProperties.length; i++) {
        let labelStr = resourceProperties[i]
        let keyStr = resourceProperties[i]
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr))
    }

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}

export const VpnGatewayContextShowInfoBarHandlerV1 = () => {
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()

    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
    let imgPath = currentContextBarNodeInfo.node_data.imgPath

    let resourceProperties = [
        "VpnGatewayId",
        "State",
        "Type",
        "AmazonSideAsn",
        "VpcAttachments"
    ]


    const resourcePropertyBody = resourceProperties.map((p) =>
        <tr key={uniqid()}>
            <td>{p}</td>
            <td>{nodeContextInfo[p]}</td>
        </tr>
    );


    return (
        <div>
            <h1><img src={imgPath} width="48" height="48"/> ec2 </h1>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
                </thead>

                <tbody>
                {resourcePropertyBody}
                </tbody>

            </Table>
        </div>
    )
}

//
//
