// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Approutes from './Approutes';
import './styles/global.css';
import 'font-awesome/css/font-awesome.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmationModalProvider } from './context/ConfirmationModalContext';
import LandingPage from './landingPage/LandingPage';
import Security from './landingPage/pages/Security/Security';

const App = () => {
  return (
    <div className='App'>
      {/*<div className='corner-ribbon top-left sticky maroon shadow'>Alpha 0.5</div>*/}
      {/* <div className="stripe">
          <span>Website Under construction</span>
        </div> */}
      <ToastContainer />
      <ConfirmationModalProvider>
        <Router>
          <Approutes />
        </Router>
      </ConfirmationModalProvider>
    </div>
  );
};

export default App;

//
