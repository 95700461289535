import React, { useEffect, useState } from 'react';
import LogoWithTextImg from '../../assets/LogoWithText.svg';
import { useHistory } from 'react-router-dom';
import {
  Header,
  HamburgerCon,
  LinksAndButtons,
  Link,
  MenuCon,
  LogoImg,
  PrimaryBtn,
  SecondaryBtn,
  ButtonCon,
  LogoWithText,
  CTAButtonCon
} from "./skins";

export const PATHS = {
  PRICING: '/pricing',
  USE_CASES: '/usecases',
  SEE_IT_LIVE: '/cgupsignup',
  SECURITY: '/security',
  CONTACT: '/contact-us',
  LOGIN: '/cguplogin',
  SIGNUP: '/cgupsignup',
  HOME: '/',
  RESET_PASSWORD: '/forgotpassword',
};

const Navbar = () => {
  const history = useHistory();
  const navigateTo = (path) => history.push(path);
  const [isNavExpanded, setNavExpanded] = useState(false);
  const fn = () => {
    setNavExpanded(false);
  }
  useEffect(() => {
    window.addEventListener('resize', fn)
  },[])

  return (
    <Header isNavExpanded={isNavExpanded}>
      <LogoWithText>
        <LogoImg onClick={() => navigateTo(PATHS.HOME)} isNavExpanded={isNavExpanded} src={LogoWithTextImg} alt='Bluefinware' />
        <HamburgerCon isNavExpanded={isNavExpanded} onClick={() => setNavExpanded(!isNavExpanded)}>
          <div class="hamburger ham1" />
          <div class="hamburger ham2" />
          <div class="hamburger ham3" />
        </HamburgerCon>
      </LogoWithText>
      <MenuCon isNavExpanded={isNavExpanded}>
        <LinksAndButtons>
          <Link onClick={() => navigateTo(PATHS.USE_CASES)}>
            USECASES
          </Link>
          <Link onClick={() => navigateTo(PATHS.SEE_IT_LIVE)}>
            SEE IT LIVE!
          </Link>
          <Link onClick={() => navigateTo(PATHS.PRICING)}>
            PRICING
          </Link>
          <Link onClick={() => navigateTo(PATHS.SECURITY)}>
            SECURITY
          </Link>
          <Link className="last-menu-item" onClick={() => navigateTo(PATHS.CONTACT)}>
            CONTACT
          </Link>
          <CTAButtonCon>
            <ButtonCon onClick={() => navigateTo(PATHS.LOGIN)}>
              <PrimaryBtn>LOGIN</PrimaryBtn>
            </ButtonCon>
            <ButtonCon onClick={() => navigateTo(PATHS.SIGNUP)}>
              <SecondaryBtn>SIGNUP</SecondaryBtn>
            </ButtonCon>
          </CTAButtonCon>
        </LinksAndButtons>
      </MenuCon>
    </Header>
  );
};

export default Navbar;