import { GetUserLoginCredentials, GetUserLoginToken } from '../UserCredentials';
import { GetCostPlannerUrl, GetEc2ParamsOptionsUrl } from '../../routes';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import uniqid from 'uniqid';
import { customDropdownStyles } from './styleUtils';

import arrowDownIcon from '../../images/arrow-down.png';
import '../../styles/spaces.css';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownIcon} />
    </components.DropdownIndicator>
  );
};

const removeKeysStartingWith =(obj, prefix)=> {
  const result = {};

  for (const key in obj) {
    if (!key.startsWith(prefix)) {
      result[key] = obj[key];
    }
  }

  return result;
}

const Ec2CostParamsChooser = ({ ec2CostParams, setEC2CostParams }) => {
  let [v1, setV1] = useState(0);
  console.log({ v1 });
  let [ec2InstanceFamilySelectionOptionsInfo, setEc2InstanceFamilySelectionOptionsInfo] = useState({});
  let [ec2InstanceRamTypesSelectionOptionsInfo, setEc2InstanceRamTypesSelectionOptionsInfo] = useState({});
  let [ec2InstanceVcpuTypesSelectionOptionsInfo, setEc2InstanceVcpuTypesSelectionOptionsInfo] = useState({});
  let [ec2InstanceNetworkPerformanceTypesSelectionOptionsInfo, setEc2InstanceNetworkPerformanceTypesSelectionOptionsInfo] = useState({});

  let commandArg = btoa('{"resource_type": "aws_ec2_instance"}');

  const getPayload = (userCredentials) => {
    return {
      concerto_user_credentials: userCredentials,
      command_to_execute: 'get_resources_info',
      command_args: {},
      command_args_as_str: commandArg,
    };
  };

  let resourceParamsData = null;

  const fetchDataForSelection = async () => {
    let userCreds = GetUserLoginCredentials()[0] || {};

    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = GetEc2ParamsOptionsUrl();
    const payload = getPayload(userCredentials);

    try {
      const resp = await axios.post(url, payload);
      if (resp.status != 200) {
        return;
      }
      resourceParamsData = resp.data;

      let instancesTypesInfo = resourceParamsData['instances_types_info'];
      let instancesTypesInfoObj = JSON.parse(instancesTypesInfo);
      setEc2InstanceFamilySelectionOptionsInfo(instancesTypesInfoObj);

      let ramTypesInfo = resourceParamsData['ram_types_info'];
      let ramTypesInfoObj = JSON.parse(ramTypesInfo);
      setEc2InstanceRamTypesSelectionOptionsInfo(ramTypesInfoObj);

      let vcpuTypesInfo = resourceParamsData['vcpu_types_info'];
      let vcpuTypesInfoObj = JSON.parse(vcpuTypesInfo);
      setEc2InstanceVcpuTypesSelectionOptionsInfo(vcpuTypesInfoObj);

      let networkPerformanceTypesInfo = resourceParamsData['network_performance_types_info'];
      let networkPerformanceTypesInfoObj = JSON.parse(networkPerformanceTypesInfo);
      setEc2InstanceNetworkPerformanceTypesSelectionOptionsInfo(networkPerformanceTypesInfoObj);

      //return resp.data
    } catch (err) {
      console.log('Ec2CostParamsChooser.fetchDataForSelection to ' + url + ' failed');
    }
  };

  useEffect(() => {
    fetchDataForSelection();
  }, []);

  const INSTANCE_FAMILY_TYPE_DROP_DOWN = 'INSTANCE_FAMILY_TYPE_DROP_DOWN_VAL';
  console.log({ ec2CostParams });
  const familyTypeSelected = Boolean(Object.keys(ec2CostParams.checkBoxMap).length || Object.keys(ec2CostParams.instancesTypeMap).length);
  const vcpuSelected = Boolean(
    Object.keys(ec2CostParams.ramTypesMap).length ||
      Object.keys(ec2CostParams.vcpuTypesMap).length ||
      Object.keys(ec2CostParams.neworkPerformanceTypesMap).length
  );

  const checkBoxClick = (e) => {
    e.preventDefault();
    let id = e.target.id;
    let checked = e.target.checked;

    const { checkBoxMap, instancesTypeMap } = ec2CostParams;
    if (!(id in checkBoxMap)) {
      console.log('checkBoxMap[id] = true');
      checkBoxMap[id] = true;
      console.log('***********',checkBoxMap);
      const checkBoxMapKeys = Object.keys(checkBoxMap);
      setV1(v1 + 1);
      setEC2CostParams((p) => ({ ...p, checkBoxMap }));
      console.log(checkBoxMapKeys);
      return;
    }

    if (checkBoxMap[id] === true) {
      delete checkBoxMap[id];
      const processedInstanceTypes = removeKeysStartingWith({ ...instancesTypeMap }, id);

      setEC2CostParams((p) => ({ ...p, checkBoxMap, instancesTypeMap: processedInstanceTypes }));
      setV1(v1 + 1);
      return;
    }
    checkBoxMap[id] = !checkBoxMap[id];
    setV1(v1 + 1);
    setEC2CostParams((p) => ({ ...p, checkBoxMap }));
  };

  const getInstanceFamilyChooser = (_ec2SelectionOptionsInfo) => {
    console.log('getInstanceFamilyChooser');
    const familyTypes = [...new Set(Object.keys(ec2InstanceFamilySelectionOptionsInfo))];

    if (familyTypes.length < 1) return <></>;

    return (
      <div>
        <h2> Instance Family Types </h2>
        <Form>
          <div key={'inline-checkbox'} className='mb-3'>
            {familyTypes.map((familyType, index) => {
              let isChecked = false;
              isChecked = ec2CostParams.checkBoxMap[familyType] === true;
              return (
                <div key={uniqid()} className='inline-block w-150px p-r-5'>
                  <Form.Check
                    inline
                    type={'checkbox'}
                    id={familyType}
                    label={familyType}
                    key={uniqid()}
                    onChange={checkBoxClick}
                    defaultChecked={isChecked}
                    disabled={vcpuSelected}
                  />
                </div>
              );
            })}
          </div>
        </Form>
      </div>
    );
  };

  const instanceTypecheckBoxClick = (e) => {
    let id = e.target.id;
    const { instancesTypeMap } = ec2CostParams;
    if (!(id in instancesTypeMap)) {
      instancesTypeMap[id] = true;
      setEC2CostParams((p) => ({ ...p, instancesTypeMap }));
      return;
    }
    if (instancesTypeMap[id] === true) {
      delete instancesTypeMap[id];
      setEC2CostParams((p) => ({ ...p, instancesTypeMap }));
      return;
    }
    //instancesTypeMap[id] = !instancesTypeMap[id]
  };

  const getInstanceTypes = (familyType, instancesTypeList) => {
    let cl = [];
    let instanceTypeSet = [...new Set(instancesTypeList)];

    instanceTypeSet.map((instanceType, index) => {
      let isChecked = false;
      isChecked = ec2CostParams.instancesTypeMap[instanceType] === true;

      cl.push(
        <div key={instanceType} className='inline-block w-150px p-r-5'>
          <Form.Check
            inline
            type={'checkbox'}
            id={instanceType}
            label={instanceType}
            key={uniqid()}
            onChange={instanceTypecheckBoxClick}
            defaultChecked={isChecked}
            disabled={vcpuSelected}
          />
        </div>
      );
    });

    return (
      <div key={familyType}>
        <h6> {familyType}</h6>
        {cl}
      </div>
    );
  };

  const getInstanceTypeChooser = () => {
    console.log('getInstanceTypeChooser');
    const checkBoxMapKeys = Object.keys(ec2CostParams.checkBoxMap);
    return (
      <>
        {checkBoxMapKeys && checkBoxMapKeys.length ? <h6> Instance Types </h6> : null}
        <Form>
          {checkBoxMapKeys.map((familyType, index) => {
            let instancesTypeList = ec2InstanceFamilySelectionOptionsInfo[familyType];
            return getInstanceTypes(familyType, instancesTypeList);
          })}
        </Form>
      </>
    );
  };

  const onRamTypeSelected = (item, e) => {
    console.log();
    let id = e.target.id;
    const { ramTypesMap } = ec2CostParams;
    if (ramTypesMap[id] == true) {
      delete ramTypesMap[id];
      console.log(ramTypesMap);
      setEC2CostParams((p) => ({ ...p, ramTypesMap }));

      return;
    }
    ramTypesMap[id] = true;
    setEC2CostParams((p) => ({ ...p, ramTypesMap }));
    console.log(ramTypesMap);
  };

  const getRAMChooser = (_ec2SelectionOptionsInfo) => {
    const curTypes = [...new Set(Object.keys(ec2InstanceRamTypesSelectionOptionsInfo).sort((a, b) => parseFloat(a) - parseFloat(b)))];

    if (curTypes.length < 1) return <></>;

    return (
      <>
        <h6>RAM Type</h6>
        <Form>
          {curTypes.map((ramType, index) => {
            let isChecked = false;
            isChecked = ec2CostParams.ramTypesMap[ramType] === true;
            return (
              <div key={uniqid()} className='inline-block w-150px p-r-5'>
                <Form.Check
                  inline
                  type={'checkbox'}
                  id={ramType}
                  label={ramType}
                  key={uniqid()}
                  onClick={(e) => {
                    onRamTypeSelected(INSTANCE_FAMILY_TYPE_DROP_DOWN, e);
                  }}
                  defaultChecked={isChecked}
                  disabled={familyTypeSelected}
                />
              </div>
            );
          })}
        </Form>
      </>
    );
  };

  const onVcpuTypeSelected = (item, options) => {
    let { vcpuTypesMap } = ec2CostParams;
    if (options && options.length === 0) {
      vcpuTypesMap = {};
      setEC2CostParams((p) => ({ ...p, vcpuTypesMap }));
      return;
    }
    options.forEach((vcpuType) => {
      console.log();
      let id = parseInt(vcpuType.value);
      if (vcpuTypesMap[id] == true) {
        //delete vcpuTypesMap[id]
        console.log(vcpuTypesMap);
        setEC2CostParams((p) => ({ ...p, vcpuTypesMap }));
        return;
      }
      vcpuTypesMap[id] = true;
      setEC2CostParams((p) => ({ ...p, vcpuTypesMap }));
      console.log(vcpuTypesMap);
    });
  };

  const getVCPUChooser = (_ec2SelectionOptionsInfo) => {
    const curTypes = Object.keys(ec2InstanceVcpuTypesSelectionOptionsInfo).map((vCpuType) => {
      return { label: vCpuType, value: vCpuType };
    });

    if (curTypes.length < 1) return <></>;

    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>VCPU Type</div>
        <Select
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(e) => {
            onVcpuTypeSelected(INSTANCE_FAMILY_TYPE_DROP_DOWN, e);
          }}
          options={curTypes}
          className='basic-multi-select'
          classNamePrefix='select'
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          name='VCPU Type'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
          isDisabled={familyTypeSelected}
        />
      </div>
    );
  };

  const onNeworkPerformanceTypeSelected = (item, options) => {
    let { neworkPerformanceTypesMap } = ec2CostParams;
    if (options && options.length === 0) {
      neworkPerformanceTypesMap = {};
      setEC2CostParams((p) => ({ ...p, neworkPerformanceTypesMap }));
      return;
    }
    options.forEach((networkPerformanceType) => {
      console.log();
      let id = networkPerformanceType.value;
      if (neworkPerformanceTypesMap[id] == true) {
        // delete neworkPerformanceTypesMap[id]
        setEC2CostParams((p) => ({ ...p, neworkPerformanceTypesMap }));
        console.log(neworkPerformanceTypesMap);
        return;
      }
      neworkPerformanceTypesMap[id] = true;
      setEC2CostParams((p) => ({ ...p, neworkPerformanceTypesMap }));
      console.log(neworkPerformanceTypesMap);
    });
  };

  const getNetworkChooser = (_ec2SelectionOptionsInfo) => {
    const curTypes = Object.keys(ec2InstanceNetworkPerformanceTypesSelectionOptionsInfo).map((networkPerfmType) => {
      return { label: networkPerfmType, value: networkPerfmType };
    });

    if (curTypes.length < 1) return <></>;

    return (
      <div className='inline-block'>
        <div className='m-b-5 fs-14'>Network Performance Type</div>
        <Select
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(e) => {
            onNeworkPerformanceTypeSelected(INSTANCE_FAMILY_TYPE_DROP_DOWN, e);
          }}
          options={curTypes}
          className='basic-multi-select'
          classNamePrefix='select'
          isMulti
          hideSelectedOptions={false}
          name='Network Performance Type'
          placeholder='Select'
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
          isDisabled={familyTypeSelected}
        />
      </div>
    );
  };

  console.log({ v1 });

  return (
    <div className='brdr-blu p-20'>
      {getInstanceFamilyChooser(ec2InstanceFamilySelectionOptionsInfo)}
      &nbsp;
      {v1 ? getInstanceTypeChooser() : <> </>}
      &nbsp;
      {getRAMChooser(ec2InstanceRamTypesSelectionOptionsInfo)}
      &nbsp;
      <div className='m-b-20'>{getVCPUChooser(ec2InstanceVcpuTypesSelectionOptionsInfo)}</div>
      <div className='m-b-20'>{getNetworkChooser(ec2InstanceNetworkPerformanceTypesSelectionOptionsInfo)}</div>
      &nbsp; &nbsp; &nbsp;
    </div>
  );
};

export default Ec2CostParamsChooser;
