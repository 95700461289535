import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../styles/ContextBar.css';
import { getCurrentActiveContextBarNodeInfo } from '../ContextBarHandlerBuilder';

export const BaseContextBarBuilder = (title, displayFields) => {
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
  let currentTags = currentContextBarNodeInfo?.node_data?.nodeContextInfo?.Tags || [];
  let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;
  let imgPath = currentContextBarNodeInfo.node_data.imgPath;

  let resourcePropertyBody = [];
  let jsonStructures = [];
  let tags = [];

  for (let i = 0; i < displayFields.length; i++) {
    let elementType = displayFields[i].element_type;

    if (elementType === 'simple_key_pair_value' && nodeContextInfo[displayFields[i].key]) {
      let propertyRow = (
        <div className='node-property row'>
          <p className='node-property-name col-5'>{displayFields[i].label} :</p>
          <p className='node-property-value col-7'>
            <b>{nodeContextInfo[displayFields[i].key]}</b>
          </p>
        </div>
      );
      resourcePropertyBody.push(propertyRow);
    }

    if (elementType === 'auto_json_value') {
      let autoJsonProperty = (
        <div className='node-property'>
          <h5 className='node-property-name '>{displayFields[i].label} :</h5>
          <p>{nodeContextInfo[displayFields[i].key]}</p>
        </div>
      );
      jsonStructures.push(autoJsonProperty);
    }

    if (elementType === 'attribute') {

      const attrs = nodeContextInfo.Attributes
      const attrKeys = Object.keys(attrs)
      const attrValues = Object.values(attrs)
      

      let attributeRow = (
        <div className='node-property row'>
          <p className='node-property-name col-5'>{displayFields[i].label} :</p>
          <p className='node-property-value col-7'>
            <b>{attrValues[0]}</b>
          </p>
        </div>
      );
      resourcePropertyBody.push(attributeRow);
    }
  }

  for (let i = 0; i < currentTags.length; i++) {
    let tagElem = currentTags[i].Value ? (
      <OverlayTrigger
        key={i}
        placement='top'
        overlay={
          <Tooltip style={{ fontSize: '1vw' }} id={`tooltip-${i}`}>
            {currentTags[i].Key}
          </Tooltip>
        }
      >
        <span className='badge alert-success node-tag'>{currentTags[i].Value}</span>
      </OverlayTrigger>
    ) : null;
    tags.push(tagElem);
  }

  return (
    <div>
      <div>
        <div className='context-bar-head'>
          <div className='flex'>
            <img alt='' src={imgPath} width='48' height='48' />
          </div>
          <div>
            <h4 className='context-bar-title'>{title}</h4>
            <div className='context-bar-subtitle'>{nodeContextInfo?.InstanceId}</div>
          </div>
        </div>

        <hr />

        <br />
        <div className='node-properties'>
          <h6 className='prop-text'>PROPERTIES</h6>
          <div className='container'>{resourcePropertyBody}</div>
          <div className='other-props'>
            <br />
            {jsonStructures}
            <h5 className='node-property-name '>Tags :</h5>
            {tags}
          </div>
        </div>
      </div>
    </div>
  );
};

export const GetSimpleKeyPairValueElement = (labelStr, keyStr) => {
  return { label: labelStr, key: keyStr, element_type: 'simple_key_pair_value' };
};

//    Example:
//     "Attributes": {
//         "QueueArn": "arn:aws:sqs:ap-south-1:206824716966:ResourceEvaluatorSqs"
//     }
export const GetAttributeKeyValuePairElements = (labelStr, attribute, ) => {
  return { labelStr, attribute, element_type: 'attribute' };
};

export const GetTagKeyValuePairElements = (label, hoverVal) => {
  return { label, hoverVal, element_type: 'tag' };
};
