import React, { useState } from 'react';
import classes from './Expandable.module.css';
import OpenIcon from './assets/open.svg';
import CloseIcon from './assets/close.svg';

const Expandable = ({ items }) => {
  const [expandedItemIndex, setExpandedItemIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={classes.Expandable}>
      {items.map((item, index) => (
        <div key={index} className={`${classes.FaqItem} ${expandedItemIndex === index ? classes.Expanded : ''}`}>
          <div
            className={`${classes.Title} ${expandedItemIndex === index ? classes.ExpandedTitle : ''}`}
            onClick={() => toggleExpand(index)}
          >
            <div>{item.title}</div>
            <div className={classes.ExpandIcon}>
              <img src={expandedItemIndex !== index ? OpenIcon : CloseIcon} alt='' />
            </div>
          </div>
          <div className={classes.SubtitleContainer}>
            <div className={classes.Subtitle}>{item.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Expandable;
