import React, { createContext, useState, useCallback, useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import '../styles/ConfirmationModal.css';

export const ConfirmationModalContext = createContext();

const ConfirmationModal = ({ message, onConfirm, closeModal }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message !== '') {
      setVisible(true);
    }
  }, [message]);

  const handleOk = useCallback(() => {
    onConfirm();
    closeModal();
  }, [onConfirm, closeModal]);

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal visible={visible} title={null} footer={null} closable={false} maskClosable={false} centered>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ExclamationCircleFilled style={{ fontSize: '3vw', color: '#faad14', marginRight: '1vw' }} />
        <div style={{ fontSize: '1.4vw', fontWeight: 'bold', color: '#333' }}>{message}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2vw' }}>
        <Button onClick={handleCancel} style={{ marginRight: '4vw' }}>
          Cancel
        </Button>
        <Button type='primary' onClick={handleOk}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmationModalProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => {});

  const openModal = useCallback((message, onConfirm) => {
    setMessage(message);
    setOnConfirm(() => onConfirm);
  }, []);

  const closeModal = useCallback(() => {
    setMessage('');
    setOnConfirm(() => {});
  }, []);

  return (
    <ConfirmationModalContext.Provider value={{ openModal }}>
      {children}
      {message && <ConfirmationModal message={message} onConfirm={onConfirm} closeModal={closeModal} />}
    </ConfirmationModalContext.Provider>
  );
};
