import React, { Suspense, useState } from 'react';
import classes from './Footer.module.css';
import TopIcon1 from '../../assets/footer/TopIcon1.png';
import TopIcon2 from '../../assets/footer/TopIcon2.png';
import Logo from '../../assets/Logo.svg';
import FacebookIcon from '../../assets/socialIcons/facebook.svg';
import TwitterIcon from '../../assets/socialIcons/twitter.svg';
import LinkedInIcon from '../../assets/socialIcons/linkedin.svg';
import { PATHS } from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = React.lazy(() => import('../../pages/Legal/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('../../pages/Legal/PrivacyPolicy'));
const CancellationAndRefund = React.lazy(() => import('../../pages/Legal/CancellationAndRefund'));

const ModalComponent = React.lazy(() => import('../ModalComponent/ModalComponent'));

const LazyModalComponent = ({ isOpen, setOpen, children }) => {
  return (
    <Suspense fallback={<div>Loading modal...</div>}>
      <ModalComponent isOpen={isOpen} setOpen={setOpen} children={children} />
    </Suspense>
  );
};
const Footer = () => {
  const [modalType, setModalType] = useState('');
  const modalContent = {
    TermsAndConditions: <TermsAndConditions />,
    PrivacyPolicy: <PrivacyPolicy />,
    CancellationAndRefund: <CancellationAndRefund />,
  };
  const handleModalClick = (type) => () => {
    setModalType(type);
  };
  const history = useHistory();
  const navigateTo = (path) => history.push(path);

  return (
    <div className={classes.Footer}>
      <div className={classes.TopIcons}>
        {/* <img src={TopIcon1} alt='' />
        <img src={TopIcon2} alt='' /> */}
      </div>
      <div className={classes.Container}>
        <div>
          <div className={classes.Logo}>
            <img src={Logo} alt='' />
          </div>
          <div className={classes.Points}>
            <div>
              <div></div>
              <div>+91 7022204436</div>
            </div>
            <div>
              <div></div>
              <div>sales@bluefinware.io</div>
            </div>
            <div>
              <div></div>
              <div>MindZaurus Technologies Pvt Ltd, Whitefield, Bengaluru, Karnataka, India 560067.</div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.Title}>USE CASES</div>
          <div className={classes.Points}>
            <div onClick={()=>navigateTo(PATHS.USE_CASES)}>Cloud diagrams & inventory</div>
            <div onClick={()=>navigateTo(PATHS.USE_CASES)}>Cloud Security & Compliance</div>
          </div>
        </div>

        <div>
          <div className={classes.Title}>LEGAL</div>
          <div className={classes.Points}>
            <div onClick={handleModalClick('TermsAndConditions')}>Terms & Conditions</div>
            <div onClick={handleModalClick('PrivacyPolicy')}>Privacy</div>
            <div onClick={handleModalClick('CancellationAndRefund')}>Cancellation & Refund Policy</div>
          </div>
        </div>

        <div>
          <div className={classes.Title}>NEWSLETTER</div>
          <div className={classes.SubscribeBox}>
            <div>Subscribe for latest articles and resources</div>
            <div className={classes.SearchInput}>
              <input placeholder='Email Address' type='text' />
              <div className={classes.SignupButton}>
                <div className={classes.RegisterButton}>REGISTER</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.CopyRights}>
        <div>© Copyright 2023 by Bluefinware Inc.</div>
        <div className={classes.SocialIcons}>
          <img src={FacebookIcon} alt='' />
          <img src={TwitterIcon} alt='' />
          <img src={LinkedInIcon} alt='' />
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyModalComponent isOpen={Boolean(modalType)} setOpen={setModalType}>
          {modalContent[modalType]}
        </LazyModalComponent>
      </Suspense>
    </div>
  );
};

export default Footer;
