import React from 'react';
import './Privacy.css';

function Privacy() {
  return (
    <div className='legal-container'>
      <p className='heading'>Privacy policy</p>
      <p className='sub-heading'>Effective as of April 1, 2023</p>
      <p className='text'>
      We are strongly committed to letting you know how we will collect and use your personal information. 
      The policies below are applicable to data and information collected through bluefinWare.io, 
      the affiliated network of websites (including any versions optimized for viewing on a wireless or 
      tablet device); all email newsletters published or distributed by BluefinWare; all apps published by 
      BluefinWare; and all other interactive features, services, and communications provided by 
      BluefinWare (“Service”), however accessed and/or used, that are operated by us, made available by us, 
      or produced and maintained by BluefinWare, Inc. (collectively “BluefinWare” or “we”, “us”, or “our”). 
      We have established this privacy policy (“Privacy Policy”) to let you know the kinds of personal 
      information we may gather during your use of this Services, why we gather your information, what we 
      use your personal information for, when we might disclose your personal information, and how you can 
      manage your personal information.
      Please be advised that the practices described in this Privacy Policy apply only to information 
      gathered online through our Service. It does not apply to information that you may submit to us 
      offline or by companies or organizations to which we may link or who may link to us. By using our 
      Service, you are accepting the practices described in our Privacy Policy. If you do not agree to the 
      terms of this Privacy Policy, please do not use the Service. We reserve the right to modify or amend 
      the terms of our Privacy Policy from time to time without notice. Your continued use of our Service 
      following the posting of changes to these terms will mean you accept those changes. If we intend to 
      apply the modifications or amendments to this Privacy Policy retroactively or to personal information 
      already in our possession, we will provide you with notice of the modifications or amendments.
      If you have any questions about this Privacy Policy or don’t see your concerns addressed here, you 
      should contact us by email at privacy@bluefinware.io
      </p>

      <p className='point-heading'>What Information About Me Is Collected and Stored?</p>
      <p className='text'>
      We collect two basic types of information from you in conjunction with your use of the Service, 
      personal information and non-personal information. Personal information is information that you may 
      supply to us, as described more fully below, i.e., when you obtain a subscription, complete a survey, 
      register on the Service, upload content, participate in a community, or provide your e-mail address. 
      Personal information is any information that can individually identify you and includes, among other 
      things, your name, e-mail address, telephone number, postal address, credit card, billing and contact 
      information. Non-personal information includes information that does not personally identify you, 
      but it may include tracking and usage information about your location, demographics, use of the Service 
      and the Internet.
      </p>
      <p className='point-heading'>Personal Information</p>
      <p className='text'>As a general matter, you can browse the Service without submitting your personal 
      information to us. However, there are a number of circumstances in which you may supply us or our 
      agents with your personal information. The following lists the most common ways in which we may 
      collect your personal information.
      <ul>
          <li>Registration for an account on the Service</li>
          <li>Registration for an event sponsored by BluefinWare</li>
          <li>Profile information that You provide for Your user profile</li>
          <li>Uploading Content to the Service</li>
          <li>Commenting to blog entries</li>
          <li>Submitting an application to work at BluefinWare</li>
          <li>Participation in surveys</li>
          <li>Sign up to receive alerts or other information via email, text or instant message from BluefinWare</li>
          <li>Request for sales calls, customer service, support requests or other assistance</li>
          <li>Service related communications, e.g. account verification; technical notification</li>
          <li>Participation in communities, commenting to blog entries and participation in other forums</li>
          <li>Submission of content or other data and information on any part of the Service that permits it</li>
          <li>Any other place on the Service where you knowingly volunteer personal information</li>
      </ul>
    </p>
    <p className='point-heading'>Non-Personal Information</p>
    <p className='text'>
    In addition, when you interact with the Service, we may collect certain information that does not 
    identify you individually and our servers may automatically keep an activity log of your use of our 
    Service (“Non-Personal Information”). Generally, we collect and store the following categories of 
    Non-Personal Information:
    <ul>
      <li>Non-identifiable demographic data such as age, gender, and five digit zip code as part of 
      collecting personal information</li>
      <li>Device information about your computer, browser, mobile device, or other device that you use 
      to access the Service. This information may include IP address, geolocation information, 
      unique device identifiers, browser type, browser language, and other transactional information</li>
      <li>Analytics and usage information about your use of the Service. 
      Data collected is limited to that required to analyse and optimize the customer cloud spend.</li>
      <li>
      Additional “traffic data” and log files such as time of access, date of access, 
      software crash reports, session identification number, access times, and referring website addresses
      </li>
      <li>Other information regarding your use of the Service</li> 
    </ul> 
    </p>
    <p className='point-heading'>Collection of Your Source IP Address/Location Information</p>
    <p className='text'>
    We may collect and store location information about you on the Service and associated with your 
    account that you volunteer on the Service or enable through the Service or your device. 
    We will not collect any location information that you do not volunteer or enable. We will delete any 
    location information that you request is deleted. We do collect and store your device’s source 
    IP address which may disclose the location of your device at the time you access the Service.   
    </p>
    <p className='point-heading'>Use of Cookies, Flash Cookies, Web Storage, and Web Beacons</p>
    <p className='text'>
    Like many websites, we use “cookies”, which are small text files that are stored on your computer or 
    equipment when you visit certain online pages that record your preferences and actions. 
    We may also use cookies to monitor traffic, improve the Sites and make it easier and/or relevant 
    for your use. We use both “session” cookies and “persistent” cookies. We use cookies for the other 
    purposes set out below:
    <ul>
      <li>We use cookies to remind us who you are and to find your account information in our database 
        when you access a service so you do not need to log in at every visit. 
        This helps us to provide you with service tailored to your specific needs and interests. 
        A cookie is created when you register for a service
      </li>
      <li>We use cookies to determine the browser the visitor uses so the site can be designed to work 
      properly with the most common versions of different browsers
      </li>
      <li>We use cookies in conjunction with sending you e-mail newsletters</li>
      <li>Advertisers that place ads on the Sites may use cookies</li>
      <li>We use cookies in conjunction with analysis of your use of our website and generate analytics 
        regarding our Service</li>
      <li>We use cookies to estimate our audience size. Your browser is given a unique cookie that helps us 
        determine whether yours is a repeat visit or a first visit</li>
    </ul>
    You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but, 
    if you prefer, you can usually modify your browser setting to decline cookies. If you delete your cookies 
    or if you set your web browser to decline cookies, some features of the Sites may not work or may not 
    work as designed. For more information on cookies and how to disable them, you can consult the information 
    provided by the Interactive Advertising Bureau at www.allaboutcookies.org.
    We do not use flash cookies, web storage, web beacons or other technology that tracks your browsing 
    history across multiple websites.
  </p>
  <p className='point-heading'>How Do We Use Your Information?</p>
  <p className='text'>
  We use the information we learn from you to help us personalize and continually improve your experience 
  on the Service. We may use your Personal and Non-Personal Information in the following ways:
  <p className='point-heading'>General Uses</p>
  <p className='text'>
    <ul>
      <li>To upload your content to our Service as you request</li>
      <li>To permit you to update, edit, and manage your content on our Service</li>
      <li>To communicate with you about your account or transactions with us (including service related 
      announcements) and send you information about features and enhancements on our Service</li>
      <li>To communicate with you about changes to our policies</li>
      <li>To communicate with you about your comment to a blog post</li>
      <li>To personalize content and experiences on our Service, including providing you reports, 
      recommendations and feedback based on your preferences</li>
      <li>To disclose anonymized Personal Information to disclose statistics and analytics and other details 
        regarding the use of our Service</li>
      <li>To optimize or improve our products, services and operations</li>
      <li>To automatically update the Service on your device</li>
      <li>To detect, investigate, and prevent activities that may violate our policies or be illegal</li>
      <li>To perform statistical, demographic, and marketing analyses of users of the Service</li>
      <li>Use of Your Location Information</li>
      <ul>Specifically, we use your location information to:
      <li>Personalize content on our Service, including providing you reports, recommendations and 
        feedback based on your preferences</li>
      <li>Optimize or improve our products, services and operations</li>
      <li>Detect, investigate, and prevent activities that may violate our policies or be illegal</li>
      <li>Perform statistical, demographic, and marketing analyses of users of the Service and their 
        purchasing patterns</li>
      </ul>
    </ul>
  </p>
  </p>
  <p className='point-heading'>Combination of Your Personal Information</p>
  <p className='text'>
      We use the information from one portion of the Sites on other portions of Sites in our network of 
      Sites or in reports and analysis, all of which are owned and operated by BluefinWare, and we may 
      combine information gathered from multiple portions of the Sites into a single customer record or 
      analysis or report. We also use and/or combine information that we collect off-line or we collect or 
      receive from third party sources to enhance, expand, and check the accuracy of your customer records.
  </p>
  <p className='point-heading'>Who Do We Provide Your Information To?</p>
  <p className='text'>
  Except as disclosed in this Privacy Policy, we do not disclose information about your Personal Information 
  collected online to any companies not part of BluefinWare or its parent, subsidiaries or related entities. 
  In no event will we sell or rent your Personal Information as part of a customer list or similar transaction.
  </p>
  <p className='point-heading'>Business Partners and Third Parties</p>
  <p className='text'>
  We may share your Personal Information with our business partners from time to time. You may withdraw your 
  consent to our sharing of your Personal Information with business partners and third parties at any time 
  by following the opt-out process described below.
  </p>
  <p className='point-heading'>Third-Party Agents</p>
  <p className='text'>
  We have third party agents, subsidiaries, affiliates and partners that perform functions on our behalf, such 
  as hosting, billing, push notifications, storage, bandwidth, content management tools, analytics, customer 
  service, fraud protection, etc. These entities have access to the Personal Information needed to perform 
  their functions and are contractually obligated to maintain the confidentiality and security of that 
  Personal Information. They are restricted from using, selling, distributing or altering this data in any 
  way other than to provide the requested services to the Service.
  </p>
  <p className='point-heading'>Emergency Situations</p>
  <p className='text'>
  We may also use or disclose Personal Information if required to do so by law or in the good-faith belief 
  that such action is necessary to (a) conform to applicable law or comply with legal process served on us or 
  the Service; (b) protect and defend our rights or property, the Service or our users, and (c) act under 
  emergency circumstances to protect the personal safety of us, our affiliates, agents, or the users of the 
  Service or the public. This includes exchanging information with other companies and organizations for 
  fraud protection.
  </p>
  <p className='point-heading'>BluefinWare E-mail and Text Communications</p>
  <p className='text'>
  E-mail communications and text messages sent from Us or through Us are designed to make Your BluefinWare 
  experience more efficient. By participating in the Services, You specifically agree to accept and consent 
  to receiving e-mail communications and text messages initiated from Us or through Us, which include, 
  without limitation: message notification e-mails, e-mails or text messages informing You about potential 
  available Tutors or Tutees and e-mails informing You of promotions We run and emails informing You of new 
  and existing features We provide. Standard text messaging charges applied by Your cell phone carrier will 
  apply to text messages We send. If You change Your mobile phone service provider, the notification service 
  may be deactivated for Your phone number and You may need to re-enroll in the notification service. 
  BluefinWare reserves the right to cancel the notification service at any time. If You do not wish to 
  receive any of our e-mail communications or text messages, please do not use the Services.
  </p>
  <p className='point-heading'>What Steps Are Taken To Keep Personal Information Secure?</p>
  <p className='text'>
  We are concerned about ensuring the security of your Personal Information. We exercise great care in 
  providing secure transmission of your information from your device to our servers. Personal Information 
  collected by our Service are stored in secure operating environments that are not available to the public. 
  Our security procedures mean that we may occasionally request proof of identity before we disclose your 
  Personal Information to you. Please understand, however, that while we try our best to safeguard your 
  Personal Information once we receive it, no transmission of data over the Internet or any other public 
  network can be guaranteed to be 100% secure.
  </p>
  <p className='point-heading'>How Can We Transfer Your Personal Information?</p>
  <p className='text'>
  Your information collected through the Service may be stored and processed in the 
  India or any other country in which BluefinWare, its Clients, 
  Affiliates or service providers maintain facilities. BluefinWare, its Clients, Affiliates, 
  or service providers may transfer information that we collect about you, including personal 
  information across borders and from your country or jurisdiction to other countries or 
  jurisdictions around the world. If you are located in the India or other regions with laws governing 
  data collection and use that may differ from Indian law, please note that we may transfer information, 
  including personal information, to a country and jurisdiction that does not have the same data protection 
  laws as your jurisdiction. By registering for and using the Service you consent to the transfer of 
  information to the US or to any other country in which BluefinWare, its Clients, Affiliates or service 
  providers maintain facilities and the use and disclosure of information about you as described in this 
  Privacy Policy.
  </p>
  <p className='point-heading'>How Long Do We Keep Your Information?</p>
  <p className='text'>
  Following termination or deactivation of your account, BluefinWare, its Clients, Affiliates, or its service 
  providers may retain information (including your profile information) and user Content for a commercially 
  reasonable time for backup, archival, and/or audit purposes. If you have any questions about termination or 
  deactivation of your account, please contact us directly at privacy@bluefinware.io
  </p>
  <p className='point-heading'>What Happens When I Link To or From Another Website?</p>
  <p className='text'>
  This Service may contain links to other websites operated by third parties. Please be advised that the 
  practices described in this Privacy Policy for BluefinWare do not apply to information gathered through 
  these other websites. We are not responsible for the actions and privacy policies of third parties and 
  other websites.
  </p>
  <p className='point-heading'>Governing Law</p>
  <p className='text'>
  We attempt to protect the Personal Information of all users of our Service and we attempt to comply with 
  local data protection and consumer rights laws to the extent they  may apply to the BluefinWare services. 
  If you are uncertain whether this privacy policy conflicts with the applicable local privacy laws where 
  you are located, you should not submit your Personal Information to BluefinWare.
  </p>
  <p className='point-heading'>Assignment</p>
  <p className='text'>
  We may change our ownership or corporate organization while providing the Service. 
  We may also sell certain assets associated with the Service. As a result, please be aware that in such 
  event we may transfer some or all of your information to a company acquiring all or part of our assets or 
  to another company with which we have merged. Under such circumstances we would, to the extent possible, 
  require the acquiring party to follow the practices described in this Privacy Policy, as it may be amended 
  from time to time. Nevertheless, we cannot promise that an acquiring company or the merged company will 
  have the same privacy practices or treat your information the same as described in this Privacy Policy.
  </p>
  <p className='point-heading'>Changes to This Policy</p>
  <p className='text'>
  As our Service continues to develop, we may add new services and features to our Service. In the event that 
  these additions affect our Privacy Policy, this document will be updated appropriately. We will post those 
  changes prominently so that you will always know what information we gather, how we might use that 
  information and whether we will disclose it to anyone. We do, however, recommend that you read this 
  Privacy Policy each time you use our Service in case you missed our notice of changes to the Privacy Policy.
  We will not, however, materially change our policies and practices to make them less protective of 
  Personal Information we have previously collected from you without your express consent.
  </p>
  <p className='point-heading'>How Do I Opt-Out or Correct Information About Me</p>
  <p className='text'>
  You may always opt-out of receiving future e-mail messages and newsletters from BluefinWare. 
  We provide you with the opportunity to opt-out of receiving communications from us by going into your 
  profile settings and choosing the appropriate options. To opt-out, you can also send us a message at  
  privacy@bluefinware.io. Please note, however, that you generally cannot opt-out of service related 
  announcements, e.g. if the Service is temporarily suspended or if delivery of a product or service is 
  delayed.
  </p>
  
  </div>
  );
}

export default Privacy;
