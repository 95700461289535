import { Route, Switch } from 'react-router-dom';
import Products from './Products';
import ResourceMakerPage from './ResourceMakerPage';
import Login from './components/Login';
import Signup from './components/Signup';
import MyProfile from './components/Settings/MyProfile';
/*import Settings from "./components/Settings";*/
import AwsAccessKeys from './components/Settings/AwsAccessKeys';
import AwsAccessKeysOld from './components/Settings/AwsAccessKeysOld';
import BillingsPlans from './components/Settings/BillingsPlans';
import AwsAuthentication from './components/Settings/AwsAuthentication';
import EmailNotification from './components/Settings/EmailNotification';
import PaymentHistory from './components/PaymentHistory';
import PaymentsFailure from './components/PaymentsFailure';
import PaymentsSuccess from './components/PaymentsSuccessfull';
import CognitoUserPoolSignup from './components/CognitoUserPoolSignup';
import CognitoUserPoolSignin from './components/CognitoUserPoolSignin';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import Privacy from './components/Legal/Privacy/Privacy';
import Terms from './components/Legal/Terms/Terms';
import CancellationAndRefundPolicy from './components/Legal/CancellationAndRefundPolicy/CancellationAndRefundPolicy';
import { DispatchAction } from './components/statemanagement/store';
import { USER_LOGOUT } from './components/statemanagement/ActionsList';
import { useHistory } from 'react-router-dom';
import { LOGOUT_TIME_IN_MINUTES } from './CommonConsts';
import ProductConfiguration from './components/ProductConfiguration/ProductConfiguration';
import CostPlanner from './components/CostManager/CostPlanner/CostPlanner';
import { Container } from 'react-bootstrap';
import { GetMenuBar } from './components/MenubarComponent';
import Footer from './components/Footer';
import LandingPage from './landingPage/LandingPage';
import Security from './landingPage/pages/Security/Security';
import Pricing from './landingPage/pages/Pricing/Pricing';
import Contact from './landingPage/pages/Contact/Contact';
import UseCases from './landingPage/pages/UseCases/UseCases';

export default function Approutes() {
  const history = useHistory();
  let inactivityTimer;

  const startInactivityTimer = () => {
    inactivityTimer = setTimeout(logout, LOGOUT_TIME_IN_MINUTES * 60 * 1000); // 60 minutes
  };

  const logout = () => {
    clearTimeout(inactivityTimer);
    DispatchAction(USER_LOGOUT, {
      what: USER_LOGOUT,
    });
    history.push('/home');
  };

  return (
    <Switch>
      <Route exact path='/' component={LandingPage} />

      <Route path='/home'>
        <LandingPage />
      </Route>

      <Route path='/computePricing'>
        {GetMenuBar()}
        <Container className='small mt-5'>
          <CostPlanner />
        </Container>
        <Footer />
      </Route>

      <Route path='/signup'>
        <Signup />
      </Route>

      <Route path='/login'>
        <Login handleLogin={startInactivityTimer} />
      </Route>

      <Route path='/forgotpassword'>
        <ForgotPassword />
      </Route>

      <Route path='/cgupsignup'>
        <CognitoUserPoolSignup />
      </Route>

      <Route path='/cguplogin'>
        <CognitoUserPoolSignin handleLogin={startInactivityTimer} />
      </Route>

      <Route path='/products'>
        <Products />
      </Route>

      <Route path='/pricing' exact>
        <Pricing />
      </Route>

      <Route path='/usecases' exact>
        <UseCases />
      </Route>

      {/* <Route path="/security" exact>
                <Security />
            </Route> */}

      <Route path='/contact-us'>
        <Contact />
      </Route>

      <Route path='/payment-history'>
        <PaymentHistory />
      </Route>
      <Route path='/payment-success'>
        <PaymentsSuccess />
      </Route>
      <Route path='/payment-failure'>
        <PaymentsFailure />
      </Route>

      <Route exact path='/resource-maker' component={ResourceMakerPage} />

      <Route exact path='/change-password' component={ChangePassword} />

      <Route path='/security' exact>
        <Security />
      </Route>

      {/* <Route path="/settings" exact>
                <Settings />
            </Route> */}

      <Route path='/settings' exact>
        <AwsAccessKeys />
      </Route>

      <Route path='/settingsold' exact>
        <AwsAccessKeysOld />
      </Route>

      <Route path='/settings/profile'>
        <MyProfile />
      </Route>

      <Route path='/settings/billing-plans'>
        <BillingsPlans />
      </Route>

      <Route path='/settings/product-configuration' exact>
        <ProductConfiguration />
      </Route>

      <Route path='/settings/aws-authentication'>
        <AwsAuthentication />
      </Route>

      <Route path='/settings/email-notification'>
        <EmailNotification />
      </Route>

      <Route path='/legal/privacy'>
        <Privacy />
      </Route>
      <Route path='/legal/termsAndConditions'>
        <Terms />
      </Route>
      <Route path='/legal/refundPolicy'>
        <CancellationAndRefundPolicy />
      </Route>
    </Switch>
  );
}

//
