import * as EditorCanvasReducer from './EditorCanvasReducerImpl';

import { linkActionCancelButtonSelectedInSidebarReducer, linkButtonSelectedInSidebarReducer } from './SidebarReducer';
import { saveButtonSelectedInContextbarReducer } from './ContextbarReducer';
import { userLoginHappenedNowReducer, userLogoutReducer } from './UserAuthenticationReducer';

const getActionType = (action) => {
  return action.type;
};

export const GetReducerImplementation = (action) => {
  let actionType = getActionType(action);
  return actionType in reducersMap ? reducersMap[actionType] : null;
};

const reducersMap = {
  CONTEXT_BAR_CLOSE_BUTTON_CLICKED: EditorCanvasReducer.closeIconClickedInContextBarReduced,
  RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS: EditorCanvasReducer.resourceNodeSelectedInEditorCanvasReducer,
  RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS: EditorCanvasReducer.resourceNodesLinkSelectedInEditorCanvasReducer,
  LINK_BUTTON_SELECTED_IN_SIDEBAR: linkButtonSelectedInSidebarReducer,
  LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR: linkActionCancelButtonSelectedInSidebarReducer,
  RESOURCE_CONTEXT_INFO_SAVED_BY_USER: saveButtonSelectedInContextbarReducer,
  RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER: saveButtonSelectedInContextbarReducer,
  USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW: userLoginHappenedNowReducer,
  USER_LOGOUT: userLogoutReducer,
};

//
