import React from 'react';
import './CancellationAndRefundPolicy.css';
function CancellationAndRefundPolicy() {
  return (
    <div className='legal-container'>
      <p className='heading'> Cancellation and Refund </p>
      <p className='sub-heading'>Last Update to Cancellation and Refund April 1, 2023</p>
      <p className='text'>
        <ul>
          <li>BluefinWare shall ensure that our customers do not have to resort to the cancellation of their 
            accounts with us in any circumstances. However, certain situations may demand that the customer 
            (“You”) may decide to cancel your account with us and ask for a refund. This policy chalks down 
            all terms and conditions under which cancellation and refund are possible. </li>
        <li>You are responsible for properly canceling your subscription, which you can conveniently do 
          online at any time.</li>
        <li>If for any reason you are unhappy with your BluefinWare subscription purchase, we offer a 30-day 
          money back guarantee.</li>
        <li>
          We claim no ownership rights over the content submitted or created exclusively by you in 
          your BluefinWare account. Any content that is yours remains yours.
        </li>
        <li>All refunds shall be made in the same mode that the original payment was made and not on an alternative 
            account/mode
        </li>
        <li>A refund or cancellation, wherever applicable, shall render the agreement between the customer 
            and BluefinWare redundant, along with the pricing and terms and conditions agreed upon. 
            Any new transaction will be on the terms and conditions and fees as applicable on entering 
            into the new agreement.
        </li>
        </ul>
      </p>
      
    </div>
  );
}

export default CancellationAndRefundPolicy;
