import "../../../styles/ContextBar.css"
import {
    ContextBarGetNewDropDownField,
    ContextBarGetNewTextInputField,
    ContextBarGetNewCustomWidgetField,
    FIELD_APPENDING_TYPE,
    FIELD_VALUES, UI_FIELDS_DEFINITION,
    CreateContextBarUiHandler, setContextBarDefinitionTypeAsResourceLink
} from "../../ContextBarHandlerBuilder";
import {Button, Card, Dropdown, DropdownButton, Form, ListGroup} from "react-bootstrap";
import {createRef} from "react";
import React, { useState } from 'react';
import {GetWidgetCloseButtonIconPath} from "../../GeneralResourceIconsList";
import uniqid from "uniqid";

const ROUTE_NAME_KEY = "routename"
const HTTP_METHOD_TYPE_KEY = "httpmethodtype"

const routesWidgetDrawFunction = (widgetAccessHelper, _uniqWidgetId, widgetPreviousDataIfAny) => {

    const routesPathRef = createRef()
    const uniqWidgetId = _uniqWidgetId

    let routePathTextFieldValue = ""
    let httpMethodDropDownValue = ""

    console.log("widgetPreviousDataIfAny")
    console.log(widgetPreviousDataIfAny)
    if (widgetPreviousDataIfAny !== null) {
        routePathTextFieldValue = widgetPreviousDataIfAny.routePath
        httpMethodDropDownValue = widgetPreviousDataIfAny.httpMethodDropDownValue
    }


    const notifyDataChange = () => {

        const widgetData = {
            routePath: routePathTextFieldValue,
            httpMethod: httpMethodDropDownValue
        }

        widgetAccessHelper.updateCustomWidgetData(uniqWidgetId, widgetData)
    }

    const onTextChangedHandler = (e) => {
        e.preventDefault()
        routePathTextFieldValue = routesPathRef.current.value
        console.log(routePathTextFieldValue)
        notifyDataChange()
    }

    const onDropDownSelected = (e) => {
        httpMethodDropDownValue = e
        console.log(httpMethodDropDownValue)
        notifyDataChange()
    }

    const getRoutesWidgetDataSerializedAsJson = (widgetData) => {
        return {
            [ROUTE_NAME_KEY]: widgetData.routePath,
            [HTTP_METHOD_TYPE_KEY]: widgetData.httpMethod
        }
    }

    const setRoutesWidgetDataAndRenderNow = (widgetJsonData) => {
    }

    widgetAccessHelper.getWidgetDataSerializedAsJson = getRoutesWidgetDataSerializedAsJson
    widgetAccessHelper.setWidgetDataAndRenderNow = setRoutesWidgetDataAndRenderNow

    const getHttpMethodTypeDropDown = () => {
        return (<>
            <DropdownButton
                alignRight
                title={"HTTP method"}
                id="dropdown-menu-align-right"
                onSelect={onDropDownSelected}
                defaultValue={httpMethodDropDownValue}
            >
                {dropdownitems.map((dropDownItem, index) => (
                    <Dropdown.Item eventKey={dropDownItem} key={index}> {dropDownItem}  </Dropdown.Item>
                ))}
            </DropdownButton>
        </>)
    }

    const getRoutesPathTextField = () => {
        return (
            <>
                <Form.Group className="mb-1" id={"asdf09jn"}>
                    <Form.Label>{}</Form.Label>
                    <Form.Control type="text" ref={routesPathRef} placeholder={"Route Path"}
                                  defaultValue={routePathTextFieldValue} onChange={onTextChangedHandler}
                    />
                </Form.Group>
            </>
        )
    }
    const dropdownitems = ["GET", "POST"]

    const getRouteCard = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // const [routePathTxt, setRoutePathTxt] = useState("")
        return (
            <>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            {getHttpMethodTypeDropDown()}
                            {getRoutesPathTextField()}
                        </ListGroup.Item>
                    </ListGroup>
            </>
        )
    }

    return (
        <>
            {getRouteCard()}
        </>
    )

}

const IP_WHITELISTING_TYPE = [
    "LINK EC2 PRIVATE IP",
    "LINK EC2 PUBLIC IP"
]

const uiFieldsDefinition = [

    ContextBarGetNewDropDownField(
        "EC2 RDS IP whitelisting type", "rds_ec2_whitelisting_type",
        FIELD_APPENDING_TYPE.NO_APPENDING,
        IP_WHITELISTING_TYPE, FIELD_VALUES.EMPTRY_STR
    ),

]

const contextBarDefinition = {
    [UI_FIELDS_DEFINITION]: uiFieldsDefinition,
}


export const Rds_linked_to_Ec2 = (contextBarStateStore, setContextBarStateStore) => {

    setContextBarDefinitionTypeAsResourceLink(contextBarDefinition)

    contextBarDefinition.contextBarStateStore = contextBarStateStore
    contextBarDefinition.setContextBarStateStore = setContextBarStateStore

    const formContent = CreateContextBarUiHandler(contextBarDefinition)

    return (
        <div>
            <h5> RDS ⟶ EC2 Relation </h5>
            {formContent}
        </div>
    )
}


//
//