import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GetResourcesDiffedData } from './HelperFunctions/DiffViewerUtils';
import { IsResourceOverviewItem } from './HelperFunctions/NodeDefUtils';
import { GetDiffUiInfo } from './HelperFunctions/DiffInfo2Ui';
import './ResourceComparision.css';
import DiffToolWithTable from './DiffToolWithTable/DiffToolWithTable';
import ComparisionForm from './ComparisionForm/ComparisionForm';

const ResourceComparision = ({ showMenu, setShowMenu }) => {
  const [accordionData, setAccordionData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const displayedTitlesRef = useRef([]);
  const isMounted = useRef(false);

  const [originalSnapshotValues, setOriginalSnapshotValues] = useState({
    accountId: '',
    region: 'ap-south-1',
    resourceSnapShotDate: '2023-04-30',
  });
  const [comparisionSnapshotValues, setComparisionSnapshotValues] = useState({
    accountId: '',
    region: 'ap-south-1',
    resourceSnapShotDate: '2023-05-01',
  });

  const makeContent = useMemo(() => {
    const generateContent = (data) => {
      const isLastChild = IsResourceOverviewItem(data);
      if (isLastChild) {
        if (!data.ChildNodes?.[0] && !['AddedItem', 'MissingItem'].includes(data.DiffType)) {
          data.ChildNodes[0] = data;
        }
        displayedTitlesRef.current = [...displayedTitlesRef.current, { title: data.NodeDisplayName, details: data }];
      }
      let { NodeDisplayName, DiffType, IconPath, ChildNodes = [] } = data;

      return {
        title: NodeDisplayName,
        isModified: DiffType === 'ModifiedItem',
        isDeleted: DiffType === 'MissingItem',
        isAdded: DiffType === 'AddedItem',
        icon: IconPath,
        content: isLastChild ? [] : ChildNodes?.map((eachChildNode) => generateContent(eachChildNode)),
        isLastChild,
        onItemClick: () => {
          setSelectedTitle({ title: data.NodeDisplayName, details: data });
        },
      };
    };

    return generateContent;
  }, []); // Empty dependency array to ensure it's only executed once

  const getDiffData = async () => {
    displayedTitlesRef.current = [];
    let processedCompareValues = {
      ...comparisionSnapshotValues,
      resourceSnapShotDatesList: [comparisionSnapshotValues.resourceSnapShotDate],
    };
    delete processedCompareValues.resourceSnapShotDate;

    let isUseDiffDemoData = process.env.REACT_APP_USE_DIFF_DEMO_DATA ? process.env.REACT_APP_USE_DIFF_DEMO_DATA : '';
    const diffData = {
      originalSnapshot: originalSnapshotValues,
      resourceDiffParams: [{ ...processedCompareValues }],
      ...{ useDemoData: isUseDiffDemoData },
    };

    let resp = await GetResourcesDiffedData(diffData);

    if (resp?.data) {
      setShowMenu(false);
      let diffUiInfo = GetDiffUiInfo(resp?.data || {});
      let accordionData = diffUiInfo?.renderList?.left?.map((eachItem) => makeContent(eachItem)) || [];
      setAccordionData(accordionData);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getDiffData();
    } else {
      isMounted.current = true;
    }
  }, []); // Add 'myValue' as a dependency to trigger the effect when it changes

  return (
    <div>
      {showMenu ? (
        <ComparisionForm
          originalSnapshotValues={originalSnapshotValues}
          setOriginalSnapshotValues={setOriginalSnapshotValues}
          comparisionSnapshotValues={comparisionSnapshotValues}
          setComparisionSnapshotValues={setComparisionSnapshotValues}
          handleSubmit={getDiffData}
        />
      ) : (
        <DiffToolWithTable
          originalSnapshotValues={originalSnapshotValues}
          setOriginalSnapshotValues={setOriginalSnapshotValues}
          comparisionSnapshotValues={comparisionSnapshotValues}
          setComparisionSnapshotValues={setComparisionSnapshotValues}
          diffData={accordionData}
          selectedTitle={selectedTitle}
          displayedTitles={displayedTitlesRef.current}
          setSelectedTitle={setSelectedTitle}
          handleSubmit={getDiffData}
        />
      )}
    </div>
  );
};

export default ResourceComparision;
