import moment from 'moment';

export function getLastSixMonths() {
  var today = moment();
  var lastSixMonths = [];

  for (var i = 0; i < 6; i++) {
    var startOfMonth = moment(today)
      .subtract(i + 1, 'month')
      .startOf('month');
    var endOfMonth = moment(today)
      .subtract(i + 1, 'month')
      .endOf('month');

    var dateRange = {
      startDate: startOfMonth.format('YYYY-MM-DD'),
      endDate: endOfMonth.format('YYYY-MM-DD'),
    };

    lastSixMonths.push(dateRange);
  }

  // reverse the order of the array so that the most recent month is first
  lastSixMonths.reverse();

  return lastSixMonths;
}

export const LAST_WEEK_START_DATE = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
export const LAST_WEEK_END_DATE = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

export const CURRENT_WEEK_START_DATE = moment().startOf('week').format('YYYY-MM-DD');

export const CURRENT_MONTH_START_DATE = moment().startOf('month').format('YYYY-MM-DD');
export const CURRENT_MONTH_END_DATE = moment().endOf('month').format('YYYY-MM-DD');

export const LAST_MONTH_START_DATE = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
export const LAST_MONTH_END_DATE = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

export const NEXT_MONTH_START_DATE = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
export const NEXT_MONTH_END_DATE = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD');
