import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert, Col, Row, Container } from 'react-bootstrap';
import Logo from '../landingPage/assets/LogoWithText.svg';
import { Link, useHistory } from 'react-router-dom';

import auth from './FirebaseUtils';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { DispatchAction, GetState, Subscribe } from './statemanagement/store';
import * as actions from './statemanagement/ActionsList';
import {
  LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR,
  LINK_BUTTON_SELECTED_IN_SIDEBAR,
  RESOURCE_CONTEXT_INFO_SAVED_BY_USER,
  RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER,
  USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
} from './statemanagement/ActionsList';
import { RedirectToHomePage } from '../HomePage';
import { GetUserLoginCredentials, GetUserLoginCredentialsInCognitoUserPoolFormat, GetUserLoginToken } from './UserCredentials';
import { GetResourcesOverviewUiCacheUrl, GetVerifyCognitoUserPoolLoginUrl, GetVerifyUserLoginUrl } from '../routes';
import axios from 'axios';

import UserPool from './AWS/UserPool';

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import classes from '../styles/Login.module.css';
import Eye from '../landingPage/assets/login/passwordEye.svg';
import EyeHide from '../landingPage/assets/login/passwordEyeHide.svg';
import { toast } from 'react-toastify';
import { PATHS } from '../landingPage/components/Navbar/Navbar';

export const AUTH_PROVIDER_AWS_COGNITO_USER_POOL = 'AWS_COGNITO_USER_POOL';

const onStoreStateChanged = () => {
  return;
};

const unsubscribe = Subscribe(onStoreStateChanged); // this is for redux

const getCognitoUserPoolEmailPasswordCreds = (userCredentials) => {
  return {
    concerto_user_credentials: userCredentials,
  };
};

const sendVerifyCognitoUserPoolLoginPostRequest = async (data) => {
  let credsData = GetUserLoginCredentialsInCognitoUserPoolFormat();
  const url = GetVerifyCognitoUserPoolLoginUrl();
  const payloadData = getCognitoUserPoolEmailPasswordCreds(credsData);

  try {
    const resp = await axios.post(url, payloadData);
    return resp;
  } catch (err) {
    console.log('sendVerifyUserLoginPostRequest to ' + url + ' failed');
  }
};

const COGNITO_USER_POOL_LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS';

function CognitoUserPoolSignin({ handleLogin }) {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState('');
  const [passwordInput, setPasswordInput] = useState('password');
  const [loading, setLoading] = useState(false);
  const [loginState, setloginState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const history = useHistory();

  function getUiCompatibilityAdaptorUserCreds(cognitoCredsFormat) {
    let u = {};

    u.email = cognitoCredsFormat.idToken.payload.email;
    u.uid = cognitoCredsFormat.accessToken.payload.username;

    let provData = [];
    let providerDataItem = {
      providerId: 'password',
      uid: cognitoCredsFormat.accessToken.payload.username,
      displayName: null,
      email: cognitoCredsFormat.idToken.payload.email,
      phoneNumber: null,
      photoURL: null,
    };

    provData.push(providerDataItem);
    u.providerData = provData;
    return u;
  }

  function handleSubmit(e) {
    e.preventDefault();

    // console.log("handleSubmit")

    const user = new CognitoUser({
      Username: emailRef.current.value,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: emailRef.current.value,
      Password: passwordRef.current.value,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        let compatData = getUiCompatibilityAdaptorUserCreds(data);

        data.authProvider = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
        data.user = compatData;

        // console.log("authenticateUser onSuccess data ", data)

        // Not a local event broadcast to whole UI
        DispatchAction(actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW, {
          what: actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
          login_event: {
            setloginStateFunc: setloginState,
            user_credentials: data,
          },
        });

        let res = sendVerifyCognitoUserPoolLoginPostRequest(data).then((res) => {
          // console.log(res.data)
          let loginAuthStatus = res?.data?.login_verification_result;
          if (loginAuthStatus === COGNITO_USER_POOL_LOGIN_AUTH_SUCCESS) {
            /*                const pathS = new URL(localStorage.getItem('path')).pathname;
                              const path = pathS !== null ? pathS : "/";*/
            const path = '/resource-maker';
            history.push(path);
            /*localStorage.removeItem('path');*/
          }
        });

        handleLogin();
      },
      onFailure: (err) => {
        console.log('authenticateUser onFailure err', err);
        toast.error(err.message)
      },
      newPasswordRequired: (data) => {
        console.log('authenticateUser newPasswordRequired data ', data);
      },
    });
  }

  return (
    <>
      <div className={classes.LoginContainer}>
        <div className={classes.leftContainer}>
          <img className={classes.LoginLogo} src={Logo} alt='' />
          <div
            className={
              `${classes.formContainer} ${classes.toggleIn}` 
            }
          >
            <div className={classes.form}>
              <div className={classes.title}>Sign in</div>
              <div className={classes.Inputs}>
                    <div>
                      <label htmlFor='email'>Email</label>
                      <div className={classes.Input}>
                        <input className={classes.inputBox} ref={emailRef} type='email' name='email' id='email' placeholder='Email' />
                      </div>
                    </div>
                    <div>
                      <label htmlFor='password'>Password</label>
                      <div className={classes.PasswordInput}>
                        <input className={classes.inputBox} type={passwordInput} ref={passwordRef}  name='password' id='password' placeholder='Password' />
                        {passwordInput === 'password' ? (
                          <img className={classes.eye} src={Eye} onClick={() => setPasswordInput('text')} />
                        ) : (
                          <img className={classes.eye} src={EyeHide} onClick={() => setPasswordInput('password')} />
                        )}
                      </div>
                    </div>
                    <div className={classes.helperLine}>
                      Forgot password?
                      <span className={classes.hint} onClick={()=>history.push(PATHS.RESET_PASSWORD)}>
                        Click here to reset
                      </span>
                    </div>
                    <div className={classes.helperLine}>
                      Don't have an account?
                      <span className={classes.hint} onClick={()=>history.push(PATHS.SIGNUP)}>
                        Signup
                      </span>
                    </div>
                    <div className='new-btn-secondary' onClick={handleSubmit} style={{height:'3vw',fontSize:'1.1vw'}}>
                      Log In 
                    </div>
                  </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* { GetMenuBar() } */}
      {/* <Container className='sign-container login-page text-center'>
        <div className='title-section d-flex'>
          <span style={{ display: 'flex', cursor: 'pointer' }} onClick={() => history.push('/home')}>
            <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
            <h4 className='text-center mb-4 micro-title'>WELCOME TO BLUEFINWARE</h4>
          </span>
        </div>

        <h2 className='text-center mb-4 title'>Signin to your account </h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3'>
            <Col sm='12'>
              <Form.Control type='text' placeholder='Email' ref={emailRef} required />
            </Col>
            <Col sm='12'>
              <div style={{ position: 'relative' }}>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  ref={passwordRef}
                  required
                  className='me-2 mt-3'
                />
                <span
                  onClick={toggleShowPassword}
                  style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </span>
              </div>
            </Col>
          </Form.Group>
          <div className='sign-button d-flex justify-content-end'>
            <Button disabled={false} className='w-100' type='submit'>
              Log In
            </Button>
          </div>
        </Form>

        <div className='w-100 text-center sign-link'>
          Don't you have an account? <Link to='/cgupsignup'>Sign Up Here</Link>
        </div>
        <div className='w-100 text-center sign-link mb-3 mt-3'>Or</div>
        <div className='w-100 text-center sign-link' style={{ margin: 0 }}>
          Forgot your password? <Link to='/forgotpassword'>Reset Here</Link>
        </div>
      </Container> */}
    </>
  );
}

export const getCredsV2 = (userCredentials) => {
  return {
    concerto_user_credentials: userCredentials,
  };
};

const sendVerifyUserLoginPostRequest = async () => {
  let userCreds = GetUserLoginCredentials()[0];

  let userCredentials = {
    displayName: userCreds.displayName,
    email: userCreds.email,
    phoneNumber: userCreds.phoneNumber,
    photoURL: userCreds.photoURL,
    providerId: userCreds.providerId,
    uid: userCreds.uid,
    user_oauth_token: GetUserLoginToken(),
  };

  const url = GetVerifyUserLoginUrl();
  try {
    const resp = await axios.post(url, getCredsV2(userCredentials));
    // console.log(resp.data)

    return resp;
  } catch (err) {
    console.log('sendVerifyUserLoginPostRequest to ' + url + ' failed');
  }
};

export default CognitoUserPoolSignin;

//
//
