import React from 'react';
import classes from './FeaturesCard.module.css';

const FeaturesCard = ({ icon,title,subtitle }) => {
  return (
    <div className={classes.FeaturesCard}>
      <div className={classes.Container}>
        <div>
          <img src={icon} alt='' />
        </div>
        <div className={classes.Title}>{title}</div>
        <div className={classes.Subtitle}>
{subtitle}
        </div>
        <div className={classes.LearnMoreContainer}>
          <div>LEARN MORE</div>
          <div className={classes.LearnMoreBorder}></div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesCard;
