import React from 'react';
import HeaderWithBg from '../../components/HeaderWithBg/HeaderWithBg';
import PricingBg from './assets/PricingBg.svg';
import classes from './Pricing.module.css';
import Footer from '../../components/Footer/Footer';
import Plans from '../../components/Plans/Plans';
import FAQ from '../../components/FAQs/FAQ';
import { COST_FAQ } from '../../newconstants';
import PricingMobileBg from '../../../images/pricingMobile.png';

const bgImage = window.innerWidth > 575 ? PricingBg : PricingMobileBg ;

const Pricing = () => {
  return (
    <div>
      <div className={classes.Header}>
        <HeaderWithBg logo={bgImage} title='PRICING' />
      </div>
      <div className={classes.Security}>
        <Plans />
        <FAQ faqItems={COST_FAQ} />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
