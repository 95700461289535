import React from 'react';
import HeaderWithBg from '../../components/HeaderWithBg/HeaderWithBg';
import UseCasesBg from './assets/UseCases.svg';
import classes from './UseCases.module.css';
import Footer from '../../components/Footer/Footer';
import CarouselComponent from '../../components/Carousel/Carousel';
import { COST_VIDEOS } from '../../LandingPage';
import UsecasesMobile from '../../../images/useCasesMobile.png';

const bgImage = window.innerWidth > 575 ? UseCasesBg : UsecasesMobile ;


const UseCases = () => {
  return (
    <div>
      <div className={classes.Header}>
        <HeaderWithBg logo={bgImage} title='USE CASES' />
      </div>
      <div className={classes.UseCases}>
        <div className={classes.CloudCardContainer}>
          <div className={classes.CloudCard}>
            <div className={classes.CloudCardTitle}>CLOUD DIAGRAMS & INVENTORY</div>
            <div className={classes.CloudCardSubTitle}>
              View your cloud infrastructure as diagrams in clean hierarchial structure or as tables. Identify Unused resources and
              excessive resource allocation . Highly useful for Devops, Development, Auditing teams
            </div>
            <div className={classes.CloudCardVideo}>
              <iframe
                width='100%' // You can adjust the width as needed
                height='100%' // You can adjust the height as needed
                src='https://www.youtube.com/embed/_ATNSC9-2OY'
                allowFullScreen
                title='ds'
              ></iframe>
            </div>
          </div>
        </div>
        <div className={classes.CostVideoContainer}>
          <div className={classes.Title}>SEE HOW IT WORKS</div>
          <div className={classes.Subtitle}>
            WHETHER YOU'RE NEW TO OUR APPLICATION OR LOOKING TO MAXIMIZE ITS POTENTIAL, THESE VIDEOS PROVIDE A COMPREHENSIVE LOOK INTO ITS
            FUNCTIONALITY. UNVEIL THE INNER WORKINGS OF OUR INNOVATION AND GAIN THE CONFIDENCE TO NAVIGATE OUR APPLICATION WITH EASE.
          </div>
          <CarouselComponent
            slides={[
              <div className={classes.CostVideosContainer}>
                {COST_VIDEOS.filter(v=>v.type!=='image').map(({ url }) => (
                  <div className={classes.CostVideo}>
                    <iframe
                      width='100%' // You can adjust the width as needed
                      height='100%' // You can adjust the height as needed
                      src={url}
                      allowFullScreen
                      title='ds'
                    />
                    {/* <div className={classes.CostVideoFooter}>
                      <div className={classes.CostVideoTitle}>{title}</div>
                      <div className={classes.CostVideoDesc}>
                        <div>{description}</div>
                        <div className={classes.LearnMoreContainer}>
                          <div>LEARN MORE</div>
                          <div className={classes.LearnMoreBorder}></div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                ))}
              </div>,
            ]}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UseCases;
