import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/LeftMenu.css';
import { useLocation } from 'react-router-dom';
import homeIcon from '../images/i-home.png';
import settingsIcon from '../images/i-settings.png';
import billingIcon from '../images/i-billing.png';
import Logo from '../images/logo.png';
import { GetUserLoginCredentials } from './UserCredentials';

function LeftMenu() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const userCreds = GetUserLoginCredentials()[0];

  return (
    <>
      <div className='left-menu-links'>
        <div className='d-flex flex-column mb-5'>
          <h5>{userCreds.displayName}</h5>
          <span>{userCreds.email}</span>
        </div>
        <Link to={`/settings/profile`} className={'link' + (splitLocation[2] === 'profile' ? ' active' : '')}>
          <i className='bi bi-person'></i> My Profile
        </Link>
        <Link to={`/settings`} className={'link' + (splitLocation[1] === 'settings' && splitLocation.length === 2 ? ' active' : '')}>
          <i className='bi bi-gear'></i> Aws keys
        </Link>
        <Link to={`/settings`} className={'link' + (splitLocation[1] === 'generateData' && splitLocation.length === 2 ? ' active' : '')}>
          <i class='bi bi-recycle'></i> Generate Data
        </Link>
        <Link to={`/settings/billing-plans`} className={'link' + (splitLocation[2] === 'billing-plans' ? ' active' : '')}>
          <i className='bi bi-receipt'> </i>
          Billings & Payments
        </Link>
        <Link to={`/settings/product-configuration`} className={'link' + (splitLocation[2] === 'product-configuration' ? ' active' : '')}>
          <i className='bi bi-gear'></i> Product Configuration
        </Link>
        <Link to={`/resource-maker`} className={'link' + (splitLocation[2] === 'aws-authentication' ? ' active' : '')}>
          <i className='bi bi-house'></i> Dashboard
        </Link>
        <Link to={`/change-password`} className={'link' + (splitLocation[1] === 'change-password' ? ' active' : '')}>
          <i className='bi bi-shield-lock'></i> Change Password
        </Link>
        <div className='company d-flex'>
          <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
          <span className='copy'>
            Bluefinware Inc.
            <br /> Copywright
          </span>
        </div>
      </div>
    </>
  );
}

export default LeftMenu;
