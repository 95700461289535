import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftMenu from "../LeftMenu";
import "../../styles/Settings/AwsAccessKeys.css";
import KeyIcon from "../../images/key.png";
import editIcon from "../../images/edit.png";
import deleteIcon from "../../images/delete.png";
import { GetMenuBar } from "../MenubarComponent";
import DropDownRegion from "../FormElements/DropDownRegion";
import {GetUserLoginCredentials, GetUserLoginToken} from "../UserCredentials";
import {GetAckSetUrl, GetMyProfileUrl} from "../../routes";
import axios from "axios";
import { Modal } from "react-bootstrap";
function AwsAccessKeys() {
  const initialKeys = [
    {
      name: "production",
      AWS_ACCOUNT_ID: "AccId001",
      AWS_ACCESS_KEY_ID: "AccessKeyId001",
      AWS_SECRET_ACCESS_KEY: "SecretAccessKeyId001",
      DEFAULT_REGION: "US",
    } ,
    {
      name: "data-science",
      AWS_ACCOUNT_ID: "AccId002",
      AWS_ACCESS_KEY_ID: "AccessKeyId002",
      AWS_SECRET_ACCESS_KEY: "SecretAccessKeyId002",
      DEFAULT_REGION: "CANADA",
    },
    {
      name: "development",
      AWS_ACCOUNT_ID: "AccId003",
      AWS_ACCESS_KEY_ID: "AccessKeyId003",
      AWS_SECRET_ACCESS_KEY: "SecretAccessKeyId003",
      DEFAULT_REGION: "INDIA",
    },
  ];
  const regionList = [
    {
      code:"us-east-2",
      name:"US East (Ohio)"
    },
    {
      code:"us-east-1",
      name:"US East (N. Virginia)"
    },
    {
      code:"us-west-1",
      name:"US West (N. California)"
    },
    {
      code:"us-west-2",
      name:"US West (Oregon)"
    },
    {
      code:"af-south-1",
      name:"Africa (Cape Town)"
    },
    {
      code:"ap-east-1",
      name:"Asia Pacific (Hong Kong)"
    },
    {
      code:"ap-southeast-3",
      name:"Asia Pacific (Jakarta)"
    },
    {
      code:"ap-south-1",
      name:"Asia Pacific (Mumbai)"
    },
    {
      code:"ap-northeast-3",
      name:"Asia Pacific (Osaka)"
    },
    {
      code:"ap-northeast-2",
      name:"Asia Pacific (Seoul)"
    },
    {
      code:"ap-southeast-1",
      name:"Asia Pacific (Singapore)"
    },
    {
      code:"ap-southeast-2",
      name:"Asia Pacific (Sydney)"
    },
    {
      code:"ap-northeast-1",
      name:"Asia Pacific (Tokyo)"
    },
    {
      code:"ca-central-1",
      name:"Canada (Central)"
    },
    {
      code:"eu-central-1",
      name:"Europe (Frankfurt)"
    },
    {
      code:"eu-west-1",
      name:"Europe (Ireland)"
    },
    {
      code:"eu-west-2",
      name:"Europe (London)"
    },
    {
      code:"eu-south-1",
      name:"Europe (Milan)"
    },
    {
      code:"eu-west-3",
      name:"Europe (Paris)"
    },
    {
      code:"eu-north-1",
      name:"Europe (Stockholm)"
    },
    {
      code:"me-south-1",
      name:"Middle East (Bahrain)"
    },
    {
      code:"sa-east-1	South",
      name:"America (São Paulo)"
    },  	
  ]

  // {
    
  //   "sa-east-1	South": "America (São Paulo)",

  // }

  const regionListMap = [
    {
      value:"us-east-2",
      name:"US East (Ohio)"
    },
    {
      value:"us-east-1",
      name:"US East (N. Virginia)"
    },
    {
      value:"us-west-1",
      name:"US West (N. California)"
    },
    {
      value:"us-west-2",
      name:"US West (Oregon)"
    },
    {
      value:"af-south-1",
      name:"Africa (Cape Town)"
    },
    {
      value:"ap-east-1",
      name:"Asia Pacific (Hong Kong)"
    },
    {
      value:"ap-southeast-3",
      name:"Asia Pacific (Jakarta)"
    },
    {
      value:"ap-south-1",
      name:"Asia Pacific (Mumbai)"
    },
    {
      value:"ap-northeast-3",
      name:"Asia Pacific (Osaka)"
    },
    {
      value:"ap-northeast-2",
      name:"Asia Pacific (Seoul)"
    },
    {
      value:"ap-southeast-1",
      name:"Asia Pacific (Singapore)"
    },
    {
      value:"ap-southeast-2",
      name:"Asia Pacific (Sydney)"
    },
    {
      value:"ap-northeast-1",
      name:"Asia Pacific (Tokyo)"
    },
    {
      value:"ca-central-1",
      name:"Canada (Central)"
    },
    {
      value:"eu-central-1",
      name:"Europe (Frankfurt)"
    },
    {
      value:"eu-west-1",
      name:"Europe (Ireland)"
    },
    {
      value:"eu-west-2",
      name:"Europe (London)"
    },
    {
      value:"eu-south-1",
      name:"Europe (Milan)"
    },
    {
      value:"eu-west-3",
      name:"Europe (Paris)"
    },
    {
      value:"eu-north-1",
      name:"Europe (Stockholm)"
    },
    {
      value:"me-south-1",
      name:"Middle East (Bahrain)"
    },
    {
      value:"sa-east-1	South",
      name:"America (São Paulo)"
    },  	
  ]
  const [showAddKeyForm, setShowAddKeyForm] = useState(false);
  const [keys, setKeys] = useState(initialKeys);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [addEditText, setAddEditText] = useState("Add Key");
  const [addkey, setAddKey] = useState({
    name: "",
    AWS_ACCOUNT_ID: "",
    AWS_ACCESS_KEY_ID: "",
    AWS_SECRET_ACCESS_KEY: "",
    DEFAULT_REGION: "",
  });
  const [selectedRegion, setSelectedRegion] = useState('');

  const readAPI = () => {
    const getAccessKeyDataRead = () => {
      addkey["data_op"] = "read"
      return addkey
    }
    const url = GetAckSetUrl()
  
    const getPayloadRead = () => {
      return {
        "concerto_user_credentials": GetLoginCredentials(),
        "ack_data": getAccessKeyDataRead()
      }
    }
    try {
        const data = axios.post(url, getPayloadRead()).then( resp => {
          console.log("resp read data >>>>>>",resp.data)
          var array = Object.keys(resp.data)
          .map(function(key) {
              return resp.data[key];
          });
          console.log("array",array);
          setKeys(array)
        });
        // console.log("data",data)
        
        // if(resp !== undefined){
            
        // }
        // console.log("useEffect",profileDetails);
        // return resp
    } catch (err) {
        console.log("onProfileSubmitRequest to " + url + " failed")
    }
   }

   
  useEffect(() => {
    // setSelectedRegion(regionList[0])
    readAPI();
  }, [])
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setAddEditText("Add Key");
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
    setAddKey({
      name: "",
      AWS_ACCOUNT_ID: "",
      AWS_ACCESS_KEY_ID: "",
      AWS_SECRET_ACCESS_KEY: "",
      DEFAULT_REGION: "",
    });
    setEditMode(false);
  }
  // const toggleForm = () => {
  //   setShowAddKeyForm(!showAddKeyForm);
  //   setAddKey({
  //     name: "",
  //     AWS_ACCOUNT_ID: "",
  //     AWS_ACCESS_KEY_ID: "",
  //     AWS_SECRET_ACCESS_KEY: "",
  //     DEFAULT_REGION: "",
  //   });
  //   setEditMode(false);
  // };

  function handleChange(evt) {
    const value = evt.target.value;
    setAddKey({
      ...addkey,
      [evt.target.name]: value,
    });
    console.log("addkey", addkey);
  }

  const GetLoginCredentials = () => {

    let userCreds = GetUserLoginCredentials()[0]
    return {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      "user_oauth_token": GetUserLoginToken()
    }
  }

  const getAccessKeyData = () => {
    addkey["data_op"] = "update"
    return addkey
  }

  const getPayload = () => {
    return {
      "concerto_user_credentials": GetLoginCredentials(),
      "ack_data": getAccessKeyData()
    }
  }

  const onAckSetDataSaveRequest = async () => {
    const url = GetAckSetUrl()

    console.log("################### onAckSetDataSaveRequest >>>>>> ")
    console.log(url)
    console.log("getPayload()",getPayload())
    try {

      const resp = await axios.post(url, getPayload());
      console.log(resp)
      return resp
    } catch (err) {
      console.log("onAckSetDataSaveRequest to " + url + " failed")
    }
  }

  function persistNewKey() {
    onAckSetDataSaveRequest()
  }

  function addKeyTolist(event) {
    event.preventDefault();
    if (editMode) {
      console.log("In Edit mode", keys);
      // setAddEditText("Update Key");
      const newKey = addkey;
      const keySet = keys.map((obj,index) => {
        if(index === editIndex){
          return {...newKey};
        }else{
          return obj
        }
      });
      console.log("keySet",keySet);
      // const updatedKey = [...keySet, newKey];
      setKeys(keySet);
      persistNewKey()
      readAPI();
      setEditMode(false);
      setAddKey({
        name: "",
        AWS_ACCOUNT_ID: "",
        AWS_ACCESS_KEY_ID: "",
        AWS_SECRET_ACCESS_KEY: "",
        DEFAULT_REGION: "",
      });
      handleClose();
    } else {
      console.log("In Add mode");
      const newKey = addkey;
      // setAddEditText("Add Key");
      console.log(addkey)

      persistNewKey()

      const keySet = [...keys];
      const updatedKey = [...keySet, newKey];
      setKeys(updatedKey);
      setAddKey({
        name: "",
        AWS_ACCOUNT_ID: "",
        AWS_ACCESS_KEY_ID: "",
        AWS_SECRET_ACCESS_KEY: "",
        DEFAULT_REGION: "",
        data_op:"update"
      });
      handleClose()
    }
    // toggleForm();

  }

  function removeKey(i) {
    const keySet = [...keys];
    const removedKeys = keySet.filter((el, index) => index !== i);
    console.log("removedKeys", removedKeys);
    setKeys(removedKeys);
  }

  function updateKey(i) {
    // toggleForm();
    handleShow()
    setAddEditText("Update Key");
    setEditIndex(i);
    setEditMode(true);
    const updateKey = {};
    updateKey.name = keys[i].FriendlyAlias;
    updateKey.AWS_ACCOUNT_ID = keys[i].AccountId;
    updateKey.AWS_ACCESS_KEY_ID = keys[i].AWS_ACCESS_KEY_ID;
    updateKey.AWS_SECRET_ACCESS_KEY = keys[i].AWS_SECRET_ACCESS_KEY;
    updateKey.DEFAULT_REGION = keys[i].DEFAULT_REGION;
    console.log("updateKey", updateKey);
    setAddKey(updateKey);
  }

  
  const getSelectedRegion = (event) => {
    console.log("event.target.value",event.target.value);

    let country = event.target.value;

    setSelectedRegion(country);


    console.log("======== setSelectedRegion ", selectedRegion)
    // storeParam = event.target.value;
    // fetchVendorList();
    //fetchCategoryList()
  }



  let defaultRegionVar = ""

  const onDropDownSelected = (event) => {
    console.log("event ===== ", event)
    // inputValuesStore[elementId] = event
    // onContextDataChanged()
    defaultRegionVar = event;
}


  return (
    <>
      { GetMenuBar() }
      <Container className="small  mt-5">
        <Row>
          <Col xs lg="3">
            <LeftMenu />
          </Col>
          <Col xs lg="9">
            <div className="addKey">
              <div className="access-key-title d-flex justify-content-between">
                <h3>SSH keys</h3>
                <button className="add-key ml-auto" onClick={handleShow}>
                  + ADD NEW
                </button>
              </div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>SSH keys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="add-key-form">
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          name="name"
                          value={addkey.name}
                          onChange={handleChange}
                          placeholder="Enter Name"
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="text"
                          name="AWS_ACCOUNT_ID"
                          value={addkey.AWS_ACCOUNT_ID}
                          onChange={handleChange}
                          placeholder="Enter Account Id"
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="text"
                          name="AWS_ACCESS_KEY_ID"
                          value={addkey.AWS_ACCESS_KEY_ID}
                          onChange={handleChange}
                          placeholder="Enter Access Key Id"
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="text"
                          name="AWS_SECRET_ACCESS_KEY"
                          value={addkey.AWS_SECRET_ACCESS_KEY}
                          onChange={handleChange}
                          placeholder="Enter Secret Access Key"
                        />
                      </Form.Group>
                      {/* <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="text"
                          name="DEFAULT_REGION"
                          value={addkey.DEFAULT_REGION}
                          onChange={handleChange}
                          placeholder="Enter Defalut Region"
                        />
                      </Form.Group> */}

                      <DropdownButton
                                  alignRight
                                  title={"AWS Regions"}
                                  id="dropdown-menu-align-right"
                                  onSelect={onDropDownSelected}
                                  defaultValue={defaultRegionVar}

                              >
                                  {regionListMap.map((dropDownItem, index) => (
                                      <Dropdown.Item eventKey={dropDownItem.value} key={index}> {dropDownItem.name}  </Dropdown.Item>
                                  ))}
                              </DropdownButton>

                      {/* <div className="">
                        <DropDownRegion
                          labelProp="Default Region"
                          listValues={regionList}
                          listId="storeId"
                          listName="name"
                          listCode="code"
                          listKey="storeId"
                          onChangeProp={getSelectedRegion}
                          selectedValue={selectedRegion}
                          classProp="field"
                          selectClass="" />
                      </div> */}
                      <Button
                      style={{float:"right"}}
                        variant="primary"
                        // type="submit"
                        onClick={addKeyTolist}
                      >
                        {addEditText}
                      </Button>
                    </Form>
                  </div>
                </Modal.Body>
              </Modal>
              {/* {showAddKeyForm && (
               
              )} */}
              <div className="access-keys-page">
                <div className="access-key-details">
                  {keys.map((key, index) => (
                    <div className="single-key d-flex" key={index}>
                      <div className="image">
                        <img src={KeyIcon} />
                      </div>
                      <div className="key-detail">
                        <span className="bold text-capitalize">{key.FriendlyAlias}</span>
                        <span>{key.AccountId}</span>
                        <span>{key.DefaultRegion}</span>
                      </div>
                      <div className="events ml-auto">
                        <button
                          className="update"
                          onClick={() => updateKey(index)}
                        >
                          {/* <img src={editIcon} /> */}
                          Edit
                        </button>
                        <button
                          className="delete"
                          onClick={() => removeKey(index)}
                        >
                          Delete
                          {/* <img src={deleteIcon} /> */}
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* <input type="text" value={key.title} onChange={(event) => changeTitle(event,index)} placeholder="type a key title here"/>
                  <input type="text" value={key.description} onChange={(event) => changeDescription(event,index)} placeholder="type a key description here"/>
                  <input type="text" value={key.description} onChange={(event) => changeDescription(event,index)} placeholder="type a key description here"/>
                  <input type="text" value={key.description} onChange={(event) => changeDescription(event,index)} placeholder="type a key description here"/> */}
                </div>
              </div>
              <div className="buttons">
                {/* <button className="add-key" onClick={addKey}>
                  Add a New Key
                </button>
                <button className="save" onClick={saveKeys}>
                  Save
                </button> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AwsAccessKeys;
