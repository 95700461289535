import React from "react";
import AddressItem from "./AddressItem";

function AddressInput(props) {
  return (
      <div className="card">
        <div className="card-body">
        <AddressItem
          parentCallback={props.parentCallback}
          label="Street"
          name="street"
          value={props.street}
          handleChange={props.handleChange}
          placeholder=""
          readonly="true"
        />
        <AddressItem
          parentCallback={props.parentCallback}
          label="City"
          name="city"
          handleChange={props.handleChange}
          value={props.city}
          placeholder=""
          readonly="true"
        />
        <AddressItem
          parentCallback={props.parentCallback}
          label="State"
          name="state"
          handleChange={props.handleChange}
          value={props.state}
          placeholder=""
          readonly="true"
        />
        <AddressItem
          parentCallback={props.parentCallback}
          label="Postal Code"
          name="postal_code"
          handleChange={props.handleChange}
          value={props.code}
          placeholder=""
          readonly="true"
        />
        <AddressItem
          handleCountryChange={props.handleCountryChange}
          country={true}
          label="Country"
          name="country"
          // handleChange={props.handleChange}
          value={props.country}
          placeholder=""
          readonly="true"
        />

        </div>
      </div>
  );
}

export default AddressInput;
