import { GetState } from './statemanagement/store';
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from './CognitoUserPoolSignin';
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from './Login';

export const GetLoginCredentialsAsJsonObject = () => {
  let userCreds = GetUserLoginCredentials()[0];
  let authProviderTypeStr = '';

  if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
    authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
  }
  if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
    authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
  }

  let credJson = {
    displayName: userCreds?.displayName,
    email: userCreds?.email,
    phoneNumber: userCreds?.phoneNumber,
    photoURL: userCreds?.photoURL,
    providerId: userCreds?.providerId,
    uid: userCreds?.uid,
    user_oauth_token: GetUserLoginToken(),
    authProviderType: authProviderTypeStr,
  };

  if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
    credJson.cognito_user_pool_email_password_info = GetCognitoUserTokenCredentials();
  }

  return credJson;
};

export const GetUserLoginToken = () => {
  let state = GetState();
  // console.log({ state });
  if (!state.userLoginInfo) {
    console.log({ returned: true });
    return null;
  }
  let payload = state.userLoginInfo.payload;
  let loginEvent = payload.login_event;
  const userCredential = loginEvent.user_credentials;
  let usrIdToken = null;

  // console.log({ userCredential, tokenResp: userCredential._tokenResponse });

  if (userCredential && userCredential._tokenResponse && userCredential._tokenResponse.idToken) {
    usrIdToken = userCredential._tokenResponse.idToken;
    // console.log(userCredential._tokenResponse.idToken)
    return usrIdToken;
  }

  return usrIdToken;
};

export const GetUserLoginCredentials = () => {
  let state = GetState();
  let payload = state?.userLoginInfo?.payload;
  let loginEvent = payload?.login_event;
  const userCredential = loginEvent?.user_credentials;
  // let usrIdToken = null

  return userCredential?.user?.providerData || {};
};

export const GetCognitoUserPoolEmailPasswordAuthTokenInfo = () => {
  let state = GetState();
  let payload = state.userLoginInfo.payload;
  let loginEvent = payload.login_event;
  return loginEvent.user_credentials;
};

export const GetAuthProvider = () => {
  let state = GetState();
  let payload = state?.userLoginInfo?.payload;
  let loginEvent = payload?.login_event;
  return loginEvent?.user_credentials?.authProvider || {};
};

export const GetCognitoUserTokenCredentials = () => {
  let state = GetState();
  let payload = state.userLoginInfo.payload;
  let loginEvent = payload.login_event;
  return loginEvent.user_credentials;
};

export const IsAuthTypeCognitoUserPoolEmailPasswordAuthType = () => {
  return GetAuthProvider() === AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
};

export const IsAuthTypeFirebaseEmailPasswordAuthType = () => {
  return GetAuthProvider() === AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
};

export const GetUserLoginCredentialsInCognitoUserPoolFormat = () => {
  let state = GetState();
  let payload = state.userLoginInfo.payload;
  let loginEvent = payload.login_event;
  const userCredential = loginEvent.user_credentials;

  if (userCredential.authProvider) {
    if (userCredential.authProvider === AUTH_PROVIDER_AWS_COGNITO_USER_POOL) {
      return userCredential;
    }
  }

  return userCredential.user.providerData;
};

//
//
