import React, {createRef} from "react";
import {Button, Card, Form, Row} from "react-bootstrap";
import uniqid from "uniqid";
import axios from "axios";
import {GetResourcesOverviewUiCacheUrl, GetSyncFromCloudURL, GetSyncFromCloudV2URL} from "../routes";
import {ComposeCloudResourceDiagram} from "./CloudResourcesDiagramComposer";
import {GetUserLoginCredentials, GetUserLoginToken} from "./UserCredentials";
import {ClearResourceEditorCanvas, getMainDiagramHandle, ShowNodes} from "./ResourceEditorCanvas";



//const getAWSTestU02AccessKey = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_MESSAGING_SENDER_ID }

const getAWSTestU02AccessKey = () => { return process.env.REACT_APP_AWS_ENG_USER2_AWS_ACCESS_KEY_ID }
const getAWSTestU02SecretKey = () => { return process.env.REACT_APP_AWS_ENG_USER2_AWS_SECRET_ACCESS_KEY }
const getAWSTestU02DefaultRegion = () => { return process.env.REACT_APP_AWS_ENG_USER2_DEFAULT_REGION }

const USE_DEBUG_ACCOUNT = true

export const getSyncFromCloudCard2 = () => {

    let currentAccessIdVal = ""
    let currentSecretKeyVal = ""

    let accessIdRef = createRef()
    let secretKeyRef = createRef()

    const onAccessIdTextChangedHandler = (event) => {
        // console.log(accessIdRef.current.value)
        currentAccessIdVal = accessIdRef.current.value
    }

    const onSecretKeyTextChangedHandler = (event) => {
        // console.log(secretKeyRef.current.value)
        currentSecretKeyVal = secretKeyRef.current.value
    }

    const getTestDebugCredentials = () => {
        return {
            CREDENTIALS_TYPE: "AWS_CLOUD_CREDENTIALS",
            ACCESS_ID: getAWSTestU02AccessKey(),
            SECRET_KEY: getAWSTestU02SecretKey(),
            REGION_LIST: [getAWSTestU02DefaultRegion()]
        }
    }

    const getUserGivenCredentials = () => {
        return {
            CREDENTIALS_TYPE: "AWS_CLOUD_CREDENTIALS",
            ACCESS_ID: currentAccessIdVal,
            SECRET_KEY: currentSecretKeyVal,
            REGION_LIST: []
        }
    }

    const createCloudCredentialsPayload = (credentialsList) => {
        return {"cloud_credentials_list": credentialsList}
    }

    const getAWSCredentialsPayload = () => {

        let credentialsList = []

        if(USE_DEBUG_ACCOUNT) {
            console.log("###### CONCERTO_WARNING!! debug account credentials used")
            credentialsList.push(getTestDebugCredentials())
        }

        else {
            console.log("User Given account credentials used")
            credentialsList.push(getUserGivenCredentials())
        }

        return createCloudCredentialsPayload(credentialsList)
    }


    const asyncUpdateDrawingBoard = (cloudResourcesDataJsonStr) => {
        // console.log("$$%%")
        // console.log(cloudResourcesDataJsonStr)
        ComposeCloudResourceDiagram(cloudResourcesDataJsonStr)
    }
/*
{
    "command_to_execute": "read_all_resources",
    "command_args": {
        "resources_read_params_list": [
            {
                "resources_provider_name": "AWS",
                "account_info": {
                    "id": "23423",
                    "region": "ap-south-1",
                    "credentials" :[
                        {
                            "credential_type": "AWS_ACCESS_KEY_ID",
                            "credential_value": "AKIATAJ5WXKTO42ZLHFC"
                        },
                        {
                            "credential_type": "AWS_SECRET_ACCESS_KEY",
                            "credential_value": "tw20/Xjm+Th3RmwDse+KbBaQgXV5mVNvTpahTi+y"
                        }
                    ]
                }
            }
        ]
    }
}
 */

    const getCredsV2 = (userCredentials) => {

        return {
            "concerto_user_credentials": userCredentials,
            "command_to_execute": "read_all_resources",
            "command_args": {
                "resources_read_params_list": [
                    {
                        "resources_provider_name": "AWS",
                        "account_info": {
                            "id": "23423",
                            "region": "ap-south-1",
                            "credentials" :[
                                {
                                    "credential_type": "AWS_ACCESS_KEY_ID",
                                    "credential_value": getAWSTestU02AccessKey()
                                },
                                {
                                    "credential_type": "AWS_SECRET_ACCESS_KEY",
                                    "credential_value": getAWSTestU02SecretKey()
                                }
                            ]
                        }
                    }
                ]
            }
        }
    }


    const getAsGojsFormattedNodes = (nodesListObj, uiAssetsInfo, awsUiAssetsBaseUrl) => {
        let imagePathsMap = {}

        uiAssetsInfo.map((uai) => {
            let resourceType = uai.resource_type
            let imageFullPath = awsUiAssetsBaseUrl+"/"+uai.image_path
            imagePathsMap[resourceType] = imageFullPath
        });

        let nodes = nodesListObj.nodes_list
        let tmpGjNodeList = []

        nodes.map((n) => {
            let gojsn = {}
            gojsn.nodeId = n.nodeId
            gojsn.isGroupNode = n.isGroupNode
            gojsn.parent = n.parentNodeId
            gojsn.imagePath = imagePathsMap[n.nodeType]
            gojsn.nodeContextInfo = n.nodeContextInfo
            tmpGjNodeList.push(gojsn)
        });

        ShowNodes(getMainDiagramHandle(), tmpGjNodeList)
    }


    const drawNodes = (nodesData) => {

        let resourcesOverviewCacheList = nodesData.resources_overview_cache_list
        let uiAssetsInfo = nodesData.ui_assets_info.aws_resource_ui_paths
        let awsUiAssetsBaseUrl = nodesData.ui_assets_info.aws_ui_assets_base_url

        for(let i=0; i<resourcesOverviewCacheList.length; i++) {
            let nodesList =  resourcesOverviewCacheList[i]
            getAsGojsFormattedNodes(nodesList, uiAssetsInfo, awsUiAssetsBaseUrl)
        }
    }

    const sendSyncFromCloudHttpPostRequest = async () => {
        let userCreds =  GetUserLoginCredentials()[0]

        let userCredentials ={
            displayName: userCreds.displayName,
            email: userCreds.email ,
            phoneNumber: userCreds.phoneNumber ,
            photoURL: userCreds.photoURL ,
            providerId: userCreds.providerId ,
            uid: userCreds.uid ,

            "user_oauth_token": GetUserLoginToken()
        }

        const url = GetResourcesOverviewUiCacheUrl()

        console.log("################### sendSyncFromCloudHttpPostRequest >>>>>> ")
        console.log(url)

        try {

            const resp = await axios.post(url, getCredsV2(userCredentials));
            // console.log(resp.data)

            let cloudResourcesListObj = resp.data.accounts_list
            console.log(cloudResourcesListObj)
            drawNodes(resp.data)
            console.log(resp.data)

            return cloudResourcesListObj
        } catch (err) {
            console.log("sendSyncFromCloudHttpPostRequest to " + url + " failed")
        }
    }

    const onSyncFromCloudClicked = () => {
        console.log(currentAccessIdVal)
        console.log(currentSecretKeyVal)
        let resp  = sendSyncFromCloudHttpPostRequest()
    }

    const getSyncFromCloudButton = () => {
        return (
            <>
                <Button onClick={() => onSyncFromCloudClicked()}>
                    Sync From Cloud
                </Button>
            </>
        )
    }

    const getTextFieldContent = (label, valRef, placeHolder, defaultVal, onTxtValChangedHandler) => {
        return (
            <>
                <Form.Label>{<b>{label}</b>}</Form.Label>
                <Form.Control type="text" ref={valRef} placeholder={placeHolder}
                              defaultValue={defaultVal}
                              onChange={onTxtValChangedHandler}
                />
                <Row> <h6> </h6></Row>
            </>
        )
    }

    return (
        <Card>
            <Form.Group className="mb-1" id={uniqid()}>

                {getTextFieldContent("ACCESS ID", accessIdRef, "Enter AWS ACCESS ID",
                    currentAccessIdVal, onAccessIdTextChangedHandler)}

                {getTextFieldContent("SECRET KEY", secretKeyRef, "Enter AWS SECRET KEY",
                    currentSecretKeyVal, onSecretKeyTextChangedHandler)}

                {getSyncFromCloudButton()}

            </Form.Group>
        </Card>
    )
}
