
import "../../styles/ContextBar.css"

import {
    ContextBarGetNewDropDownField,
    ContextBarGetNewTextInputField,
    FIELD_APPENDING_TYPE,
    FIELD_VALUES, UI_FIELDS_DEFINITION,
    CreateContextBarUiHandler
} from "../ContextBarHandlerBuilder";

const protocol_types_list = ["HTTP", "WEBSOCKET"]
const NAME_ID = "name"
const DESCRIPTION_ID = "description"
const PROTOCOL_TYPE_ID = "protocol-type"

const uiFieldsDefinition = [
    ContextBarGetNewTextInputField(
        "API Gateway Name", NAME_ID,
        FIELD_APPENDING_TYPE.NO_APPENDING,
        "Name of API Gateway no spaces", FIELD_VALUES.EMPTRY_STR
    ),

    ContextBarGetNewTextInputField(
        "Description for API gateway", DESCRIPTION_ID,
        FIELD_APPENDING_TYPE.NO_APPENDING,
        "Short Description of Gateway", FIELD_VALUES.EMPTRY_STR
    ),

    ContextBarGetNewDropDownField(
        "Protocol Type", PROTOCOL_TYPE_ID,
        FIELD_APPENDING_TYPE.NO_APPENDING,
        protocol_types_list, FIELD_VALUES.EMPTRY_STR
    )
]

const contextBarDefinition = {
    [UI_FIELDS_DEFINITION]: uiFieldsDefinition,
}


export const ApiGatewayContextHandler = (contextBarStateStore, setContextBarStateStore) => {
    const formContent = CreateContextBarUiHandler(contextBarDefinition)

    return (
        <div>
            {formContent}
        </div>
    )

}
