import React, { useContext, useEffect, useState } from 'react';
import { getCurrentActiveContextBarNodeInfo } from '../ContextBarHandlerBuilder';
import { BaseContextBarBuilder, GetSimpleKeyPairValueElement } from './ContextBarBuilder';
import { GetState } from '../statemanagement/store';
import { EC2_INSTANCE_RESOURCE_INFO_SHOW_CONTEXT, RDS_RESOURCE_INFO_SHOW_CONTEXT } from '../statemanagement/ResourceContextSetter';
import { GetAssetInfoUrl, GetLatestResourcesInfoUrl, GetSetResourceParamsUrl } from '../../routes';
import axios from 'axios';
import { GetAssetsInfoPayload, GetLatestContextInfoPayload, PrepareSetResourceParamsPayload } from './Ec2ContextBarHandler';
import { GetLoginCredentialsAsJsonObject } from '../UserCredentials';
import { GetGlobalResourceInfoCache } from '../NestedAccordion/ResourceBrowser';
import EditableDynamicForm from '../../newComponents/EditableDynamicForm/EditableDynamicForm';
import { ConfirmationModalContext } from '../../context/ConfirmationModalContext';
import { Button, Dropdown } from 'react-bootstrap';
import { Divider, Input, Tabs } from 'antd';
import { startCase } from 'lodash';
import LineChart from '../CostManager/linechart.d3';

const RdshowInfoBarHandler = () => {
  let resourceProperties = [
    'AvailabilityZone',
    'BackupRetentionPeriod',
    'BackupTarget',
    'DBInstanceArn',
    'DBInstanceClass',
    'DBInstanceIdentifier',
    'DBInstanceStatus',
    'DBName',
    'DbInstancePort',
    'DeletionProtection',
    'Engine',
    'EngineVersion',
    'LicenseModel',
    'MaxAllocatedStorage',
    'PubliclyAccessible',
    'SecondaryAvailabilityZone',
    'Timezone',
    'VpcId',
  ];

  const displayFields = [];
  const title = 'RDS Database';

  for (let i = 0; i < resourceProperties.length; i++) {
    let labelStr = resourceProperties[i];
    let keyStr = resourceProperties[i];
    displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr));
  }

  return BaseContextBarBuilder(title, displayFields);
};

const rdsInstanceNodeType = 'rds_db_node';

const getAccountUserIdForRdsDb = (resourcedId) => {
  let gric = GetGlobalResourceInfoCache();
  let accountsList = gric.connection_diagram_info;

  for (var i = 0; i < accountsList.length; i++) {
    let nodesInfoOfSingleAccount = accountsList[i];

    let accountId = nodesInfoOfSingleAccount.network_connection_info.aws_account_id;
    let awsRegion = nodesInfoOfSingleAccount.network_connection_info.aws_region;
    let concertoUserId = nodesInfoOfSingleAccount.network_connection_info.concerto_user_id;

    let nodesList = nodesInfoOfSingleAccount.network_connection_info.nodes_list;

    for (var j = 0; j < nodesList.length; j++) {
      let node = nodesList[j];

      if (node.nodeType !== rdsInstanceNodeType) {
        continue;
      }
      let curRdsIdArn = node.nodeContextInfo.DBInstanceArn;
      if (curRdsIdArn === resourcedId) {
        return { accountId: accountId, awsRegion: awsRegion, concertoUserId: concertoUserId, rdsDbArn: curRdsIdArn };
      }
    }
  }

  return null;
};

const processAssetInfoForDynamicForm = (assets_info) => {
  const prefilledValues = {};
  Object.keys(assets_info).forEach((key) => {
    prefilledValues[key] = assets_info[key];
  });
  const inputFields = Object.keys(assets_info).map((key) => {
    return { name: key, label: key, id: `${key}Input` };
  });

  return { inputFields, prefilledValues };
};

const RdsAssetHandler = ({ node_info: nodeInfo }) => {
  const formKey = JSON.stringify(nodeInfo);
  let imgPath = nodeInfo.node_data.imgPath;
  const [assetsInfo, setAssetsInfo] = useState({});
  const [cpuPerformanceData, setCpuPerformanceData] = useState({});
  const [viewGraphData, setViewGraphData] = useState({});

  useEffect(() => {
    getAssetsInfo();
  }, [nodeInfo]);

  useEffect(() => {
    if (viewGraphData) getCpuPerformanceInfo();
  }, [nodeInfo, viewGraphData]);

  const getAssetsInfo = async () => {
    let ids = getRdsDbIdentifier();
    const response = await getRdsAssetInfo(ids.dbArn);
    if (response.data) setAssetsInfo(response?.data?.assets_info);
  };

  const getCpuPerformanceInfo = async () => {
    let performanceData = await getRdsAssetPerformance();
    const { cpu_stats_info } = performanceData?.data || {};
    const timeStampArray = cpu_stats_info?.[0].Timestamps;

    let seriesArray = [];
    if (timeStampArray?.length) {
      seriesArray = cpu_stats_info?.map((eachInfo) => ({
        name: eachInfo.Label,
        data: eachInfo.Values,
      }));
      setCpuPerformanceData({
        seriesArray,
        timeStampArray,
      });
    }
  };

  const onSave = (formValues) => {
    let testVal = 0;
    let allTagsList = [];
    for (let tagKey in formValues) {
      let val = formValues[tagKey];
      let key = tagKey;
      let kvPair = [key, val];
      allTagsList.push(kvPair);
    }

    if (allTagsList.length < 1) return;

    return updateRdsDbInstanceAssetInfo(allTagsList);
  };

  const updateRdsDbInstanceAssetInfo = (assetsInfoTags) => {
    let rdsDbIds = getRdsDbIdentifier();
    const dbArn = rdsDbIds.dbArn;

    let resourceLocator = getAccountUserIdForRdsDb(dbArn);
    resourceLocator.dbIdentifier = rdsDbIds.dbIdentifier;
    resourceLocator.assetsInfoTags = assetsInfoTags;

    let rsStr = JSON.stringify(resourceLocator);

    let userCredentials = GetLoginCredentialsAsJsonObject();
    const url = GetAssetInfoUrl();
    const payload = GetAssetsInfoPayload(userCredentials, [UPDATE_RDS_DB_INSTANCE_ASSET_INFO_COMMAND], rsStr);
    let resp = axios.post(url, payload);
    // console.log(resp)
    return resp;
  };

  return (
    <div className='contextbar-actionContainer'>
      <div className='context-bar-head'>
        <div className='flex'>
          <img alt='' src={imgPath} width='48' height='48' />
        </div>
        <div>
          <h4 className='context-bar-title'>RDS Database</h4>
        </div>
      </div>
      <br />
      <div className='node-property'></div>
      <div>
        <EditableDynamicForm key={formKey} {...processAssetInfoForDynamicForm(assetsInfo)} onSave={onSave} />
      </div>

      <br />
      <div className='node-property'></div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 10px', marginTop: '10px' }}>
        <h6 className='context-bar-title'>CPU Usage</h6>

        <Button className='search-button' onClick={() => setViewGraphData((p) => !p)}>
          {!viewGraphData ? 'View Graph' : 'Hide Graph'}
        </Button>
      </div>
      {viewGraphData ? (
        <div>
          <LineChart
            seriesArray={cpuPerformanceData.seriesArray || []}
            timeStampArray={cpuPerformanceData.timeStampArray || []}
            formatterFunction='roundDecimal'
          />
        </div>
      ) : null}
    </div>
  );
};

const STOP_TEMPORARILY_RDS_DB_INSTANCE = 'STOP_TEMPORARILY_RDS_DB_INSTANCE';
const START_RDS_DB_INSTANCE = 'START_RDS_DB_INSTANCE';
const REBOOT_RDS_DB_INSTANCE = 'REBOOT_RDS_DB_INSTANCE';

const UPDATE_RDS_DB_INSTANCE_ASSET_INFO_COMMAND = 'update_rds_db_instance_asset_info';

const getRdsDbActionParams = (newState) => {
  let rdsDbActionParams = {
    resourceType: 'RdsDbInstanceType',
    command: 'SetRdsDbInstanceState',
    params: newState,
  };
  return rdsDbActionParams;
};

const moveRdsDbStateTo = async (nodesListContextInfo, newState) => {
  // console.log('moveRdsDbStateTo requested ', newState);

  const payload = PrepareSetResourceParamsPayload(nodesListContextInfo);
  const url = GetSetResourceParamsUrl();

  payload.actionParams = getRdsDbActionParams(newState);

  try {
    const resp = await axios.post(url, payload);
    // console.log(resp);
    return;
  } catch (err) {
    console.log(err);
    console.log('moveRdsDbStateTo to ' + url + ' failed');
  }
};

const getRdsDbNodesListContextInfo = () => {
  console.log('getRdsDbNodesListContextInfo');
  let state = GetState();
  if (state.contextBarInfo && state.contextBarInfo.payload) {
    let nodeContextInfo = state.contextBarInfo.payload;

    let a = [];
    var rdsDbNodeInfo = { nodeContextInfo: nodeContextInfo, nodeContextInfoType: RDS_RESOURCE_INFO_SHOW_CONTEXT };
    a.push(rdsDbNodeInfo);
    return a;
  }
  return [];
};

const onStopClicked = () => {
  moveRdsDbStateTo(getRdsDbNodesListContextInfo(), STOP_TEMPORARILY_RDS_DB_INSTANCE);
};

const onStartClicked = () => {
  moveRdsDbStateTo(getRdsDbNodesListContextInfo(), START_RDS_DB_INSTANCE);
};

const onRebootClicked = () => {
  moveRdsDbStateTo(getRdsDbNodesListContextInfo(), REBOOT_RDS_DB_INSTANCE);
};

const RdsActionHandler = ({ node_info: nodeInfo }) => {
  const { openModal } = useContext(ConfirmationModalContext);
  const [latestResourceData, setLatestResourceData] = useState({});
  let imgPath = nodeInfo.node_data.imgPath;

  useEffect(() => {
    getLatestResourceInfo();
  }, [nodeInfo]);

  const getLatestResourceInfo = async () => {
    let resp = await getRdsLatestResourceInfo();
    setLatestResourceData(resp.data);
  };

  const latestData = latestResourceData?.latestResourcesInfoList?.[0]?.resource_info_data?.[0] || {};

  const getLatestResourceWithDelay = () => {
    let timeoutId;
    const clearTimer = () => clearTimeout(timeoutId);
    timeoutId = setTimeout(async () => {
      getLatestResourceInfo();
      clearTimer();
    }, 30000);
    return timeoutId;
  };

  const handleChangeRdsInstanceStatus = async (action) => {
    openModal(`Are you sure you want to ${action} the RDS db?`, () => {
      switch (action) {
        case ACTION_START:
          onStartClicked();
          getLatestResourceWithDelay();
          break;
        case ACTION_STOP_TEMPORARILY:
          onStopClicked();
          getLatestResourceWithDelay();
          break;
        case ACTION_REBOOT:
          onRebootClicked();
          getLatestResourceWithDelay();
          break;
        default:
          console.log('Unknown state change request for RDS instance action == ', action);
          break;
      }
    });
  };

  const ACTION_STOP_TEMPORARILY = 'stop_temporarily';
  const ACTION_START = 'start';
  const ACTION_REBOOT = 'reboot';

  const getNextPossibleStates = () => {
    const { DBInstanceStatus } = latestData;
    if (DBInstanceStatus === 'available') return [ACTION_STOP_TEMPORARILY, ACTION_REBOOT];
    else if (DBInstanceStatus === 'stopped') return [ACTION_START];
    else return [];
  };

  const getCurrentState = () => {
    const { DBInstanceStatus } = latestData;
    return DBInstanceStatus?.toUpperCase();
  };

  return (
    <div className='contextbar-actionContainer'>
      <div className='context-bar-head'>
        <div className='flex'>
          <img alt='' src={imgPath} width='48' height='48' />
        </div>
        <div>
          <h4 className='context-bar-title'>RDS Database</h4>
          <div className='context-bar-subtitle'>{latestData?.DBInstanceIdentifier}</div>
        </div>
      </div>

      <br />

      <Divider style={{ margin: '1.5vw 0' }} />
      <div style={{ padding: '0 1vw' }}>
        <label htmlFor='Current_State'>Current State</label>
        <Input placeholder='Current_State' id='Current_State' value={getCurrentState()} disabled />
      </div>
      <div className='contextbar-dropdownContainer'>
        <Dropdown drop='down'>
          <Dropdown.Toggle id='dropdown-basic'>Change State</Dropdown.Toggle>

          <Dropdown.Menu>
            {getNextPossibleStates()?.map((eachState) => (
              <Dropdown.Item onClick={() => handleChangeRdsInstanceStatus(eachState)}>{startCase(eachState)}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export const rdsContextBarItems = [
  { label: 'Info', children: <RdshowInfoBarHandler /> },
  { label: 'Asset', children: <RdsAssetHandler /> },
  { label: 'Action', children: <RdsActionHandler /> },
];

export const getRdsLatestResourceInfo = async () => {
  let state = GetState();
  if (state.contextBarInfo && state.contextBarInfo.payload) {
    let nodeContextInfo = state.contextBarInfo.payload;
    const payload = GetLatestContextInfoPayload([
      { nodeContextInfo: nodeContextInfo, nodeContextInfoType: RDS_RESOURCE_INFO_SHOW_CONTEXT },
    ]);

    const url = GetLatestResourcesInfoUrl();
    try {
      const resp = await axios.post(url, payload);
      return resp;
    } catch (err) {
      console.log(err);
      console.log('sendSyncFromCloudHttpPostRequest to ' + url + ' failed');
    }
  }
};

const getRdsAssetInfo = (dbArn) => {
  let resourceLocator = getAccountUserIdForRdsDb(dbArn);
  let rsStr = JSON.stringify(resourceLocator);
  let userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetAssetInfoUrl();
  const payload = GetAssetsInfoPayload(userCredentials, ['get_rds_asset_info'], rsStr);
  let resp = axios.post(url, payload);
  return resp;
};

const getRdsDbIdentifier = () => {
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
  const dbArn = currentContextBarNodeInfo.node_data.nodeContextInfo.DBInstanceArn;
  const dbIdentifier = currentContextBarNodeInfo.node_data.nodeContextInfo.DBInstanceIdentifier;

  let dbIds = { dbArn: dbArn, dbIdentifier: dbIdentifier };

  return dbIds;
};

const GET_RDS_ASSET_PERFORMANCE_INFO = 'get_rds_cpu_performance_info';

export const getRdsAssetPerformance = () => {
  let rdsDbIds = getRdsDbIdentifier();
  const dbArn = rdsDbIds.dbArn;

  let resourceLocator = getAccountUserIdForRdsDb(dbArn);
  resourceLocator.dbIdentifier = rdsDbIds.dbIdentifier;

  let rsStr = JSON.stringify(resourceLocator);
  let userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetAssetInfoUrl();
  const payload = GetAssetsInfoPayload(userCredentials, [GET_RDS_ASSET_PERFORMANCE_INFO], rsStr);
  return axios.post(url, payload);
};
