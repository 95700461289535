export const customDropdownStyles = {
  container: (provided, state) => ({
    ...provided,
    width: state?.selectProps?.name === 'Regions' ? '567px' : state.isMulti ? '456px' : '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    height: state.isMulti ? 'auto' : '48px',
    paddingTop: state.isMulti ? '5px !Important' : '0px',
    paddingBottom: state.isMulti ? '5px !Important' : '0px',
    borderRadius: '4px',
    padding: '0px 16px',
    backgroundColor: '#F9FAFB',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
    height: state.isMulti ? 'auto' : '24px',
    overflow: state.isMulti ? 'inherit' : 'hidden',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#6B7280',
    fontSize: '14px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#6B7280',
    fontSize: '14px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
};
