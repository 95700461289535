import React from 'react';
import { Container } from 'react-bootstrap';
import union from '../images/union.png';
import facebook from '../images/facebook.png';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ContactUsModal from './ContactUs/ContactUs';

function Footer() {
  const [contactUsModal, setContactUsModal] = useState(false);
  return (
    <footer>
      <Container className='small'>
        <div className='footer-content d-flex'>
          <div className='social d-flex flex-column'>
            <div className='company d-flex'>
              <img src={union} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
              <span className=''>
                Bluefinware Inc.
                <br /> Copyright
              </span>
            </div>
            <div className='social-links'>
              <a href='#'>
                <img src={facebook} width='32' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
              </a>
              <a href='#'>
                <img src={linkedin} width='32' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
              </a>
              <a href='#'>
                <img src={twitter} width='32' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
              </a>
            </div>
          </div>
          <div className='usecases'>
            <h3>Usecases</h3>
            <ul>
              <li>
                <a href='#'>Cloud diagrams & inventory</a>
              </li>
              <li>
                <a href='#'>Cloud Security & Compliance</a>
              </li>
            </ul>
          </div>
          <div className='legal'>
            <h3>Legal</h3>
            <ul>
              <li>
                <Link to={'/legal/termsAndConditions'} target='_blank'>
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to={'/legal/privacy'} target='_blank'>
                  Privacy
                </Link>
              </li>
              <li>
                <Link to={'/legal/refundPolicy'} target='_blank'>
                  Cancellation & Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className='pricing'>
            <h3>Pricing</h3>
            <ul>
              <li>
                <a href='#'>For Organizations</a>
              </li>
              <li>
                <a href='#'>Personal use</a>
              </li>
            </ul>
          </div>
          <div className='contact d-flex flex-column'>
            <span>
              Bluefinware Inc. Bengaluru, Karnataka <br /> India
            </span>
            {/*<button onClick={() => setContactUsModal((p) => !p)}>CONTACT US</button>*/}
            {/*{contactUsModal && <ContactUsModal setShowModal={setContactUsModal} showModal={contactUsModal} />}*/}
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
