import React from 'react';
import HeaderWithBg from '../../components/HeaderWithBg/HeaderWithBg';
import SecurityBg from './assets/SecurityBg.svg';
import classes from './Security.module.css';
import Footer from '../../components/Footer/Footer';
import SslIcon from './assets/ssl.svg';
import SheildIcon from './assets/shield.svg';
import CertificateIcon from './assets/certificate.svg';
import SecurityMobile from '../../../images/securityMobile.png';

const bgImage = window.innerWidth > 575 ? SecurityBg : SecurityMobile ;

const Security = () => {
  return (
    <div>
      <div className={classes.Header}>
        <HeaderWithBg logo={bgImage} title='SECURITY' />
      </div>
      <div className={classes.Security}>
        <div className={classes.Title}>
          We know the importance of data security is, therefore security is the forefront of everything we do.
        </div>
        <div className={classes.SecurityContainer}>
          <div className={classes.SecurityContainerLeft}>
            <div style={{ display: 'flex', columnGap: '1vw' }}>
              <div className={classes.SecurityCard}>
                <div className={classes.SecurityCardTitle}>HTTPS</div>
                <img className={classes.SecurityCardIcon} style={{ width: '3.85vw', height: '5.05vw' }} src={SslIcon} alt='' />
              </div>
              <div className={classes.SecurityCard}>
                <div className={classes.SecurityCardTitle}>ENCRYPTION</div>
                <img
                  style={{
                    width: '4.3vw',
                    height: '5.05vw',
                  }}
                  className={classes.SecurityCardIcon}
                  src={SheildIcon}
                  alt=''
                />
              </div>
            </div>
            <div>
              <div className={classes.SecurityCardLarge}>
                <div className={classes.SecurityCardTitle}>PLANNED COMPLIANCE</div>
                <img
                  style={{
                    width: '16vw',
                    height: '6.7vw',
                  }}
                  className={classes.SecurityCardIcon}
                  src={CertificateIcon}
                  alt=''
                />
              </div>
            </div>
          </div>

          <div>
            <div className={classes.RightTitle}>STEPS WE TAKE TO ENSURE YOUR SECURITY</div>
            <div className={classes.RightDesc}>
              BLUEFINWARE maintains active SOC 2 Type II compliance, and is annually audited by an independent, certified third-party. Our
              SOC 2 Security, Availability & Confidentiality Report is available to current and prospective customers, please contact us at
              security@BLUEFINWARE.co for a copy.
              <br />
              <br />
              All of BLUEFINWARE’s infrastructure is hosted on Amazon Web Services (AWS). BLUEFINWARE uses AWS data centers that are SOC 1,
              SOC 2 and ISO/IEC 27001 certified in the US East and GovCloud (for our government customers) regions.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Security;
