import React, { useRef, useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import Logo from '../images/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { DispatchAction } from './statemanagement/store';
import * as actions from './statemanagement/ActionsList';

export const AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD = 'FIREBASE';

function Login({ handleLogin }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loginState, setloginState] = useState(false);

  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    const auth = getAuth();

    auth.onAuthStateChanged((userCredential) => {
      console.log('auth.onAuthStateChanged ');

      if (userCredential === null) {
        // FIXME to handle unsuccessfull logins
        console.log('onAuthStateChanged userCredential === null ---> redirected to login page');
        history.push('/login');
        return;
      }

      console.log('userCredential>>', userCredential);

      userCredential.getIdToken().then((idToken) => {
        console.log('idToken', idToken);
        // Not a local event broadcast to whole UI
        DispatchAction(actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW, {
          what: actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
          login_event: {
            setloginStateFunc: setloginState,
            user_credentials: userCredential,
          },
        });
      });
    });

    signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
      .then((userCredential) => {
        if (userCredential) {
          userCredential.authProvider = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
          // Not a local event broadcast to whole UI
          DispatchAction(actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW, {
            what: actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
            login_event: {
              setloginStateFunc: setloginState,
              user_credentials: userCredential,
            },
          });
          handleLogin();
        }
      })
      .catch((error) => {
        console.log('.catch((error) ---> redirected to login page');
        history.push('/login');
        console.log(error.code);
        console.log(error.message);
      });
  }

  return (
    <>
      <Container className='sign-container login-page text-center'>
        <div className='title-section d-flex'>
          <span style={{ display: 'flex', cursor: 'pointer' }} onClick={() => history.push('/home')}>
            <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
            <h4 className='text-center mb-4 micro-title'>WELCOME TO BLUEFINWARE</h4>
          </span>
        </div>

        <h2 className='text-center mb-4 title'>Signin to your account</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3'>
            <Col sm='12'>
              <Form.Control type='text' placeholder='Email' ref={emailRef} required />
            </Col>
            <Col sm='12'>
              <Form.Control type='password' placeholder='password' ref={passwordRef} required />
            </Col>
          </Form.Group>
          <div className='sign-button d-flex justify-content-end'>
            <Button disabled={false} className='w-100' type='submit'>
              Log In
            </Button>
          </div>
        </Form>

        <div className='w-100 text-center sign-link'>
          Do you have an account? <Link to='/signup'>Sign Up Here</Link>
        </div>
      </Container>
    </>
  );
}

export default Login;
