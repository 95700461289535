import React from 'react';
import classes from './PlanCard.module.css';
import { PATHS } from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const PlanCard = ({
    title,
    subtitle,
    amount,
    usage,
    benefits,
    isPopular
  } ) => {

  const history = useHistory();

  return (
    <div className={`${classes.PlanCard} ${isPopular ? classes.Popular : ''}`}>
       {isPopular? <div className={classes.Ribbon}>
            MOST POPULAR
        </div>:null}
      <div className={classes.Head}>
        <div className={classes.Title}>{title}</div>
        <div className={classes.Subtitle}>{subtitle}</div>
        <div className={classes.AmountContainer}>
          <div className={classes.Amount}>{amount}</div>
          <div className={classes.Usage}>{usage}</div>
        </div>
      </div>
      <div className={classes.Body}>
        <div className={classes.Title}>BENEFITS</div>
        <ul className={classes.List}>
          {benefits.map((benefit, index) => (
            <li key={index} className={classes.ListItem}>
              <div>

              <div className={classes.Bullet}></div>
              </div>
              {benefit}
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.SignupButton} onClick={()=>history.push(PATHS.SIGNUP)}>
        <div className="new-btn-primary">SIGNUP</div>
      </div>
    </div>
  );
};

export default PlanCard;
