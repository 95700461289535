import { ReactFlowProvider } from 'reactflow';
import '../../styles/ContextBar.css';
import {
  ContextBarGetNewDropDownField,
  ContextBarGetNewTextInputField,
  FIELD_APPENDING_TYPE,
  FIELD_VALUES,
  UI_FIELDS_DEFINITION,
  CreateContextBarUiHandler,
  getCurrentActiveContextBarNodeInfo,
} from '../ContextBarHandlerBuilder';
import LineChart from '../CostManager/linechart.d3';
import { GetGlobalResourceInfoCache } from '../NestedAccordion/ResourceBrowser';
import { BaseContextBarBuilder, GetSimpleKeyPairValueElement } from './ContextBarBuilder';
import LambdaSqsFlow from './FlowDiagram';



const LambdaGatewayContextShowInfoBarHandler = () => {
  const title = 'LAMBDA';
  const displayFields = [
    { label: 'Function Name', key: 'FunctionName', element_type: 'simple_key_pair_value' },
    { label: 'VPC ID', key: 'VpcId', element_type: 'simple_key_pair_value' },
    { label: 'Code Sha 256', key: 'CodeSha256', element_type: 'simple_key_pair_value' },
    { label: 'Description', key: 'Description', element_type: 'simple_key_pair_value' },
    { label: 'Function ARN', key: 'FunctionArn', element_type: 'simple_key_pair_value' },
    { label: 'Architectures', key: 'Architectures', element_type: 'auto_json_value' },

    GetSimpleKeyPairValueElement('Handler', 'Handler'),
    GetSimpleKeyPairValueElement('LastModified', 'LastModified'),
    GetSimpleKeyPairValueElement('MasterArn', 'MasterArn'),
    GetSimpleKeyPairValueElement('MemorySize', 'MemorySize'),

    GetSimpleKeyPairValueElement('RevisionId', 'RevisionId'),
    { label: 'Role', key: 'Role', element_type: 'auto_json_value' },
    GetSimpleKeyPairValueElement('Version', 'Version'),
    GetSimpleKeyPairValueElement('Runtime', 'Runtime'),

    GetSimpleKeyPairValueElement('State', 'State'),
    GetSimpleKeyPairValueElement('PackageType', 'PackageType'),
    GetSimpleKeyPairValueElement('Timeout', 'Timeout'),
    GetSimpleKeyPairValueElement('LastUpdateStatus', 'LastUpdateStatus'),

    // {"label": "Tag 1", "key":"Tag1", "element_type": "tag"},
    // {"label": "Tag 2", "key":"Tag2", "element_type": "tag"},
  ];
  return <div>{BaseContextBarBuilder(title, displayFields)}</div>;
};

const LambdaGatewayContextAssetHandler = (contextBarStateStore, setContextBarStateStore, EditableForm) => {
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
  let imgPath = currentContextBarNodeInfo.node_data.imgPath;
  const SAMPLE_DATA = [
    { date: '2022-01-01T00:00:00.000Z', value: 10 },
    { date: '2022-01-02T00:00:00.000Z', value: 15 },
    { date: '2022-01-03T00:00:00.000Z', value: 13 },
    { date: '2022-01-04T00:00:00.000Z', value: 17 },
    { date: '2022-01-05T00:00:00.000Z', value: 20 },
    // Add more data points as needed
  ];
  return (
    <div className='contextbar-actionContainer'>
      <div className='context-bar-head'>
        <div className='flex'>
          <img alt='' src={imgPath} width='48' height='48' />
        </div>
        <div>
          <h4 className='context-bar-title'>Lambda</h4>
          {/* <div className='context-bar-subtitle'>{'latestData?.InstanceId'}</div> */}
        </div>
      </div>

      <br />

      <div className='node-property'>
        <EditableForm
          initialValues={{ costCentre: 'Test Cost Centre', instanceName: 'Test instance', vpcId: 'scasdadadasd' }}
          inputFields={[
            { name: 'costCentre', label: 'Cost Centre', id: 'costCentreInput' },
            { name: 'instanceName', label: 'Instance Name', id: 'instanceInput' },
            { name: 'vpcId', label: 'VPC ID', id: 'vpcInput' },
          ]}
        />
      </div>
      <div>
        <LineChart data={SAMPLE_DATA} />
      </div>
    </div>
  );
};

const LambdaGatewayFlowHandler = () => {
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
  let completeResourceInfo = GetGlobalResourceInfoCache()?.connection_diagram_info;
  let assets_map = GetGlobalResourceInfoCache()?.ui_assets_map;

  console.log('curNodeInfo', { currentContextBarNodeInfo });
  const [filteredAccount] =
    completeResourceInfo?.filter(
      (eachAccount) => eachAccount.network_connection_info.aws_account_id === currentContextBarNodeInfo.node_data.OwnedByAccountId
    ) || [];

  const sqsNodeList = filteredAccount.network_connection_info.nodes_list.filter((eachNode) => eachNode.nodeType === 'sqs_node');
  console.log('sqsNodeList', sqsNodeList);

  const basePath = assets_map.aws_ui_assets_base_url;
  const [sqsIcon] = assets_map.aws_resource_ui_paths.filter((eachIconObj) => eachIconObj.resource_type === 'aws_sqs_node');
  const mappedSqsNodes = sqsNodeList
    .map((eachSqsNode) => {
      const {
        nodeContextInfo: { DestinationLambdaInfo },
      } = eachSqsNode;
      return DestinationLambdaInfo[0]?.FunctionArn.includes(currentContextBarNodeInfo.node_data.title)
        ? { ...eachSqsNode, iconUrl: basePath + '/' + sqsIcon.image_path }
        : null;
    })
    .filter(Boolean);


  return (
    <div style={{ height: '500px', width: '500px' }}>
      <ReactFlowProvider>
        <LambdaSqsFlow mappedSqsNodes={mappedSqsNodes} currentNodeInfo={currentContextBarNodeInfo} />
      </ReactFlowProvider>
    </div>
  );
};

export const lambdaContextBarHandler = [
  { label: 'Info', children: <LambdaGatewayContextShowInfoBarHandler /> },
  { label: 'Flow', children: <LambdaGatewayFlowHandler /> },
];
/*

.----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.
| .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
| |     ____     | || |   ______     | || |    _______   | || |     ____     | || |   _____      | || |  _________   | || |  _________   | || |  _________   | |
| |   .'    `.   | || |  |_   _ \    | || |   /  ___  |  | || |   .'    `.   | || |  |_   _|     | || | |_   ___  |  | || | |  _   _  |  | || | |_   ___  |  | |
| |  /  .--.  \  | || |    | |_) |   | || |  |  (__ \_|  | || |  /  .--.  \  | || |    | |       | || |   | |_  \_|  | || | |_/ | | \_|  | || |   | |_  \_|  | |
| |  | |    | |  | || |    |  __'.   | || |   '.___`-.   | || |  | |    | |  | || |    | |   _   | || |   |  _|  _   | || |     | |      | || |   |  _|  _   | |
| |  \  `--'  /  | || |   _| |__) |  | || |  |`\____) |  | || |  \  `--'  /  | || |   _| |__/ |  | || |  _| |___/ |  | || |    _| |_     | || |  _| |___/ |  | |
| |   `.____.'   | || |  |_______/   | || |  |_______.'  | || |   `.____.'   | || |  |________|  | || | |_________|  | || |   |_____|    | || | |_________|  | |
| |              | || |              | || |              | || |              | || |              | || |              | || |              | || |              | |
| '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
 '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------


 */

const lambda_runtime_types = [
  'nodejs',
  'nodejs4.3',
  'nodejs6.10',
  'nodejs8.10',
  'nodejs10.x',
  'nodejs12.x',
  'nodejs14.x',
  'nodejs4.3-edge',
  'java8',
  'java8.al2',
  'java11',
  'python2.7',
  'python3.6',
  'python3.7',
  'python3.8',
  'python3.9',
  'dotnetcore1.0',
  'dotnetcore2.0',
  'dotnetcore2.1',
  'dotnetcore3.1',
  'go1.x',
  'ruby2.5',
  'ruby2.7',
  'provided',
  'provided.al2',
];

// const [resourceCreationCliCmd, setResourceCreationCliCmd] = useState("bogus Cli Cmd")
const LAMBDA_FUNCTION_NAME_ID = 'lambdaname';
const RUNTIME_ID = 'runtimename';
const LAMBDAHANDLER_ID = 'lambdahandler';
const ZIPFILE_PATH_ID = 'zipfilepath';

const uiFieldsDefinition = [
  ContextBarGetNewTextInputField(
    'Lambda Function Nameeo',
    LAMBDA_FUNCTION_NAME_ID,
    FIELD_APPENDING_TYPE.NO_APPENDING,
    'Enter Function Name',
    FIELD_VALUES.EMPTRY_STR
  ),

  ContextBarGetNewDropDownField(
    'Runtime Type',
    RUNTIME_ID,
    FIELD_APPENDING_TYPE.NO_APPENDING,
    lambda_runtime_types,
    FIELD_VALUES.EMPTRY_STR
  ),

  ContextBarGetNewTextInputField(
    'Zip file location',
    ZIPFILE_PATH_ID,
    FIELD_APPENDING_TYPE.NO_APPENDING,
    'Lambda code zip file path',
    FIELD_VALUES.EMPTRY_STR
  ),

  ContextBarGetNewTextInputField(
    'Lambda handler function',
    LAMBDAHANDLER_ID,
    FIELD_APPENDING_TYPE.NO_APPENDING,
    'Lambda entry point handler',
    FIELD_VALUES.EMPTRY_STR
  ),
];

const contextBarDefinition = {
  [UI_FIELDS_DEFINITION]: uiFieldsDefinition,
};

export const LambdaContextBarContentHandler = (contextBarStateStore, setContextBarStateStore) => {
  const formContent = CreateContextBarUiHandler(contextBarDefinition);

  return <div>{formContent}</div>;
};

//
//
