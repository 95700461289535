import {
    LambdaLinkedWithApiGatewayHandler
} from "./AwsContextBarHandlersImpl/LambdaLinkedWithApiGatewayHandler";

import {
    ApiGateway_linked_to_Lambda
} from "./AwsContextBarHandlersImpl/LinkRelations/ApiGateway_linked_to_Lambda"
import {Rds_linked_to_Ec2} from "./AwsContextBarHandlersImpl/LinkRelations/Rds_linked_to_Ec2";
import {Rds_linked_to_Secrets_Manager} from "./AwsContextBarHandlersImpl/LinkRelations/Rds_linked_to_Secrets_Manager";

export const ToNodeLinkedStrFormat = (typeA, typeB) => {
    return typeA + "->" + typeB
}

 const checkLink = (typeA, typeB, typeHandlerKey) => {
     if (ToNodeLinkedStrFormat(typeA, typeB) === typeHandlerKey || ToNodeLinkedStrFormat(typeB, typeA) === typeHandlerKey) {
         return true
     }
     return false
 }

export const FindLinksHandler = (typeA, typeB) => {
    console.log("typeA")
    console.log(typeA)
    console.log("typeB")
    console.log(typeB)
   if(checkLink(typeA, typeB, AWS_LAMBDA_LINKED_TO_APIGW)) {
       //return LambdaLinkedWithApiGatewayHandler
       return ApiGateway_linked_to_Lambda
   }

    if(checkLink(typeA, typeB, AWS_RDS_LINKED_TO_EC2)) {
        //return LambdaLinkedWithApiGatewayHandler
        return Rds_linked_to_Ec2
    }

    if(checkLink(typeA, typeB, AWS_RDS_LINKED_TO_SECRETS_MANAGER)) {
        //return LambdaLinkedWithApiGatewayHandler
        return Rds_linked_to_Secrets_Manager
    }

}

export const AWS_LAMBDA_LINKED_TO_APIGW = ToNodeLinkedStrFormat("awscomputelambda","awsnetworkingandcontentdeliveryapigateway")
export const AWS_RDS_LINKED_TO_EC2 = ToNodeLinkedStrFormat("awsdatabaserds","awscomputeec2")
export const AWS_RDS_LINKED_TO_SECRETS_MANAGER = ToNodeLinkedStrFormat("awsdatabaserds",
    "awssecurity_identity_and_compliancesecrets_manager")



//
//