

const baseURL = "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/"

export const AWS_RESOURCE_ICONS_BASE_PATH = () => {
    return baseURL
}

const getBaseUiAssetUrlPath = () => {
    return baseURL
}

const GENERAL_RESOURCE_ICONS = [

    {"path": "general-resources/chrome.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "General", "subgroup_1":"chrome"}},
    {"path": "general-resources/man-01.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "General", "subgroup_1":"user"}},
    {"path": "general-resources/woman-01.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "General", "subgroup_1":"user"}},
]

export const GetAllGeneralResourceIconsWithInfo = () => {
    let allIconResources = []

    for (var i = 0; i<GENERAL_RESOURCE_ICONS.length; i++) {
        let iconResource = GENERAL_RESOURCE_ICONS[i]
        let isEnabled = iconResource["enabled"]
        if (isEnabled === "true") {
            allIconResources.push(iconResource)
        }
        iconResource["full_http_path"] = AWS_RESOURCE_ICONS_BASE_PATH()+iconResource["path"]
    }
    return allIconResources
}


const CLOSE_BUTTON_ICON = "general-resources/close.png"
const GetWidgetCloseButtonIconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH()+CLOSE_BUTTON_ICON
}

const PLUS_BUTTON_ICON = "general-resources/plus.png"
const GetWidgetNewAddButtonIconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH()+PLUS_BUTTON_ICON
}

export const GET_PLUS_ICON_PATH = GetWidgetNewAddButtonIconPath
export const GET_MINUS_ICON_PATH = GetWidgetCloseButtonIconPath

export default  GetAllGeneralResourceIconsWithInfo;

//
//