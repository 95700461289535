
const baseURL = "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/"

export const AWS_RESOURCE_ICONS_BASE_PATH = () => {
    return baseURL
}

const AWS_RESOURCE_ICONS = [
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Application-Cost-Profiler_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Application-Cost-Profiler_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Budgets_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Budgets_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Cost-Explorer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Cost-Explorer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Cost-and-Usage-Report_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_AWS-Cost-and-Usage-Report_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_Reserved-Instance-Reporting_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_Reserved-Instance-Reporting_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_Savings-Plans_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/16/Arch_Savings-Plans_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Application-Cost-Profiler_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Application-Cost-Profiler_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Budgets_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Budgets_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Cost-Explorer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Cost-Explorer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Cost-and-Usage-Report_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_AWS-Cost-and-Usage-Report_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_Reserved-Instance-Reporting_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_Reserved-Instance-Reporting_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_Savings-Plans_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/32/Arch_Savings-Plans_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Application-Cost-Profiler_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Application-Cost-Profiler_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Budgets_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Budgets_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Cost-Explorer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Cost-Explorer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Cost-and-Usage-Report_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_AWS-Cost-and-Usage-Report_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_Reserved-Instance-Reporting_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_Reserved-Instance-Reporting_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_Savings-Plans_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/48/Arch_Savings-Plans_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Application-Cost-Profiler_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Application-Cost-Profiler_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Application-Cost-Profiler_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Budgets_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Budgets_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Budgets_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-Explorer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-Explorer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-Explorer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-and-Usage-Report_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-and-Usage-Report_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_AWS-Cost-and-Usage-Report_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Reserved-Instance-Reporting_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Reserved-Instance-Reporting_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Reserved-Instance-Reporting_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Savings-Plans_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Savings-Plans_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_AWS-Cost-Management/64/Arch_Savings-Plans_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Data-Exchange_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Data-Exchange_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Data-Pipeline_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Data-Pipeline_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue-DataBrew_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue-DataBrew_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue-Elastic-Views_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue-Elastic-Views_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Glue_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Lake-Formation_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_AWS-Lake-Formation_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Athena_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Athena_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-EMR_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-EMR_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Elasticsearch-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Elasticsearch-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-FinSpace_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-FinSpace_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Data-Analytics_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Data-Analytics_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Data-Streams_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Data-Streams_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Firehose_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Firehose_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Video-Streams_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis-Video-Streams_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Kinesis_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-QuickSight_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_16/Arch_Amazon-QuickSight_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Data-Exchange_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Data-Exchange_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Data-Pipeline_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Data-Pipeline_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue-DataBrew_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue-DataBrew_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue-Elastic-Views_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue-Elastic-Views_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Glue_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Lake-Formation_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_AWS-Lake-Formation_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Athena_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Athena_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-EMR_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-EMR_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Elasticsearch-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Elasticsearch-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-FinSpace_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-FinSpace_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Data-Analytics_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Data-Analytics_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Data-Streams_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Data-Streams_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Firehose_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Firehose_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Video-Streams_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis-Video-Streams_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Kinesis_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-QuickSight_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_32/Arch_Amazon-QuickSight_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Data-Exchange_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Data-Exchange_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Data-Pipeline_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Data-Pipeline_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue-DataBrew_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue-DataBrew_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue-Elastic-Views_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue-Elastic-Views_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Glue_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Lake-Formation_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_AWS-Lake-Formation_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Athena_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Athena_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-EMR_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-EMR_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Elasticsearch-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Elasticsearch-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-FinSpace_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-FinSpace_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Data-Analytics_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Data-Analytics_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Data-Streams_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Data-Streams_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Firehose_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Firehose_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Video-Streams_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis-Video-Streams_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Kinesis_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-QuickSight_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_48/Arch_Amazon-QuickSight_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Exchange_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Exchange_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Exchange_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Pipeline_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Pipeline_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Data-Pipeline_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-DataBrew_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-DataBrew_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-DataBrew_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-Elastic-Views_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-Elastic-Views_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue-Elastic-Views_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Glue_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Lake-Formation_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Lake-Formation_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_AWS-Lake-Formation_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Athena_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Athena_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Athena_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-EMR_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-EMR_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-EMR_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Elasticsearch-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Elasticsearch-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Elasticsearch-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-FinSpace_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-FinSpace_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-FinSpace_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Analytics_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Analytics_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Analytics_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Streams_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Streams_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Data-Streams_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Firehose_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Firehose_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Firehose_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Video-Streams_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Video-Streams_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis-Video-Streams_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Kinesis_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-QuickSight_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-QuickSight_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Analytics/Arch_64/Arch_Amazon-QuickSight_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-AppSync_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-AppSync_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Express-Workflows_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Express-Workflows_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Step-Functions_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_AWS-Step-Functions_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-AppFlow_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-AppFlow_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-EventBridge_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-EventBridge_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-MQ_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-MQ_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Simple-Notification-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Simple-Notification-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Simple-Queue-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_16/Arch_Amazon-Simple-Queue-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-AppSync_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-AppSync_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Express-Workflows_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Express-Workflows_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Step-Functions_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_AWS-Step-Functions_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-AppFlow_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-AppFlow_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-EventBridge_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-EventBridge_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-MQ_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-MQ_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Simple-Notification-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Simple-Notification-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Simple-Queue-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_32/Arch_Amazon-Simple-Queue-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-AppSync_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-AppSync_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Console-Mobile-Application",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Express-Workflows_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Express-Workflows_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Step-Functions_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_AWS-Step-Functions_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-AppFlow_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-AppFlow_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-EventBridge_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-EventBridge_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-MQ_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-MQ_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Simple-Notification-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Simple-Notification-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Simple-Queue-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_48/Arch_Amazon-Simple-Queue-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-AppSync_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-AppSync_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-AppSync_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Console-Mobile-Application_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Console-Mobile-Application_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Console-Mobile-Application_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Express-Workflows_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Express-Workflows_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Express-Workflows_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Step-Functions_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Step-Functions_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_AWS-Step-Functions_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-API-Gateway_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "networkingandcontentdelivery", "subgroup_1":"apigateway"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-API-Gateway_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-API-Gateway_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-AppFlow_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-AppFlow_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-AppFlow_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-EventBridge_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-EventBridge_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-EventBridge_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-MQ_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-MQ_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-MQ_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Managed-Workflows-for-Apache-Airflow_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Notification-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Notification-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Notification-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Queue-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Queue-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-Simple-Queue-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/16/Arch_Amazon-Managed-Blockchain_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/16/Arch_Amazon-Managed-Blockchain_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/16/Arch_Amazon-Quantum-Ledger-Database_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/16/Arch_Amazon-Quantum-Ledger-Database_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/32/Arch_Amazon-Managed-Blockchain_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/32/Arch_Amazon-Managed-Blockchain_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/32/Arch_Amazon-Quantum-Ledger-Database_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/32/Arch_Amazon-Quantum-Ledger-Database_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/48/Arch_Amazon-Managed-Blockchain_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/48/Arch_Amazon-Managed-Blockchain_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/48/Arch_Amazon-Quantum-Ledger-Database_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/48/Arch_Amazon-Quantum-Ledger-Database_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Managed-Blockchain_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Managed-Blockchain_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Managed-Blockchain_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Quantum-Ledger-Database_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Quantum-Ledger-Database_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Blockchain/64/Arch_Amazon-Quantum-Ledger-Database_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Alexa-For-Business_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Alexa-For-Business_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime-SDK_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime-SDK_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime-Voice-Connector_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime-Voice-Connector_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Chime_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Connect_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Connect_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Honeycode_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Honeycode_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Pinpoint-APIs_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Pinpoint-APIs_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Pinpoint_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Pinpoint_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Simple-Email-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-Simple-Email-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkDocs-SDK_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkDocs-SDK_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkDocs_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkDocs_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkMail_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/16/Arch_Amazon-WorkMail_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Alexa-For-Business_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Alexa-For-Business_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime-SDK_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime-SDK_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime-Voice-Connector_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime-Voice-Connector_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Chime_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Connect_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Connect_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Honeycode_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Honeycode_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Pinpoint-APIs_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Pinpoint-APIs_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Pinpoint_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Pinpoint_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Simple-Email-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-Simple-Email-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkDocs-SDK_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkDocs-SDK_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkDocs_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkDocs_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkMail_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/32/Arch_Amazon-WorkMail_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Alexa-For-Business_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Alexa-For-Business_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime-SDK_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime-SDK_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime-Voice-Connector_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime-Voice-Connector_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Chime_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Connect_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Connect_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Honeycode_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Honeycode_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Pinpoint-APIs_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Pinpoint-APIs_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Pinpoint_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Pinpoint_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Simple-Email-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-Simple-Email-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkDocs-SDK_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkDocs-SDK_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkDocs_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkDocs_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkMail_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/48/Arch_Amazon-WorkMail_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Alexa-For-Business_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Alexa-For-Business_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Alexa-For-Business_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-SDK_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-SDK_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-SDK_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-Voice-Connector_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-Voice-Connector_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime-Voice-Connector_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Chime_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Connect_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Connect_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Connect_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Honeycode_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Honeycode_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Honeycode_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint-APIs_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint-APIs_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint-APIs_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Pinpoint_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Simple-Email-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Simple-Email-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-Simple-Email-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs-SDK_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs-SDK_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs-SDK_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkDocs_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkMail_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkMail_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Business-Applications/64/Arch_Amazon-WorkMail_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-App-Runner_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-App-Runner_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Batch_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Batch_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Compute-Optimizer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Compute-Optimizer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Elastic-Beanstalk_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Elastic-Beanstalk_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Fargate_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Fargate_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Lambda_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Lambda_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Local-Zones_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Local-Zones_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Nitro-Enclaves_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Nitro-Enclaves_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Outposts-1U-and-2U-Servers_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Outposts-1U-and-2U-Servers_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Outposts_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Outposts_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ParallelCluster_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ParallelCluster_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Serverless-Application-Repository_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Serverless-Application-Repository_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Deadline_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Deadline_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Frost_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Frost_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Krakatoa_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Krakatoa_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Sequoia_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Sequoia_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Stoke_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-Stoke_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-XMesh_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-ThinkBox-XMesh_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Wavelength_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_AWS-Wavelength_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-Auto-Scaling_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-Auto-Scaling_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-Image-Builder_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-Image-Builder_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-M5n_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-M5n_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-R5n_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2-R5n_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-EC2_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Kubernetes_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Kubernetes_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Registry_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Registry_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Elastic-Container-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Lightsail_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Amazon-Lightsail_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Bottlerocket_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Bottlerocket_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Elastic-Fabric-Adapter_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_Elastic-Fabric-Adapter_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_NICE-DCV_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_NICE-DCV_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_VMware-Cloud-on-AWS_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/16/Arch_VMware-Cloud-on-AWS_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-App-Runner_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-App-Runner_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Batch_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Batch_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Compute-Optimizer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Compute-Optimizer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Elastic-Beanstalk_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Elastic-Beanstalk_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Fargate_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Fargate_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Lambda_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Lambda_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Local-Zones_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Local-Zones_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Nitro-Enclaves_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Nitro-Enclaves_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Outposts-1U-and-2U-Servers_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Outposts-1U-and-2U-Servers_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Outposts_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Outposts_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ParallelCluster_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ParallelCluster_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Serverless-Application-Repository_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Serverless-Application-Repository_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Deadline_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Deadline_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Frost_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Frost_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Krakatoa_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Krakatoa_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Sequoia_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Sequoia_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Stoke_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-Stoke_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-XMesh_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-ThinkBox-XMesh_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Wavelength_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_AWS-Wavelength_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-Auto-Scaling_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-Auto-Scaling_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-Image-Builder_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-Image-Builder_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-M5n_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-M5n_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-R5n_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2-R5n_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-EC2_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Kubernetes_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Kubernetes_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Registry_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Registry_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Elastic-Container-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Lightsail_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Amazon-Lightsail_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Bottlerocket_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Bottlerocket_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Elastic-Fabric-Adapter_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_Elastic-Fabric-Adapter_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_NICE-DCV_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_NICE-DCV_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_VMware-Cloud-on-AWS_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/32/Arch_VMware-Cloud-on-AWS_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-App-Runner_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-App-Runner_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Batch_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Batch_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Compute-Optimizer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Compute-Optimizer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Elastic-Beanstalk_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Elastic-Beanstalk_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Fargate_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Fargate_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Lambda_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Lambda_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Local-Zones_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Local-Zones_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Nitro-Enclaves_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Nitro-Enclaves_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Outposts-1U-and-2U-Servers_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Outposts-1U-and-2U-Servers_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Outposts_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Outposts_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ParallelCluster_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ParallelCluster_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Serverless-Application-Repository_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Serverless-Application-Repository_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Deadline_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Deadline_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Frost_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Frost_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Krakatoa_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Krakatoa_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Sequoia_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Sequoia_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Stoke_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-Stoke_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-XMesh_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-ThinkBox-XMesh_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Wavelength_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_AWS-Wavelength_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-Auto-Scaling_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-Auto-Scaling_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-Image-Builder_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-Image-Builder_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-M5n_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-M5n_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-R5n_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2-R5n_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-EC2_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Kubernetes_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Kubernetes_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Registry_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Registry_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Elastic-Container-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Lightsail_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Amazon-Lightsail_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Bottlerocket_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Bottlerocket_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Elastic-Fabric-Adapter_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_Elastic-Fabric-Adapter_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_NICE-DCV_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_NICE-DCV_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_VMware-Cloud-on-AWS_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/48/Arch_VMware-Cloud-on-AWS_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-App-Runner_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-App-Runner_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-App-Runner_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Batch_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Batch_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Batch_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Compute-Optimizer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Compute-Optimizer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Compute-Optimizer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Elastic-Beanstalk_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Elastic-Beanstalk_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Elastic-Beanstalk_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Fargate_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Fargate_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Fargate_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "compute", "subgroup_1":"lambda"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Local-Zones_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Local-Zones_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Local-Zones_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Nitro-Enclaves_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Nitro-Enclaves_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Nitro-Enclaves_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts-1U-and-2U-Servers_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts-1U-and-2U-Servers_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts-1U-and-2U-Servers_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Outposts_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ParallelCluster_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ParallelCluster_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ParallelCluster_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Serverless-Application-Repository_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Serverless-Application-Repository_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Serverless-Application-Repository_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Deadline_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Deadline_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Deadline_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Frost_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Frost_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Frost_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Krakatoa_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Krakatoa_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Krakatoa_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Sequoia_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Sequoia_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Sequoia_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Stoke_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Stoke_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-Stoke_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-XMesh_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-XMesh_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-ThinkBox-XMesh_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Wavelength_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Wavelength_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Wavelength_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Application-Auto-Scaling_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Application-Auto-Scaling_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Application-Auto-Scaling_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Auto-Scaling_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Auto-Scaling_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Auto-Scaling_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Image-Builder_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Image-Builder_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Image-Builder_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "compute", "subgroup_1":"ec2"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Lightsail_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Lightsail_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-Lightsail_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Bottlerocket_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Bottlerocket_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Bottlerocket_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Elastic-Fabric-Adapter_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Elastic-Fabric-Adapter_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Elastic-Fabric-Adapter_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_NICE-DCV_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_NICE-DCV_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_NICE-DCV_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_VMware-Cloud-on-AWS_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_VMware-Cloud-on-AWS_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_VMware-Cloud-on-AWS_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_AWS-Fargate_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_AWS-Fargate_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Anywhere_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Anywhere_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Cloud_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Cloud_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Distro_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-EKS-Distro_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Container-Registry_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Container-Registry_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Container-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Container-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Kubernetes-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Amazon-Elastic-Kubernetes-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Red-Hat-OpenShift_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/16/Arch_Red-Hat-OpenShift_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_AWS-Fargate_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_AWS-Fargate_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Anywhere_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Anywhere_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Cloud_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Cloud_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Distro_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-EKS-Distro_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Container-Registry_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Container-Registry_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Container-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Container-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Kubernetes-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Amazon-Elastic-Kubernetes-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Red-Hat-OpenShift_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/32/Arch_Red-Hat-OpenShift_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_AWS-Fargate_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_AWS-Fargate_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Anywhere_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Anywhere_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Cloud_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Cloud_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Distro_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-EKS-Distro_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Container-Registry_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Container-Registry_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Container-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Container-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Kubernetes-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Amazon-Elastic-Kubernetes-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Red-Hat-OpenShift_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/48/Arch_Red-Hat-OpenShift_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_AWS-Fargate_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_AWS-Fargate_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_AWS-Fargate_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Anywhere_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Anywhere_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Anywhere_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Cloud_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Cloud_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Cloud_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Distro_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Distro_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-EKS-Distro_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Registry_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Registry_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Registry_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Kubernetes-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Kubernetes-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Kubernetes-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Red-Hat-OpenShift_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Red-Hat-OpenShift_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Red-Hat-OpenShift_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Activate_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Activate_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-IQ_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-IQ_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Managed-Services_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Managed-Services_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Professional-Services_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Professional-Services_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Support_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Support_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Training-Certification_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/16/Arch_AWS-Training-Certification_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Activate_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Activate_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-IQ_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-IQ_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Managed-Services_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Managed-Services_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Professional-Services_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Professional-Services_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Support_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Support_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Training-Certification_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/32/Arch_AWS-Training-Certification_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Activate_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Activate_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-IQ_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-IQ_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Managed-Services_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Managed-Services_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Professional-Services_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Professional-Services_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Support_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Support_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Training-Certification_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/48/Arch_AWS-Training-Certification_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Activate_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Activate_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Activate_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-IQ_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-IQ_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-IQ_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Managed-Services_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Managed-Services_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Managed-Services_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Professional-Services_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Professional-Services_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Professional-Services_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Support_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Support_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Support_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Training-Certification_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Training-Certification_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Customer-Enablement/64/Arch_AWS-Training-Certification_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_AWS-Database-Migration-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_AWS-Database-Migration-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Aurora_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Aurora_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-DocumentDB_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-DocumentDB_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-DynamoDB_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-DynamoDB_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-ElastiCache_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-ElastiCache_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Keyspaces_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Keyspaces_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Neptune_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Neptune_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Quantum-Ledger-Database_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Quantum-Ledger-Database_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Timestream_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/16/Arch_Amazon-Timestream_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_AWS-Database-Migration-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_AWS-Database-Migration-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Aurora_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Aurora_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-DocumentDB_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-DocumentDB_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-DynamoDB_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-DynamoDB_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-ElastiCache_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-ElastiCache_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Keyspaces_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Keyspaces_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Neptune_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Neptune_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Quantum-Ledger-Database_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Quantum-Ledger-Database_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Timestream_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/32/Arch_Amazon-Timestream_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_AWS-Database-Migration-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_AWS-Database-Migration-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Aurora_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Aurora_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-DocumentDB_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-DocumentDB_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-DynamoDB_48.png",
        "enabled": "false", "resource_details": {"group": "database", "subgroup_1":"dynamodb"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-DynamoDB_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-ElastiCache_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-ElastiCache_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Keyspaces_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Keyspaces_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Neptune_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Neptune_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Quantum-Ledger-Database_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Quantum-Ledger-Database_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Timestream_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/48/Arch_Amazon-Timestream_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_AWS-Database-Migration-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_AWS-Database-Migration-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_AWS-Database-Migration-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Aurora_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Aurora_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Aurora_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DocumentDB_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DocumentDB_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DocumentDB_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DynamoDB_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DynamoDB_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DynamoDB_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-ElastiCache_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-ElastiCache_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-ElastiCache_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Keyspaces_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Keyspaces_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Keyspaces_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Neptune_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Neptune_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Neptune_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Quantum-Ledger-Database_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Quantum-Ledger-Database_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Quantum-Ledger-Database_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Timestream_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Timestream_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-Timestream_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Cloud-Development-Kit_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Cloud-Development-Kit_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Cloud9_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Cloud9_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeArtifact_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeArtifact_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeBuild_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeBuild_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeCommit_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeCommit_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeDeploy_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeDeploy_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodePipeline_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodePipeline_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeStar_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-CodeStar_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Command-Line-Interface_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Command-Line-Interface_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Tools-and-SDKs_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-Tools-and-SDKs_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-X-Ray_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_AWS-X-Ray_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_Amazon-Corretto_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/16/Arch_Amazon-Corretto_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Cloud-Development-Kit_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Cloud-Development-Kit_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Cloud9_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Cloud9_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeArtifact_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeArtifact_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeBuild_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeBuild_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeCommit_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeCommit_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeDeploy_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeDeploy_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodePipeline_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodePipeline_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeStar_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-CodeStar_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Command-Line-Interface_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Command-Line-Interface_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Tools-and-SDKs_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-Tools-and-SDKs_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-X-Ray_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_AWS-X-Ray_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_Amazon-Corretto_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/32/Arch_Amazon-Corretto_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Cloud-Development-Kit_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Cloud-Development-Kit_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Cloud9_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Cloud9_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeArtifact_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeArtifact_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeBuild_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeBuild_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeCommit_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeCommit_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeDeploy_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeDeploy_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodePipeline_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodePipeline_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeStar_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-CodeStar_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Command-Line-Interface_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Command-Line-Interface_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Tools-and-SDKs_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-Tools-and-SDKs_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-X-Ray_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_AWS-X-Ray_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_Amazon-Corretto_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/48/Arch_Amazon-Corretto_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud-Development-Kit_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud-Development-Kit_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud-Development-Kit_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud9_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud9_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Cloud9_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeArtifact_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeArtifact_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeArtifact_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeBuild_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeBuild_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeBuild_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeCommit_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeCommit_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeCommit_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeDeploy_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeDeploy_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeDeploy_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodePipeline_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodePipeline_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodePipeline_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeStar_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeStar_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-CodeStar_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Command-Line-Interface_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Command-Line-Interface_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Command-Line-Interface_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Tools-and-SDKs_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Tools-and-SDKs_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-Tools-and-SDKs_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-X-Ray_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-X-Ray_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_AWS-X-Ray_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_Amazon-Corretto_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_Amazon-Corretto_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Developer-Tools/64/Arch_Amazon-Corretto_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-AppStream_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-AppStream_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-WorkLink_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-WorkLink_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-WorkSpaces_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/16/Arch_Amazon-WorkSpaces_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-AppStream_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-AppStream_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-WorkLink_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-WorkLink_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-WorkSpaces_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/32/Arch_Amazon-WorkSpaces_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-AppStream_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-AppStream_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-WorkLink_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-WorkLink_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-WorkSpaces_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/48/Arch_Amazon-WorkSpaces_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-AppStream_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-AppStream_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-AppStream_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkLink_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkLink_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkLink_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkSpaces_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkSpaces_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_End-User-Computing/64/Arch_Amazon-WorkSpaces_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_AWS-Amplify_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_AWS-Amplify_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_AWS-Device-Farm_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_AWS-Device-Farm_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_Amazon-Location-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/16/Arch_Amazon-Location-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_AWS-Amplify_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_AWS-Amplify_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_AWS-Device-Farm_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_AWS-Device-Farm_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_Amazon-Location-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/32/Arch_Amazon-Location-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_AWS-Amplify_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_AWS-Amplify_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_AWS-Device-Farm_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_AWS-Device-Farm_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_Amazon-Location-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/48/Arch_Amazon-Location-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Amplify_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Amplify_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Amplify_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Device-Farm_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Device-Farm_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Device-Farm_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_Amazon-Location-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_Amazon-Location-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_Amazon-Location-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/16/Arch_Amazon-GameLift_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/16/Arch_Amazon-GameLift_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/32/Arch_Amazon-GameLift_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/32/Arch_Amazon-GameLift_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/48/Arch_Amazon-GameLift_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/48/Arch_Amazon-GameLift_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-GameLift_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-GameLift_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-GameLift_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-Lumberyard_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-Lumberyard_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Game-Tech/64/Arch_Amazon-Lumberyard_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/16/Arch_AWS-Marketplace_Dark_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/16/Arch_AWS-Marketplace_Dark_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/16/Arch_AWS-Marketplace_Light_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/16/Arch_AWS-Marketplace_Light_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/32/Arch_AWS-Marketplace_Dark_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/32/Arch_AWS-Marketplace_Dark_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/32/Arch_AWS-Marketplace_Light_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/32/Arch_AWS-Marketplace_Light_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/48/Arch_AWS-Marketplace_Dark_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/48/Arch_AWS-Marketplace_Dark_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/48/Arch_AWS-Marketplace_Light_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/48/Arch_AWS-Marketplace_Light_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Dark_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Dark_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Dark_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Light_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Light_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_General-Icons/64/Arch_AWS-Marketplace_Light_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-1-Click_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-1-Click_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Analytics_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Analytics_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Button_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Button_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Core_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Core_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Device-Defender_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Device-Defender_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Device-Management_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Device-Management_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Events_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Events_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Greengrass_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Greengrass_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-SiteWise_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-SiteWise_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Things-Graph_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_AWS-IoT-Things-Graph_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_FreeRTOS_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/16/Arch_FreeRTOS_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-1-Click_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-1-Click_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Analytics_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Analytics_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Button_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Button_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Core_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Core_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Device-Defender_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Device-Defender_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Device-Management_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Device-Management_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Events_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Events_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Greengrass_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Greengrass_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-SiteWise_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-SiteWise_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Things-Graph_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_AWS-IoT-Things-Graph_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_FreeRTOS_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/32/Arch_FreeRTOS_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-1-Click_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-1-Click_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Analytics_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Analytics_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Button_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Button_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Core_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Core_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Device-Defender_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Device-Defender_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Device-Management_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Device-Management_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Events_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Events_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Greengrass_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Greengrass_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-SiteWise_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-SiteWise_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Things-Graph_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_AWS-IoT-Things-Graph_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_FreeRTOS_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/48/Arch_FreeRTOS_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-1-Click_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-1-Click_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-1-Click_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Analytics_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Analytics_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Analytics_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Button_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Button_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Button_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Core_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Core_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Core_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Defender_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Defender_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Defender_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Management_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Management_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Device-Management_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Events_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Events_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Events_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Greengrass_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Greengrass_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Greengrass_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-SiteWise_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-SiteWise_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-SiteWise_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Things-Graph_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Things-Graph_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_AWS-IoT-Things-Graph_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_FreeRTOS_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_FreeRTOS_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Internet-of-Things/64/Arch_FreeRTOS_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Deep-Learning-AMIs_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Deep-Learning-AMIs_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Deep-Learning-Containers_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Deep-Learning-Containers_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepComposer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepComposer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepLens_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepLens_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepRacer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-DeepRacer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Neuron_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Neuron_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Panorama_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_AWS-Panorama_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Augmented-AI-A2I_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Augmented-AI-A2I_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-CodeGuru_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-CodeGuru_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Comprehend_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Comprehend_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-DevOps-Guru_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-DevOps-Guru_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Elastic-Inference_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Elastic-Inference_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Forecast_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Forecast_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Fraud-Detector_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Fraud-Detector_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-HealthLake_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-HealthLake_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Kendra_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Kendra_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lex_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lex_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Equipment_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Equipment_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Metrics_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Metrics_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Vision_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Lookout-for-Vision_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Monitron_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Monitron_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Personalize_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Personalize_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Polly_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Polly_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Rekognition_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Rekognition_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-SageMaker-Ground-Truth_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-SageMaker-Ground-Truth_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-SageMaker_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-SageMaker_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Textract_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Textract_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Transcribe_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Transcribe_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Translate_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Amazon-Translate_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Apache-MXNet-on-AWS_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_Apache-MXNet-on-AWS_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_TensorFlow-on-AWS_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_TensorFlow-on-AWS_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_TorchServe_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/16/Arch_TorchServe_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Deep-Learning-AMIs_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Deep-Learning-AMIs_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Deep-Learning-Containers_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Deep-Learning-Containers_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepComposer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepComposer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepLens_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepLens_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepRacer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-DeepRacer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Neuron_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Neuron_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Panorama_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_AWS-Panorama_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Augmented-AI-A2I_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Augmented-AI-A2I_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-CodeGuru_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-CodeGuru_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Comprehend_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Comprehend_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-DevOps-Guru_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-DevOps-Guru_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Elastic-Inference_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Elastic-Inference_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Forecast_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Forecast_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Fraud-Detector_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Fraud-Detector_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-HealthLake_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-HealthLake_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Kendra_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Kendra_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lex_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lex_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Equipment_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Equipment_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Metrics_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Metrics_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Vision_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Lookout-for-Vision_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Monitron_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Monitron_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Personalize_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Personalize_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Polly_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Polly_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Rekognition_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Rekognition_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-SageMaker-Ground-Truth_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-SageMaker-Ground-Truth_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-SageMaker_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-SageMaker_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Textract_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Textract_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Transcribe_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Transcribe_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Translate_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Amazon-Translate_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Apache-MXNet-on-AWS_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_Apache-MXNet-on-AWS_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_TensorFlow-on-AWS_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_TensorFlow-on-AWS_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_TorchServe_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/32/Arch_TorchServe_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Deep-Learning-AMIs_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Deep-Learning-AMIs_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Deep-Learning-Containers_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Deep-Learning-Containers_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepComposer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepComposer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepLens_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepLens_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepRacer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-DeepRacer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Neuron_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Neuron_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Panorama_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_AWS-Panorama_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Augmented-AI-A2I_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Augmented-AI-A2I_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-CodeGuru_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-CodeGuru_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Comprehend_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Comprehend_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-DevOps-Guru_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-DevOps-Guru_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Elastic-Inference_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Elastic-Inference_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Forecast_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Forecast_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Fraud-Detector_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Fraud-Detector_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-HealthLake_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-HealthLake_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Kendra_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Kendra_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lex_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lex_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Equipment_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Equipment_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Metrics_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Metrics_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Vision_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Lookout-for-Vision_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Monitron_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Monitron_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Personalize_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Personalize_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Polly_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Polly_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Rekognition_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Rekognition_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-SageMaker-Ground-Truth_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-SageMaker-Ground-Truth_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-SageMaker_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-SageMaker_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Textract_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Textract_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Transcribe_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Transcribe_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Translate_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Amazon-Translate_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Apache-MXNet-on-AWS_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_Apache-MXNet-on-AWS_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_TensorFlow-on-AWS_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_TensorFlow-on-AWS_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_TorchServe_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/48/Arch_TorchServe_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-AMIs_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-AMIs_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-AMIs_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-Containers_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-Containers_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Deep-Learning-Containers_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepComposer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepComposer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepComposer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepLens_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepLens_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepLens_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepRacer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepRacer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-DeepRacer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Neuron_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Neuron_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Neuron_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Panorama_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Panorama_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_AWS-Panorama_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Augmented-AI-A2I_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Augmented-AI-A2I_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Augmented-AI-A2I_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-CodeGuru_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-CodeGuru_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-CodeGuru_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Comprehend_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Comprehend_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Comprehend_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-DevOps-Guru_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-DevOps-Guru_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-DevOps-Guru_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Elastic-Inference_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Elastic-Inference_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Elastic-Inference_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Forecast_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Forecast_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Forecast_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Fraud-Detector_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Fraud-Detector_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Fraud-Detector_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-HealthLake_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-HealthLake_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-HealthLake_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Kendra_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Kendra_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Kendra_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lex_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lex_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lex_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Equipment_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Equipment_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Equipment_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Metrics_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Metrics_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Metrics_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Vision_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Vision_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Lookout-for-Vision_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Monitron_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Monitron_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Monitron_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Personalize_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Personalize_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Personalize_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Polly_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Polly_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Polly_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Rekognition_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Rekognition_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Rekognition_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker-Ground-Truth_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker-Ground-Truth_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker-Ground-Truth_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-SageMaker_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Textract_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Textract_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Textract_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Transcribe_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Transcribe_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Transcribe_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Translate_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Translate_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Amazon-Translate_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Apache-MXNet-on-AWS_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Apache-MXNet-on-AWS_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_Apache-MXNet-on-AWS_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TensorFlow-on-AWS_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TensorFlow-on-AWS_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TensorFlow-on-AWS_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TorchServe_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TorchServe_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Machine-Learning/64/Arch_TorchServe_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-AppConfig_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-AppConfig_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Application-Auto-Scaling_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Application-Auto-Scaling_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Auto-Scaling_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Auto-Scaling_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Backint-Agent_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Backint-Agent_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Chatbot_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Chatbot_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-CloudFormation_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-CloudFormation_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-CloudTrail_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-CloudTrail_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Config_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Config_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Control-Tower_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Control-Tower_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Distro-for-OpenTelemetry_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Distro-for-OpenTelemetry_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Fault-Injection-Simulator_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Fault-Injection-Simulator_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Launch-Wizard_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Launch-Wizard_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-License-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-License-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Management-Console_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Management-Console_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-OpsWorks_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-OpsWorks_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Organizations_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Organizations_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Personal-Health-Dashboard_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Personal-Health-Dashboard_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Proton_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Proton_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Service-Catalog_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Service-Catalog_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Systems-Manager-Incident-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Systems-Manager-Incident-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Systems-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Systems-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Trusted-Advisor_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Trusted-Advisor_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Well-Architected-Tool_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_AWS-Well-Architected-Tool_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-CloudWatch_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-CloudWatch_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-Managed-Service-for-Grafana_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-Managed-Service-for-Grafana_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-Managed-Service-for-Prometheus_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/16/Arch_Amazon-Managed-Service-for-Prometheus_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-AppConfig_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-AppConfig_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Application-Auto-Scaling_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Application-Auto-Scaling_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Auto-Scaling_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Auto-Scaling_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Backint-Agent_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Backint-Agent_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Chatbot_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Chatbot_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-CloudFormation_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-CloudFormation_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-CloudTrail_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-CloudTrail_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Config_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Config_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Control-Tower_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Control-Tower_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Distro-for-OpenTelemetry_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Distro-for-OpenTelemetry_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Fault-Injection-Simulator_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Fault-Injection-Simulator_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Launch-Wizard_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Launch-Wizard_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-License-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-License-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Management-Console_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Management-Console_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-OpsWorks_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-OpsWorks_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Organizations_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Organizations_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Personal-Health-Dashboard_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Personal-Health-Dashboard_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Proton_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Proton_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Service-Catalog_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Service-Catalog_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Systems-Manager-Incident-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Systems-Manager-Incident-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Systems-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Systems-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Trusted-Advisor_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Trusted-Advisor_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Well-Architected-Tool_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_AWS-Well-Architected-Tool_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-CloudWatch_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-CloudWatch_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-Managed-Service-for-Grafana_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-Managed-Service-for-Grafana_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-Managed-Service-for-Prometheus_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/32/Arch_Amazon-Managed-Service-for-Prometheus_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-AppConfig_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-AppConfig_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Application-Auto-Scaling_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Application-Auto-Scaling_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Auto-Scaling_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Auto-Scaling_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Backint-Agent_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Backint-Agent_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Chatbot_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Chatbot_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-CloudFormation_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-CloudFormation_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-CloudTrail_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-CloudTrail_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Config_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Config_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Control-Tower_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Control-Tower_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Distro-for-OpenTelemetry_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Distro-for-OpenTelemetry_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Fault-Injection-Simulator_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Fault-Injection-Simulator_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Launch-Wizard_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Launch-Wizard_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-License-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-License-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Management-Console_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Management-Console_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-OpsWorks_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-OpsWorks_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Organizations_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Organizations_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Personal-Health-Dashboard_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Personal-Health-Dashboard_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Proton_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Proton_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Service-Catalog_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Service-Catalog_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Systems-Manager-Incident-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Systems-Manager-Incident-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Systems-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Systems-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Trusted-Advisor_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Trusted-Advisor_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Well-Architected-Tool_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_AWS-Well-Architected-Tool_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-CloudWatch_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-CloudWatch_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-Managed-Service-for-Grafana_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-Managed-Service-for-Grafana_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-Managed-Service-for-Prometheus_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/48/Arch_Amazon-Managed-Service-for-Prometheus_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-AppConfig_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-AppConfig_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-AppConfig_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Application-Auto-Scaling_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Application-Auto-Scaling_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Application-Auto-Scaling_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Auto-Scaling_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Auto-Scaling_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Auto-Scaling_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Backint-Agent_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Backint-Agent_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Backint-Agent_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Chatbot_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Chatbot_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Chatbot_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudFormation_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudFormation_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudFormation_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudTrail_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudTrail_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-CloudTrail_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Config_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Config_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Config_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Control-Tower_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Control-Tower_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Control-Tower_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Distro-for-OpenTelemetry_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Distro-for-OpenTelemetry_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Distro-for-OpenTelemetry_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Fault-Injection-Simulator_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Fault-Injection-Simulator_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Fault-Injection-Simulator_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Launch-Wizard_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Launch-Wizard_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Launch-Wizard_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-License-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-License-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-License-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Management-Console_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Management-Console_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Management-Console_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-OpsWorks_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-OpsWorks_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-OpsWorks_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Organizations_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Organizations_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Organizations_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Personal-Health-Dashboard_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Personal-Health-Dashboard_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Personal-Health-Dashboard_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Proton_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Proton_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Proton_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Service-Catalog_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Service-Catalog_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Service-Catalog_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager-Incident-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager-Incident-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager-Incident-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Systems-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Trusted-Advisor_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Trusted-Advisor_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Trusted-Advisor_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Well-Architected-Tool_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Well-Architected-Tool_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_AWS-Well-Architected-Tool_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-CloudWatch_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-CloudWatch_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-CloudWatch_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Grafana_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Grafana_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Grafana_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Prometheus_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Prometheus_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Management-Governance/64/Arch_Amazon-Managed-Service-for-Prometheus_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Appliances-&-Software_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Appliances-&-Software_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Conductor_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Conductor_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Delta_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Delta_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Link_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Link_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Live_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Live_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaConnect_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaConnect_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaConvert_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaConvert_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaLive_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaLive_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaPackage_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaPackage_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaStore_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaStore_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaTailor_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-MediaTailor_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Server_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_AWS-Elemental-Server_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Elastic-Transcoder_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Elastic-Transcoder_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Interactive-Video-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Interactive-Video-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Kinesis-Video-Streams_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Kinesis-Video-Streams_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Nimble-Studio_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/16/Arch_Amazon-Nimble-Studio_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Appliances-&-Software_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Appliances-&-Software_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Conductor_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Conductor_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Delta_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Delta_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Link_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Link_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Live_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Live_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaConnect_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaConnect_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaConvert_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaConvert_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaLive_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaLive_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaPackage_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaPackage_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaStore_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaStore_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaTailor_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-MediaTailor_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Server_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_AWS-Elemental-Server_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Elastic-Transcoder_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Elastic-Transcoder_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Interactive-Video-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Interactive-Video-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Kinesis-Video-Streams_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Kinesis-Video-Streams_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Nimble-Studio_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/32/Arch_Amazon-Nimble-Studio_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Appliances-&-Software_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Appliances-&-Software_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Conductor_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Conductor_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Delta_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Delta_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Link_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Link_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Live_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Live_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaConnect_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaConnect_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaConvert_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaConvert_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaLive_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaLive_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaPackage_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaPackage_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaStore_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaStore_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaTailor_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-MediaTailor_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Server_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_AWS-Elemental-Server_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Elastic-Transcoder_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Elastic-Transcoder_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Interactive-Video-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Interactive-Video-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Kinesis-Video-Streams_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Kinesis-Video-Streams_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Nimble-Studio_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/48/Arch_Amazon-Nimble-Studio_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Appliances-&-Software_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Appliances-&-Software_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Appliances-&-Software_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Conductor_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Conductor_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Conductor_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Delta_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Delta_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Delta_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Link_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Link_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Link_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Live_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Live_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Live_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConnect_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConnect_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConnect_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConvert_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConvert_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaConvert_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaLive_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaLive_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaLive_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaPackage_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaPackage_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaPackage_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaStore_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaStore_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaStore_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaTailor_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaTailor_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-MediaTailor_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Server_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Server_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_AWS-Elemental-Server_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Elastic-Transcoder_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Elastic-Transcoder_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Elastic-Transcoder_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Interactive-Video-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Interactive-Video-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Interactive-Video-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Kinesis-Video-Streams_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Kinesis-Video-Streams_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Kinesis-Video-Streams_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Nimble-Studio_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Nimble-Studio_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Media-Services/64/Arch_Amazon-Nimble-Studio_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Application-Discovery-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Application-Discovery-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-DataSync_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-DataSync_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Migration-Evaluator_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Migration-Evaluator_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Migration-Hub_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Migration-Hub_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Server-Migration-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Server-Migration-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Transfer-Family_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_AWS-Transfer-Family_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_CloudEndure-Migration_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/16/Arch_CloudEndure-Migration_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Application-Discovery-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Application-Discovery-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-DataSync_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-DataSync_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Migration-Evaluator_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Migration-Evaluator_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Migration-Hub_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Migration-Hub_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Server-Migration-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Server-Migration-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Transfer-Family_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_AWS-Transfer-Family_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_CloudEndure-Migration_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/32/Arch_CloudEndure-Migration_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Application-Discovery-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Application-Discovery-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-DataSync_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-DataSync_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Migration-Evaluator_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Migration-Evaluator_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Migration-Hub_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Migration-Hub_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Server-Migration-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Server-Migration-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Transfer-Family_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_AWS-Transfer-Family_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_CloudEndure-Migration_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/48/Arch_CloudEndure-Migration_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Application-Discovery-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Application-Discovery-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Application-Discovery-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-DataSync_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-DataSync_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-DataSync_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Evaluator_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Evaluator_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Evaluator_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Hub_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Hub_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Migration-Hub_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Server-Migration-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Server-Migration-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Server-Migration-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Transfer-Family_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Transfer-Family_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_AWS-Transfer-Family_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_CloudEndure-Migration_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_CloudEndure-Migration_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Migration-Transfer/64/Arch_CloudEndure-Migration_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-App-Mesh_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-App-Mesh_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Client-VPN_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Client-VPN_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Cloud-Map_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Cloud-Map_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Direct-Connect_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Direct-Connect_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Global-Accelerator_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Global-Accelerator_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-PrivateLink_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-PrivateLink_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Site-to-Site-VPN_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Site-to-Site-VPN_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Transit-Gateway_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_AWS-Transit-Gateway_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Cloud-Directory_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Cloud-Directory_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-CloudFront_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-CloudFront_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Route-53_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Route-53_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Virtual-Private-Cloud_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Amazon-Virtual-Private-Cloud_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Elastic-Load-Balancing_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/16/Arch_Elastic-Load-Balancing_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-App-Mesh_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-App-Mesh_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Client-VPN_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Client-VPN_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Cloud-Map_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Cloud-Map_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Direct-Connect_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Direct-Connect_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Global-Accelerator_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Global-Accelerator_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-PrivateLink_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-PrivateLink_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Site-to-Site-VPN_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Site-to-Site-VPN_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Transit-Gateway_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_AWS-Transit-Gateway_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Cloud-Directory_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Cloud-Directory_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-CloudFront_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-CloudFront_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Route-53_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Route-53_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Virtual-Private-Cloud_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Amazon-Virtual-Private-Cloud_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Elastic-Load-Balancing_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/32/Arch_Elastic-Load-Balancing_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-App-Mesh_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-App-Mesh_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Client-VPN_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Client-VPN_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Cloud-Map_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Cloud-Map_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Direct-Connect_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Direct-Connect_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Global-Accelerator_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Global-Accelerator_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-PrivateLink_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-PrivateLink_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Site-to-Site-VPN_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Site-to-Site-VPN_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Transit-Gateway_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_AWS-Transit-Gateway_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Cloud-Directory_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Cloud-Directory_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-CloudFront_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-CloudFront_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Route-53_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Route-53_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Virtual-Private-Cloud_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Amazon-Virtual-Private-Cloud_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Elastic-Load-Balancing_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/48/Arch_Elastic-Load-Balancing_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-App-Mesh_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-App-Mesh_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-App-Mesh_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Client-VPN_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Client-VPN_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Client-VPN_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Cloud-Map_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Cloud-Map_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Cloud-Map_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Direct-Connect_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Direct-Connect_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Direct-Connect_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Global-Accelerator_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Global-Accelerator_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Global-Accelerator_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-PrivateLink_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-PrivateLink_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-PrivateLink_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Site-to-Site-VPN_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Site-to-Site-VPN_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Site-to-Site-VPN_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Transit-Gateway_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Transit-Gateway_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_AWS-Transit-Gateway_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Cloud-Directory_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Cloud-Directory_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Cloud-Directory_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-CloudFront_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "security_identity_and_compliance", "subgroup_1":"cloudfront"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-CloudFront_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-CloudFront_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Route-53_64.png",
        "enabled": "true",  "resource_details": {"provider": "aws", "group": "networking_and_content_delivery", "subgroup_1":"route53"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Route-53_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Route-53_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Virtual-Private-Cloud_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Virtual-Private-Cloud_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Virtual-Private-Cloud_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Elastic-Load-Balancing_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Elastic-Load-Balancing_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Elastic-Load-Balancing_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/16/Arch_Amazon-Braket_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/16/Arch_Amazon-Braket_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/32/Arch_Amazon-Braket_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/32/Arch_Amazon-Braket_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/48/Arch_Amazon-Braket_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/48/Arch_Amazon-Braket_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/64/Arch_Amazon-Braket_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/64/Arch_Amazon-Braket_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Quantum_Technologies/64/Arch_Amazon-Braket_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/16/Arch_AWS-RoboMaker_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/16/Arch_AWS-RoboMaker_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/32/Arch_AWS-RoboMaker_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/32/Arch_AWS-RoboMaker_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/48/Arch_AWS-RoboMaker_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/48/Arch_AWS-RoboMaker_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/64/Arch_AWS-RoboMaker_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/64/Arch_AWS-RoboMaker_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Robotics/64/Arch_AWS-RoboMaker_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/16/Arch_AWS-Ground-Station_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/16/Arch_AWS-Ground-Station_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/32/Arch_AWS-Ground-Station_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/32/Arch_AWS-Ground-Station_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/48/Arch_AWS-Ground-Station_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/48/Arch_AWS-Ground-Station_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/64/Arch_AWS-Ground-Station_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/64/Arch_AWS-Ground-Station_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Satellite/64/Arch_AWS-Ground-Station_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Artifact_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Artifact_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Audit-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Audit-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Certificate-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Certificate-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-CloudHSM_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-CloudHSM_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Directory-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Directory-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Firewall-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Firewall-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Identity-and-Access-Management_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Identity-and-Access-Management_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Key-Management-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Key-Management-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Network-Firewall_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Network-Firewall_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Resource-Access-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Resource-Access-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Secrets-Manager_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Secrets-Manager_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Security-Hub_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Security-Hub_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Shield_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Shield_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Signer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Signer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Single-Sign-On_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-Single-Sign-On_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-WAF_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_AWS-WAF_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Cloud-Directory_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Cloud-Directory_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Cognito_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Cognito_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Detective_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Detective_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-GuardDuty_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-GuardDuty_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Inspector_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Inspector_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Macie_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/16/Arch_Amazon-Macie_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Artifact_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Artifact_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Audit-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Audit-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Certificate-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Certificate-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-CloudHSM_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-CloudHSM_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Directory-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Directory-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Firewall-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Firewall-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Identity-and-Access-Management_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Identity-and-Access-Management_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Key-Management-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Key-Management-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Network-Firewall_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Network-Firewall_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Resource-Access-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Resource-Access-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Secrets-Manager_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Secrets-Manager_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Security-Hub_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Security-Hub_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Shield_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Shield_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Signer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Signer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Single-Sign-On_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-Single-Sign-On_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-WAF_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_AWS-WAF_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Cloud-Directory_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Cloud-Directory_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Cognito_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Cognito_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Detective_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Detective_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-GuardDuty_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-GuardDuty_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Inspector_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Inspector_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Macie_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/32/Arch_Amazon-Macie_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Artifact_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Artifact_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Audit-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Audit-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Certificate-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Certificate-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-CloudHSM_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-CloudHSM_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Directory-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Directory-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Firewall-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Firewall-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Identity-and-Access-Management_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Identity-and-Access-Management_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Key-Management-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Key-Management-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Network-Firewall_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Network-Firewall_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Resource-Access-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Resource-Access-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Secrets-Manager_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Secrets-Manager_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Security-Hub_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Security-Hub_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Shield_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Shield_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Signer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Signer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Single-Sign-On_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-Single-Sign-On_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-WAF_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_AWS-WAF_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Cloud-Directory_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Cloud-Directory_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Cognito_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Cognito_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Detective_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Detective_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-GuardDuty_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-GuardDuty_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Inspector_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Inspector_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Macie_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/48/Arch_Amazon-Macie_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Artifact_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Artifact_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Artifact_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Audit-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Audit-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Audit-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Certificate-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Certificate-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Certificate-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-CloudHSM_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-CloudHSM_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-CloudHSM_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Directory-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Directory-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Directory-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Firewall-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Firewall-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Firewall-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Identity-and-Access-Management_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Identity-and-Access-Management_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Identity-and-Access-Management_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Key-Management-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Key-Management-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Key-Management-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Network-Firewall_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Network-Firewall_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Network-Firewall_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Resource-Access-Manager_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Resource-Access-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Resource-Access-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Secrets-Manager_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "security_identity_and_compliance", "subgroup_1":"secrets_manager"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Secrets-Manager_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Secrets-Manager_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Security-Hub_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Security-Hub_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Security-Hub_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Shield_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Shield_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Shield_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Signer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Signer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Signer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Single-Sign-On_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Single-Sign-On_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-Single-Sign-On_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-WAF_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-WAF_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_AWS-WAF_64@5x.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "security_identity_and_compliance", "subgroup_1":"waf"}},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cloud-Directory_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cloud-Directory_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cloud-Directory_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cognito_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cognito_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Cognito_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Detective_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Detective_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Detective_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-GuardDuty_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-GuardDuty_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-GuardDuty_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Inspector_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Inspector_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Inspector_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Macie_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Macie_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Security-Identity-Compliance/64/Arch_Amazon-Macie_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Backup_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Backup_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowball-Edge_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowball-Edge_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowball_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowball_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowcone_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowcone_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowmobile_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Snowmobile_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Storage-Gateway_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_AWS-Storage-Gateway_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Elastic-Block-Store_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Elastic-Block-Store_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Elastic-File-System_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Elastic-File-System_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx-for-Lustre_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx-for-Lustre_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx-for-Windows-File-Server_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx-for-Windows-File-Server_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-FSx_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-S3-on-Outposts_Storage_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-S3-on-Outposts_Storage_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Simple-Storage-Service-Glacier_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Simple-Storage-Service-Glacier_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Simple-Storage-Service_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_Amazon-Simple-Storage-Service_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_CloudEndure-Disaster-Recovery_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/16/Arch_CloudEndure-Disaster-Recovery_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Backup_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Backup_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowball-Edge_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowball-Edge_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowball_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowball_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowcone_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowcone_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowmobile_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Snowmobile_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Storage-Gateway_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_AWS-Storage-Gateway_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Elastic-Block-Store_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Elastic-Block-Store_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Elastic-File-System_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Elastic-File-System_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx-for-Lustre_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx-for-Lustre_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx-for-Windows-File-Server_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx-for-Windows-File-Server_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-FSx_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-S3-on-Outposts_Storage_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-S3-on-Outposts_Storage_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Simple-Storage-Service-Glacier_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Simple-Storage-Service-Glacier_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Simple-Storage-Service_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_Amazon-Simple-Storage-Service_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_CloudEndure-Disaster-Recovery_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/32/Arch_CloudEndure-Disaster-Recovery_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Backup_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Backup_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowball-Edge_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowball-Edge_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowball_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowball_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowcone_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowcone_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowmobile_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Snowmobile_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Storage-Gateway_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_AWS-Storage-Gateway_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Elastic-Block-Store_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Elastic-Block-Store_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Elastic-File-System_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Elastic-File-System_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx-for-Lustre_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx-for-Lustre_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx-for-Windows-File-Server_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx-for-Windows-File-Server_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-FSx_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-S3-on-Outposts_Storage_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-S3-on-Outposts_Storage_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Simple-Storage-Service-Glacier_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Simple-Storage-Service-Glacier_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Simple-Storage-Service_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_Amazon-Simple-Storage-Service_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_CloudEndure-Disaster-Recovery_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/48/Arch_CloudEndure-Disaster-Recovery_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Backup_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Backup_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Backup_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball-Edge_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball-Edge_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball-Edge_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowball_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowcone_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowcone_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowcone_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowmobile_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowmobile_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Snowmobile_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Storage-Gateway_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Storage-Gateway_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_AWS-Storage-Gateway_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-Block-Store_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-Block-Store_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-Block-Store_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-File-System_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-File-System_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-File-System_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Lustre_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Lustre_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Lustre_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Windows-File-Server_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Windows-File-Server_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx-for-Windows-File-Server_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-FSx_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-S3-on-Outposts_Storage_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-S3-on-Outposts_Storage_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-S3-on-Outposts_Storage_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service-Glacier_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service-Glacier_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service-Glacier_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_CloudEndure-Disaster-Recovery_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_CloudEndure-Disaster-Recovery_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_CloudEndure-Disaster-Recovery_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_16/Arch_Amazon-Sumerian_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_16/Arch_Amazon-Sumerian_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_32/Arch_Amazon-Sumerian_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_32/Arch_Amazon-Sumerian_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_48/Arch_Amazon-Sumerian_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_48/Arch_Amazon-Sumerian_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_64/Arch_Amazon-Sumerian_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_64/Arch_Amazon-Sumerian_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_VR-AR/Arch_64/Arch_Amazon-Sumerian_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_AR-VR_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_AR-VR_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_AWS-Cost-Management_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_AWS-Cost-Management_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Analytics_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Analytics_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Application-Integration_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Application-Integration_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Blockchain_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Blockchain_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Business-Applications_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Business-Applications_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Compute_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Compute_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Containers_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Containers_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Customer-Enablement_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Customer-Enablement_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Database_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Database_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Developer-Tools_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Developer-Tools_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_End-User-Computing_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_End-User-Computing_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Front-End-Web-Mobile_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Front-End-Web-Mobile_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Game-Tech_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Game-Tech_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Internet-of-Things_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Internet-of-Things_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Machine-Learning_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Machine-Learning_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Management-Governance_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Management-Governance_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Media-Services_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Media-Services_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Migration-Transfer_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Migration-Transfer_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Networking-Content-Delivery_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Networking-Content-Delivery_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Quantum-Technologies_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Quantum-Technologies_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Robotics_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Robotics_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Satellite_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Satellite_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Security-Identity-Compliance_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Security-Identity-Compliance_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Serverless_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Serverless_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Storage_16.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_16/Arch-Category_Storage_16.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_AR-VR_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_AR-VR_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_AWS-Cost-Management_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_AWS-Cost-Management_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Analytics_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Analytics_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Application-Integration_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Application-Integration_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Blockchain_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Blockchain_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Business-Applications_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Business-Applications_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Compute_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Compute_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Containers_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Containers_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Customer-Enablement_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Customer-Enablement_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Database_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Database_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Developer-Tools_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Developer-Tools_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_End-User-Computing_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_End-User-Computing_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Front-End-Web-Mobile_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Front-End-Web-Mobile_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Game-Tech_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Game-Tech_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Internet-of-Things_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Internet-of-Things_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Machine-Learning_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Machine-Learning_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Management-Governance_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Management-Governance_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Media-Services_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Media-Services_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Migration-Transfer_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Migration-Transfer_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Networking-Content-Delivery_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Networking-Content-Delivery_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Quantum-Technologies_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Quantum-Technologies_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Robotics_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Robotics_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Satellite_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Satellite_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Security-Identity-Compliance_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Security-Identity-Compliance_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Serverless_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Serverless_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Storage_32.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_32/Arch-Category_Storage_32.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_AR-VR_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_AR-VR_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_AWS-Cost-Management_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_AWS-Cost-Management_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Analytics_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Analytics_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Application-Integration_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Application-Integration_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Blockchain_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Blockchain_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Business-Applications_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Business-Applications_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Compute_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Compute_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Containers_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Containers_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Customer-Enablement_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Customer-Enablement_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Database_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Database_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Developer-Tools_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Developer-Tools_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_End-User-Computing_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_End-User-Computing_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Front-End-Web-Mobile_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Front-End-Web-Mobile_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Game-Tech_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Game-Tech_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Internet-of-Things_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Internet-of-Things_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Machine-Learning_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Machine-Learning_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Management-Governance_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Management-Governance_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Media-Services_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Media-Services_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Migration-Transfer_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Migration-Transfer_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Networking-Content-Delivery_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Networking-Content-Delivery_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Quantum-Technologies_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Quantum-Technologies_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Robotics_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Robotics_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Satellite_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Satellite_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Security-Identity-Compliance_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Security-Identity-Compliance_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Serverless_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Serverless_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Storage_48.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_48/Arch-Category_Storage_48.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AR-VR_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AR-VR_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AR-VR_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AWS-Cost-Management_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AWS-Cost-Management_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_AWS-Cost-Management_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Analytics_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Analytics_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Analytics_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Application-Integration_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Application-Integration_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Application-Integration_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Blockchain_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Blockchain_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Blockchain_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Business-Applications_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Business-Applications_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Business-Applications_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Compute_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Compute_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Compute_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Containers_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Containers_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Containers_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Customer-Enablement_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Customer-Enablement_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Customer-Enablement_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Database_64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "database", "subgroup_1":"rds"}
    },
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Database_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Database_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Developer-Tools_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Developer-Tools_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Developer-Tools_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_End-User-Computing_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_End-User-Computing_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_End-User-Computing_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Front-End-Web-Mobile_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Front-End-Web-Mobile_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Front-End-Web-Mobile_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Game-Tech_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Game-Tech_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Game-Tech_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Internet-of-Things_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Internet-of-Things_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Internet-of-Things_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Machine-Learning_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Machine-Learning_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Machine-Learning_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Management-Governance_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Management-Governance_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Management-Governance_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Media-Services_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Media-Services_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Media-Services_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Migration-Transfer_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Migration-Transfer_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Migration-Transfer_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Networking-Content-Delivery_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Networking-Content-Delivery_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Networking-Content-Delivery_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Quantum-Technologies_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Quantum-Technologies_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Quantum-Technologies_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Robotics_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Robotics_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Robotics_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Satellite_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Satellite_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Satellite_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Security-Identity-Compliance_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Security-Identity-Compliance_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Security-Identity-Compliance_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Serverless_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Serverless_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Serverless_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Storage_64.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Storage_64.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Category-Icons_07302021/Arch-Category_64/Arch-Category_Storage_64@5x.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Glue_Crawler_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Glue_Crawler_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Glue_Data-Catalog_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Glue_Data-Catalog_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Lake-Formation_Data-Lake_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_AWS-Lake-Formation_Data-Lake_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_Cluster_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_Cluster_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_EMR-Engine_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_EMR-Engine_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_HDFS-Cluster_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Dark/Res_Amazon-EMR_HDFS-Cluster_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Glue_Crawler_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Glue_Crawler_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Glue_Data-Catalog_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Glue_Data-Catalog_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Lake-Formation_Data-Lake_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_AWS-Lake-Formation_Data-Lake_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_Cluster_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_Cluster_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_EMR-Engine_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_EMR-Engine_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_HDFS-Cluster_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Analytics/Res_48_Light/Res_Amazon-EMR_HDFS-Cluster_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-API-Gateway_Endpoint_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-API-Gateway_Endpoint_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Custom-Event-Bus_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Custom-Event-Bus_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Default-Event-Bus_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Default-Event-Bus_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Event_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Event_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Saas-Partner-Event_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Saas-Partner-Event_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Schema-Registry_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Schema-Registry_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Schema_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-EventBridge_Schema_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-MQ_Broker_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-MQ_Broker_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_Email-Notification_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_Email-Notification_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_HTTP-Notification_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_HTTP-Notification_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_Topic_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Notification-Service_Topic_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Queue-Service_Message_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Queue-Service_Message_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Queue-Service_Queue_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Dark/Res_Amazon-Simple-Queue-Service_Queue_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-API-Gateway_Endpoint_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-API-Gateway_Endpoint_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Custom-Event-Bus_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Custom-Event-Bus_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Default-Event-Bus_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Default-Event-Bus_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Event_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Event_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Saas-Partner-Event_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Saas-Partner-Event_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Schema-Registry_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Schema-Registry_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Schema_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-EventBridge_Schema_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-MQ_Broker_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-MQ_Broker_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_Email-Notification_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_Email-Notification_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_HTTP-Notification_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_HTTP-Notification_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_Topic_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Notification-Service_Topic_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Queue-Service_Message_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Queue-Service_Message_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Queue-Service_Queue_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Application-Integration/Res_48_Light/Res_Amazon-Simple-Queue-Service_Queue_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Blockchain/Res_48_Dark/Res_Amazon-Managed-Blockchain_Blockchain_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Blockchain/Res_48_Dark/Res_Amazon-Managed-Blockchain_Blockchain_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Blockchain/Res_48_Light/Res_Amazon-Managed-Blockchain_Blockchain_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Blockchain/Res_48_Light/Res_Amazon-Managed-Blockchain_Blockchain_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Dark/Res_Amazon-Pinpoint_Journey_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Dark/Res_Amazon-Pinpoint_Journey_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Dark/Res_Amazon-Simple-Email-Service_Email_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Dark/Res_Amazon-Simple-Email-Service_Email_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Light/Res_Amazon-Pinpoint_Journey_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Light/Res_Amazon-Pinpoint_Journey_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Light/Res_Amazon-Simple-Email-Service_Email_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Business-Applications/Res_48_Light/Res_Amazon-Simple-Email-Service_Email_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Elastic-Beanstalk_Application_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Elastic-Beanstalk_Application_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Elastic-Beanstalk_Deployment_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Elastic-Beanstalk_Deployment_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Lambda_Lambda-Function_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_AWS-Lambda_Lambda-Function_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_A1-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_A1-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_AMI_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_AMI_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_AWS-Inferentia_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_AWS-Inferentia_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Auto-Scaling_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Auto-Scaling_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C4-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C4-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5a_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5a_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5ad_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5ad_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5d_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5d_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5n-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C5n-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C6g-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C6g-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C6gd_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_C6gd_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D2-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D2-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D3-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D3-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D3en-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_D3en-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_DB-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_DB-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Elastic-IP-Address_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Elastic-IP-Address_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_F1-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_F1-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G3-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G3-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G4ad-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G4ad-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G4dn_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_G4dn_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_H1-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_H1-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_HMI-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_HMI-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Habana-Gaudi_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Habana-Gaudi_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I2_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I2_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I3-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I3-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I3en_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_I3en_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Inf1_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Inf1_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instance-with-CloudWatch_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instance-with-CloudWatch_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instances_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Instances_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M4-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M4-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5a-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5a-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5d-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5d-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5dn-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5dn-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5n-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5n-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5zn-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M5zn-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M6g-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M6g-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M6gd-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_M6gd-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Mac-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Mac-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P2-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P2-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P3-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P3-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P3dn-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P3dn-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P4-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P4-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P4d-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_P4d-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R4-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R4-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5a-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5a-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5ad-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5ad-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5b-Instance",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5b-Instance",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5d-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5d-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5gd-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5gd-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5n-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R5n-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R6g-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_R6g-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Rdn-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Rdn-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Rescue_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Rescue_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Spot-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Spot-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T2-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T2-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T3-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T3-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T3a-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T3a-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T4g-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_T4g-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Trainium-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_Trainium-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_X1-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_X1-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_X1e-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_X1e-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_z1d-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Dark/Res_Amazon-EC2_z1d-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Elastic-Beanstalk_Application_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Elastic-Beanstalk_Application_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Elastic-Beanstalk_Deployment_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Elastic-Beanstalk_Deployment_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Lambda_Lambda-Function_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_AWS-Lambda_Lambda-Function_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_A1-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_A1-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_AMI_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_AMI_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_AWS-Inferentia_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_AWS-Inferentia_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Auto-Scaling_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Auto-Scaling_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C4-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C4-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5a-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5a-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5ad-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5ad-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5d-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5d-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5n-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C5n-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C6g-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C6g-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C6gd-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_C6gd-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D2-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D2-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D3-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D3-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D3en-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_D3en-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_DB-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_DB-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Elastic-IP-Address_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Elastic-IP-Address_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_F1-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_F1-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G3-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G3-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G4ad-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G4ad-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G4dn-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_G4dn-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_H1-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_H1-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_HMI-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_HMI-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Habana-Gaudi-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Habana-Gaudi-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I2-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I2-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I3-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I3-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I3en-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_I3en-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Inf1-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Inf1-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance-with-CloudWatch_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance-with-CloudWatch_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instances_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instances_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M4-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M4-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5a-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5a-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5d-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5d-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5dn-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5dn-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5n-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5n-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5zn-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M5zn-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M6g-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M6g-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M6gd-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_M6gd-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Mac-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Mac-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P2-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P2-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P3-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P3-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P3dn-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P3dn-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P4-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P4-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P4d-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_P4d-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R4-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R4-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5a-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5a-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5ad-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5ad-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5b-Instance",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5b-Instance",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5d-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5d-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5gd-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5gd-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5n-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R5n-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R6g-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_R6g-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Rdn-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Rdn-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Rescue_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Rescue_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Spot-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Spot-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T2-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T2-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T3-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T3-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T3a-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T3a-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T4g-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_T4g-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Trainium-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_Trainium-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_X1-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_X1-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_X1e-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_X1e-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_z1d-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Compute/Res_48_Light/Res_Amazon-EC2_z1d-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Registry_Image_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Registry_Image_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Registry_Registry_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Registry_Registry_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-1_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-1_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-2_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-2_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-3_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Container-3_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Copilot-CLI_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Copilot-CLI_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Service_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Service_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Task_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Dark/Res_Amazon-Elastic-Container-Service_Task_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Registry_Image_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Registry_Image_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Registry_Registry_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Registry_Registry_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-1_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-1_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-2_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-2_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-3_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-3_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Copilot-CLI_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Copilot-CLI_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Service_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Service_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Task_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Task_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_AWS-Database-Migration-Service_Database-migration-workflow-job_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_AWS-Database-Migration-Service_Database-migration-workflow-job_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MariaDB-Instance-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MariaDB-Instance-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MariaDB-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MariaDB-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MySQL-Instance-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MySQL-Instance-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MySQL-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-MySQL-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Oracle-Instance-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Oracle-Instance-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Oracle-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-Oracle-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PIOPS-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PIOPS-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PostgreSQL-Instance-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PostgreSQL-Instance-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PostgreSQL-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-PostgreSQL-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-SQL-Server-Instance-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-SQL-Server-Instance-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-SQL-Server-Instance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora-SQL-Server-Instance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora_Amazon-Aurora-Instance-alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-Aurora_Amazon-Aurora-Instance-alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Amazon-DynamoDB-Accelerator_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Amazon-DynamoDB-Accelerator_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Attribute_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Attribute_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Attributes_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Attributes_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Global-secondary-index_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Global-secondary-index_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Item_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Item_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Items_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Items_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Stream_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Stream_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Table_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Table_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_Cache-Node_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_Cache-Node_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_ElastiCache-for-Memcached_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_ElastiCache-for-Memcached_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_ElastiCache-for-Redis_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Dark/Res_Amazon-ElastiCache_ElastiCache-for-Redis_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_AWS-Database-Migration-Service_Database-migration-workflow-job_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_AWS-Database-Migration-Service_Database-migration-workflow-job_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MariaDB-Instance-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MariaDB-Instance-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MariaDB-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MariaDB-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MySQL-Instance-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MySQL-Instance-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MySQL-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-MySQL-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Oracle-Instance-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Oracle-Instance-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Oracle-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-Oracle-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PIOPS-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PIOPS-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PostgreSQL-Instance-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PostgreSQL-Instance-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PostgreSQL-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-PostgreSQL-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-SQL-Server-Instance-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-SQL-Server-Instance-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-SQL-Server-Instance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora-SQL-Server-Instance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora_Amazon-Aurora-Instance-alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-Aurora_Amazon-Aurora-Instance-alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Amazon-DynamoDB-Accelerator_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Amazon-DynamoDB-Accelerator_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Attribute_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Attribute_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Attributes_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Attributes_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Global-secondary-index_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Global-secondary-index_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Item_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Item_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Items_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Items_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Stream_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Stream_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Table_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-DynamoDB_Table_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_Cache-Node_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_Cache-Node_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_ElastiCache-for-Memcached_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_ElastiCache-for-Memcached_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_ElastiCache-for-Redis_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Database/Res_48_Light/Res_Amazon-ElastiCache_ElastiCache-for-Redis_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Developer-Tools/Res_48_Dark/Res_AWS-Cloud9_Cloud9_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Developer-Tools/Res_48_Dark/Res_AWS-Cloud9_Cloud9_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Developer-Tools/Res_48_Light/Res_AWS-Cloud9_Cloud9_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Developer-Tools/Res_48_Light/Res_AWS-Cloud9_Cloud9_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Geofence_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Geofence_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Map_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Map_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Place_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Place_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Routes_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Routes_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Track_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Dark/Res_Amazon-Location-Service_Track_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Geofence_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Geofence_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Map_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Map_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Place_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Place_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Routes_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Routes_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Track_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Front-End-Web-Mobile/Res_48_Light/Res_Amazon-Location-Service_Track_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Camera_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Camera_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Client_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Client_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Disk_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Disk_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Documents_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Documents_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Email_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Email_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Firewall_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Firewall_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Forums_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Forums_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Gear_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Gear_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Generic-database_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Generic-database_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet-alt1_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet-alt1_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet-alt2_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet-alt2_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Internet_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Mobile-client_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Mobile-client_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Multimedia_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Multimedia_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Office-building_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Office-building_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Question_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Question_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SAML-token_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SAML-token_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SDK_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SDK_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SSL-padlock_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_SSL-padlock_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Tape-storage_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Tape-storage_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Toolkit_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Toolkit_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Traditional-server_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Traditional-server_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_User_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_User_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Users_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Dark/Res_Users_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Camera_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Camera_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Client_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Client_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Disk_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Disk_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Documents_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Documents_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Email_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Email_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Firewall_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Firewall_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Forums_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Forums_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Gear_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Gear_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Generic-database_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Generic-database_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet-alt1_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet-alt1_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet-alt2_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet-alt2_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Internet_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Mobile-client_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Mobile-client_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Multimedia_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Multimedia_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Office-building_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Office-building_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Question_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Question_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SAML-token_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SAML-token_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SDK_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SDK_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SSL-padlock_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_SSL-padlock_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Tape-storage_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Tape-storage_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Toolkit_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Toolkit_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Traditional-server_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Traditional-server_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_User_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_User_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Users_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_General-Icons/Res_48_Light/Res_Users_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-DevOps-Guru_Insights_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-DevOps-Guru_Insights_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-Rekognition_Image_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-Rekognition_Image_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-Rekognition_Video_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-Rekognition_Video_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Model_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Model_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Notebook_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Notebook_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Train_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Dark/Res_Amazon-SageMaker_Train_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-DevOps-Guru_Insights_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-DevOps-Guru_Insights_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-Rekognition_Image_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-Rekognition_Image_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-Rekognition_Video_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-Rekognition_Video_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Model_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Model_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Notebook_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Notebook_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Train_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Machine-Learning/Res_48_Light/Res_Amazon-SageMaker_Train_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Change-Set_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Change-Set_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Stack_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Stack_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Template_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-CloudFormation_Template_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-License-Manager_Application-Discovery_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-License-Manager_Application-Discovery_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-License-Manager_License-Blending_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-License-Manager_License-Blending_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Apps_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Apps_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Deployments_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Deployments_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Instances_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Instances_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Layers_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Layers_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Monitoring_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Monitoring_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Permissions_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Permissions_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Resources_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Resources_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Stack2_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-OpsWorks_Stack2_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Account_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Account_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Management-Account_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Management-Account_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Organizational-Unit_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Organizations_Organizational-Unit_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Automation_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Automation_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Documents_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Documents_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Inventory_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Inventory_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Maintenance-Windows_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Maintenance-Windows_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_OpsCenter_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_OpsCenter_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Parameter-Store_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Parameter-Store_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Patch-Manager_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Patch-Manager_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Run-Command_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_Run-Command_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_State-Manager_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Systems-Manager_State-Manager_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Cost_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Cost_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Fault-Tolerant_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Fault-Tolerant_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Performance_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Performance_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Security_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist-Security_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_AWS-Trusted-Advisor_Checklist_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Alarm_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Alarm_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Event-Event-Based_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Event-Event-Based_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Event-Time-Based_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Event-Time-Based_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Logs_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Logs_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Synthetics_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Dark/Res_Amazon-CloudWatch_Synthetics_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Change-Set_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Change-Set_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Stack_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Stack_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Template_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-CloudFormation_Template_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-License-Manager_Application-Discovery_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-License-Manager_Application-Discovery_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-License-Manager_License-Blending_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-License-Manager_License-Blending_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Apps_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Apps_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Deployments_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Deployments_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Instances_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Instances_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Layers_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Layers_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Monitoring_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Monitoring_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Permissions_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Permissions_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Resources_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Resources_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Stack2_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-OpsWorks_Stack2_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Account_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Account_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Management-Account_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Management-Account_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Organizational-Unit_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Organizational-Unit_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Automation_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Automation_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Documents_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Documents_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Inventory_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Inventory_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Maintenance-Windows_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Maintenance-Windows_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_OpsCenter_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_OpsCenter_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Parameter-Store_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Parameter-Store_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Patch-Manager_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Patch-Manager_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Run-Command_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_Run-Command_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_State-Manager_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Systems-Manager_State-Manager_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Cost_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Cost_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Fault-Tolerant_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Fault-Tolerant_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Performance_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Performance_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Security_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist-Security_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_AWS-Trusted-Advisor_Checklist_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Alarm_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Alarm_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Event-Event-Based_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Event-Event-Based_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Event-Time-Based_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Event-Time-Based_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Logs_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Logs_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Synthetics_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Management-Governance/Res_48_Light/Res_Amazon-CloudWatch_Synthetics_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Media-Services/Res_48_Dark/Res_AWS-Cloud-Digital-Interface_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Media-Services/Res_48_Dark/Res_AWS-Cloud-Digital-Interface_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Media-Services/Res_48_Light/Res_AWS-Cloud-Digital-Interface_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Media-Services/Res_48_Light/Res_AWS-Cloud-Digital-Interface_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Datasync_Agent_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Datasync_Agent_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-FTPS_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-FTPS_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-FTP_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-FTP_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-SFTP_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Dark/Res_AWS-Transfer-Family_AWS-SFTP_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Datasync_Agent_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Datasync_Agent_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-FTPS_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-FTPS_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-FTP_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-FTP_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-SFTP_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Migration-and-Transfer/Res_48_Light/Res_AWS-Transfer-Family_AWS-SFTP_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Mesh_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Mesh_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Node_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Node_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Router_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Router_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Service_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-App-Mesh_Virtual-Service_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Namespace_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Namespace_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Resource_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Resource_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Service_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Cloud-Map_Service_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Direct-Connect_Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_AWS-Direct-Connect_Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Download-Distribution_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Download-Distribution_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Edge-Location_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Edge-Location_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Functions_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Functions_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Streaming-Distribution_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-CloudFront_Streaming-Distribution_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53-Hosted-Zone_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53-Hosted-Zone_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver-DNS-Firewall_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver-DNS-Firewall_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver-Query-Logging_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver-Query-Logging_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Resolver_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Route-Table_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-Route-53_Route-Table_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Carrier-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Carrier-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Customer-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Customer-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Elastic-Network-Adapter_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Elastic-Network-Adapter_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Elastic-Network-Interface_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Elastic-Network-Interface_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Endpoints_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Endpoints_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Flow-Logs_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Flow-Logs_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Internet-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Internet-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_NAT-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_NAT-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Network-Access-Control-List_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Network-Access-Control-List_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Peering-Connection_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Peering-Connection_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Reachability-Analyzer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Reachability-Analyzer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Router_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Router_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Traffic-Mirroring_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_Traffic-Mirroring_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Connection_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Connection_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Gateway-Load-Balancer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Gateway-Load-Balancer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Mesh_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Mesh_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Node_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Node_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Router_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Router_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Service_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-App-Mesh-Virtual-Service_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map-Namespace_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map-Namespace_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map-Resource_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map-Resource_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map_Service_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Cloud-Map_Service_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Direct-Connect-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_AWS-Direct-Connect-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Download-Distribution_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Download-Distribution_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Edge-Location_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Edge-Location_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Functions_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Functions_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Streaming-Distribution_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-CloudFront_Streaming-Distribution_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53-Hosted-Zone_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53-Hosted-Zone_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver-DNS-Firewall_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver-DNS-Firewall_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver-Query-Logging_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver-Query-Logging_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Resolver_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Route-Table_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Route-Table_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Carrier-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Carrier-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Customer-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Customer-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Adapter_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Adapter_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Interface_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Interface_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Endpoints_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Endpoints_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Flow-Logs_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Flow-Logs_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Internet-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Internet-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_NAT-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_NAT-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Network-Access-Control-List_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Network-Access-Control-List_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Peering-Connection_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Peering-Connection_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Reachability-Analyzer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Reachability-Analyzer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Router_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Router_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Traffic-Mirroring_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Traffic-Mirroring_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/vpc_64x64.png",
        "enabled": "true", "resource_details": {"provider": "aws", "group": "networking_and_content_delivery", "subgroup_1":"vpc"}},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_VPN-Connection_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_VPN-Connection_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_VPN-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_VPN-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_App-Mesh_Virtual-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_App-Mesh_Virtual-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Gateway-Load-Balancer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Gateway-Load-Balancer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Chandelier",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Chandelier",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Chip_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Chip_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Noise-Simulator_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Noise-Simulator_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_QPU_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_QPU_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-1_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-1_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-2_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-2_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-3_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-3_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-4_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator-4_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Simulator_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_State-Vector_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_State-Vector_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Tensor-Network_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Dark/Res_Amazon-Braket_Tensor-Network_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Chandelier_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Chandelier_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Chip_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Chip_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Noise-Simulator_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Noise-Simulator_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_QPU",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_QPU",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-1_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-1_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-2_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-2_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-3_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-3_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-4_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator-4_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Simulator_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_State-Vector_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_State-Vector_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Tensor-Network_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Quantum-Technologies/Res_48_Light/Res_Amazon-Braket_Tensor-Network_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Cloud-Extensions-ROS_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Cloud-Extensions-ROS_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Development-Environment_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Development-Environment_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Fleet-Management_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Fleet-Management_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Simulation_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Dark/Res_AWS-RoboMaker_Simulation_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Cloud-Extensions-ROS_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Cloud-Extensions-ROS_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Development-Environment_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Development-Environment_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Fleet-Management_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Fleet-Management_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Simulation_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Robotics/Res_48_Light/Res_AWS-RoboMaker_Simulation_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Certificate-Manager_Certificate-Authority_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Certificate-Manager_Certificate-Authority_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_AD-Connector_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_AD-Connector_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_AWS-Managed-Microsoft-AD_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_AWS-Managed-Microsoft-AD_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_Simple-AD_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Directory-Service_Simple-AD_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-IAM-Access-Analyzer_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-IAM-Access-Analyzer_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-STS-Alternate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-STS-Alternate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-STS_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_AWS-STS_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Add-on_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Add-on_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Data-Encryption-Key_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Data-Encryption-Key_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Encrypted-Data_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Encrypted-Data_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Long-Term-Security-Credential_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Long-Term-Security-Credential_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_MFA-Token_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_MFA-Token_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Permissions_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Permissions_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Role_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Role_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Temporary-Security-Credential_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Identity-Access-Management_Temporary-Security-Credential_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Network-Firewall_Endpoints_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Network-Firewall_Endpoints_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Security-Hub_Finding_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Security-Hub_Finding_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Shield_AWS-Shield-Advanced_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-Shield_AWS-Shield-Advanced_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bad-Bot_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bad-Bot_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bot-Control_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bot-Control_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bot_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Bot_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Filtering-Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Filtering-Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Labels_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Labels_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Managed-Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Managed-Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_AWS-WAF_Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_Amazon-Inspector_Agent_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Dark/Res_Amazon-Inspector_Agent_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Certificate-Manager_Certificate-Authority_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Certificate-Manager_Certificate-Authority_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_AD-Connector_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_AD-Connector_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_AWS-Managed-Microsoft-AD_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_AWS-Managed-Microsoft-AD_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_Simple-AD_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Directory-Service_Simple-AD_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-IAM-Access-Analyzer_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-IAM-Access-Analyzer_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-STS-Alternate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-STS-Alternate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-STS_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-STS_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Add-on_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Add-on_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Data-Encryption-Key_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Data-Encryption-Key_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Encrypted-Data_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Encrypted-Data_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Long-Term-Security-Credential_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Long-Term-Security-Credential_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_MFA-Token_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_MFA-Token_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Permissions_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Permissions_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Role_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Role_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Temporary-Security-Credential_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Temporary-Security-Credential_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Network-Firewall_Endpoints_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Network-Firewall_Endpoints_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Security-Hub_Finding_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Security-Hub_Finding_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Shield_AWS-Shield-Advanced_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Shield_AWS-Shield-Advanced_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bad-Bot_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bad-Bot_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bot-Control_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bot-Control_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bot_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Bot_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Filtering-Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Filtering-Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Labels_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Labels_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Managed-Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Managed-Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-WAF_Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_Amazon-Inspector_Agent_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Security-Identity-and-Compliance/Res_48_Light/Res_Amazon-Inspector_Agent_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Plan_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Plan_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Restore_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Restore_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Vault_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Backup-Vault_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Compliance-Reporting_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Compliance-Reporting_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Recovery-Point-Objective_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Recovery-Point-Objective_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Recovery-Time-Objective_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Backup_Recovery-Time-Objective_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Snowball_Snowball-Import-Export_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Snowball_Snowball-Import-Export_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Amazon-FSx-File-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Amazon-FSx-File-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Amazon-S3-File-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Amazon-S3-File-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Cached-Volume_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Cached-Volume_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_File-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_File-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Non-Cached-Volume_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Non-Cached-Volume_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Tape-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Tape-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Virtual-Tape-Library_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Virtual-Tape-Library_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Volume-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_AWS-Storage-Gateway_Volume-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Amazon-Data-Lifecycle-Manager_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Amazon-Data-Lifecycle-Manager_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Multiple-Volumes_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Multiple-Volumes_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Snapshot_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Snapshot_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Volume-gp3_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Volume-gp3_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Volume_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-Block-Store_Volume_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_File-System_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_File-System_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_One-Zone-Infrequent-Access_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_One-Zone-Infrequent-Access_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_One-Zone_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_One-Zone_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_Standard-Infrequent-Access_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_Standard-Infrequent-Access_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_Standard_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Elastic-File-System_Standard_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service-Glacier_Archive_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service-Glacier_Archive_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service-Glacier_Vault_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service-Glacier_Vault_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Bucket-With-Objects_48_Dark.png",
        "enabled": "true", "resource_details": {"group": "storage", "subgroup_1":"s3"}},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Bucket-With-Objects_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Bucket_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Bucket_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Object_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Object_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Glacier-Deep-Archive_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Glacier-Deep-Archive_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Glacier_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Glacier_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Intelligent-Tiering_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Intelligent-Tiering_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-On-Outposts_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-On-Outposts_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-One-Zone-IA_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-One-Zone-IA_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Replication-Time-Control_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Replication-Time-Control_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Replication_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Replication_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Standard-IA_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Standard-IA_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Standard_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Standard_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Storage-Lens_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_S3-Storage-Lens_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_VPC-Access-Points_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_VPC-Access-Points_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Plan_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Plan_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Restore_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Restore_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Vault_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Backup-Vault_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Compliance-Reporting_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Compliance-Reporting_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Recovery-Point-Objective_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Recovery-Point-Objective_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Recovery-Time-Objective_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Backup_Recovery-Time-Objective_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Snowball_Snowball-Import-Export_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Snowball_Snowball-Import-Export_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Amazon-FSx-File-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Amazon-FSx-File-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Amazon-S3-File-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Amazon-S3-File-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Cached-Volume_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Cached-Volume_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_File-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_File-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Non-Cached-Volume_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Non-Cached-Volume_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Tape-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Tape-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Virtual-Tape-Library_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Virtual-Tape-Library_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Volume-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_AWS-Storage-Gateway_Volume-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Amazon-Data-Lifecycle-Manager_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Amazon-Data-Lifecycle-Manager_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Multiple-Volumes_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Multiple-Volumes_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Snapshot_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Snapshot_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Volume-gp3_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Volume-gp3_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Volume_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Volume_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_File-System_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_File-System_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_One-Zone-Infrequent-Access_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_One-Zone-Infrequent-Access_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_One-Zone_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_One-Zone_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_Standard-Infrequent-Access_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_Standard-Infrequent-Access_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_Standard_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Elastic-File-System_Standard_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service-Glacier_Archive_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service-Glacier_Archive_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service-Glacier_Vault_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service-Glacier_Vault_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Bucket-With-Objects_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Bucket-With-Objects_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Bucket_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Bucket_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Object_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_Object_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Glacier-Deep-Archive_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Glacier-Deep-Archive_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Glacier_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Glacier_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Intelligent-Tiering_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Intelligent-Tiering_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-On-Outposts_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-On-Outposts_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-One-Zone-IA_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-One-Zone-IA_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Replication-Time-Control_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Replication-Time-Control_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Replication_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Replication_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Standard-IA_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Standard-IA_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Standard_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Standard_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Storage-Lens_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_S3-Storage-Lens_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_VPC-Access-Points_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Storage/Res_48_Light/Res_Amazon-Simple-Storage-Service_VPC-Access-Points_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Channel_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Channel_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Data-Store_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Data-Store_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Dataset_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Dataset_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Notebook_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Notebook_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Pipeline_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Analytics_Pipeline_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Device-Defender_IoT-Device-Jobs_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Device-Defender_IoT-Device-Jobs_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Artifact_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Artifact_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Machine-Learning_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Machine-Learning_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Nucleus_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Nucleus_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Private_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Private_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Public_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component-Public_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Component_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Interprocess-Communication_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Interprocess-Communication_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Protocol_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Protocol_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Recipe_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Recipe_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Stream-Manager_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Greengrass_Stream-Manager_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Hardware-Board_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Hardware-Board_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Rule_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT-Rule_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Action_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Action_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Actuator_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Actuator_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Enabled-Device_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Enabled-Device_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Skill_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Skill_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Voice-Service_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Alexa_Voice-Service_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Certificate_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Certificate_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Desired-State_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Desired-State_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Device-Gateway_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Device-Gateway_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Echo_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Echo_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Fire-TV_Stick_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Fire-TV_Stick_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Fire_TV_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Fire_TV_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Greengrass_Connector_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Greengrass_Connector_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_HTTP2-Protocol_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_HTTP2-Protocol_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_HTTP_Protocol_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_HTTP_Protocol_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Lambda_Function_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Lambda_Function_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_LoRaWAN-Protocol",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_LoRaWAN-Protocol",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_MQTT_Protocol_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_MQTT_Protocol_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Over-Air-Update_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Over-Air-Update_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Policy_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Policy_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Reported-State_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Reported-State_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Sailboat",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Sailboat",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Servo_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Servo_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Shadow_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Shadow_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Simulator_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Simulator_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Bank_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Bank_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Bicycle_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Bicycle_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Camera_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Camera_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Car_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Car_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Cart_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Cart_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Coffee-Pot_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Coffee-Pot_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Door-Lock_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Door-Lock_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Factory_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Factory_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_FreeRTOS-Device_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_FreeRTOS-Device_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Generic_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Generic_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_House_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_House_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Humidity-Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Humidity-Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Industrial-PC_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Industrial-PC_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Lightbulb_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Lightbulb_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Medical-Emergency_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Medical-Emergency_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_PLC_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_PLC_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Police-Emergency_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Police-Emergency_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Relay_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Relay_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Stacklight_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Stacklight_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Humidity-Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Humidity-Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Vibration-Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Temperature-Vibration-Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Thermostat_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Thermostat_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Travel_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Travel_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Utility_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Utility_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Vibration-Sensor_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Vibration-Sensor_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Windfarm_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Thing_Windfarm_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Topic_48_Dark.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Dark/Res_AWS-IoT_Topic_48_Dark.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Channel_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Channel_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Data-Store_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Data-Store_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Dataset_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Dataset_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Notebook_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Notebook_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Pipeline_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Analytics_Pipeline_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Device-Defender_IoT-Device-Jobs_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Device-Defender_IoT-Device-Jobs_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Artifact_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Artifact_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Machine-Learning_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Machine-Learning_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Nucleus_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Nucleus_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Private_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Private_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Public_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component-Public_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Component_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Connector_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Connector_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Interprocess-Communication_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Interprocess-Communication_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Protocol_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Protocol_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Recipe_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Recipe_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Stream-Manager_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Greengrass_Stream-Manager_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-HTTP_Protocol_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-HTTP_Protocol_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Hardware-Board_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Hardware-Board_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-MQTT_Protocol_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-MQTT_Protocol_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Rule_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Rule_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Bank_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Bank_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Bicycle_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Bicycle_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Camera_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Camera_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Car_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Car_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Cart_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Cart_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Coffee-Pot_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Coffee-Pot_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Door-Lock_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Door-Lock_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Factory_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Factory_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Generic_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Generic_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_House_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_House_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Lightbulb_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Lightbulb_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Medical-Emergency_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Medical-Emergency_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Police-Emergency_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Police-Emergency_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Thermostat_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Thermostat_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Travel_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Travel_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Utility_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Utility_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Windfarm_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT-Thing_Windfarm_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Action_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Action_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Actuator_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Actuator_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Enabled-Device_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Enabled-Device_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Skill_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Skill_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Voice-Service_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Alexa_Voice-Service_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Certificate_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Certificate_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Desired-State_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Desired-State_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Device-Gateway_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Device-Gateway_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Echo_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Echo_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Fire-TV_Stick_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Fire-TV_Stick_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Fire_TV_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Fire_TV_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_HTTP2-Protocol_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_HTTP2-Protocol_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Lambda_Function_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Lambda_Function_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_LoRaWAN-Protocol",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_LoRaWAN-Protocol",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Over-Air-Update_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Over-Air-Update_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Policy_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Policy_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Reported-State_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Reported-State_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Sailboat",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Sailboat",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Servo_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Servo_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Shadow_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Shadow_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Simulator_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Simulator_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_FreeRTOS-Device_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_FreeRTOS-Device_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Humidity-Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Humidity-Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Industrial-PC_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Industrial-PC_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_PLC_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_PLC_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Relay_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Relay_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Stacklight_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Stacklight_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Humidity-Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Humidity-Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Vibration-Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Temperature-Vibration-Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Vibration-Sensor_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Thing_Vibration-Sensor_48_Light.svg",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Topic_48_Light.png",
        "enabled": "false"},
    {"path": "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_loT/Res_48_Light/Res_AWS-IoT_Topic_48_Light.svg",
        "enabled": "false"}
]

export default AWS_RESOURCE_ICONS;