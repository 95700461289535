import React from "react";
import PropTypes from 'prop-types';

const SeverityRenderer = ({severity}) =>{
    const severityMap = {
        5: { severityLevel: "Critical", class: "severity-critical" },
        4: { severityLevel: "High", class: "severity-high" },
        3: { severityLevel: "Medium", class: "severity-medium" },
        2: { severityLevel: "Low", class: "severity-low" },
        1: { severityLevel: "Lower", class: "severity-lower" },
        0: { severityLevel: "Lowest", class: "severity-lowest" }
    }
    return(
        <div className={`flex txt-white p-l-r-7 fs-12 fw-600 ${severityMap[severity]?.class}`}>{severityMap[severity]?.severityLevel}</div>
    )
}

SeverityRenderer.propTypes = {
    severity : PropTypes.number
}

SeverityRenderer.defaultProps={
    severity : 0
}

export default SeverityRenderer