
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";
// import ReactFlow from "reactflow";

export const SqsContextShowInfoBarHandler = () => {

    const displayFields = [  ]
    const title = "SQS"

    
    displayFields.push(GetSimpleKeyPairValueElement("QueueUrl", "QueueUrl"))

    // add attributes here
    const attrObj = {
        label: "QueueUrn",
        key: "QueueUrn",
        element_type: "attribute",
    }

    displayFields.push(attrObj)

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}


export const SqsContextAssetHandler = () => {
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;
    
    const initialNodes = [
        { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
        { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
      ];
    const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
  
    return (
      <div className='contextbar-actionContainer'>
        <div className='context-bar-head'>
          <div className='flex'>
            <img alt='' src={imgPath} width='48' height='48' />
          </div>
          <div>
            <h6 className='context-bar-title'>Connected Lambdas</h6>

            {/* <ReactFlow nodes={initialNodes} edges={initialEdges} /> */}
            {/* <div className='context-bar-subtitle'>{'latestData?.InstanceId'}</div> */}
          </div>
        </div>
  
 
        <div className='node-property'></div>
      </div>
    );
  };

// looks like the below function is used anywhere
export const SqsContextShowInfoBarHandlerV1 = () => {
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()

    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
    let imgPath = currentContextBarNodeInfo.node_data.imgPath

    let resourceProperties = [
        "QueueUrl",
        // "Attributes",
    ]

    const resourcePropertyBody = resourceProperties.map((p) =>
        <tr key={uniqid()}>
            <td>{p}</td>
            <td>{nodeContextInfo[p]}</td>
        </tr>
    );


    return (
        <div>
            <h1><img src={imgPath} width="48" height="48"/> ec2 </h1>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
                </thead>

                <tbody>
                {resourcePropertyBody}
                </tbody>

            </Table>
        </div>
    )
}

//
//
