import React, {createRef} from 'react';
import styled from 'styled-components';
import {Accordion, Button, Card, Col, Form, Row} from "react-bootstrap";
import {GetAllAwsResourceData} from "./AWS/AwsResourceList";
import {
    CREATE_RESOURCE_NODE,
    GetNodesListData,
    GetResourcesGraphSnapshotSerialized, RESOURCE_CONTEXT_INFO_KEY_TAG,
    sendMessageToDiagramComponent
} from "./ResourceEditorCanvas";

import "../styles/Sidebar.css"
import {AWS_RESOURCE_ICONS_BASE_PATH} from "./AWS/AWSResourcePickerIconAcess";
import {DispatchAction, GetState, Subscribe} from "./statemanagement/store";
import * as actions from "./statemanagement/ActionsList";
import GetAllGeneralResourceIconsWithInfo from "./GeneralResourceIconsList";
import axios from "axios";
import {SendGetHttpRequestAndGetNewCliCmd} from "./CliCmdToast";
import {GetTerraformScriptsURL} from "../routes";
import {getCurrentContextBarNodeInfo} from "./ContextBar";
import {GetUserLoginToken} from "./UserCredentials";
import {CONCERTO_HTTP_OK} from "../CommonConsts";
import uniqid from "uniqid";
import {getSyncFromCloudCard2} from "./CloudSyncUtil";

const onResourceImageClicked = (clickData) => {
    clickData.message_type = CREATE_RESOURCE_NODE
    sendMessageToDiagramComponent(clickData)
}

const onStoreStateChanged_G = () => {
    // console.log("onStoreStateChanged_G")
}

const unsubscribe_G = Subscribe(onStoreStateChanged_G)

const getButtonStyleResourcePickerIcons = (allResources) => {
    return (
        <>
            {
                allResources.map((resourceInfo, index) => (
                    <Button variant='outline-light' style={{marginRight: "1px", marginTop: "1px"}} key={index}>
                        <img
                            src={resourceInfo["full_http_path"]}
                            style={{height: '32px', width: '32px'}}
                            className="mt-2"
                            onClick={() => onResourceImageClicked(resourceInfo)}
                        />
                    </Button>
                ))
            }
        </>
    )
}

const getImageStyleResourcePickerIcons = (allResources) => {
    return (
        <>
            {
                allResources.map((resourceInfo, index) => (
                    <img
                        src={resourceInfo["full_http_path"]}
                        key={index}
                        style={{height: '32px', width: '32px'}}
                        className="mt-2"
                        onClick={() => onResourceImageClicked(resourceInfo)}
                    />
                ))
            }
        </>
    )
}


const getGeneralButtonStylePickerIcons = () => {
    const allResources = GetAllGeneralResourceIconsWithInfo()
    return (
        <div style={{marginTop: "10px"}}>
            {
                allResources.map((resourceInfo, index) => (
                    <Button variant='outline-light' style={{marginRight: "1px", marginTop: "1px"}} key={index}>
                        <img
                            src={resourceInfo["full_http_path"]}
                            style={{height: '32px', width: '32px'}}
                            className="mt-2"
                            onClick={() => onResourceImageClicked(resourceInfo)}
                        />
                    </Button>
                ))
            }
        </div>
    )
}

const GetAllResourcePickerIconImages = (resourceIcontype = "image") => {
    let allResources = GetAllAwsResourceData()
    const awsResourceButtonStylePickerIcons = getButtonStyleResourcePickerIcons(allResources)
    const awsResourceImageStylePickerIcons = getImageStyleResourcePickerIcons(allResources)

    const generalButtonStylePickerIcons = getGeneralButtonStylePickerIcons()

    const getAllResourcePickerIcons = () => {
        return (
            <>
                {resourceIcontype === "button" ? awsResourceButtonStylePickerIcons : awsResourceImageStylePickerIcons}
                {generalButtonStylePickerIcons}
            </>
        )
    }

    return getAllResourcePickerIcons()
}

const GetIconsBasePath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH() + "icons" + "/"
}

const GetLinkIconPath = () => {
    return GetIconsBasePath() + "right-arrow1.png"
}

const GetCancelLinkIconPath = () => {
    return GetIconsBasePath() + "cancel.png"
}

const GetLinkOkIconPath = () => {
    return GetIconsBasePath() + "checked.png"
}

const onLinkButtonClickedHandler = () => {
    DispatchAction(actions.LINK_BUTTON_SELECTED_IN_SIDEBAR, {
        "what": actions.LINK_BUTTON_SELECTED_IN_SIDEBAR
    })
}

const onLinkCancelButtonClickedHandler = () => {
    DispatchAction(actions.LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR, {
        "what": actions.LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR,
    })
}

const onLinkOkButtonClickedHandler = () => {

}

const GetNewButton = (iconPath, onClickHandler) => {
    const height = '40px'
    const width = '40px'

    return <>
        <Button variant='outline-light' className={"btn-primary-spacing"}>
            <img
                src={iconPath}
                style={{height: height, width: width}}
                className="mt-2"
                onClick={onClickHandler}
            />
        </Button>
    </>
}

const GetColWrapped = (tmpComponent, screenType, colWidth) => {
    if (screenType === "lg") {
        return <Col lg={colWidth}>
            {tmpComponent}
        </Col>
    } else {
        return {tmpComponent}
    }
}


const GetLinkUnlinkButtons = () => {

    const linkButton = GetNewButton(GetLinkIconPath(), onLinkButtonClickedHandler)
    const linkCancelButton = GetNewButton(GetCancelLinkIconPath(), onLinkCancelButtonClickedHandler)
    const linkOkButton = GetNewButton(GetLinkOkIconPath(), onLinkOkButtonClickedHandler)

    const colWidth = "6"

    return (
        <>
            <Row>
                {GetColWrapped(linkButton, "lg", colWidth)}
                {GetColWrapped(linkCancelButton, "lg", colWidth)}
                {/*{GetColWrapped(linkOkButton, "lg", colWidth)}*/}
            </Row>
        </>
    )
}

export const SendGetHttpRequestAndTerraformScripts = async (httpUrl, resourceData) => {
    console.log("SendGetHttpRequestAndTerraformScripts => " + httpUrl)
    try {

        // console.log(GetNodesListData())

        const resp = await axios.post(httpUrl, resourceData);
        console.log(resp)
        console.log(typeof(resp.status))
        console.log(resp.status)
        //resp.data.msg.verified_user_login_authentication_result

        if(resp.status !== CONCERTO_HTTP_OK) {
            return
        }


        console.log(resp.data.msg.base64_zip_content)
        var FileSaver = require('file-saver');

        let zip_content_base64_str = resp.data.msg.base64_zip_content
        let zip_content_binary_array = new Buffer(zip_content_base64_str, 'base64')
        let fileSaveOptions = {type: "octet/stream"}
        var blob = new Blob([zip_content_binary_array], fileSaveOptions);
        let saveAs = FileSaver.saveAs(blob, "tf_setup_files.zip");
        // console.log(zip_content_binary_array)
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
}

const getObjectAsBase64Stringified = (obj) => {
    let objJsonStr = JSON.stringify(obj)
    let objJsonStrBuf = Buffer(objJsonStr)
    let objJsonStrBase64Str = objJsonStrBuf.toString('base64')
    return objJsonStrBase64Str
}

const SETUP_SCRIPT_TAG = "setup_script_type"
const TERRAFORM_SCRIPT_TYPE = "TERRAFORM_SCRIPT"
const RESOURCE_GRAPH_DATA_TAG = "resource_graph_data"
const USER_LOGIN_TOKEN_TAG  = "user_oath_token"

const createTfScriptReqPayload = (userLoginToken) => {
    let oauth_token  = GetUserLoginToken()

    return {
        [SETUP_SCRIPT_TAG]: TERRAFORM_SCRIPT_TYPE,
        [RESOURCE_GRAPH_DATA_TAG]: getObjectAsBase64Stringified(GetResourcesGraphSnapshotSerialized()),
        [USER_LOGIN_TOKEN_TAG] : oauth_token
    }
}

export const GetSavedNodeContextValuesIfAny = (savedParamKey) => {
    let state = GetState()
    let nodeInfo = getCurrentContextBarNodeInfo()

    if (nodeInfo == null) {
        // console.log("getNodeStateValues if (nodeInfo == null) { ")
        return ""
    }

    if ("node_data" in nodeInfo) {
        let nodeData = nodeInfo["node_data"]

        if (RESOURCE_CONTEXT_INFO_KEY_TAG in nodeData) {
            let resource_context_info = nodeData[RESOURCE_CONTEXT_INFO_KEY_TAG]
            if (savedParamKey in resource_context_info)
                return resource_context_info[savedParamKey]
        }
    }
    return ""
}

export const GetSavedNodesLinkContextValuesIfAny = (savedParamKey) => {
    let state = GetState()
    let nodesLinkInfo = getCurrentContextBarNodeInfo()
    let nodesLinkData = nodesLinkInfo.node_link_data

    if (!(nodesLinkInfo && ("node_link_data" in nodesLinkInfo))) {
        return ""
    }
    nodesLinkData = nodesLinkInfo.node_link_data


    if (!("nodes_link_saved_info" in nodesLinkData)) {
        return ""
    }
    let saved_info = nodesLinkData.nodes_link_saved_info

    if (savedParamKey in saved_info)
        return saved_info[savedParamKey]

    return ""
}

// const getNodeGraphsAsBase64Stringified = () => {
//     let nodesListData = GetNodesListData()
//     let nodesListDataBase64EncodedStr = getObjectAsBase64Stringified(nodesListData)
//     // console.log(nodesListDataBase64EncodedStr)
//     return nodesListDataBase64EncodedStr
// }

const onDownloadTFClicked = () => {
    console.log("onDownloadTFClicked")
    let payload = createTfScriptReqPayload()  // {"resource_data": }
    let promise = SendGetHttpRequestAndTerraformScripts(GetTerraformScriptsURL(), payload)
    console.log(payload)
    console.log(promise)
    console.log("promise ignored in onDownloadTFClicked")
}

const getTerraformDownloadButton = () => {
    console.log("getTerraformDownloadButton")
    return (
        <>
            <Button onClick={() => onDownloadTFClicked()}>
                Download TF Scripts
            </Button>
        </>
    )
}

const GetCmdToolbar = () => {
    return (
        <div className={"toolbar-panel"}>

            {GetLinkUnlinkButtons()}
            <Row> <h6> </h6></Row>

            <Row> {getTerraformDownloadButton()} </Row>
            <Row> <h6> </h6></Row>

            <Row> {getSyncFromCloudCard2()} </Row>

        </div>
    )
}

const getAllIconPalletes = () => {
    return (
        <div>
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <h6> AWS </h6>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="sidebar-pallette" style={{marginLeft: "10px", marginTop: "10px"}}>
                                {GetAllResourcePickerIconImages("button")}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        <h6> Azure </h6>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <h4> Azure placeholder </h4>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                        <h6> GCP </h6>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <h4> GCP placeholder </h4>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}
const getDefaultSidebar = () => {

    return (
        <>
            <div className="sidebar-container">
                <div style={{marginLeft: "10px", marginTop: "10px"}}>
                    {GetAllResourcePickerIconImages("button")}
                </div>

                <div className="toolbar-panel">
                    {GetCmdToolbar()}
                </div>
            </div>
        </>
    );
}

const getAllPalletedSidebar = () => {
    return (
        <>

            <div className="sidebar-container">
                {getAllIconPalletes()}
                <div className="commmon-tools-pallette">
                    {GetCmdToolbar()}
                </div>
            </div>
        </>
    );
}

const Sidebar = () => {
    let showAllPalletes = false

    if (showAllPalletes)
        return getAllPalletedSidebar()

    return getDefaultSidebar()

};

export default Sidebar;

//