import React, { useEffect } from 'react';
import { Form, Button, Row, Col, Select, DatePicker } from 'antd';
import moment from 'moment';
import './ComparisionForm.css';
import { getAllAccountsRequestPayloadV2 } from '../../../AdvancedSearch';
import useAxios from 'axios-hooks';
import { GetAllAccountsURL, GetRegionsForAwsKeysUrl, IsDemoModeHideAccountDetails } from '../../../../routes';
import { GetLoginCredentials } from '../../../CommonFunctions';
import { GetUserLoginCredentials, GetUserLoginToken } from '../../../UserCredentials';

const { Option } = Select;

const ComparisionForm = ({
  handleSubmit,
  originalSnapshotValues,
  setOriginalSnapshotValues,
  comparisionSnapshotValues,
  setComparisionSnapshotValues,
}) => {
  const getPayloadForFetchRegions = async () => {
    let userCreds = GetUserLoginCredentials()[0];
    const { displayName, email, phoneNumber, photoURL, providerId, uid } = userCreds || {};
    let userCredentials = {
      displayName,
      email,
      phoneNumber,
      photoURL,
      providerId,
      uid,
      user_oauth_token: GetUserLoginToken(),
      concerto_user_credentials: GetLoginCredentials(),
      ack_data: {},
    };

    return userCredentials;
  };

  const handleLeftFormChange = (name, value) => {
    setOriginalSnapshotValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRightFormChange = (name, value) => {
    setComparisionSnapshotValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [{ data: accountsData }, fetchAccountsData] = useAxios(
    {
      url: GetAllAccountsURL(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: getAllAccountsRequestPayloadV2(),
    },
    { manual: true }
  );

  const [{ data: regionData }, fetchRegionData] = useAxios(
    {
      url: GetRegionsForAwsKeysUrl(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: getPayloadForFetchRegions(),
    },
    { manual: true }
  );

  useEffect(() => {
    fetchAccountsData();
    fetchRegionData();
  }, []);

  const getAccountsDropdown = () => {
    if (!accountsData) {
        return <Select value={''} onChange={(value) => handleLeftFormChange('accountId', value)}></Select>;
    }
      const accountIDs = accountsData.account_ids;

      const getAwsAccountId = accountIDs.map((number) => {
          const numberString = number.toString();
          const blurredPart =
              IsDemoModeHideAccountDetails() === "true" ? numberString.slice(0, 2) + "*********"  : number ;
          return blurredPart;
      });

      return (
      <div className='accounts-dropdown'>
        <Select
          value={originalSnapshotValues.accountId}
          onChange={(value) => {
            handleLeftFormChange('accountId', value);
            handleRightFormChange('accountId', value);
          }}
        >
          {getAwsAccountId?.map((accountNum) => (
            <Option value={accountNum.toString()}>{accountNum}</Option>
          ))}
        </Select>
      </div>
    );
  };

  const getRegionsDropdown = () => {
    if (!regionData) {
      return <Select value={''} onChange={(value) => handleLeftFormChange('regionId', value)}></Select>;
    }
    const regions = regionData.aws_regions_list;

    return (
      <div className='accounts-dropdown'>
        <Select
          value={originalSnapshotValues.regionId}
          onChange={(value) => {
            handleLeftFormChange('regionId', value);
            handleRightFormChange('regionId', value);
          }}
        >
          {regions?.map(({ RegionCode, RegionName }) => (
            <Option value={RegionCode.toString()}>
              {RegionName} {RegionCode}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <div className='comparision-form'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
        <h2 style={{ marginBottom: '0px' }}>Resource Comparison</h2>
        <Button className=' compare-button search-button btn btn-primary' onClick={handleSubmit}>
          COMPARE
        </Button>
      </div>

      <Row gutter={10}>
        <Col span={12}>
          <div
            style={{
              border: '1px solid #EEF6FF',
              borderRadius: '8px',
              padding: '15px',
            }}
          >
            <Form>
              <Form.Item label='Select Account'>{getAccountsDropdown()}</Form.Item>

              <Form.Item label='Select Region'>{getRegionsDropdown()}</Form.Item>

              <Form.Item label='Select Date'>
                <DatePicker
                  value={originalSnapshotValues.resourceSnapShotDate ? moment(originalSnapshotValues.resourceSnapShotDate) : null}
                  onChange={(value) => handleLeftFormChange('resourceSnapShotDate', value ? value.format('YYYY-MM-DD') : null)}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              {/* <Form.Item label='Resource Category'>
                <Select value={originalSnapshotValues.category} onChange={(value) => handleLeftFormChange('category', value)}>
                  <Option value='Category 1'>Category 1</Option>
                  <Option value='Category 2'>Category 2</Option>
                </Select>
              </Form.Item> */}
            </Form>
          </div>
        </Col>

        <Col span={12}>
          <div
            style={{
              border: '1px solid #EEF6FF',
              borderRadius: '8px',
              padding: '15px',
            }}
          >
            <Form>
              <Form.Item label='Account' style={{ visibility: 'hidden' }}>
                <Select value={originalSnapshotValues.accountId} disabled onChange={(value) => handleRightFormChange('accountId', value)}>
                  <Option value='Account 1'>Account 1</Option>
                  <Option value='Account 2'>Account 2</Option>
                </Select>
              </Form.Item>

              <Form.Item label='Region' style={{ visibility: 'hidden' }}>
                <Select value={originalSnapshotValues.region} disabled onChange={(value) => handleRightFormChange('region', value)}>
                  <Option value='Region 1'>Region 1</Option>
                  <Option value='Region 2'>Region 2</Option>
                </Select>
              </Form.Item>

              <Form.Item label='Select Date'>
                <DatePicker
                  value={comparisionSnapshotValues.resourceSnapShotDate ? moment(comparisionSnapshotValues.resourceSnapShotDate) : null}
                  onChange={(value) => handleRightFormChange('resourceSnapShotDate', value ? value.format('YYYY-MM-DD') : null)}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              {/* <Form.Item label='Resource Category'>
                <Select value={comparisionSnapshotValues.category} onChange={(value) => handleRightFormChange('category', value)}>
                  <Option value='Category 1'>Category 1</Option>
                  <Option value='Category 2'>Category 2</Option>
                </Select>
              </Form.Item> */}
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ComparisionForm;
