import React from 'react';
import './Accordion.css';
import Arrow from './Arrow.png';

const Accordion = ({ data = [], location, expandedItems, setExpandedItems, selectedTitle }) => {
  const getLabelStyle = ({ location, isAdded, isModified, isDeleted }) => ({
    background: location === 'left' ? null : isAdded ? '#53A258' : isModified ? '#810384' : isDeleted ? 'red' : '',
    borderRadius: '3px',
    letterSpacing: '0.25px',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '10px',
    padding: '1.1px',
  });

  const getTextStyle = ({ location, isDeleted, isAdded }) => ({
    textDecoration: (isDeleted && location === 'right') || (isAdded && location === 'left') ? 'line-through' : 'none',
  });

  const toggleAccordion = (title) => {
    setExpandedItems((prevState) => {
      const isExpanded = prevState.includes(title);
      const updatedExpanded = isExpanded ? prevState.filter((item) => item !== title) : [...prevState, title];
      return updatedExpanded;
    });
  };

  return (
    <>
      {data?.map((eachData, idx) => {
        let { title, icon, content, isAdded, isModified, isDeleted, isLastChild } = eachData;
        const shouldShowArrow = !isDeleted && content && Array.isArray(content) && !isLastChild;
        const isItemExpanded = expandedItems.includes(title);
        console.log({ selectedTitle, title });
        return (
          <div
            key={title}
            className='diff-accordion-accordion'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              eachData?.onItemClick?.();
            }}
          >
            <div
              className='diff-accordion-accordion-header'
              style={
                selectedTitle === title && !shouldShowArrow
                  ? { background: '#E7F7F8', border: '2px solid #5B9BD6', borderRadius: '8px' }
                  : {}
              }
              onClick={shouldShowArrow ? () => toggleAccordion(title) : () => {}}
            >
              <div className='diff-accordion-accordion-icon'>
                <img src={icon} alt='' />
              </div>
              <div className='diff-accordion-accordion-title'>
                <span style={getTextStyle({ location, isAdded, isDeleted })}>{title}</span> &nbsp;&nbsp;&nbsp;
                {(isAdded || isModified || (isDeleted && location === 'right')) && (
                  <span className='label' style={getLabelStyle({ location, isAdded, isDeleted, isModified })}>
                    {isAdded ? 'Add' : isModified ? 'Mod' : 'Del'}
                  </span>
                )}
              </div>
              {shouldShowArrow && (
                <div className={`diff-accordion-accordion-arrow ${isItemExpanded ? 'expanded' : ''}`}>
                  <img src={Arrow} alt='' />
                </div>
              )}
            </div>
            {isItemExpanded && content && Array.isArray(content) && (
              <div className='diff-accordion-accordion-content'>
                <Accordion
                  data={content}
                  selectedTitle={selectedTitle}
                  location={location}
                  expandedItems={expandedItems}
                  setExpandedItems={setExpandedItems}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Accordion;
