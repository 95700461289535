import React, { useRef, useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import Logo from '../landingPage/assets/LogoWithText.svg';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GetCognitoResetConfirmCode } from '../routes';
import ReCAPTCHA from 'react-google-recaptcha';
import classes from '../styles/Login.module.css';
import Eye from '../landingPage/assets/login/passwordEye.svg';
import EyeHide from '../landingPage/assets/login/passwordEyeHide.svg';
import { PATHS } from '../landingPage/components/Navbar/Navbar';

function ForgotPassword() {
  const emailRef = useRef();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const recaptchaRef = useRef();

  async function handleResetPassword(e) {
    e.preventDefault();
    setDisabled(true);
    recaptchaRef.current.reset();
    let captchaToken = await recaptchaRef.current.executeAsync();

    // try {
    //   await axios.post(GetCaptchaVerificationUrl(), { captchaToken });
    // } catch (error) {
    //   return toast.error('Captcha Verification Failed!');
    // }

    let url = GetCognitoResetConfirmCode();
    axios
      .post(url, { cognito_userpool_user_email_id: emailRef.current.value })
      .then(() => {
        setDisabled(false);
        toast.success('Please check your email to reset password!');
        history.push('/cguplogin');
      })
      .catch((err) => {
        setDisabled(false);
        toast.error(err.message);
      });
  }

  return (
    <>
      <div className={classes.LoginContainer}>
        <div className={classes.leftContainer}>
          <img className={classes.LoginLogo} src={Logo} alt='' />
          <div className={`${classes.formContainer} ${classes.toggleIn}`}>
            <div className={classes.form}>
              <div className={classes.title}>Reset Password</div>
              <div className={classes.Inputs}>
                <div>
                  <label htmlFor='email'>Work Email</label>
                  <div className={classes.Input}>
                    <input className={classes.inputBox} ref={emailRef} type='email' name='email' id='email' placeholder='Email' />
                  </div>
                </div>
                <div className={classes.helperLine}>
                  Remember the password?
                  <span className={classes.hint} onClick={() => history.push(PATHS.LOGIN)}>
                    Sign In Here
                  </span>
                </div>
                <div className='new-btn-secondary' onClick={handleResetPassword} style={{ height: '3vw', fontSize: '1.1vw' }}>
                  Reset Password
                </div>
                <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
