import React, { useEffect } from 'react';
import aws_logo from '../../images/Amazon_Web_Services_Logo.svg';
import { getTooltipInfo, RoundUp } from '../CommonFunctions';
import AdvancedSearch from '../AdvancedSearch';
import _ from 'lodash';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { LAST_MONTH_END_DATE, LAST_MONTH_START_DATE } from '../../dateUtil';

const TEXT_INFO_TOTAL_COST_THIS_WEEK = 'Total cost occured this week';
const TEXT_INFO_TOTAL_COST_THIS_MONTH = 'Total cost occured this month';
const TEXT_INFO_TOTAL_COST_LAST_WEEK = 'Total cost occured last week';
const TEXT_INFO_TOTAL_COST_LAST_CYCLE = 'Total cost occured last cycle';
const TEXT_INFO_TOTAL_COST_PREDICTION = 'Total cost prediction for next cycle';

const Account = (props) => {
  const data = props.data;
  const setAccounts = props.onSetAccounts;
  const selectedAccountIndex = props.selectedAccountIndex;

  const roundUp = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  const getAccounts = () => {
    let accounts = [];
    const accounts_data = props.data?.account_wise_cost;
    for (let i = 0; i < accounts_data.length; i++) {
      let tmp = [];
      tmp['name'] = accounts_data[i].friendly_name;
      accounts.push(tmp);
    }
    setAccounts(accounts);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getCloudServiceLogo = (accountType) => {
    let imgSrc = aws_logo;
    /*        if(accountType === "aws" || accountType === "") {
                    imgSrc = aws_logo
                }*/
    return <img alt='' height='50' src={imgSrc} />;
  };

  const getResourceTextByCycle = (resource, resourceWiseCostByCycle) => {
    if (resourceWiseCostByCycle && resourceWiseCostByCycle[resource] !== undefined) {
      return '$' + RoundUp(resourceWiseCostByCycle[resource]);
    }
    return 'N/A';
  };

  const getCurrBillingCycle = () => {
    const accountData = data?.account_wise_cost[selectedAccountIndex];

    const { last_week_details, this_week_total_details, this_month_total_details } = accountData['current_billing_details'];
    const resourceWiseLastWeek = last_week_details.ResourceWiseBreakUp;
    const resourceWiseThisWeek = this_week_total_details.ResourceWiseBreakUp;
    const resourceWiseThisMonth = this_month_total_details.ResourceWiseBreakUp;

    let resourceKeys = [];
    resourceKeys = resourceWiseThisWeek ? Object.keys(resourceWiseThisWeek) : [];
    resourceKeys = resourceWiseLastWeek ? resourceKeys.concat(Object.keys(resourceWiseLastWeek)) : resourceKeys;
    resourceKeys = resourceWiseThisMonth ? resourceKeys.concat(Object.keys(resourceWiseThisMonth)) : resourceKeys;

    resourceKeys = _.uniq(resourceKeys);

    return (
      <table className='costTable'>
        <thead>
          <tr>
            <th>
              {' '}
              {getCloudServiceLogo()} {accountData.friendly_name} : {accountData.account_name}
            </th>
            <th>Last week cost {getTooltipInfo(`${last_week_details?.StartDate} to ${last_week_details?.EndDate}`)}</th>
            <th>Total cost this week {getTooltipInfo(`${this_week_total_details?.StartDate} to ${this_week_total_details?.EndDate}`)}</th>
            <th>
              Total cost this month {getTooltipInfo(`${this_month_total_details?.StartDate} to ${this_month_total_details?.EndDate}`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {resourceKeys.map((resource) => {
            return (
              <tr key={'curr_cost_table_' + selectedAccountIndex + '_' + resource}>
                <td>{resource}</td>
                <td>{getResourceTextByCycle(resource, resourceWiseLastWeek)}</td>
                <td>{getResourceTextByCycle(resource, resourceWiseThisWeek)}</td>
                <td>{getResourceTextByCycle(resource, resourceWiseThisMonth)}</td>
              </tr>
            );
          })}
          <tr>
            <td>Tax</td>
            <td>$ {roundUp(accountData['current_billing_details'].last_week_details.Tax)}</td>
            <td>$ {roundUp(accountData['current_billing_details'].this_week_total_details.Tax)}</td>
            <td>$ {roundUp(accountData['current_billing_details'].this_month_total_details.Tax)}</td>
          </tr>
          <tr className='sum-row'>
            <td>Total Cost</td>
            <td>$ {roundUp(accountData['current_billing_details'].last_week_details.Total)}</td>
            <td>$ {roundUp(accountData['current_billing_details'].this_week_total_details.Total)}</td>
            <td>$ {roundUp(accountData['current_billing_details'].this_month_total_details.Total)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getLastBillingCycle = () => {
    const accountData = data?.account_wise_cost[selectedAccountIndex];
    const resourceWiseLastMonth = accountData.previous_billing_details.last_month_total_details['ResourceWiseBreakUp'];

    return (
      <table className='costTable'>
        <thead>
          <tr>
            <th> </th>
            <th>Total cost last month {getTooltipInfo(`${LAST_MONTH_START_DATE} to ${LAST_MONTH_END_DATE}`)}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(resourceWiseLastMonth).map((resource) => {
            return (
              <tr key={'last_' + selectedAccountIndex + '_' + resource}>
                <td>{resource}</td>
                <td>$ {roundUp(resourceWiseLastMonth[resource])}</td>
              </tr>
            );
          })}
          <tr>
            <td>Tax</td>
            <td>$ {roundUp(accountData.previous_billing_details.last_month_total_details.Tax)}</td>
          </tr>
          <tr className='sum-row'>
            <td>Total Cost</td>
            <td>$ {roundUp(accountData.previous_billing_details.last_month_total_details.Total)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getCostPredictions = () => {
    const accountData = data?.account_wise_cost[selectedAccountIndex];
    const forecast = accountData.forecasted_billing_details.current_month_forecast_details;
    const resourceWisePrediction = forecast['ResourceWiseBreakUp'];
    return (
      <table className='costTable'>
        <thead>
          <tr>
            <th> </th>
            <th>Total cost last month {getTooltipInfo(`${forecast.StartDate} to ${forecast.EndDate}`)}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(resourceWisePrediction).map((resource, monthCost) => {
            return (
              <tr key={'prediction_' + selectedAccountIndex + '_' + resource}>
                <td>{resource}</td>
                <td>$ {roundUp(resourceWisePrediction[resource])}</td>
              </tr>
            );
          })}
          <tr>
            <td>Tax</td>
            <td>$ {roundUp(accountData.forecasted_billing_details.current_month_forecast_details.Tax)}</td>
          </tr>
          <tr className='sum-row'>
            <td>Total Cost</td>
            <td>$ {roundUp(accountData.forecasted_billing_details.current_month_forecast_details.Total)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getRender = () => {
    if (props.isAdvancedSearchSelected) {
      return <AdvancedSearch />;
    }
    return (
      <>
        <Collapse defaultActiveKey={['1']}>
          <CollapsePanel key='1' header='Current Billing Cycle'>
            {getCurrBillingCycle()}
          </CollapsePanel>
          <CollapsePanel key='2' header='Last Billing Cycle'>
            {getLastBillingCycle()}
          </CollapsePanel>
          <CollapsePanel key='3' header='Cost Predictor'>
            {getCostPredictions()}
          </CollapsePanel>
        </Collapse>
      </>
    );
  };

  return getRender();
};

export default Account;
