export const USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW = 'USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW';
export const USER_LOGOUT = 'USER_LOGOUT';

export const RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS = 'RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS';
export const RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS = 'RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS';
export const CONTEXT_BAR_CLOSE_BUTTON_CLICKED = 'CONTEXT_BAR_CLOSE_BUTTON_CLICKED';

// Sidebar actions
export const LINK_BUTTON_SELECTED_IN_SIDEBAR = 'LINK_BUTTON_SELECTED_IN_SIDEBAR';
export const LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR = 'LINK_ACTION_CANCEL_BUTTON_SELECTED_IN_SIDEBAR';
export const RESOURCE_CONTEXT_INFO_SAVED_BY_USER = 'RESOURCE_CONTEXT_INFO_SAVED_BY_USER';
export const RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER = 'RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER';
//
