import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftMenu from "../LeftMenu";
import { GetMenuBar } from "../MenubarComponent";

function AwsAuthentication() {
  return (
    <>
      { GetMenuBar() }
      <Container className="small  mt-5">
        <Row>
          <Col xs lg="3">
            <LeftMenu />
          </Col>
          <Col xs lg="9">AwsAuthentication</Col>
        </Row>
      </Container>
    </>
  );
}

export default AwsAuthentication;
