import styled from "styled-components";
import { Dropdown, DropdownButton } from "react-bootstrap";


export const ToolbarBtn = styled.button`
	margin-top:22px;
    border-radius: 25px;
`
export const SidebarItem = styled.h5`
    margin-left: 20px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202020;
    cursor: pointer;
`

export const DiagramDropdown = styled(DropdownButton)`
    margin-left: 20px;
    border-radius: 40px;
`

export const DiagramDropdownItem = styled(Dropdown.Item)`
    
`