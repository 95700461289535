import {
    getCurrentActiveContextBarNodeInfo
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";

export const SubnetContextShowInfoBarHandler = () => {

    let resourceProperties = [
        "AssignIpv6AddressOnCreation",
        "AvailabilityZone",
        "AvailabilityZoneId",
        "AvailableIpAddressCount",
        "CidrBlock",
        "CustomerOwnedIpv4Pool",
        "DefaultForAz",
        "EnableDns64",
        "EnableLniAtDeviceIndex",
        "EnableResourceNameDnsAAAARecord",
        "EnableResourceNameDnsARecord",
        "HostnameType",
        "Ipv6Native",
        "MapCustomerOwnedIpOnLaunch",
        "MapPublicIpOnLaunch",
        "OutpostArn",
        "OwnerId",
        "SubnetArn",
        "SubnetId",
        "VpcId",
        "SubnetState",
    ]

    const displayFields = [  ]
    const title = "Subnet"

    for (let i=0 ; i<resourceProperties.length; i++) {
        let labelStr = resourceProperties[i]
        let keyStr = resourceProperties[i]
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr))
    }

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}
