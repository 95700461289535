import React, { useRef, useState } from 'react';
import { Form, Button,  Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import Logo from '../images/logo.png';
import { Link, useHistory } from 'react-router-dom';

import  { PrintFirebaseAppObj } from './FirebaseUtils';

import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { cloneDeep } from 'lodash';
function Signup() {
  // async function handleSubmit(e) {
  //     console.log("target",e);
  // }
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    password: '',
    confirmPassword: '',
  });
  //const { signup } = null // useAuth()
  const [error, setError] = useState('');
  // const [loading, setLoading] = useState(false)
  const history = useHistory();

  // const signup = (email, password) => {
  //     return firebaseUserAuth.createUserWithEmailAndPassword(email, password)
  // }

  const handleFormChange = (e) => {
    let form = cloneDeep(formData);
    form[e.target.name] = e.target.value;
    setFormData(form);
  };

  const disableSubmit = () => {
    return formData.password !== formData.confirmPassword;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    PrintFirebaseAppObj();
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(userCredential);
        history.push('/');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.code);
        console.log(error.message);
      });

    /*
                if (passwordRef.current.value !== passwordConfirmRef.current.value) {
                    return setError("Passwords do not match")
                }

                try {

                    setError("")
                    setLoading(true)
                    await signup(emailRef.current.value, passwordRef.current.value)
                    history.push("/")

                } catch {
                    setError("Failed to create an account")
                }
        */
    // setLoading(false)
  }

  return (
    <>
      <Container className='sign-container login-page text-center'>
        <div className='title-section d-flex' style={{ cursor: 'pointer' }}>
          <span style={{ display: 'flex', cursor: 'pointer' }} onClick={() => history.push('/home')}>
            <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
            <h4 className='text-center mb-4 micro-title'>WELCOME TO BLUEFINWARE</h4>
          </span>
        </div>
        <h2 className='text-center mb-4 title'>Create your free account</h2>
        {/* {error && <Alert variant="danger">{error}</Alert>} */}
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Control type='text' placeholder='Name' name='name' onChange={handleFormChange} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Control type='email' placeholder='Work Email' name='email' ref={emailRef} onChange={handleFormChange} />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Control type='text' placeholder='Company' name='company' onChange={handleFormChange} />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Control type='password' placeholder='password' name='password' ref={passwordRef} onChange={handleFormChange} />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Control
              type='password'
              placeholder='password confirm'
              name='confirmPassword'
              ref={passwordConfirmRef}
              onChange={handleFormChange}
              isInvalid={formData.password !== formData.confirmPassword}
            />
            <Form.Control.Feedback type='invalid'>Password did not match</Form.Control.Feedback>
          </Form.Group>

          <div className='sign-button d-flex justify-content-end'>
            <Button disabled={disableSubmit()} className='w-100' type='submit'>
              SIGN UP
            </Button>
          </div>
        </Form>
        {/* <div className="w-100 text-center mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
            </div> */}
        <div className='w-100 text-center sign-link'>
          Already have an account? <Link to='/cguplogin'>Sign In</Link>
        </div>
      </Container>
      {/* <Footer></Footer> */}
    </>
  );
}

export default Signup;
