import React from 'react';
import Expandable from '../Expandable/Expandable';
import classes from './FAQ.module.css';

const FAQ = ({ faqItems }) => {
  return (
    <div className={classes.FaqContainer}>
      <div className={classes.FaqTitle}>Frequently Asked Questions</div>
      <Expandable items={faqItems} />
    </div>
  );
};

export default FAQ;
