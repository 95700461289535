import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import LeftMenu from '../LeftMenu';
import { GetMenuBar } from '../MenubarComponent';
import { GetUserLoginCredentials, GetUserLoginToken } from '../UserCredentials';
import { GetMyProfileUrl } from '../../routes';
import axios from 'axios';
import { CountryDropdown } from 'react-country-region-selector';
import { Alert } from 'react-bootstrap';

const UPDATE_UNSUBMITED = 'unsubmitted';
const UPDATE_SUCCESSFUL = 'successful';
const UPDATE_FAILED = 'update-failed';
const UPDATE_HTTP_ERROR = 'http-error';

// let countryVar = "India";
function MyProfile() {
  let nameRef = createRef();
  let companyRef = createRef();
  let streetRef = createRef();
  let cityRef = createRef();
  let stateRef = createRef();
  let pincodeRef = createRef();

  let countryRef = createRef('India');

  const [countryValue, setCountryValue] = React.useState(null);

  const [profileDetails, setProfileDetails] = useState({
    endpoint: 'handle_myprofile',
    data_type: 'myprofile',
    data_op: 'update',
    data: {
      person_details: {
        full_name: 'Wiley Coyote',
      },
      company_details: {
        name: 'LongTail Inc',
        street: 'guitar street',
        city: 'Tokyo',
        state: 'Kanto',
        postal_code: '1010062',
        country: 'India',
      },
    },
  });
  const [formSubmitStatus, setFormSubmitStatus] = useState('UPDATE_UNSUBMITED');

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    return {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };
  };

  const getProfileReadPayloadTemplate = () => {
    return {
      endpoint: 'handle_myprofile',
      data_type: 'myprofile',
      data_op: 'read',
      data: {
        person_details: {
          full_name: 'Bugs Bunny',
        },
        company_details: {
          name: 'LongTail Inc',
          street: 'guitar street',
          city: 'Tokyo',
          state: 'Kanto',
          postal_code: '1010062',
          country: 'Japan',
        },
      },
    };
  };

  const getProfilePostPayload2 = () => {
    return {
      endpoint: 'handle_myprofile',
      data_type: 'myprofile',
      data_op: 'update',
      data: {
        person_details: {
          full_name: 'Bugs Bunny',
        },
        company_details: {
          name: 'LongTail Inc',
          street: 'guitar street',
          city: 'Tokyo',
          state: 'Kanto',
          postal_code: '1010062',
          country: 'Japan',
        },
      },
    };
  };

  const getProfilePostPayload = () => {
    const country = countryValue;

    console.log('getpayload ', country);
    return {
      endpoint: 'handle_myprofile',
      data_type: 'myprofile',
      data_op: 'update',
      data: {
        person_details: {
          full_name: nameRef.current.value,
        },
        company_details: {
          name: companyRef.current.value,
          street: streetRef.current.value,
          city: cityRef.current.value,
          state: stateRef.current.value,
          postal_code: pincodeRef.current.value,
          country: country,
        },
      },
    };
  };

  const getProfileReadPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      user_profile_data: getProfileReadPayloadTemplate(),
    };
  };

  const onProfileSubmitRequest = async () => {
    const url = GetMyProfileUrl();

    let payload = {
      concerto_user_credentials: GetLoginCredentials(),
      user_profile_data: getProfilePostPayload(),
    };

    try {
      const resp = await axios.post(url, payload);
      if (resp && resp.data && resp.data.profile_update_status && resp.data.profile_update_status === 'success') {
        setFormSubmitStatus(UPDATE_SUCCESSFUL);
      } else {
        setFormSubmitStatus(UPDATE_FAILED);
      }
      return resp;
    } catch (err) {
      setFormSubmitStatus(UPDATE_HTTP_ERROR);
      console.log('onProfileSubmitRequest to ' + url + ' failed');
    }
  };

  const getProfileDetails = async () => {
    const url = GetMyProfileUrl();

    console.log('################### getProfileDetails >>>>>> ');
    console.log(url);

    try {
      const resp = await axios.post(url, getProfileReadPayload());
      console.log(resp);
      console.log(resp.data.FullName);
      nameRef.current.value = resp.data.FullName;
      companyRef.current.value = resp.data.CompanyName;
      streetRef.current.value = resp.data.CompanyStreet;
      cityRef.current.value = resp.data.CompanyCity;
      stateRef.current.value = resp.data.CompanyState;
      pincodeRef.current.value = resp.data.PostalCode;

      return resp;
    } catch (err) {
      console.log('getProfileDetails to ' + url + ' failed');
    }
  };

  const onInputChangedHandler = (event) => {
    // const targetRef = event.target.attributes.getNamedItem("ref").value;

    console.log('nameRef.current.value', nameRef.current.value);

    // console.log("nameRef.current.value", event.target.attributes.getNamedItem("ref").value)
  };

  const onDropDownSelected = (event) => {
    countryRef = event;
    console.log('event', event);
  };

  const onSubmitClicked = () => {
    console.log('onSubmitClicked');
    let resp = onProfileSubmitRequest();
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getAlert = () => {
    switch (formSubmitStatus) {
      case UPDATE_UNSUBMITED:
        return <></>;
      case UPDATE_SUCCESSFUL:
        return <Alert variant='success'>Profile has been submitted successfully.</Alert>;
      case UPDATE_FAILED:
        return <Alert variant='danger'>Error updating profile.</Alert>;
      case UPDATE_HTTP_ERROR:
        return <Alert variant='danger'>Network Call Failed.</Alert>;
      default:
        return <></>;
    }
  };

  return (
    <>
      {GetMenuBar('user_logged_in')}

      <Container className='small mt-5 my-profile-page'>
        <Row>
          <Col xs lg='3'>
            <LeftMenu />
          </Col>
          <Col xs lg='9'>
            <h3>Update Details</h3>
            {getAlert()}
            <br />
            <Form.Group className='profile-input-field'>
              <Form.Label>Name</Form.Label>
              <Form.Control type='text' ref={nameRef} placeholder='Name' defaultValue='' onChange={onInputChangedHandler} />
            </Form.Group>

            <Form.Group className='profile-input-field'>
              <Form.Label>Company</Form.Label>
              <Form.Control
                type='text'
                ref={companyRef}
                placeholder='Company'
                defaultValue=''
                //  onChange={onInputChangedHandler}
              />
            </Form.Group>

            <br />
            <Form.Group className='profile-input-field'>
              <Form.Label>Street</Form.Label>
              <Form.Control
                type='text'
                ref={streetRef}
                placeholder='Street'
                defaultValue=''
                //  onChange={onInputChangedHandler}
              />
            </Form.Group>

            <Form.Group className='profile-input-field'>
              <Form.Label>City</Form.Label>
              <Form.Control
                type='text'
                ref={cityRef}
                placeholder='City'
                defaultValue=''
                //  onChange={onInputChangedHandler}
              />
            </Form.Group>

            <Form.Group className='profile-input-field'>
              <Form.Label>State</Form.Label>
              <Form.Control
                type='text'
                ref={stateRef}
                placeholder='State'
                defaultValue=''
                //  onChange={onInputChangedHandler}
              />
            </Form.Group>

            <Form.Group className='profile-input-field'>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control type='text' ref={pincodeRef} placeholder='pincode' defaultValue='' onChange={onInputChangedHandler} />
            </Form.Group>

            <br />
            <br />

            <Form.Group className='profile-input-field'>
              <Form.Label>Country</Form.Label>
              <br />
              <CountryDropdown
                value={countryValue || ''}
                onChange={setCountryValue}
                // className="profile-input-field"
              />
            </Form.Group>
            <br />
            <br />
            <Button
              variant='primary'
              className='custom-button filled-button profile-input-field'
              // type="submit"
              onClick={() => onSubmitClicked()}
            >
              Update Profile
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyProfile;
