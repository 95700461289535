import { DatePicker } from 'antd';
import React, { useState } from 'react';
import Accordion from '../../Accordion/Accordion';
import Table from '../../Table/Table';
import moment from 'moment';
import DownArrowIcon from './downArr.svg';
import UpArrowIcon from './upArr.svg';

const DiffToolWithTable = ({
  diffData = [],

  originalSnapshotValues,
  setOriginalSnapshotValues,
  comparisionSnapshotValues,
  setComparisionSnapshotValues,
  selectedTitle,
  setSelectedTitle,
  displayedTitles,
  handleSubmit,
}) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const handleNextClick = () => {
    setSelectedTitle((prevItem) => {
      const currentIndex = displayedTitles.findIndex((element) => element.title === prevItem.title);
      const newIndex = currentIndex !== -1 ? currentIndex + 1 : 0;
      return displayedTitles[newIndex % displayedTitles.length];
    });
  };

  const handlePreviousClick = () => {
    setSelectedTitle((prevItem) => {
      const currentIndex = displayedTitles.findIndex((element) => element.title === prevItem.title);
      const newIndex = currentIndex !== -1 ? currentIndex - 1 : displayedTitles.length - 1;
      return displayedTitles[(newIndex + displayedTitles.length) % displayedTitles.length];
    });
  };

  const activeNodeDetails = selectedTitle?.details;
  console.log({ activeNodeDetails });
  return (
    <div className='diff'>
      <div className='topcontainer'>
        <div>
          <div style={{ visibility: 'hidden', marginBottom: '0.51vw' }}>d </div>
          <div className='right-top'>
            <div>
              <div></div>
              <DatePicker
                value={originalSnapshotValues.resourceSnapShotDate ? moment(originalSnapshotValues.resourceSnapShotDate) : null}
                onChange={(value) =>
                  setOriginalSnapshotValues((prevValues) => ({
                    ...prevValues,
                    resourceSnapShotDate: value ? value.format('YYYY-MM-DD') : null,
                  }))
                }
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div>
            <div className='accordion-container'>
              <Accordion
                selectedTitle={selectedTitle?.title}
                data={diffData}
                location='left'
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='diff-legends-container'>
            <div>
              <label class='diff-checkbox-container'>
                <input type='checkbox' checked />
                <span class='checkmark'></span>
              </label>
              Added
              <span className='active-mark'></span>
            </div>
            <div>
              <label class='diff-checkbox-container'>
                <input type='checkbox' checked />
                <span class='checkmark'></span>
              </label>
              Modified
              <span className='modified-mark'></span>
            </div>
            <div>
              <label class='diff-checkbox-container'>
                <input type='checkbox' checked />
                <span class='checkmark'></span>
              </label>
              Removed
              <span className='deleted-mark'></span>
            </div>
          </div>
          <div className='right-top'>
            <div>
              <DatePicker
                value={comparisionSnapshotValues.resourceSnapShotDate ? moment(comparisionSnapshotValues.resourceSnapShotDate) : null}
                onChange={(value) =>
                  setComparisionSnapshotValues((prevValues) => ({
                    ...prevValues,
                    resourceSnapShotDate: value ? value.format('YYYY-MM-DD') : null,
                  }))
                }
                style={{ width: '100%' }}
              />
            </div>
            <div className='buttons-container'>
              <div onClick={handleSubmit}>Refresh</div>
              <div onClick={handlePreviousClick}>
                Prev
                <img src={UpArrowIcon} alt='' />
              </div>
              <div onClick={handleNextClick}>
                Next
                <img src={DownArrowIcon} alt='' />
              </div>
            </div>
          </div>
          <div className='accordion-container'>
            <Accordion
              data={diffData}
              selectedTitle={selectedTitle?.title}
              location='right'
              expandedItems={expandedItems}
              setExpandedItems={setExpandedItems}
            />
          </div>
        </div>
      </div>
      {activeNodeDetails && activeNodeDetails?.NodeType && !activeNodeDetails?.NodeType?.includes('Virtual') ? (
        <div className='footer'>
          <div className='footer-head'>
            <div className='footer-icon'>
              <img src={activeNodeDetails?.IconPath} alt='' />
            </div>
            <div>
              <div>{activeNodeDetails?.NodeType}</div>
              <div>{activeNodeDetails?.NodeDisplayName}</div>
            </div>
          </div>
          <div className='footer-content'>
            <Table activeNodeDetails={activeNodeDetails} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DiffToolWithTable;
