
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";

import {Table, Card} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";
import { GetAssetInfoUrl } from '../../routes';
import { GetLoginCredentialsAsJsonObject } from "../UserCredentials";
import axios from "axios";
import { GetGlobalResourceInfoCache } from "../NestedAccordion/ResourceBrowser";


export const ApiGatewayContextShowInfoBarHandler = () => {

    /*
        "RestApiName",
        "RestApiId",
        "Description",
        "CreatedDate",
        "DisableExecuteApiEndpoint"
    ]
     */
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;
    let stagesInfoList = nodeContextInfo.StagesInfoList
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;


    let stagesTableEntries = []
    stagesInfoList.map((stageInfo) => {

        let deploymentIDs = []

        stageInfo.DeploymentList && stageInfo.DeploymentList.map((deployment) => {
            deploymentIDs.push(deployment.DeploymentId)
        })


        stagesTableEntries.push(
            <tr key={uniqid()}>
                <td>{stageInfo.StageName}</td>
                <td>{deploymentIDs.join(", ")}</td>
            </tr>
        )

    })

    const title = "ApiGateway"
    const displayFields = [
        GetSimpleKeyPairValueElement("RestApiName", "RestApiName"),
        GetSimpleKeyPairValueElement("PathPart", "PathPart"),
        GetSimpleKeyPairValueElement("RestApiId", "RestApiId"),
        GetSimpleKeyPairValueElement("Description", "Description"),
        GetSimpleKeyPairValueElement("CreatedDate", "CreatedDate"),
        GetSimpleKeyPairValueElement("DisableExecuteApiEndpoint", "DisableExecuteApiEndpoint"),
        GetSimpleKeyPairValueElement("GatewayType", "GatewayType"),

        {"label": "Stages", "key":"Stages", "element_type": "auto_json_value"},

    ]

    let resourcePropertyBody = [];

    
    for (let i = 0; i < displayFields.length; i++) {
      let elementType = displayFields[i].element_type;

      let propertyRow = (
        <div className='node-property row'>
          <p className='node-property-name col-5'>{displayFields[i].label} :</p>
          <p className='node-property-value col-7'>
            <b>{nodeContextInfo[displayFields[i].key]}</b>
          </p>
        </div>
      );
      resourcePropertyBody.push(propertyRow);
    }

      return (
        <div>
            <div className='context-bar-head'>
            <div className='flex'>
                <img alt='' src={imgPath} width='48' height='48' />
            </div>
            <div>
                <h4 className='context-bar-title'>{title}</h4>
                <div className='context-bar-subtitle'>{nodeContextInfo?.InstanceId}</div>
            </div>
            </div>

            <hr />

            <br />
            <div className='node-properties'>
            <h6 className='prop-text'>PROPERTIES</h6>
            <div className='container'>{resourcePropertyBody}</div>
            </div>
            </div>
      );

}



export const ApiGatewayContextAssetHandler = (
    EditableForm,
) => {
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;
    let stagesInfoList = nodeContextInfo.StagesInfoList

    let stagesTableEntries = []
    stagesInfoList.map((stageInfo) => {

        let deploymentIDs = []

        stageInfo.DeploymentList && stageInfo.DeploymentList.map((deployment) => {
            deploymentIDs.push(deployment.DeploymentId)
        })

        stagesTableEntries.push(
            <tr key={uniqid()}>
                <td>{stageInfo.StageName}</td>
                <td>{deploymentIDs.join(", ")}</td>
            </tr>
        )

    })

    const getAssetsInfo = () => {
        let rsStr = JSON.stringify(getAssetsInfoCommandArgs());
        let userCredentials = GetLoginCredentialsAsJsonObject();
        const url = GetAssetInfoUrl();
        const payload = getAssetsInfoPayload(userCredentials, ['get_api_gateway_asset_info'], rsStr);
        let result = axios.post(url, payload);
        return result;
    };
    

    const getAssetsInfoPayload = (userCredentials, commandToExecute, commmandArgs) => {
        return {
          concerto_user_credentials: userCredentials,
          command_to_execute: commandToExecute,
          command_args: commmandArgs,
        };
    };

    const getAssetsInfoCommandArgs = () => {
        const resourceCache = GetGlobalResourceInfoCache();
        const awsRegion = 'ap-south-1'
        const concertoUserId = resourceCache.connection_diagram_info[0].network_connection_info.concerto_user_id
        const accountId = resourceCache.connection_diagram_info[0].network_connection_info.aws_account_id
        return {
            accountId: accountId,
            concertoUserId: concertoUserId,
            apiId: nodeContextInfo.RestApiId,
            region: awsRegion,
        };
    }



    const onSave = (formValues) => {
        console.log(" ========= onSave ", formValues);
    }

    return (
        <div className='contextbar-actionContainer'>
            <div className='context-bar-head'>
                <div>
                    <h5 className='context-bar-title'>Tags</h5>
                </div>
            </div>
            <Card>
                <div className='node-property'>
                    <EditableForm getAssetInfo={() => getAssetsInfo()} onSave={onSave} />
                </div>
            </Card>
        </div>
    )
}

// export const ApiGatewayContextShowInfoBarHandlerV1 = () => {
//     let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()
//
//     let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
//     let imgPath = currentContextBarNodeInfo.node_data.imgPath
//
//     let resourceProperties = [
//         "RestApiName",
//         "RestApiId",
//         "Description",
//         "CreatedDate",
//         "DisableExecuteApiEndpoint"
//     ]
//
//
//     const resourcePropertyBody = resourceProperties.map((p) =>
//         <tr key={uniqid()}>
//             <td>{p}</td>
//             <td>{nodeContextInfo[p]}</td>
//         </tr>
//     );
//
//
//     return (
//         <div>
//             <h1><img src={imgPath} width="48" height="48"/> ec2 </h1>
//
//             <Table striped bordered hover>
//                 <thead>
//                 <tr>
//                     <th>Property</th>
//                     <th>Value</th>
//                 </tr>
//                 </thead>
//
//                 <tbody>
//                 {resourcePropertyBody}
//                 </tbody>
//
//             </Table>
//         </div>
//     )
// }

//
//
