import React from 'react';
import PropTypes from 'prop-types';

const ViolatedCategories = ({ categories }) => {
  return (
    <div className='grid grid-gap-6p '>
      {categories && categories.length
        ? categories.map((category) => {
            return (
              <div key={category} className='fs-11 fw-600 clr-gry40 flex a-i-c p-l-r-7 violated-categories'>
                {category}
              </div>
            );
          })
        : null}
    </div>
  );
};

ViolatedCategories.propTypes = {
  categories: PropTypes.array,
};

ViolatedCategories.defaultProps = {
  categories: [],
};

export default ViolatedCategories;
