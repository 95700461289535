import {Button, Dropdown, DropdownButton, Form, Row, Col, Card} from "react-bootstrap";
import uniqid from "uniqid";
import {createRef, useState} from "react";
import {DispatchAction, GetState} from "./statemanagement/store";
import * as actions from "./statemanagement/ActionsList";

import {
    CREATE_RESOURCE_NODE,
    RESOURCE_CONTEXT_INFO_KEY_TAG,
} from "./ResourceEditorCanvas";
import {dprint0, dprint3} from "../DebugUtil";
import {GET_MINUS_ICON_PATH, GET_PLUS_ICON_PATH, GetWidgetCloseButtonIconPath} from "./GeneralResourceIconsList";
import {RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS} from "./statemanagement/ActionsList";

export const UI_FIELDS_DEFINITION = "UI_FIELDS_DEFINITION"
export const FIELD_IDENTIFIER_TAG = "FIELD_IDENTIFIER_TAG"
export const FIELD_DISPLAY_LABEL_TAG = "FIELD_DISPLAY_LABEL_TAG"


export const CONTEXT_TYPE = {
    RESOURCE_ATTRIBUTES: 1,
    RESOURCE_LINK_ATTRIBUTES: 2
}

export const FIELD_TYPE = {
    TEXT_FIELD: 1,
    DROP_DOWN: 2
}

export const FIELD_VALUES = {
    VALUE_NONE: null,
    IGNORE_VALUE: null,
    NOT_APPLICABLE: null,
    VALUE_NONE_STR: "",
    EMPTRY_STR: ""
}

// IS THIS A SINGLE FIELD OR DYNAMICALLY EXPANDABLE NUMBER OF FIELDS USING SOME + BUTTON ETC ..
// SIMILARLY on clicking some - button a fields may be dynamically removed
// e.g
//      routes can be 1 or more for an api gateway so ONE_OR_MORE
//      security group can be ZERO_OR_MORE for a resource which can belong to zero or multiple security groups
//      AMI id can be only one unique field so it has to be NO_APPENDING a single field only
export const FIELD_APPENDING_TYPE = {
    // BASIC FIELD TYPES just a single type like textfield or drop down no grouping of different types
    NO_APPENDING: 1, // fixed number of field just one
    ZERO_OR_MORE: 2, // zero or more fields of such type may be dynamically added in UI
    ONE_OR_MORE: 3, // one or more fields of such type may be dynamically added in UI
    GROUP_FIXED_NUMBER: 4,// occurs as a fixed number of fields, REPEAT N times

    // CUSTOM FIELD TYPES e.g {texfield_label, textfield, dropdown} => e.g {"route", "", [GET, POST]}
    CUSTOM_NO_APPENDING: 5,
    CUSTOM_ZERO_OR_MORE: 6,
    CUSTOM_ONE_OR_MORE: 7,
    CUSTOM_FIXED_NUMBER: 8
}

const UI_FIELD_TYPE_TEXTFIELD = "text"
const UI_FIELD_TYPE_DROPDOWN = "dropdown"
const UI_FIELD_CUSTOM_DRAWN = "UI_FIELD_CUSTOM_DRAWN"

export const ContextBarGetNewDropDownField = (
    _fieldUiDisplayLabel,
    _jsonSerializationKey,
    _fieldAppendType,
    _dropdownitems,
    _defaultValue
) => {
    return (
        {
            label: _fieldUiDisplayLabel,
            id: _jsonSerializationKey,
            fieldAppendType: _fieldAppendType,
            fieldType: FIELD_TYPE.DROP_DOWN,
            dropdownitems: _dropdownitems,
            defaultvalue: _defaultValue,
            elementType: UI_FIELD_TYPE_DROPDOWN
        }
    )
}

export const ContextBarGetNewTextInputField = (
    _fieldUiDisplayLabel,
    _jsonSerializationKey,
    _fieldAppendType,
    _placeHolder,
    _defaultValue
) => {
    return (
        {
            label: _fieldUiDisplayLabel,
            id: _jsonSerializationKey,
            fieldAppendType: _fieldAppendType,
            fieldType: FIELD_TYPE.TEXT_FIELD,
            placeholder: _placeHolder,
            defaultvalue: _defaultValue,
            elementType: UI_FIELD_TYPE_TEXTFIELD,
        }
    )
}

export const ContextBarGetNewCustomWidgetField = (
    _jsonSerializationKey,
    _groupName,
    _fieldAppendType,
    _widgetDrawingFunction
) => {
    return (
        {
            id: _jsonSerializationKey,
            groupName: _groupName,
            fieldAppendType: _fieldAppendType,
            widgetDrawingFunction: _widgetDrawingFunction
        }
    )
}

export const getCurrentActiveContextBarNodeInfo = () => {
    let state = GetState()
    if ("contextBarInfo" in state) {
        return GetState().contextBarInfo.payload
    }
    return null
}

const GetPreviousSavedNodeContextValuesIfAny = (savedParamKey) => {
    let nodeInfo = getCurrentActiveContextBarNodeInfo()

    if (nodeInfo == null) {
        return ""
    }

    if ("node_data" in nodeInfo) {
        let nodeData = nodeInfo["node_data"]

        if (RESOURCE_CONTEXT_INFO_KEY_TAG in nodeData) {
            let resource_context_info = nodeData[RESOURCE_CONTEXT_INFO_KEY_TAG]
            console.log(resource_context_info)
            if (savedParamKey in resource_context_info)
                return resource_context_info[savedParamKey]
        }
    }
    return ""
}

const ContextBarFormTextElementHandler = (elementDefinition, refsMap, onContextDataChanged, inputValuesStore,
                                          contextBarStateStore, setContextBarStateStore) => {

    const elementId = elementDefinition.id // _jsonSerializationKey
    const elementRef = refsMap[elementId]

    const onTextChangedHandler = (event) => {
        inputValuesStore[elementId] = refsMap[elementId].current.value
        onContextDataChanged()
    }

    return (
        <Form.Group className="mb-1" id={elementDefinition.id}>
            <Form.Label>{elementDefinition.label}</Form.Label>
            <Form.Control type="text" ref={elementRef} placeholder={elementDefinition.placeholder}
                          defaultValue={GetPreviousSavedNodeContextValuesIfAny(elementId)}
                          onChange={onTextChangedHandler}
            />
        </Form.Group>
    )
}

const ContextBarFormDropDownElementHandler = (elementDefinition, refsMap, onContextDataChanged, inputValuesStore,
                                              contextBarStateStore, setContextBarStateStore) => {
    const elementId = elementDefinition.id

    const onDropDownSelected = (event) => {
        inputValuesStore[elementId] = event
        onContextDataChanged()
    }

    return (
        <DropdownButton
            alignRight
            title={elementDefinition.label}
            id="dropdown-menu-align-right"
            onSelect={onDropDownSelected}
            defaultValue={GetPreviousSavedNodeContextValuesIfAny(elementId)}

        >
            {elementDefinition.dropdownitems.map((dropDownItem, index) => (
                <Dropdown.Item eventKey={dropDownItem} key={index}> {dropDownItem}  </Dropdown.Item>
            ))}
        </DropdownButton>
    )
}

const elementHandlerMap = {
    "text": ContextBarFormTextElementHandler,
    "dropdown": ContextBarFormDropDownElementHandler
}

const isElementNoAppending = (element) => {
    return element.fieldAppendType == FIELD_APPENDING_TYPE.NO_APPENDING
}

const isElementCustomAndCanBeAppended = (element) => {
    return element.fieldAppendType == FIELD_APPENDING_TYPE.CUSTOM_ONE_OR_MORE ||
        element.fieldAppendType == FIELD_APPENDING_TYPE.CUSTOM_ZERO_OR_MORE
}

const CUSTOM_WIDGET_REMOVE_BUTTON_CLICKED = "CUSTOM_WIDGET_REMOVE_BUTTON_CLICKED"

const getLinkData = (nodeInfo) => {
    if ("what" in nodeInfo) {
        if (nodeInfo.what === RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS) {
            if ("node_link_data" in nodeInfo) {
                return {
                    fromNodeId: nodeInfo.node_link_data.from,
                    toNodeId: nodeInfo.node_link_data.to
                }
            }
        }
    }

    return null
}

const getContextBarStateStoreKey = (resource_type_key, elementId) => {
    // "custom_widget_content_" +  + element.id
    return "custom_widget_content_" + resource_type_key + elementId
}

const getContextBarStateStoreKeyForResourcesLink = (elementId, fromNodeId, toNodeId) => {
    return getContextBarStateStoreKey("resources_link_", elementId) +
        "___" + fromNodeId + "___" + toNodeId
}

let widgetDataStore = {}

const getCustomWidgetRenderedContent = (element, contextBarStateStore, setContextBarStateStore) => {
    const REMOVE_WIDGET = "REMOVE_WIDGET"
    const ADD_WIDGET = "ADD_WIDGET"

    let widgetAccessHelper = {}
    let fromNodeId = null
    let toNodeId = null
    let nodeInfo = getCurrentActiveContextBarNodeInfo()
    let resourceLinkData = getLinkData(nodeInfo)
    let contextBarStateCustomWidgetDataStoreKey = null
    // let inMemModel = []

    const storeWidgetData = (widgetId, widgetData) => {
        widgetDataStore[widgetId] = widgetData
    }

    const getWidgetDataFromStore = (widgetId) => {
        if (widgetId === null) {
            return null
        }

        if (widgetId in widgetDataStore) {
            return widgetDataStore[widgetId]
        }

        return null
    }

    // const addToModel = (itemData) => {
    //     inMemModel.push(itemData)
    // }
    //
    // const getModel = () => {
    //     return inMemModel
    // }

    const initializeCustomWidgetDataInContextBarState = (contextBarStateCustomWidgetDataStoreKey) => {
        const tmpContextBarStateStore = {...contextBarStateStore}
        tmpContextBarStateStore.CustomWidgetDataStore[contextBarStateCustomWidgetDataStoreKey] = {
            widgetDataList: []
        }
        setContextBarStateStore(tmpContextBarStateStore)
    }

    const getCustomWidgetDataFromContextBarState = (contextBarStateCustomWidgetDataStoreKey) => {
        if ("CustomWidgetDataStore" in contextBarStateStore) {
            if (contextBarStateCustomWidgetDataStoreKey in contextBarStateStore.CustomWidgetDataStore) {
                return contextBarStateStore.CustomWidgetDataStore
            }
        }
        return null
    }

    if (resourceLinkData !== null) {  // THIS WOULD BE A RESOURCE DATA NOT RESOURCE LINK DATA
        fromNodeId = resourceLinkData.fromNodeId
        toNodeId = resourceLinkData.toNodeId
        contextBarStateCustomWidgetDataStoreKey =
            getContextBarStateStoreKeyForResourcesLink(element.id, fromNodeId, toNodeId)

        const customWidgetData = getCustomWidgetDataFromContextBarState(contextBarStateCustomWidgetDataStoreKey)

        if (customWidgetData === null) {
            initializeCustomWidgetDataInContextBarState(contextBarStateCustomWidgetDataStoreKey)
        } else {

        }
    } else {
        throw "custom widget implementation required for RESOURCE NODES already implemented for RESOURCE LINK NODES"
    }

    const getWidgetDataList = () => {
        return contextBarStateStore.CustomWidgetDataStore[contextBarStateCustomWidgetDataStoreKey].widgetDataList
    }

    const updateWidgetDataList = (newWidgetDataList) => {

        const tmpContextBarStateStore = {...contextBarStateStore}
        tmpContextBarStateStore.CustomWidgetDataStore[contextBarStateCustomWidgetDataStoreKey].widgetDataList =
            [...newWidgetDataList]
        setContextBarStateStore(tmpContextBarStateStore)
    }

    const updateWidgetDataListInPlace = (item) => {

        const tmpContextBarStateStore = {...contextBarStateStore}

        let newWidgetDataList = [...tmpContextBarStateStore.CustomWidgetDataStore[contextBarStateCustomWidgetDataStoreKey].widgetDataList]
        newWidgetDataList.push(item)

        tmpContextBarStateStore.CustomWidgetDataStore[contextBarStateCustomWidgetDataStoreKey].widgetDataList =
            [...newWidgetDataList]
        setContextBarStateStore(tmpContextBarStateStore)
    }

    const createNewItemDefaultData = (_widgetId, widget_specific_data) => { // widget_specific_data be understood only by caller
        return {
            widgetId: _widgetId,
            data: widget_specific_data
        }
    }

    const onCustomWidgetDataUpdated = (widgetItemId, widgetItemData) => {
        storeWidgetData(widgetItemId, widgetItemData)

        let savedParamsInfo = []
        let contextInfo = {}

        for (let i = 0; i < getWidgetDataList().length; i++) { // all items have a minus button, only last item has plus button
            const widgetData = getWidgetDataList()[i]
            let widgetId = widgetData.widgetId
            let customWidgetData =   getWidgetDataFromStore(widgetId)
            let widgetJsonData = widgetAccessHelper.getWidgetDataSerializedAsJson(customWidgetData)
            savedParamsInfo.push(widgetJsonData)
        }

        contextInfo["nodes_link_info"] = nodeInfo
        contextInfo["saved_params"] = { routes_info:savedParamsInfo }

        console.log("DispatchAction RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER")
        // Not a local event broadcast to whole UI
        DispatchAction(actions.RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER, {
            "what": actions.RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER,
            "saved_context_info": contextInfo
        })

    }

    widgetAccessHelper.updateCustomWidgetData = onCustomWidgetDataUpdated

    const addNewComponentItem = () => {

        let newItemDefaultData = createNewItemDefaultData(uniqid(), null)
        updateWidgetDataListInPlace(newItemDefaultData)
    }

    const removeComponentItem = (widgetId) => {
        let newWidgetDataList = getWidgetDataList().filter(widgetData =>  widgetData.widgetId !== widgetId )
        updateWidgetDataList(newWidgetDataList)
    }

    const widgetAddOrRemoveClicked = (clickData) => {
        console.log("widgetAddOrRemoveClicked " + clickData)
        if(clickData.clickType === REMOVE_WIDGET) {
            removeComponentItem(clickData.widgetId)
        }
        else if(clickData.clickType === ADD_WIDGET) {
            addNewComponentItem()
        }
    }

    const getAddOrRemoveButton = (iconPath, onClickHandler) => {
        return (
            <Button>
                <img
                    src={iconPath}
                    style={{height: '16px', width: '16px'}}
                    className="mt-2"
                    onClick={() => onClickHandler()}
                />
            </Button>
        )
    }

    const getRemoveButton = (_widgetId) => {
        return getAddOrRemoveButton(GET_MINUS_ICON_PATH(),
            () => {
                widgetAddOrRemoveClicked({clickType: REMOVE_WIDGET, widgetId: _widgetId})
            }
        )
    }

    const getAddButton = (_widgetId) => {
        return getAddOrRemoveButton(GET_PLUS_ICON_PATH(),
            () => {
                widgetAddOrRemoveClicked({clickType: ADD_WIDGET, widgetId: _widgetId})
            }
        )
    }

    const getPlusMinusButtonContent = (widgetId) => {
        return (
            <>
                <Row>
                    <Col>
                        {getRemoveButton(widgetId)}
                    </Col>
                    <Col>
                        {getAddButton(widgetId)}
                    </Col>
                </Row>
            </>
        )
    }

    const getMinusButtonContent = (widgetId) => {
        return (
            <>
                <Row>
                    <Col>
                        {getRemoveButton(widgetId)}
                    </Col>
                </Row>
            </>
        )
    }


    if (getWidgetDataList().length == 0) {
        addNewComponentItem()
    }

    const renderedContent = () => {
        const items = []

        for (let i = 0; i < getWidgetDataList().length; i++) { // all items have a minus button, only last item has plus button
            const widgetData = getWidgetDataList()[i]
            let widgetId = widgetData.widgetId
            let customWidgetData =   getWidgetDataFromStore(widgetId)

            items.push(
                <div key={widgetId}>
                    <Card>
                        <h6>{widgetId}</h6>
                        { (i == getWidgetDataList().length-1) ? getPlusMinusButtonContent(widgetId) : getMinusButtonContent(widgetId)}
                        {element.widgetDrawingFunction(widgetAccessHelper, widgetId, customWidgetData)}
                    </Card>
                </div>
            )
        }

        return items

        return (
            <>
                <h6> customComponentList {getWidgetDataList().length} </h6>
            </>
        )
    }

    return renderedContent()
}

const CreateNewContextBarContentForm = (uiFieldsDefinition, refsMap, formSubmitHandler, onContextDataChanged,
                                        inputValuesStore, contextBarStateStore, setContextBarStateStore) => {

    const getContentForElement = (element) => {

        if (isElementNoAppending(element)) {
            return elementHandlerMap[element.elementType](element, refsMap, onContextDataChanged, inputValuesStore,
                contextBarStateStore, setContextBarStateStore)
        }
        if (isElementCustomAndCanBeAppended(element)) {
            return getCustomWidgetRenderedContent(element, contextBarStateStore, setContextBarStateStore)
        }
    }

    const contextBarDefinitionMarkup = (
        <Form onSubmit={formSubmitHandler}>
            {uiFieldsDefinition.map((element, index) => (
                <div key={uniqid()}
                     className="contextBarElement"> {getContentForElement(element)} </div>
            ))}
        </Form>
    )

    return contextBarDefinitionMarkup
}

const CreateRefsForContextBarFormUiFields = (uiFieldsDefinition) => {
    let refsMap = {}
    uiFieldsDefinition.map((elementDefinition) => {
        refsMap[elementDefinition.id] = createRef()
    })
    return refsMap
}

const createInputValuesStorageMap = (uiFieldsDefinition) => {
    let inputValuesStore = {}
    uiFieldsDefinition.map((elementDefinition) => {
        inputValuesStore[elementDefinition.id] = null
    })
    // console.log(inputValuesStore)
    return inputValuesStore

}

const contextTypeTag = "contextType"

export const setContextBarDefinitionTypeAsResourceLink = (contextBarDefinition) => {
    contextBarDefinition[contextTypeTag] = CONTEXT_TYPE.RESOURCE_LINK_ATTRIBUTES
}

const getContextBarDefinitionType  = (contextBarDefinition) => {
    if (contextTypeTag in contextBarDefinition) {
        return contextBarDefinition[contextTypeTag]
    }

    return CONTEXT_TYPE.RESOURCE_ATTRIBUTES
}


const isContextBarTypeResourceLinkAttributes = (contextBarDefinition) => {
    return getContextBarDefinitionType(contextBarDefinition) === CONTEXT_TYPE.RESOURCE_LINK_ATTRIBUTES
}


const isContextBarTypeResourceAttributes = (contextBarDefinition) => {
    return getContextBarDefinitionType(contextBarDefinition) === CONTEXT_TYPE.RESOURCE_ATTRIBUTES
}


export const CreateContextBarUiHandler = (contextBarDefinition) => {
    let refsMap = null
    let inputValuesStore = null // createInputValuesStorageMap()
    let uiFieldsDefinition = contextBarDefinition[UI_FIELDS_DEFINITION]

    let contextBarStateStore = contextBarDefinition.contextBarStateStore
    let setContextBarStateStore = contextBarDefinition.setContextBarStateStore

    let contextType = getContextBarDefinitionType(contextBarDefinition)



    console.log("CreateContextBarUiHandler ")

    const onContextDataChanged = () => {

        // console.log("CreateContextBarUiHandler::onContextDataChanged ")

        let nodeInfo = getCurrentActiveContextBarNodeInfo()
        if (nodeInfo == null) {
            console.log("Error: LambdaContextBarHandler.js saveBtnClicked contextBarInfo == null")
            return
        }

        let savedParamsInfo = {}
        let contextInfo = {}

        uiFieldsDefinition.map((elementDefinition) => {
            let elementId = elementDefinition.id
            savedParamsInfo[elementId] = inputValuesStore[elementId]
        })



        if (isContextBarTypeResourceLinkAttributes(contextBarDefinition)) {
            contextInfo["nodes_link_info"] = nodeInfo
            contextInfo["saved_params"] = savedParamsInfo

            console.log("DispatchAction RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER")
            // Not a local event broadcast to whole UI
            DispatchAction(actions.RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER, {
                "what": actions.RESOURCES_LINK_CONTEXT_INFO_SAVED_BY_USER,
                "saved_context_info": contextInfo,
                "writer_who": "CreateContextBarUiHandler::onContextDataChanged"
            })

        } else if (isContextBarTypeResourceAttributes(contextBarDefinition)) {

            contextInfo["node_info"] = nodeInfo
            contextInfo["saved_params"] = savedParamsInfo

            // Not a local event broadcast to whole UI
            DispatchAction(actions.RESOURCE_CONTEXT_INFO_SAVED_BY_USER, {
                "what": actions.RESOURCE_CONTEXT_INFO_SAVED_BY_USER,
                "saved_context_info": contextInfo,
                "writer_who": "CreateContextBarUiHandler::onContextDataChanged"
            })

        } else {

        }
    }


    refsMap = CreateRefsForContextBarFormUiFields(uiFieldsDefinition)
    inputValuesStore = createInputValuesStorageMap(uiFieldsDefinition)

    const formContent = CreateNewContextBarContentForm(uiFieldsDefinition, refsMap, () => { },
        onContextDataChanged, inputValuesStore, contextBarStateStore, setContextBarStateStore)

    return <>
        {formContent}
    </>
}


//
//