import React from 'react';
import classes from './LandingPage.module.css';
import './LandingPage.globals.css';
import FeaturesCard from './components/FeaturesCard/FeaturesCard';
import DollarIcon from './assets/Dollar.svg';
import SecureShieldIcon from './assets/SecureShield.svg';
import CloudNetworkIcon from './assets/CloudNetwork.svg';
import HealthMonitoringIcon from './assets/HealthMonitoring.svg';
import SlicesLeftBg from './assets/SlicesLeft.png';
import SlicesRightBg from './assets/SlicesRight.png';
import DolphinLogo from './assets/Dolphin.svg';
import Footer from './components/Footer/Footer';
import CarouselComponent from './components/Carousel/Carousel';
import Navbar, { PATHS } from './components/Navbar/Navbar';
import { useHistory } from 'react-router-dom';
import Plans from './components/Plans/Plans';
import FAQ from './components/FAQs/FAQ';
import { COST_FAQ } from './newconstants';
import CloudSecurityImageIcon from './assets/CloudSecurityImage.png';

const FEATURES = [
  {
    icon: DollarIcon,
    title: (
      <>
        Continuous Cost <br /> Monitoring
      </>
    ),
    subtitle: 'Point and hover to find top cost infra resources. Compare usage to cost benefits. Get cost ranking for all resources.',
  },
  {
    icon: SecureShieldIcon,
    title: (
      <>
        Security <br /> & Alerts
      </>
    ),
    subtitle: 'Get security alerts and early notifications. Regular automated scans help you improve security.',
  },
  {
    icon: CloudNetworkIcon,
    title: (
      <>
        Thorough Visibility Of <br /> Infrastructure
      </>
    ),
    subtitle: 'Get security alerts and early notifications. Regular automated scans help you improve security.',
  },
  {
    icon: HealthMonitoringIcon,
    title: (
      <>
        Health <br /> Monitoring
      </>
    ),
    subtitle: 'Continuously monitors status of your resources in a simplified manner.',
  },
];

export const COST_VIDEOS = [
  {
    title: 'Cost Manager',
    url: 'https://www.youtube.com/embed/GlL26DgOdd0',
    description:
      'View consolidated cost of your multiple cloud accounts in a single place. Get the detailed breakup of cost per resource types as tables or pie charts. Our trend analysis visual representation gives you quick insight into a rising or falling trends in your cloud expenditure month on month.',
  },
  {
    title: 'Cloud Security & Compliance',
    url: CloudSecurityImageIcon,
    type: 'image',
    description:
      'Identify and fix security issues found in your cloud resources setup using our user friendly Security Advisor tool. We will scan your cloud resources regularly and present the reports to you.',
  },
  {
    title: 'Cloud Diagrams & Inventory',
    url: 'https://www.youtube.com/embed/_ATNSC9-2OY',
    description:
      'View your cloud infrastructure as diagrams in clean hierarchial structure or as tables. Identify Unused resources and excessive resource allocation . Highly useful for Devops, Development, Auditing teams',
  },
  {
    title: 'Disaster Recovery',
    url: 'https://www.youtube.com/embed/c1TeOfN5mTg',
    description:
      'Quickly find out what has changed in your cloud configuration. We take a snapshot of your cloud resource configuration on a daily basis. Take advantage of resource configuration diff tool to find what has changed in your resource configuration.',
  },
];

const LandingPage = () => {
  const history = useHistory();

  const handleGetStartedClick = () => history.push('/cgupsignup');
  return (
    <div className={`${classes.LandingPage} landingPage`}>
      <div className={classes.TopContainer}>
        <div className={classes.HeaderAndSection1}>
          <Navbar />
          <div className={classes.WelcomeContainer}>
            <div className={classes.WelcomeText}>Welcome to Bluefinware</div>
            <div className={classes.Slogan}>Simplified & Cost-Effective Cloud Management at Your Fingertips.</div>
            <div className={classes.Description}>
              Explore the unmatched ease of effortlessly overseeing your cloud operations through our user-friendly solution. Uncover the
              potential of Streamlined and Budget-Friendly Cloud Management directly accessible on your fingertips, offering unprecedented
              control and savings.
            </div>
            <div className={classes.GetStartedButton}>
              <div onClick={handleGetStartedClick}>
                GET STARTED ⟶
              </div>
            </div>
            <div className={classes.EC2PlannerContainer}>
              <div className={classes.Text}>Use EC2 planner for free!</div>
              <div className={classes.CalculateButton} onClick={() => history.push('/computePricing')}>
                <div>CALCULATE NOW</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.DiscoverContainer}>
        <div className={classes.SlicesLeft}>
          <img src={SlicesLeftBg} alt='' />
        </div>
        <div className={classes.Title}>Discover The Power Of Bluefinware</div>
        <div className={classes.Subtitle}>
          Experience streamlined operations, optimized resource allocation, and real-time insights with our cutting-edge cloud management
          app. Unlock the benefits of seamless scalability, enhanced security, and simplified monitoring, all in one comprehensive solution.
          Revolutionize your cloud management experience today.
        </div>
        <div className={classes.FeatureCards}>
          {FEATURES.map(({ icon, title, subtitle }) => (
            <FeaturesCard icon={icon} title={title} subtitle={subtitle} />
          ))}
        </div>
      </div>
      <div className={classes.CostVideoContainer}>
        <div className={classes.SlicesRight}>
          <img src={SlicesRightBg} alt='' />
        </div>
        <div className={classes.Title}>Peace of Mind & Cloud Cost Serenity</div>
        <div className={classes.Subtitle}>
          Experience the peace of mind that comes with effective cost management & Manage your cloud costs with a sense of calm and
          tranquillity.
        </div>
        <CarouselComponent
          slides={[
            <div className={classes.CostVideosContainer}>
              {COST_VIDEOS.map(({ title, description, url, type }) => (
                <div className={classes.CostVideo}>
                  {type === 'image' ? (
                    <img style={{ width: '100%', height: '100%', alignSelf: 'center' }} src={url} alt='' />
                  ) : (
                    <iframe
                      width='100%' // You can adjust the width as needed
                      height='100%' // You can adjust the height as needed
                      src={url}
                      allowFullScreen
                      title='Bluefinware'
                    />
                  )}
                  <div className={classes.CostVideoFooter}>
                    <div className={classes.CostVideoTitle}>{title}</div>
                    <div className={classes.CostVideoDesc}>
                      <div>{description}</div>
                      <div className={classes.LearnMoreContainer}>
                        <div onClick={()=>history.push(PATHS.USE_CASES)}>LEARN MORE</div>
                        <div className={classes.LearnMoreBorder}></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>,
          ]}
        />
      </div>
      <div className={classes.PlansAndFaq} style={{ paddingTop: '1.5vw', background: '#000000' }}>
        <Plans />
        <div className={classes.FaqContainer}>
          <FAQ faqItems={COST_FAQ} />
        </div>
      </div>
      <div className={classes.StartContainer}>
        <div>We're committed to providing a top-notch customer experience.</div>
        <div className={classes.DolphinLogo}>
          <img src={DolphinLogo} alt='' />
        </div>
        <div className={classes.LetsStartButton}>
          <div onClick={handleGetStartedClick}>
            LET'S GET STARTED
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
