import React from 'react';
import ComingSoonIcon from '../../../images/comingSoonImage.svg';

const InfraAsCode = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%',
        marginLeft: '-15vw',
      }}
    >
      <img style={{ height: '20vw' }} src={ComingSoonIcon} alt='' />
    </div>
  );
};

export default InfraAsCode;
