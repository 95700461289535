
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";

export const PeeringConnectionContextBarHandler = () => {

    /*
            "nodeContextInfo": {
                "AcceptorVpcInfo_CidrBlockSet": "{\t\"CidrBlockSet\" : [{\t\"0\": {\t\"CidrBlock\": \"10.0.0.0/16\" } }, ] } ",
                "AcceptorVpcInfo_VpcId": "vpc-0a770d6b71971be33",
                "Status_Code": "active",
                "VpcPeeringConnectionId": "pcx-0769fa6522f678c00",
                "ExpirationTime": "",
                "AcceptorVpcInfo_CidrBlock": "10.0.0.0/16",
                "AcceptorVpcInfo_OwnerId": "206824716966",
                "AcceptorVpcInfo_Region": "ap-south-1",
                "Status_Message": "Active"
 */

    let resourceProperties = [
        "AcceptorVpcInfo_CidrBlockSet",
        "AcceptorVpcInfo_VpcId",
        "Status_Code",
        "VpcPeeringConnectionId",
        "ExpirationTime",
        "AcceptorVpcInfo_CidrBlock",
        "AcceptorVpcInfo_OwnerId",
        "AcceptorVpcInfo_Region",
        "Status_Message"
    ]

    const displayFields = [  ]
    const title = "Peering Connection"

    for (let i=0 ; i<resourceProperties.length; i++) {
        let labelStr = resourceProperties[i]
        let keyStr = resourceProperties[i]
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr))
    }

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}

//