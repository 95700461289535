export const COST_FAQ = [
  {
    title: 'Which cloud providers are supported?',
    subtitle: 'We support AWS as of now. We will be supporting more cloud providers in the future.',
  },
  {
    title: 'Is there a demo or trial version available?',
    subtitle: 'Yes we give 90 days of trial without any restrictions. The highest priced offering of our product is given as trial',
  },
  {
    title: 'Is there a mobile version of your product?',
    subtitle: 'No this software is designed for PC use only',
  },
  {
    title: 'Which browser is supported for your web app?',
    subtitle: 'We support Chrome browser later than 2023 Jan releases',
  },
  {
    title: 'How can I get in touch with your support team?',
    subtitle: 'Please send your queries to support@bluefinware.io',
  },
];
