
export const saveButtonSelectedInContextbarReducer = (state, action) => {
    console.log("saveButtonSelectedInContextbarReducer")
    console.log(action)
    return {
        ...state,
        currentAction: action,
        contextBarSavedInfo: {
            desc: "saveButtonSelectedInContextbarReducer",
            payload: action.payload
        }
    }
}


////
///