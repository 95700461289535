import React, {useEffect, useState,useRef} from "react";
import LeftMenu from "./LeftMenu";
import {Col, Container, Row} from "react-bootstrap";
import Billing from "./Billing";
import Account from "./Account";
import useAxios from "axios-hooks";
import {GetCostManagerUrl, GetSimulatedCostManagerUrl} from "../../routes";
import {GetLoginCredentialsAsJsonObject, GetUserLoginCredentials, GetUserLoginToken} from "../UserCredentials";

import "../../styles/CostManager.css"

export const CONST_GROUP_BY_BILLING = "Billing";
export const CONST_GROUP_BY_ACCOUNT = "Account";

export const CONST_BILLING_CURR_CYCLE = "current_billing_cycle";
export const CONST_BILLING_LAST_CYCLE = "last_billing_cycle";
export const CONST_BILLING_COST_PREDICTOR = "cost_predictor";
export const CONST_ADVANCED_SEARCH = "advanced_search";
export const CONST_COST_PLANNER = "advanced_planner";

const COST_MANAGER_URL = GetCostManagerUrl()
const COST_SIMULATED_MANAGER_URL = GetSimulatedCostManagerUrl()


const CostManagerPage = () => {
    const [groupBy, setGroupBy] = useState(CONST_GROUP_BY_BILLING)
    const [billingCycle, setBillingCycle] = useState(CONST_ADVANCED_SEARCH)
    const [accounts, setAccounts] = useState([])
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(0)
    const [isAdvancedSearchSelected, setAdvancedSearchSelected] = useState(true)
    const isMounted = useRef(false)
    const isCostFetchedRef = useRef(false)

    const onSelectedDropdown = (selection) => {
        setGroupBy(selection.value)
    }

    const onBillingCycleSelect = (cycle) => {
        setBillingCycle(cycle)
    }

    const onSetAccounts = (_accounts) => {
        setAccounts(_accounts)
    }
    
    const onSelectedAccount = (accountIndex) => {
        setSelectedAccountIndex(accountIndex)
    }

    const getCostData = () => {
        switch (groupBy) {
            case CONST_GROUP_BY_BILLING :
                    return <Billing
                        billingCycle={billingCycle}
                        data={data}
                        loading={loading}
                        error={error}
                    />
            case CONST_GROUP_BY_ACCOUNT:
                return <Account
                            data={data}
                            accounts={accounts}
                            onSetAccounts={onSetAccounts}
                            selectedAccountIndex={selectedAccountIndex}
                            isAdvancedSearchSelected={isAdvancedSearchSelected}
                />
            default:
                return <h1>Group By</h1>
        }
    }

    const GetLoginCredentials = () => {

        let userCreds = GetUserLoginCredentials()[0]
        return {
            displayName: userCreds.displayName,
            email: userCreds.email,
            phoneNumber: userCreds.phoneNumber,
            photoURL: userCreds.photoURL,
            providerId: userCreds.providerId,
            uid: userCreds.uid,
            "user_oauth_token": GetUserLoginToken()
        }
    }

    const getRequestPayload = () => {
        const payload = {
            "concerto_user_credentials": GetLoginCredentialsAsJsonObject(),
            "command_to_execute":"get_cost_details"
        }
      return payload
    }

    const COST_MANAGER_URL_STR = COST_MANAGER_URL
    //const COST_MANAGER_URL_STR = COST_SIMULATED_MANAGER_URL

    const [{ data, loading, error }, fetchData] = useAxios({
            url:  COST_MANAGER_URL_STR,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data:  getRequestPayload()
        },
        {manual : true}
    )

    useEffect(() => {
        //fetchData()
        isMounted.current = true
    }, []);

    useEffect(() =>{
        if(isMounted.current && !isCostFetchedRef.current &&  (billingCycle === CONST_BILLING_CURR_CYCLE || billingCycle === CONST_BILLING_LAST_CYCLE || billingCycle === CONST_BILLING_COST_PREDICTOR)){
            fetchData()
            isCostFetchedRef.current = true
        }
    },[billingCycle])

    // if (loading) return <p>Loading...</p>
    // if (error) return <p>Error!</p>

    return (
            <>
                <Container className="mt-5 my-profile-page">
                    <Row>
                        <Col xs lg="3">
                            <LeftMenu
                                billingCycle={billingCycle}
                                onBillingCycleSelect={onBillingCycleSelect}
                                groupBy={groupBy}
                                onSelectedDropdown={onSelectedDropdown}
                                accounts={accounts}
                                onSelectedAccount={onSelectedAccount}
                                selectedAccountIndex={selectedAccountIndex}
                                isAdvancedSearchSelected={isAdvancedSearchSelected}
                                setAdvancedSearchSelected={setAdvancedSearchSelected}
                            />
                        </Col>
                        <Col xs lg="9">
                            {getCostData()}
                        </Col>
                    </Row>
                </Container>
            </>
        )
}

export default CostManagerPage;