import Logo from "../images/logo.png";
import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {GetUserLoginCredentials, GetUserLoginToken} from "./UserCredentials";

export const CompanyCopywright = () => {
    return <div className="company d-flex">
        <img
            src={Logo}
            width="49"
            height="32"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
        />
        <span className="copy">Bluefinware Inc.
                <br /> Copyright
              </span>
    </div>
}

export const getTooltipInfo = (text) => {

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
    >
        <i className="bi bi-info-circle"></i>
    </OverlayTrigger>
}

export const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0]
    return {
        displayName: userCreds.displayName,
        email: userCreds.email,
        phoneNumber: userCreds.phoneNumber,
        photoURL: userCreds.photoURL,
        providerId: userCreds.providerId,
        uid: userCreds.uid,
        "user_oauth_token": GetUserLoginToken()
    }
}

export const RoundUp = (amount) => {
    return parseFloat(amount).toFixed(2)

}