import {Toast} from "react-bootstrap";
import {GetCreateApiGwURL} from "../routes";
import axios from "axios";


export const GetCliCmdContentSetter = (cliCmdAvailable, cliCmdObj) => {
    let cliCmdTxtSetter = null
    //
    console.log(cliCmdAvailable)

    if (cliCmdAvailable === undefined ||
        cliCmdAvailable === null) {
        console.log("cliCmdAvailable not available")
        cliCmdTxtSetter = () => {
            return <> </>
        }
        return cliCmdTxtSetter
    }

    console.log("cliCmdAvailable is available")
    cliCmdTxtSetter = () => {
        const cliCmds = cliCmdObj

        return <>
            <Toast>
                <Toast.Header>
                    <strong className="me-auto">AWS Cli command </strong>
                </Toast.Header>
                <Toast.Body>
                    {cliCmds.map((line, index) => (
                        <pre key={index}> {line} </pre>
                    ))}
                </Toast.Body>
            </Toast>
        </>
    }

    return cliCmdTxtSetter
}

export const SendGetHttpRequestAndGetNewCliCmd = async (httpUrl, contextBarStateStore,
                                                        setContextBarStateStore, apiGwData, cliCmdName) => {
    console.log("SendGetHttpRequestAndGetNewCliCmd => " + cliCmdName + " "+ httpUrl)
    try {
        console.log(apiGwData)
        const resp = await axios.post(httpUrl, apiGwData);
        console.log(resp.data.msg)

        setContextBarStateStore({
            ...contextBarStateStore,
            LambdaContextBarContentHandlerStore: {
                cliCmd: cliCmdName,
                cliCmdObj: resp.data.msg,
                cliCmdAvailable: true
            }
        })

    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
}

//