import {
    getCurrentActiveContextBarNodeInfo
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";

export const RouteTableContextShowInfoBarHandler = () => {

    let resourceProperties = [
        "OwnerId",
        "RouteTableId",
        "VpcId",
    ]

    const displayFields = [  ]
    const title = "Route Table"

    for (let i=0 ; i<resourceProperties.length; i++) {
        let labelStr = resourceProperties[i]
        let keyStr = resourceProperties[i]
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr))
    }

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}
