import React, { useState } from 'react';
import HeaderWithBg from '../../components/HeaderWithBg/HeaderWithBg';
import ContactBg from './assets/ContactBg.svg';
import classes from './Contact.module.css';
import Footer from '../../components/Footer/Footer';
import FAQ from '../../components/FAQs/FAQ';
import { COST_FAQ } from '../../newconstants';
import ContactMobile from '../../../images/contactusMobile.svg';

const bgImage = window.innerWidth > 575 ? ContactBg : ContactMobile ;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      name: formData.name ? '' : 'Name is required',
      email: formData.email ? (validateEmail(formData.email) ? '' : 'Invalid email') : 'Email is required',
      message: formData.message ? '' : 'Message is required',
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === '')) {
      console.log('Form submitted:', formData);
    }
  };

  return (
    <div>
      <div className={classes.Header}>
        <HeaderWithBg logo={bgImage} title='Contact' />
      </div>
      <div className={classes.Contact}>
        <div className={classes.Container}>
          <div className={classes.LeftContainer}>
            <div className={classes.Title}>REACH OUT TO OUR EXPERT TEAM</div>
            <div className={classes.Subtitle}>Our services have gained the trust of over 100+ satisfied customers.</div>
          </div>
          <div className={classes.RightContainer}>
            <form onSubmit={handleSubmit}>
              <div className={classes.inputRow}>
                <div className={classes.inputFieldContainer}>
                  <input
                    type='text'
                    name='name'
                    placeholder='Name'
                    value={formData.name}
                    onChange={handleChange}
                    className={classes.inputField}
                  />
                  {errors.name && <div className={classes.errorMessage}>{errors.name}</div>}
                </div>
                <div className={classes.inputFieldContainer}>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                    className={classes.inputField}
                  />
                  {errors.email && <div className={classes.errorMessage}>{errors.email}</div>}
                </div>
              </div>
              <div className={classes.inputRowBottom}>
                <textarea
                  name='message'
                  rows={5}
                  placeholder='Message'
                  value={formData.message}
                  onChange={handleChange}
                  className={classes.textareaField}
                />
                {errors.message && <div className={classes.errorMessage}>{errors.message}</div>}
              </div>
              <div
                className={classes.submitContactButton}
                type='submit'
                role='button'
                onClick={handleSubmit}
              >
                Submit
              </div>
            </form>
          </div>
        </div>
      </div>
      <FAQ faqItems={COST_FAQ} />
      <Footer />
    </div>
  );
};

export default Contact;
