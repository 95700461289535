import uniqid from 'uniqid';
import {
  GetDisplayType,
  GetIconPath,
  GetIsNodeVirtual,
  GetNodeDisplayNameKey,
  GetNodeIdKey,
  GetNodeIsContainsDiffDetailsChildren,
  GetTrailPathIndexRange,
} from './NodesDefinition';

export const GetNewNodeFromDiffItem0 = (diffItem) => {
  let nodeId = uniqid();

  let nodeType = diffItem.resource_type;
  let diffType = diffItem.diff_type;
  let nodeDisplayName = GetNodeDisplayNameOfNode(diffItem, nodeType);
  let cloudResourceId = GetIdKeyOfNode(diffItem, nodeType);

  if (diffItem.ResourceDetails) {
    let nodeDisplayNameKey = GetNodeDisplayNameKey(nodeType);
    nodeDisplayName = diffItem.ResourceDetails[nodeDisplayNameKey];
  }

  return GetNewNode(nodeId, nodeType, diffType, nodeDisplayName, cloudResourceId, GetIconPath(nodeType), GetDisplayType(nodeType));
};

export const GetNewNodeFromDiffItem = (diffItem) => {
  let nodeId = uniqid();

  let nodeType = diffItem.resource_type;
  let diffType = diffItem.diff_type;
  let nodeDisplayName = GetNodeDisplayNameOfNode(diffItem, nodeType);
  let cloudResourceId = GetIdKeyOfNode(diffItem, nodeType);

  if (diffItem.ResourceDetails) {
    let nodeDisplayNameKey = GetNodeDisplayNameKey(nodeType);
    nodeDisplayName = diffItem.ResourceDetails[nodeDisplayNameKey];
  }

  return GetNewNode(
    nodeId,
    nodeType,
    diffType,
    nodeDisplayName,
    cloudResourceId,
    GetIconPath(nodeType),
    GetDisplayType(nodeType),
    GetTrailPathIndexRange(nodeType)
  );
};

export const GetNewNodeForResourceDetailsItem = (diffItem) => {
  return GetNewNodeFromDiffItem(diffItem);
};

export const GetNewNode = (nodeId, nodeType, diffType, nodeDisplayName, cloudResourceId, iconPath, displayType, trailPathIndexRange) => {
  return {
    NodeId: nodeId,
    NodeType: nodeType,
    DiffType: diffType,
    IsVirtual: GetIsNodeVirtual(nodeType),
    NodeDisplayName: nodeDisplayName,
    IsContainsDiffDetailsChildren: GetNodeIsContainsDiffDetailsChildren(nodeType),
    CloudResourceId: cloudResourceId,
    ChildNodes: [],
    ChildInfoMap: {},
    IconPath: iconPath,
    DisplayType: displayType,
    ResourceDetailsInfo: {},
    TrailPathIndexRange: trailPathIndexRange,
  };
};

export const SetResourceDetailsInfo = (node, resourceDetailsInfo) => {
  node.ResourceDetailsInfo = resourceDetailsInfo;
};

export const AddToChildInfoMap = (node, key, value) => {
  node.ChildInfoMap = { ...node.ChildInfoMap, [key]: value };
};

export const SetDisplayNameForNode = (node, DisplayName) => {
  node.NodeDisplayName = DisplayName;
};

export const SetIsResourceOverviewItem = (node, isResourceOverviewItem) => {
  node.IsResourceOverviewItem = isResourceOverviewItem;
};

export const SetDisplayType = (node, displayType) => (node.DisplayType = displayType);

export const GetNodeDisplayNameOfNode = (diffItem, nodeType) => {
  let nodeDisplayNameKey = GetNodeDisplayNameKey(nodeType);
  return diffItem.ResourceDetails[nodeDisplayNameKey];
};

export const GetIdKeyOfNode = (diffItem, nodeType) => {
  let nodeIdKey = GetNodeIdKey(nodeType);
  return diffItem.ResourceDetails[nodeIdKey];
};

export const GetVirtualNode = (virtualNodeType, displayName, virtualCloudResourceId) => {
  let nodeId = uniqid();

  let nodeType = virtualNodeType;
  let diffType = 'None';
  let nodeDisplayName = displayName;
  let cloudResourceId = virtualCloudResourceId;

  return GetNewNode(nodeId, nodeType, diffType, nodeDisplayName, cloudResourceId, GetIconPath(nodeType));
};

export const AddChildToParent = (parentNode, childNode) => {
  parentNode.ChildNodes.push(childNode);
};

export const IsResourceOverviewItem = (node) => {
  if (!node.DisplayType) {
    return false;
  }
  return node.DisplayType === 'ResourceOverviewItem';
};
