import { BaseContextBarBuilder } from "./ContextBarBuilder";

import { getCurrentActiveContextBarNodeInfo } from "../ContextBarHandlerBuilder";
import { GetSimpleKeyPairValueElement } from "./ContextBarBuilder";

export const EcsContextBarHandler = () => {
    console.log("     EcsContextBarHandler ========= ");
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;
    console.log("nodeContextInfo: ", nodeContextInfo);
    let resourceProperties = [
        "ClusterArn",
        // "ClusterName",
        // "Status"
    ];

    const title = "ECS Cluster";
    const displayFields = [];
    
    for (let i = 0; i < resourceProperties.length; i++) {
        let labelStr = resourceProperties[i];
        let keyStr = resourceProperties[i];
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr));
    }
    
    return BaseContextBarBuilder(title, displayFields);
};
