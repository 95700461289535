import axios from 'axios';
import { GetResourcesDiffInfoUrl } from '../../../../routes';
import { GetLoginCredentialsAsJsonObject } from '../../../UserCredentials';
const diffMultipleSnapshots = 'diff_multiple_snap_shots';

const PostGetResourcesDiffHttpRequest = async (payload) => {
  const url = GetResourcesDiffInfoUrl();
  try {
    const resp = await axios.post(url, payload);
    let diffData = resp;

    if (resp.status !== 200) {
      return {};
    }

    return diffData;
  } catch (err) {
    console.log('PostGetResourcesDiffHttpRequest to ' + url + ' failed');
  }
};

export const GetResourcesDiffedData = (diffPayload) => {
  let userCredentials = GetLoginCredentialsAsJsonObject();
  let payload = getResourcesDiffPayload(userCredentials, diffMultipleSnapshots, diffPayload);
  let resp = PostGetResourcesDiffHttpRequest(payload);
  let val = resp.then((data) => {
    return data;
  });
  return val;
};

const getResourcesDiffPayload = (userCredentials, commandToExecute, commmandArgs) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: commandToExecute,
    command_args: commmandArgs,
  };
};


