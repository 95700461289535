import {GetAllAwsResourceData} from "./AwsResourceList";
import {sendMessageCreateImmutableResourceNode} from "../ResourceEditorCanvas";
import uniqid from "uniqid";

const S3_BUCKETS_JSON_TAG = "s3_buckets"
const EC2_INSTANCES = "ec2_instances"
const RESOURCE_LIST = "resources_list"
const RESOURCE_TYPE_TAG = "resource_type"
const EXTERNAL_RESOURCE_CREATOR = "EXTERNAL_RESOURCE_CREATOR(NOT CONCERTO)"
const RESOURCE_INFO_DATA_TAG = "resource_info_data"
const REST_API_GATEWAY_INFO_V1 = "rest_api_gateway_info_v1"
const LAMBDA_FUNCTIONS_JSON_TAG = "lambda_functions_info"
const CLOUDFRONT_FUNCTIONS_JSON_TAG = "cloud_front_distributions_info"
const ROUTE53_JSON_TAG = "route_53_info"
const VPC_JSON_TAG = "vpc_info"



const allAwsResources = GetAllAwsResourceData()

const isS3Bucket = (resourceType) => {
    return resourceType === S3_BUCKETS_JSON_TAG
}

const isEc2InstancesInfo = (resourceType) => {
    return resourceType === EC2_INSTANCES
}

const isRestApiGatewayInfo = (resourceType) => {
    return resourceType === REST_API_GATEWAY_INFO_V1
}

const isLambdaFunctionInfo = (resourceType) => {
    return resourceType === LAMBDA_FUNCTIONS_JSON_TAG
}

const isCloudFrontInfo = (resourceType) => {
    return resourceType === CLOUDFRONT_FUNCTIONS_JSON_TAG
}

const isRoute53Info = (resourceType) => {
    return resourceType === ROUTE53_JSON_TAG
}

const isVpcInfo = (resourceType) => {
    return resourceType === VPC_JSON_TAG
}

let S3_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let EC2_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let REST_API_GATEWAY_V1_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let LAMBDA_FUNCTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let CLOUDFRONT_DISTRIBUTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let ROUTE53_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null
let VPC_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = null


let isInitResourceDisplayInfoLookupTable = false
const initResourceDisplayInfoLookupTable  = () => {
    if (isInitResourceDisplayInfoLookupTable)
        return

    allAwsResources.map(
        (resourceInfo, index) => {
            if(resourceInfo["enabled"] === "true") {
                let resourceDetails = resourceInfo["resource_details"]
                if (resourceDetails.group === 'storage' && resourceDetails.subgroup_1 === 's3') {
                    S3_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'compute' && resourceDetails.subgroup_1 === 'ec2') {
                    EC2_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'networkingandcontentdelivery' && resourceDetails.subgroup_1 === 'apigateway') {
                    REST_API_GATEWAY_V1_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'compute' && resourceDetails.subgroup_1 === 'lambda') {
                    LAMBDA_FUNCTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'security_identity_and_compliance' && resourceDetails.subgroup_1 === 'cloudfront') {
                    CLOUDFRONT_DISTRIBUTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'networking_and_content_delivery' && resourceDetails.subgroup_1 === 'route53') {
                    ROUTE53_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }
                else if (resourceDetails.group === 'networking_and_content_delivery' && resourceDetails.subgroup_1 === 'vpc') {
                    VPC_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ = {...resourceInfo, node_creator: EXTERNAL_RESOURCE_CREATOR}
                }

            }
        }
    )

}


export const handleAWSCloudResourceCompose = (cloudResourcesListObj) => {

    initResourceDisplayInfoLookupTable()

    // console.log("handleAWSCloudResourceCompose")
    // console.log(cloudResourcesListObj[RESOURCE_LIST])
    let resourcesList = cloudResourcesListObj[RESOURCE_LIST]

    resourcesList.map(
        (resourceInfoObj, index) => {
            // console.log(resourceInfoObj)
            let resourceType = resourceInfoObj[RESOURCE_TYPE_TAG]
            composeResourceOfType(resourceType, resourceInfoObj)
        }
    )

}

const composeResourceOfType = (resourceType, resourceInfoObj) => {

    if(isS3Bucket(resourceType)) {
        composeS3BucketsDiagramElements(resourceInfoObj)
    }
    else if(isEc2InstancesInfo(resourceType)) {
        composeEc2InstancesDiagramElements(resourceInfoObj)
    }
    else if(isRestApiGatewayInfo(resourceType)) {
        composeRestApiGatewayDiagramElements(resourceInfoObj)
    }
    else if (isLambdaFunctionInfo(resourceType)) {
        composeLambdaFunctionDiagramElements(resourceInfoObj)
    }
    else if (isCloudFrontInfo(resourceType)) {
        composeCloudFrontDiagramElements(resourceInfoObj)
    }
    else if (isRoute53Info(resourceType)) {
        composeRoute53DiagramElements(resourceInfoObj)
    }
    else if (isVpcInfo(resourceType)) {
        composeVpcDiagramElements(resourceInfoObj)
    }
    else {
        console.log("AwsResourcesComposer.js: composeResourceOfType unhandled resource type " + " " +resourceType)
    }
}

const composeVpcDiagramElements = (resourceInfoObj) => {
    const getNodeName = (resourceInfo) => {
        let vpc_id = resourceInfo.vpc_id
        let vpc_name = resourceInfo.vpc_name
        return vpc_id + ":\n" + vpc_name
    }

    composeDiagramElementsForResource(resourceInfoObj, VPC_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ, getNodeName)
}

const composeRoute53DiagramElements = (resourceInfoObj) => {
    const getNodeName = (resourceInfo) => {
        return resourceInfo.hosted_zone_name
    }

    composeDiagramElementsForResource(resourceInfoObj, ROUTE53_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ, getNodeName)
}


const  composeDiagramElementsForResource = (resourceInfoObj, IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ, getNodeNameFunction) => {
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            let _nodeName = getNodeNameFunction(resourceInfo)
            let msg = {
                ...IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: _nodeName
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )
}

const composeCloudFrontDiagramElements = (resourceInfoObj) => {
    // console.log(resourceInfoObj)
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            // console.log(resourceInfo)
            let distribution_domain_name = resourceInfo.distribution_domain_name
            let distribution_alias_name = ""

            if ("distribution_alias_name" in resourceInfo) {
                distribution_alias_name = resourceInfo.distribution_alias_name
                distribution_domain_name += "\n" + distribution_alias_name
            }


            let msg = {
                ...CLOUDFRONT_DISTRIBUTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: distribution_domain_name
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )
}

const composeLambdaFunctionDiagramElements = (resourceInfoObj) => {

    // console.log(resourceInfoObj)
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            let lambda_function_name = resourceInfo.name
            let msg = {
                ...LAMBDA_FUNCTION_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: lambda_function_name
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )
}

const composeRestApiGatewayDiagramElements = (resourceInfoObj) => {
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            // console.log(resourceInfo)
            let restApiName = resourceInfo.name
            let msg = {
                ...REST_API_GATEWAY_V1_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: restApiName
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )

}

const composeS3BucketsDiagramElements = (resourceInfoObj) => {
    // console.log("composeS3BucketsDiagramElements")
    // console.log(resourceInfoObj)
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            // console.log(resourceInfo)
            let s3BucketName = resourceInfo.Name
            let msg = {
                ...S3_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: s3BucketName
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )
}

const INSTANCE_ID_TAG = 'instance_id'
const composeEc2InstancesDiagramElements = (resourceInfoObj) => {
    // console.log("composeEc2InstancesDiagramElements")
    const resourceInfoDataList =  resourceInfoObj.resource_info_data

    resourceInfoDataList.map(
        (resourceInfo, index) => {
            let ec2InstanceId = "EC2: " + resourceInfo[INSTANCE_ID_TAG]
            let msg = {
                ...EC2_IMMUTABLE_RESOURCE_DISPLAY_INFO_OBJ,
                nodeName: ec2InstanceId
            }
            sendMessageCreateImmutableResourceNode(msg)
        }
    )
}

//
//
