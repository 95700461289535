import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import User from '../images/user.png';
import '../styles/global.css';
import { USER_LOGOUT } from './statemanagement/ActionsList';
import { DispatchAction } from './statemanagement/store';

function UserProfile() {
  const history = useHistory();
  const handleLogout = () => {
    DispatchAction(USER_LOGOUT, {
      what: USER_LOGOUT,
    });
    history.push('/home');
  };

  return (
    <div className='profile-details'>
      <img alt='' src={User} />
      <NavDropdown title='' id='basic-nav-dropdown'>
        <NavDropdown.Item as={Link} Link to={`/settings/profile`} className='nav-link'>
          My Profile
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} Link to={`/settings`} className='nav-link'>
          AWS Keys
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} Link to={`/settings/billing-plans`} className='nav-link'>
          Billings & Payments
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} Link to={`/resource-maker`} className='nav-link'>
          Dashboard
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} Link to={`/settings/product-configuration`} className='nav-link'>
          Product Configuration
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => handleLogout()} className='nav-link'>
          Signout
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default UserProfile;
