import {
  AddChildToParent,
  AddToChildInfoMap,
  GetNewNode,
  GetNewNodeForResourceDetailsItem,
  GetNewNodeFromDiffItem,
  GetVirtualNode,
  SetDisplayNameForNode,
  SetDisplayType,
  SetResourceDetailsInfo,
  SetTrailPathIndexRange,
} from './NodeDefUtils';
import {
  GetCloudResourceId,
  GetDisplayName,
  GetIconPath,
  GetNodeIsContainsDiffDetailsChildren,
  GetParentNameTrailPathIndex,
  GetParentNodeType,
  GetVirtualNodeTypesDefinition,
} from './NodesDefinition';
import uniqid from 'uniqid';

const createVirtualParentNode = (nodeType) => {
  let tempParentVirtualNode = GetVirtualNode(nodeType, GetDisplayName(nodeType), GetCloudResourceId(nodeType));
  return tempParentVirtualNode;
};

const handleOnParentTypeAvailable = (parentInfoMap, renderList, parentNodeType, tmpVirtualNode) => {
  let parentNode = parentInfoMap[parentNodeType];
  if (!parentNode) {
    let currentNode = createVirtualParentNode(parentNodeType);
    AddNodeToRenderList(renderList, currentNode);
    addToParentInfoMap(parentInfoMap, currentNode.NodeType, currentNode, 'handleOnParentTypeAvailable');
    parentNode = parentInfoMap[parentNodeType];
  }
  AddChildToParent(parentNode, tmpVirtualNode);
  console.log({ err: true, parentInfoMap });
};

const addToParentInfoMap = (parentInfoMap, key, node, who) => {
  parentInfoMap[key] = node;
};

const AddVirtualNodes = (renderList) => {
  let nodesDefinitionList = GetVirtualNodeTypesDefinition();

  let parentInfoMap = {};

  for (const nodeDefinitionItem of nodesDefinitionList) {
    const nodeType = nodeDefinitionItem.nodeType;
    const parentNodeType = GetParentNodeType(nodeType);

    if (Object.keys(parentInfoMap).includes(nodeType)) {
      continue;
    }

    let nodeDefinition = nodeDefinitionItem.nodeDefinition;
    let tmpVirtualNode = GetVirtualNode(nodeType, nodeDefinition.DisplayName, nodeDefinition.CloudResourceId);
    addToParentInfoMap(parentInfoMap, nodeType, tmpVirtualNode, 'AddVirtualNodes - line 72');

    if (parentNodeType) {
      handleOnParentTypeAvailable(parentInfoMap, renderList, parentNodeType, tmpVirtualNode);
      continue;
    }

    AddNodeToRenderList(renderList, tmpVirtualNode);
  }

  return parentInfoMap;
};

const createParentNodeForChild = (childNode, diffType, parentInfoMap) => {
  let nodeId = uniqid();
  const parentNodeType = GetParentNodeType(childNode.NodeType);
  if (!parentNodeType) return;
  let nodeDisplayName = GetDisplayName(parentNodeType);
  let cloudResourceId = GetCloudResourceId(parentNodeType);

  let parentNode = GetNewNode(nodeId, parentNodeType, diffType, nodeDisplayName, cloudResourceId, GetIconPath(parentNodeType));
  return parentNode;
};

const createOverviewParentNode = (childNode, diffType, parentInfoMap) => {
  return createParentNodeForChild(childNode, diffType, parentInfoMap);
};

const handleOverviewItem = (diffItem, parentInfoMap) => {
  let tmpNode = GetNewNodeFromDiffItem(diffItem);
  SetResourceDetailsInfo(tmpNode, diffItem);
  const parentNodeType = GetParentNodeType(tmpNode.NodeType);
  let parentNode = null;
  if (Object.keys(parentInfoMap).includes(parentNodeType)) {
    parentNode = parentInfoMap[parentNodeType];
  } else {
    let diffType = diffItem.diff_type;
    parentNode = createOverviewParentNode(tmpNode, diffType, parentInfoMap);
  }
  AddChildToParent(parentNode, tmpNode);
  AddToChildInfoMap(parentNode, tmpNode.NodeDisplayName, tmpNode);
};

const handleResourceDetailsItem = (diffItem, parentInfoMap) => {
  const resourceDetailItemNode = GetNewNodeForResourceDetailsItem(diffItem);
  SetResourceDetailsInfo(resourceDetailItemNode, diffItem);
  const parentType = GetParentNodeType(resourceDetailItemNode.NodeType);
  const resourceOverviewItemParentType = GetParentNodeType(parentType);

  const virtualNode = parentInfoMap[resourceOverviewItemParentType];
  console.log({ virtualNode, resourceDetailItemNode });

  const parentNameTrailPathIndex = GetParentNameTrailPathIndex(resourceDetailItemNode.NodeType);

  const parentId = GetParentIdForResourceDetailsItem(diffItem, parentNameTrailPathIndex);

  let resourceOverviewParentNode;
  if (!(parentId in virtualNode.ChildInfoMap)) {
    resourceOverviewParentNode = createParentNodeForChild(resourceDetailItemNode, 'ModifiedItem', parentInfoMap);
    SetDisplayNameForNode(resourceOverviewParentNode, parentId);
    SetDisplayType(resourceOverviewParentNode, 'ResourceOverviewItem');
    AddToChildInfoMap(virtualNode, parentId, resourceOverviewParentNode);
    AddChildToParent(virtualNode, resourceOverviewParentNode);
  } else {
    resourceOverviewParentNode = virtualNode.ChildInfoMap[parentId];
  }
  AddChildToParent(resourceOverviewParentNode, resourceDetailItemNode);
};

const GetParentIdForResourceDetailsItem = (diffItem, parentNameTrailPathIndex) => {
  return diffItem.trail_path[parentNameTrailPathIndex];
};

export const GetDiffUiInfo = (diffData) => {
  let renderList = GetNewRenderList();
  let diffDetailsMap = {};
  let diffsList = diffData?.DiffItemsList?.reverse();
  let parentInfoMap = AddVirtualNodes(renderList);

  diffsList?.forEach((diffItem) => {
    let resourceType = diffItem.resource_type;
    let IsContainsDiffDetailsChildren = GetNodeIsContainsDiffDetailsChildren(resourceType);
    if (IsContainsDiffDetailsChildren) {
      handleOverviewItem(diffItem, parentInfoMap);
    } else {
      handleResourceDetailsItem(diffItem, parentInfoMap);
    }
  });
  console.log({
    diffDetailsMap: diffDetailsMap,
    renderList: renderList,
  });
  return {
    diffDetailsMap: diffDetailsMap,
    renderList: renderList,
  };
};

const AddNodeToRenderList = (renderList, node) => {
  renderList.left = [...renderList.left, node];
};

const GetNewRenderList = () => {
  return {
    left: [],
    right: [],
    onSelected: [],
  };
};
