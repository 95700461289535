import { APIGATEWAY, EC2, LAMBDA, RDS, SECRETS_MANAGER } from '../ResourceEditorCanvas';
import {
  LambdaContextBarContentHandler,
} from '../AwsContextBarHandlersImpl/LambdaContextBarHandlers';
import { ApiGatewayContextHandler } from '../AwsContextBarHandlersImpl/ApiGatewayContextHandler';

import { SecretsManagerContextBarHandler } from '../AwsContextBarHandlersImpl/SecretsManagerContextBarHandler';
import { S3ContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/S3ContextBarHandler';
import { VpcContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/VpcContextBarHandler';
import { TransitGatewayContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/TransitGatewayContextBarHandler';
import { NatGatewayContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/NatGatewayContextBarHandler';
import { InternetGatewayContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/InternetGatewayContextBarHandler';
import { CloudfrontContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/CloudfrontContextBarHandler';
import { ApiGatewayContextShowInfoBarHandler, ApiGatewayContextAssetHandler } from '../AwsContextBarHandlersImpl/ApiGatewayContextBarHandler';
import { VpnGatewayContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/VpnGatewayContextBarHandler';
import { Route53GatewayContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/Route53ContextBarHandler';
import { SnsContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/SnsContextBarHandler';
import { SqsContextShowInfoBarHandler, SqsContextAssetHandler } from '../AwsContextBarHandlersImpl/SqsContextBarHandler';
import { PeeringConnectionContextBarHandler } from '../AwsContextBarHandlersImpl/PeeringConnectionContextBarHandler';
import { LoadBalancerV2ContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/LoadBalancerV2ContextBarHandler';
import { IamContextShowInfoBarHandler, IamRolePoliciesContextShowInfoBarHandler, IamRolesListContextShowInfoBarHandler, IamUserAttachedPoliciesContextShowInfoBarHandler, IamUserInlinePoliciesContextShowInfoBarHandler, IamUsersResourcesContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/IamContextBarHandler';
import { ElasticIPContextBarHandler, ElasticLoadBalancerContextBarHandler, ElasticLoadBalancerV2ContextBarHandler } from '../AwsContextBarHandlersImpl/ElasticContextBarHandler';
import { EcsContextBarHandler } from '../AwsContextBarHandlersImpl/EcsContextBarHandler';
import { NetworkInterfaceContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/NetworkInterfaceContextBarHandler';
import { RouteTableContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/RouteTableContextBarHandler';
import { SubnetContextShowInfoBarHandler } from '../AwsContextBarHandlersImpl/SubnetContextBarHandler';

export const EC2_RESOURCE_INFO_SHOW_CONTEXT = 'ec2_resource_info_show_context';
export const EC2_INSTANCE_RESOURCE_INFO_SHOW_CONTEXT = 'ec2_instance_resource_info_show_context';
export const S3_RESOURCE_INFO_SHOW_CONTEXT = 's3_resource_info_show_context';
export const VPC_RESOURCE_INFO_SHOW_CONTEXT = 'vpc_resource_info_show_context';
export const TRANSIT_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT = 'transit_gateway_resource_info_show_context';
export const NAT_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT = 'nat_gateway_resource_info_show_context';
export const NETWORK_INTERFACE_RESOURCE_INFO_SHOW_CONTEXT = 'network_interface_resource_info_show_context';
export const INTERNET_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT = 'internet_gateway_resource_info_show_context';
export const CLOUDFRONT_RESOURCE_INFO_SHOW_CONTEXT = 'cloudfront_resource_info_show_context';
export const API_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT = 'api_resource_info_show_context';
export const IAM_RESOURCE_INFO_SHOW_CONTEXT = 'iam_resource_info_show_context';
export const IAM_ROLE_POLICIES_RESOURCE_INFO_SHOW_CONTEXT = 'iam_role_policies_resource_show_context';
export const IAM_ROLE_LIST_RESOURCE_INFO_SHOW_CONTEXT = 'iam_roles_list_show_context';
export const IAM_USER_ATTACHED_POLICIES_RESOURCE_INFO_SHOW_CONTEXT = 'iam_user_attached_policies_show_context';
export const IAM_USER_INLINE_POLICIES_RESOURCE_INFO_SHOW_CONTEXT = 'iam_user_inline_policies_show_context';
export const IAM_USERS_RESOURCES_INFO_SHOW_CONTEXT = 'iam_users_resource_show_context';
export const ELASTIC_IP_RESOURCE_INFO_SHOW_CONTEXT = 'elastic_ip_resource_info_show_context';
export const ELASTIC_LOAD_BALANCER_RESOURCE_INFO_SHOW_CONTEXT = 'elastic_load_balancer_resource_info_show_context';
export const ELASTIC_LOAD_BALANCER_V2_RESOURCE_INFO_SHOW_CONTEXT = 'elastic_load_balancer_v2_resource_info_show_context';
export const ECS_RESOURCE_INFO_SHOW_CONTEXT = 'ecs_resource_info_show_context';
export const VPN_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT = 'vpn_gateway_resource_info_show_context';
export const ROUTE_TABLE_RESOURCE_INFO_SHOW_CONTEXT = 'route_table_resource_info_show_context';
export const ROUTE53_RESOURCE_INFO_SHOW_CONTEXT = 'route53_node_resource_info_show_context';
export const SUBNET_RESOURCE_INFO_SHOW_CONTEXT = 'subnet_node_resource_info_show_context';
export const RDS_RESOURCE_INFO_SHOW_CONTEXT = 'rds_resource_info_show_context';
export const LAMBDA_RESOURCE_INFO_SHOW_CONTEXT = 'lambda_resource_info_show_context';
export const SNS_RESOURCE_INFO_SHOW_CONTEXT = 'sns_resource_info_show_context';
export const SQS_RESOURCE_INFO_SHOW_CONTEXT = 'sqs_resource_info_show_context';
export const PEERING_CONECTION_RESOURCE_INFO_SHOW_CONTEXT = 'peering_connection_resource_info_show_context';

export const SetAwsResourceHandlerForAutoDiagramcontext = (
  contextContent,
  setContextContent,
  contextBarInfoPayload,
  currentContextBarContent
) => {
  if (!contextBarInfoPayload.node_data) {
    return;
  }

  let nodeData = contextBarInfoPayload.node_data;

  if (!nodeData.nodeContextInfo) {
    return;
  }

  if (nodeData.nodeContextInfo.resource_context_type === VPC_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: VpcContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  

  if (nodeData.nodeContextInfo.resource_context_type === S3_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: S3ContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === TRANSIT_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: TransitGatewayContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === NAT_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: NatGatewayContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === INTERNET_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: InternetGatewayContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === NETWORK_INTERFACE_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: NetworkInterfaceContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === CLOUDFRONT_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: CloudfrontContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === API_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: ApiGatewayContextShowInfoBarHandler,
      // assetHandlerFunc: ApiGatewayContextAssetHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === ELASTIC_IP_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: ElasticIPContextBarHandler,
    }
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === ELASTIC_LOAD_BALANCER_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: ElasticLoadBalancerContextBarHandler,
    }
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === ELASTIC_LOAD_BALANCER_V2_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: ElasticLoadBalancerV2ContextBarHandler,
      // assetHandlerFunc: LoadBalancerV2ContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if(nodeData.nodeContextInfo.resource_context_type === ECS_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: EcsContextBarHandler,
    };
    setContextContent(currentContextBarContent);
  }


  if (nodeData.nodeContextInfo.resource_context_type === VPN_GATEWAY_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: VpnGatewayContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === ROUTE_TABLE_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: RouteTableContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }


  if (nodeData.nodeContextInfo.resource_context_type === ROUTE53_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: Route53GatewayContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }


  if (nodeData.nodeContextInfo.resource_context_type === SNS_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: SnsContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if( nodeData.nodeContextInfo.resource_context_type === IAM_ROLE_POLICIES_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: IamRolePoliciesContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === IAM_ROLE_LIST_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: IamRolesListContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if( nodeData.nodeContextInfo.resource_context_type === IAM_USER_ATTACHED_POLICIES_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: IamUserAttachedPoliciesContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === IAM_USER_INLINE_POLICIES_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: IamUserInlinePoliciesContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === IAM_USERS_RESOURCES_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: IamUsersResourcesContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === SQS_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: SqsContextShowInfoBarHandler,
      // assetHandlerFunc: SqsContextAssetHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === PEERING_CONECTION_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: PeeringConnectionContextBarHandler,
    };
    setContextContent(currentContextBarContent);
  }

  if (nodeData.nodeContextInfo.resource_context_type === SUBNET_RESOURCE_INFO_SHOW_CONTEXT) {
    currentContextBarContent = {
      contextHandlerFunc: SubnetContextShowInfoBarHandler,
    };
    setContextContent(currentContextBarContent);
  }




};

/*

.----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .----------------.
| .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
| |     ____     | || |   ______     | || |    _______   | || |     ____     | || |   _____      | || |  _________   | || |  _________   | || |  _________   | |
| |   .'    `.   | || |  |_   _ \    | || |   /  ___  |  | || |   .'    `.   | || |  |_   _|     | || | |_   ___  |  | || | |  _   _  |  | || | |_   ___  |  | |
| |  /  .--.  \  | || |    | |_) |   | || |  |  (__ \_|  | || |  /  .--.  \  | || |    | |       | || |   | |_  \_|  | || | |_/ | | \_|  | || |   | |_  \_|  | |
| |  | |    | |  | || |    |  __'.   | || |   '.___`-.   | || |  | |    | |  | || |    | |   _   | || |   |  _|  _   | || |     | |      | || |   |  _|  _   | |
| |  \  `--'  /  | || |   _| |__) |  | || |  |`\____) |  | || |  \  `--'  /  | || |   _| |__/ |  | || |  _| |___/ |  | || |    _| |_     | || |  _| |___/ |  | |
| |   `.____.'   | || |  |_______/   | || |  |_______.'  | || |   `.____.'   | || |  |________|  | || | |_________|  | || |   |_____|    | || | |_________|  | |
| |              | || |              | || |              | || |              | || |              | || |              | || |              | || |              | |
| '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
 '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------


 */

export const setAwsResourceHandler = (contextContent, setContextContent, contextBarInfoPayload, subgroup_1, currentContextBarContent) => {
  if (subgroup_1 === LAMBDA) {
    setAwsLambdaContextContentHandler(contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent);
  } else if (subgroup_1 === APIGATEWAY) {
    setAwsApiGatewayContextContentHandler(contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent);
  } else if (subgroup_1 === SECRETS_MANAGER) {
    setAwsSecretsManagerContextContentHandler(contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent);
  } else {
    console.log('No context handler for resource type subgroup_1 ' + ' "' + subgroup_1 + '"');
  }
};

const setAwsSecretsManagerContextContentHandler = (contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent) => {
  console.log('setting setAwsLambdaContextContentHandler to () => {return <p> EC2 handler </p>}');
  currentContextBarContent = {
    contextHandlerFunc: SecretsManagerContextBarHandler,
  };
  setContextContent(currentContextBarContent);
};

const setAwsLambdaContextContentHandler = (contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent) => {
  console.log('setting setAwsLambdaContextContentHandler to () => {return <p> EC2 handler </p>}');
  currentContextBarContent = {
    contextHandlerFunc: LambdaContextBarContentHandler,
  };
  setContextContent(currentContextBarContent);
};

const setAwsApiGatewayContextContentHandler = (contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent) => {
  currentContextBarContent = {
    contextHandlerFunc: ApiGatewayContextHandler,
  };
  setContextContent(currentContextBarContent);
};

//
