import React, { useState } from 'react';
import { Button, Modal, Input, Select, TimePicker, Table, Form } from 'antd';
import moment from 'moment-timezone';
import { startCase } from 'lodash';
import { isMoment } from 'moment/moment';

const { Option } = Select;
const getAllTimezones = () => {
  const timezones = moment.tz.names();
  return timezones;
};

const ScheduleForm = ({ isOpen, setIsOpen, form, handleSubmit }) => {
  const [searchValue, setSearchValue] = useState('');
  const [visibleTimezones, setVisibleTimezones] = useState(5);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmitForm = () => {
    form.validateFields().then((values) => {
      let { schedule_timings } = values;
      let scheduleTimingObj = {};

      Object.keys(schedule_timings).forEach((key) => {
        scheduleTimingObj[key] = isMoment(schedule_timings[key]) ? schedule_timings[key]?.format('HH:mm') : schedule_timings[key];
      });
      handleSubmit({ ...values, schedule_timings: scheduleTimingObj });
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setVisibleTimezones(5); // Reset visible timezones to initial count
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // User has scrolled to the bottom of the dropdown
      setVisibleTimezones((prevCount) => prevCount + 10); // Increase visible timezones count
    }
  };

  const filteredTimezones = getAllTimezones().filter((timezone) => timezone.toLowerCase().includes(searchValue.toLowerCase()));

  const visibleTimezonesOptions = filteredTimezones.slice(0, visibleTimezones).map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  return (
    <Modal width='45vw' title='Create/Update Schedule' visible={isOpen} onCancel={handleCancel} onOk={handleSubmitForm}>
      <Form form={form} layout='vertical'>
        <Form.Item name='schedule_id' label='Name' rules={[{ required: true, message: 'Please enter the name' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='timezone' label='Timezone' rules={[{ required: true, message: 'Please select a timezone' }]}>
          <Select showSearch filterOption={false} onSearch={handleSearch} onPopupScroll={handlePopupScroll} placeholder='Select Timezone'>
            {visibleTimezonesOptions}
          </Select>
        </Form.Item>
        <table>
          <tbody>
            <tr>
              {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                <td key={day}>{startCase(day)}</td>
              ))}
            </tr>
            <tr>
              {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                <td key={day}>
                  <Form.Item name={['schedule_timings', day]}>
                    <TimePicker placeholder='HH:mm' format='HH:mm' />
                  </Form.Item>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Form>
    </Modal>
  );
};

export default ScheduleForm;
