import React from 'react'
import "../../styles/LeftMenu.css";
import {CompanyCopywright} from "../CommonFunctions";
import {DiagramDropdown, DiagramDropdownItem} from "../../styles/ResourceMakerPage";
import {
    CONST_GROUP_BY_ACCOUNT,
    CONST_GROUP_BY_BILLING,
    CONST_BILLING_CURR_CYCLE,
    CONST_BILLING_LAST_CYCLE,
    CONST_BILLING_COST_PREDICTOR,
    CONST_ADVANCED_SEARCH,
    CONST_COST_PLANNER
} from "./CostManagerPage";
import Select , { components }from "react-select";
import {DropdownButton, Dropdown} from "react-bootstrap";
import arrowDownIcon from "../../images/arrow-down.png";
import aws_logo from "../../images/Amazon_Web_Services_Logo.svg";
import { customDropdownStyles } from './styleUtils';

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrowDownIcon}/>
      </components.DropdownIndicator>
    );
};

function LeftMenu(props) {
    const getCompanyAndLogo = () => {
        return CompanyCopywright()
    }

    const getSelectedGroupBy = () => {
        return props.groupBy
    }

    const billingCycle = props.billingCycle
    const accounts = props.accounts
    const selectedAccountIndex = props.selectedAccountIndex

    const getCloudServiceLogo = (accountType) => {
        let imgSrc = aws_logo
        /*        if(accountType === "aws" || accountType === "") {
                    imgSrc = aws_logo
                }*/
        return <img alt="" height="50" src={imgSrc}/>
    }

    const handleAccountClick = (index) => {
        props.setAdvancedSearchSelected(false)
        props.onSelectedAccount(index)
    }

    const getGroupByAccountOptions = () => {
        return  (accounts && <ul className="group-options">
            {
                accounts.map((value, index) => {
                    return <li className={(selectedAccountIndex === index) && !props.isAdvancedSearchSelected ? "selected-group-option" : ''}
                               onClick={() => handleAccountClick(index)}
                               key={"group_options_"+index} >
                        {getCloudServiceLogo()} {accounts[index].name}
                    </li>
                })
            }
            <li className={props.isAdvancedSearchSelected ? "selected-group-option" : ''}
                onClick={() => props.setAdvancedSearchSelected(true)}>
                Advanced Cost Search
            </li>
        </ul>)
    }

    const getOptions = () => {
        switch (getSelectedGroupBy()) {
            case CONST_GROUP_BY_BILLING:
                return <ul className="group-options">
                    <li className={billingCycle === CONST_BILLING_CURR_CYCLE ? "selected-group-option" : ''}
                        onClick={() =>{props.onBillingCycleSelect(CONST_BILLING_CURR_CYCLE)}}>
                        Current Billing Cycle
                    </li>
                    <li className={billingCycle === CONST_BILLING_LAST_CYCLE ? "selected-group-option" : ''}
                        onClick={() =>{props.onBillingCycleSelect(CONST_BILLING_LAST_CYCLE)}}>
                        Last Billing Cycle
                    </li>
                    <li className={billingCycle === CONST_BILLING_COST_PREDICTOR ? "selected-group-option" : ''}
                        onClick={() =>{props.onBillingCycleSelect(CONST_BILLING_COST_PREDICTOR)}}>
                        Cost Predictor
                    </li>
                    <li className={billingCycle === CONST_ADVANCED_SEARCH ? "selected-group-option" : ''}
                        onClick={() =>{props.onBillingCycleSelect(CONST_ADVANCED_SEARCH)}}>
                        Advanced Cost Search
                    </li>
                    <li className={billingCycle === CONST_COST_PLANNER ? "selected-group-option" : ''}
                        onClick={() =>{props.onBillingCycleSelect(CONST_COST_PLANNER)}}>
                        Cost Planner
                    </li>
                </ul>
            case CONST_GROUP_BY_ACCOUNT:
                return getGroupByAccountOptions()
            default:
                return <></>
        }
    }

    const getGroupByOptions = () => {
        const billingOptions = [{label :CONST_GROUP_BY_BILLING, value : CONST_GROUP_BY_BILLING},{label :CONST_GROUP_BY_ACCOUNT, value : CONST_GROUP_BY_ACCOUNT}]
        return (
            <div className="inline-block m-b-15">
                <div className="m-b-5 fs-16">Group By:</div>
                <Select
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => { props.onSelectedDropdown(e) }}
                    options={billingOptions}
                    defaultValue={billingOptions[0]}
                    name="Group By"
                    placeholder="Select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    styles={customDropdownStyles}
                />
            </div>

        )
    }

    return (
        <>
            <div className="left-menu-links">
                {getGroupByOptions()}
                {getOptions()}
                {getCompanyAndLogo()}
            </div>
        </>
    )
}

export default LeftMenu