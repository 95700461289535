import { initializeApp } from 'firebase/app';
//import firebase from "firebase/app"
import "firebase/auth"

const getFirebaseApiKey = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_API_KEY }
const getFirebaseAuthDomain  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_AUTH_DOMAIN }
const getFirebaseProjectId  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_PROJECT_ID }
const getFirebaseStorageBucket  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_STORAGE_BUCKET }
const getFirebaseMessagingSenderId  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_MESSAGING_SENDER_ID }
const getFirebaseAppId  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_APP_ID }
const getFirebaseMeasurementId  = () => { return process.env.REACT_APP_FIREBASE_USER_AUTH_MEASUREMENT_ID }

const firebaseAppObj = initializeApp(
    {
        apiKey: getFirebaseApiKey(),
        authDomain: getFirebaseAuthDomain(),
        projectId: getFirebaseProjectId(),
        storageBucket: getFirebaseStorageBucket(),
        messagingSenderId: getFirebaseMessagingSenderId(),
        appId: getFirebaseAppId(),
        measurementId: getFirebaseMeasurementId()
    }
)

export const PrintFirebaseAppObj = () => {
        console.log(firebaseAppObj)
}

// export const firebaseUserAuth = firebaseAppObj.auth()
// export default firebaseAppObj


// firebaseUserAuth.onAuthStateChanged = (user => {
//         console.log(user)
// })


//
// const app = initializeApp({
//         apiKey: getFirebaseApiKey(),
//         authDomain: getFirebaseAuthDomain(),
//         projectId: getFirebaseProjectId(),
//         storageBucket: getFirebaseStorageBucket(),
//         messagingSenderId: getFirebaseMessagingSenderId(),
//         appId: getFirebaseAppId(),
//         measurementId: getFirebaseMeasurementId()
// })
//
// export const auth = app.default.auth()
// export default app
//

//
//