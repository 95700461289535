export const userLoginHappenedNowReducer = (state, action) => {
  console.log('userLoginHappenedNowReducer');
  console.log('userLoginHappenedNowReducer', { action });
  return {
    ...state,
    currentAction: action,
    userLoginInfo: {
      desc: 'userLoginHappenedNowReducer',
      payload: action.payload,
    },
  };
};

export const userLogoutReducer = (state, action) => {
  console.log('userLogoutReducer', { action });
  return {
    // clear the entire state
    userLoginInfo: null,
    currentAction: action,
  };
};
//
