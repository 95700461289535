import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import { GetLoginCredentialsAsJsonObject, GetUserLoginCredentials, GetUserLoginToken } from './UserCredentials';
import { GetLoginCredentials, getTooltipInfo, RoundUp } from './CommonFunctions';
import useAxios from 'axios-hooks';
import { GetAllAccountsURL, GetAdvancedSearchURL, GetMyProfileUrl } from '../routes';
import Select from 'react-select';
import aws_logo from '../images/Amazon_Web_Services_Logo.svg';
import '../styles/AdvancedSearch.css';
import '../styles/CostManager.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import DatePickerWithIcon from './DatePicker';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { isEmpty } from 'lodash';
const TEXT_INFO_SEARCH_RESULT_COST = 'Resource-Wise Cost Breakup';

const GET_ALL_ACCOUNTS_API = GetAllAccountsURL();
const ADVANCED_SEARCH_API = GetAdvancedSearchURL();

const getCredsV2 = (userCredentials) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: 'get_account_ids',
  };
};

export const getAllAccountsRequestPayloadV2 = () => {
  return getCredsV2(GetLoginCredentialsAsJsonObject());
};

const AdvancedSearch = () => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [account_wise_cost, set_account_wise_cost] = useState([]);
  const [total_cost, set_total_cost] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // const getAllAccountsRequestPayload = () => {
  //     const payload = {
  //         "concerto_user_credentials": GetLoginCredentials(),
  //         "command_to_execute":"get_account_ids"
  //     }
  //     return payload
  // }

  const [{ data }, fetchData] = useAxios(
    {
      url: GET_ALL_ACCOUNTS_API,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: getAllAccountsRequestPayloadV2(),
    },
    { manual: true }
  );

  useEffect(() => {
    fetchData();
  }, []);

  const getSearchPayload = () => {
    const result = selectedOption.map((a) => a.value);
    const _startDate = moment(startDate).format('YYYY-MM-DD');
    const _endDate = moment(endDate).format('YYYY-MM-DD');

    return {
      startDate: _startDate,
      endDate: _endDate,
      accountIds: [...result],
    };
  };

  const getCloudServiceLogo = (accountType) => {
    let imgSrc = aws_logo;
    return <img alt='' height='50' src={imgSrc} />;
  };

  const getAdvancedSearchRequestPayload = () => {
    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), // GetLoginCredentials(),
      query: getSearchPayload(),
      command_to_execute: 'get_cost_details_advance_search',
    };
    return payload;
  };

  const [{ data2, loading, error }, searchAPICall] = useAxios(
    {
      url: ADVANCED_SEARCH_API,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: getAdvancedSearchRequestPayload(),
    },
    { manual: true }
  );

  const onSearchRequest = async () => {
    const url = ADVANCED_SEARCH_API;

    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), //GetLoginCredentials(),
      query: getSearchPayload(),
      command_to_execute: 'get_cost_details_advance_search',
    };

    try {
      const resp = await axios.post(url, payload);
      if (resp && resp.data && resp.data.account_wise_cost) {
        set_account_wise_cost(resp.data.account_wise_cost);
        set_total_cost(resp.data.total_cost);
      }
      return resp;
    } catch (err) {
      console.log('onSearchRequest to ' + url + ' failed');
    }
  };

  const searchHandler = () => {
    onSearchRequest().then();
  };

  const CustomStyle = {
    control: (styles) => ({
      ...styles,
      width: '464px',
      height: '48px',
      background: '#F9FAFB',
      border: '1px solid #EEF6FF',
      borderRadius: '4px',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'black' : 'white',
      overflow: 'hidden',
    }),
  };

  const getAccountsDropdown = () => {
    if (!data) {
      return <Select options={[]} isMulti={true} styles={CustomStyle} />;
    }
    const accountIDs = data.account_ids;
    let dropdownOptions = [];

    accountIDs &&
      accountIDs.map((accountNum) => {
        let tmp = {
          value: accountNum,
          label: accountNum,
        };
        dropdownOptions.push(tmp);
      });
    return (
      <div className='accounts-dropdown'>
        <Select
          options={dropdownOptions}
          classNamePrefix='reactSelect'
          isMulti={true}
          styles={CustomStyle}
          defaultValue={selectedOption}
          onChange={setSelectedOption}
        />
      </div>
    );
  };

  const getTotalCostTable = () => {
    const account = account_wise_cost[0];
    return (
      account_wise_cost.length > 0 && (
        <table className='costTable adv-search-table'>
          <thead>
            <tr>
              <th> </th>
              <th>
                From {account && account.cost_breakup && moment(account.cost_breakup.StartDate).format('YYYY-MMM-DD')}
                &nbsp; To {account && account.cost_breakup && moment(account.cost_breakup.EndDate).format('YYYY-MMM-DD')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='only-row sum-row'>
              <td>Total Cost</td>
              <td> $ {RoundUp(total_cost)}</td>
            </tr>
          </tbody>
        </table>
      )
    );
  };

  const getSearchResultsTable = () => {
    const getResourceWise = (account, resource) => {
      if (
        account &&
        account.cost_breakup &&
        account.cost_breakup.ResourceWiseBreakUp &&
        account.cost_breakup.ResourceWiseBreakUp[resource] !== undefined
      ) {
        return '$ ' + RoundUp(account.cost_breakup.ResourceWiseBreakUp[resource]);
      }
      return 'N/A';
    };
    return (
      <Collapse defaultActiveKey={['1']}>
        {account_wise_cost.map((account, index) => {
          return (
            <CollapsePanel
              header={
                <div className='cost-search-header'>
                  <div>
                    {getCloudServiceLogo()} {account && account.friendly_name} : {account && account.account_name} &nbsp;
                  </div>
                  <div className='table-info-small-font'>
                    {account && account.cost_breakup && moment(account.cost_breakup.StartDate).format('YYYY-MMM-DD')}
                    &nbsp; To {account && account.cost_breakup && moment(account.cost_breakup.EndDate).format('YYYY-MMM-DD')}
                  </div>
                </div>
              }
              key={(index + 1).toString()}
            >
              <table className='costTable adv-search-table' key={account.account_name}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Cost </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(account && account.cost_breakup && account.cost_breakup.ResourceWiseBreakUp).map((resource) => {
                    return (
                      <tr key={resource}>
                        <td>{resource}</td>
                        <td>{getResourceWise(account, resource)}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>Tax</td>
                    <td>$ {RoundUp(account.cost_breakup.Tax)}</td>
                  </tr>
                  <tr className='sum-row'>
                    <td>Total Cost</td>
                    <td>$ {RoundUp(account.cost_breakup.Total)}</td>
                  </tr>
                </tbody>
              </table>
            </CollapsePanel>
          );
        })}
      </Collapse>
    );
  };

  const getContent = () => {
    return (
      <Container className='adv-search-div'>
        <Row>
          <Col>
            <h3 className='page-heading'>Advanced Cost Search</h3>
          </Col>
          <Col>
            <Button className='search-button' disabled={!selectedOption?.length} onClick={searchHandler}>
              SEARCH
            </Button>
          </Col>
        </Row>

        <br />
        <p>Select Account</p>
        {getAccountsDropdown()}
        <br />

        <br />
        <p>Select Date Range</p>

        <Row>
          <div className='date-div'>
            <Row className='date-labels'>
              <Col className='date-label'>From: </Col>
              <Col className='date-label'>To: </Col>
            </Row>
            <Row>
              <Col>
                <DatePickerWithIcon showIcon selectedDate={startDate} setSelectedDate={setStartDate} />
              </Col>
              <Col>
                <DatePickerWithIcon showIcon selectedDate={endDate} setSelectedDate={setEndDate} />
              </Col>
            </Row>
          </div>
        </Row>

        <br />
        <br />

        <Row>
          <Col>{getTotalCostTable()}</Col>
        </Row>

        <Row>
          <Col>{getSearchResultsTable()}</Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <Container>
        <Row>
          <Col>{getContent()}</Col>
        </Row>
      </Container>
    </>
  );
};

export default AdvancedSearch;
