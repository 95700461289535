import {Dropdown} from "react-bootstrap";
import {handleAWSCloudResourceCompose} from "./AWS/AwsResourcesComposer";
import {
    CreateGroupNode,
    CreateGroupNodeWithParams,
    FULL_HTTP_PATH,
    GroupNodeWithinAnotherGroup,
    sendMessageCreateImmutableResourceNodeGroupInsideGroup, sendMessageCreateImmutableResourceNodeInsideGroup,
} from "./ResourceEditorCanvas";
import uniqid from "uniqid";

const  AWS_CLOUD = 'AWS_CLOUD'

const baseURL = "https://concerto-ui-assets.s3.ap-south-1.amazonaws.com/"

export const AWS_RESOURCE_ICONS_BASE_PATH = () => {
    return baseURL
}

const getAwsIconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH() + "cloud/aws/aws.png"
}

const getAwsVpcIconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH() + "cloud/aws/Asset-Package_07302021/Resource-Icons_07302021/Res_Networking-and-Content-Delivery/Res_48_Dark/vpc_64x64.png"
}

const getEc2IconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH() + "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.png"
}

const getAwsEc2InstanceIconPath = () => {
    return AWS_RESOURCE_ICONS_BASE_PATH() + "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.png"
}


export const ComposeCloudResourceDiagram = (cloudResourcesListObj) => {
    // console.log("ComposeCloudResourceDiagram")
    cloudResourcesListObj.map(handleCloudAccountVisualise);
}

const handleCloudAccountVisualise = (cloudAccountObj) => {

    // console.log(cloudAccountObj.service_vendor)
    if (cloudAccountObj.service_vendor == "AWS"){
        return handleAwsCloudAccountVisualise(cloudAccountObj)
    }
}

const getCloudResourcesInfoForAwsAccount = (visualizerObjForAwsAccount) => {
    return visualizerObjForAwsAccount.cloudAccountObjData
}

const getAccountIdFromResourcesDataObject = (visualizerObjForAwsAccount) => {
    return getCloudResourcesInfoForAwsAccount(visualizerObjForAwsAccount).account_info
}

const getVpcResourcesFromResourcesDataObject = (visualizerObjForAwsAccount) => {
    return getCloudResourcesInfoForAwsAccount(visualizerObjForAwsAccount).resources_list.compute_resources.vpc_resources.resource_info_data
}

const getEc2ResourcesFromResourcesDataObject = (visualizerObjForAwsAccount) => {
    return getCloudResourcesInfoForAwsAccount(visualizerObjForAwsAccount).resources_list.compute_resources.ec2_resources.resource_info_data
}

const getLambdaResourcesFromResourcesDataObject = (visualizerObjForAwsAccount) => {
    return getCloudResourcesInfoForAwsAccount(visualizerObjForAwsAccount).resources_list.compute_resources.lambda_resources.resource_info_data
}

const getDefaultVpcNodeUniqueId = (visualizerObjForAwsAccount) =>  {
    return visualizerObjForAwsAccount.defaultVpcNodeUniqueId
}

const setDefaultVpcNodeUniqueId = (visualizerObjForAwsAccount, defaultVpcNodeUniqueId) =>  {
    return visualizerObjForAwsAccount.defaultVpcNodeUniqueId = defaultVpcNodeUniqueId
}

const setAccountGroupNodeUniqueId = (visualizerObjForAwsAccount, accountGroupNodeUniqueId) => {
    visualizerObjForAwsAccount.accountGroupNodeUniqueId = accountGroupNodeUniqueId
}

const getAccountGroupNodeUniqueId = (visualizerObjForAwsAccount) => {
    return visualizerObjForAwsAccount.accountGroupNodeUniqueId
}

const createMsg = (iconPath, nodeNameStr) => {
   return {full_http_path: iconPath, nodeName: nodeNameStr}
}

const handleAwsCloudAccountVisualise = (cloudAccountObj) => {
    let visualizerObjForAwsAccount = {
        cloudAccountObjData: cloudAccountObj
    }

    visualizeAwsAccount(visualizerObjForAwsAccount)
    visualizeVpcs(visualizerObjForAwsAccount)
    visualizeComputeResources(visualizerObjForAwsAccount)
    return visualizerObjForAwsAccount
}

const getParentVpcUniqueNodeIdForEc2Instance = (visualizerObjForAwsAccount) => {
    return getDefaultVpcNodeUniqueId(visualizerObjForAwsAccount)
}

const visualizeEc2Resources = (visualizerObjForAwsAccount) => {
    let ec2ResourcesData = getEc2ResourcesFromResourcesDataObject(visualizerObjForAwsAccount)

    ec2ResourcesData.map(ec2Info => {
        let instanceId = ec2Info.InstanceId
        let parentVpcId = getParentVpcUniqueNodeIdForEc2Instance(visualizerObjForAwsAccount)
        let msg = createMsg(getAwsEc2InstanceIconPath(), instanceId)

        let computeGroup = visualizerObjForAwsAccount.computeGroups[parentVpcId]
        let computeGroupNodeUniqueId = computeGroup.computeGroupNodeUniqueId

        sendMessageCreateImmutableResourceNodeInsideGroup(msg, computeGroupNodeUniqueId)
    });
}

const visualizeLambdaResources = (visualizerObjForAwsAccount) => {

}

const visualizeComputeResourcesForAllVpcs = (visualizerObjForAwsAccount) => {

    let vpcsNodeInfoMap = visualizerObjForAwsAccount.vpcs
    // console.log(vpcsNodeInfoMap)

    let k = getAccountGroupNodeUniqueId(visualizerObjForAwsAccount)

    visualizerObjForAwsAccount.computeGroups = {}

    for (let m in vpcsNodeInfoMap) {
        let vpcNodeInfo = vpcsNodeInfoMap[m]
        let vpcUniqueNodeId = vpcNodeInfo.uniqueNodeId
        let msg = createMsg(getAwsVpcIconPath(), "Compute Group")
        // console.log(vpcUniqueNodeId)

        let awsVpcId = vpcNodeInfo.awsVpcId
        let computeGroupNodeUniqueId = sendMessageCreateImmutableResourceNodeGroupInsideGroup(msg, vpcUniqueNodeId)
        visualizerObjForAwsAccount.computeGroups[vpcUniqueNodeId] = {computeGroupNodeUniqueId: computeGroupNodeUniqueId}
        visualizerObjForAwsAccount.computeGroups[awsVpcId] = {computeGroupNodeUniqueId: computeGroupNodeUniqueId}
    }
}

const visualizeComputeResources = (visualizerObjForAwsAccount) => {
    visualizeComputeResourcesForAllVpcs(visualizerObjForAwsAccount)
    visualizeEc2Resources(visualizerObjForAwsAccount)
    visualizeLambdaResources(visualizerObjForAwsAccount)
}

const visualizeAwsAccount = (visualizerObjForAwsAccount) => {
    const accountId = getAccountIdFromResourcesDataObject(visualizerObjForAwsAccount)
    let msg = {full_http_path: getAwsIconPath()}
    let accountGroupNodeUniqueId  = CreateGroupNodeWithParams(uniqid(),"AWS account " + accountId, msg)

    setAccountGroupNodeUniqueId(visualizerObjForAwsAccount, accountGroupNodeUniqueId)
}

const visualizeVpcs = (visualizerObjForAwsAccount) => {
    let vpcResourcesInfo = getVpcResourcesFromResourcesDataObject(visualizerObjForAwsAccount)
    // console.log(vpcResourcesInfo)

    let accountGroupNodeUniqueId = getAccountGroupNodeUniqueId(visualizerObjForAwsAccount)

    // console.log(accountGroupNodeUniqueId)

    visualizerObjForAwsAccount.vpcs = {}

    vpcResourcesInfo.map(vpc => {
        // console.log(vpc)
        let vpcId = vpc.VpcId
        let nodeNameStr = vpcId
        if (vpc.IsDefault) {
            nodeNameStr = vpcId + "(default)"
            setDefaultVpcNodeUniqueId(visualizerObjForAwsAccount, vpcId)
        }
        let msg = {FULL_HTTP_PATH: getAwsVpcIconPath(), nodeName: nodeNameStr}
        let vpcNodeUniqId = sendMessageCreateImmutableResourceNodeGroupInsideGroup(msg, accountGroupNodeUniqueId)

        visualizerObjForAwsAccount.vpcs[vpcId] = {uniqueNodeId: vpcNodeUniqId, awsVpcId: vpcId }
    });
}

//
//
