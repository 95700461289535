import React, { useState } from 'react';
import { Calendar } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/DatePicker.css';

const DatePickerWithIcon = ({ selectedDate, setSelectedDate }) => {
  return (
    <div className='datepicker-container'>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat='MM/dd/yyyy'
        placeholderText='Select date'
        id='dateCurrPicker'
      />
      <label htmlFor='dateCurrPicker'>
        <span className='calendar-icon'>
          <Calendar />
        </span>
      </label>
    </div>
  );
};

export default DatePickerWithIcon;
