import React from 'react';
import './Table.css'; // Import the external CSS file

const getLeftVal = (ResourceDetailsInfo) => {
  switch (ResourceDetailsInfo.diff_type) {
    case 'MissingItem':
      return JSON.stringify(ResourceDetailsInfo.ResourceDetails);

    case 'ModifiedItem':
      return JSON.stringify(ResourceDetailsInfo?.ResourceDetails?.leftVal);

    default:
      return '-';
  }
};

const getRightVal = (ResourceDetailsInfo) => {
  switch (ResourceDetailsInfo.diff_type) {
    case 'AddedItem':
      return JSON.stringify(ResourceDetailsInfo.ResourceDetails);

    case 'ModifiedItem':
      return JSON.stringify(ResourceDetailsInfo?.ResourceDetails?.rightVal);

    default:
      return '-';
  }
};

const getChip = (DiffType) => {
  console.log({ DiffType });
  switch (DiffType) {
    case 'AddedItem':
      return <span className='add-chip'>Add</span>;
    case 'ModifiedItem':
      return <span className='mod-chip'>Mod</span>;
    case 'MissingItem':
      return <span className='del-chip'>Del</span>;

    default:
      return '';
  }
};

const Table = ({ activeNodeDetails }) => {
  console.log({});

  const headings = ['Change Type', 'Path', 'Old Value', 'New Value'];
  return (
    <div className='diff-table'>
      <div className='table-container'>
        <table className='table'>
          <thead>
            <tr>
              {headings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {activeNodeDetails?.ChildNodes?.map(({ TrailPathIndexRange, ResourceDetailsInfo }, rowIndex) => {
              let path = '-';
              if (TrailPathIndexRange) {
                try {
                  path = ResourceDetailsInfo?.trail_path?.slice(TrailPathIndexRange[0], TrailPathIndexRange[1] + 1).join('/');
                } catch (error) {
                  path = '-';
                }
              }
              return (
                <tr key={rowIndex}>
                  <td>
                    <div>
                      {ResourceDetailsInfo?.diff_type} {getChip(ResourceDetailsInfo?.diff_type)}
                    </div>
                    <div>{ResourceDetailsInfo?.resource_type}</div>
                  </td>
                  <td>{path}</td>
                  <td>{getLeftVal(ResourceDetailsInfo)}</td>
                  <td>{getRightVal(ResourceDetailsInfo)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
