import React from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './HeaderWithBg.module.css';
import SlicesLeftBg from '../../assets/SlicesLeft.png';

const HeaderWithBg = ({ logo, title }) => {
  return (
    <div className={classes.HeaderWithBg} style={{ backgroundImage: `url(${logo})` }}>
      <Navbar />
      <div className={classes.SlicesLeft}>
        <img src={SlicesLeftBg} alt='' />
      </div>
      <div className={classes.Title}>{title}</div>
    </div>
  );
};

export default HeaderWithBg;
