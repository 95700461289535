import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { GetAwsResourceIconPath } from '../../../../routes';

const AwsNodesDefinition = {
  //
  AwsAccountNodeVirtual: {
    AddedItem: {},
    IsVirtual: true,
    IconPath: 'https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png',
    DisplayName: 'AWS ACCOUNT',
    CloudResourceId: '202828',
    ParentNodeType: null,
    DisplayType: 'VirtualGroupItem',
  },
  AwsApiGatewayVirtual: {
    AddedItem: {},
    IsVirtual: true,
    IconPath: 'https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png',
    DisplayName: 'AWS API GATEWAY',
    CloudResourceId: '202828',
    ParentNodeType: 'AwsAccountNodeVirtual',
    DisplayType: 'VirtualGroupItem',
  },
  //
  //
  // API GATEWAY
  //
  //
  ApiGateway: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: true,
    NodeDisplayNameKey: 'RestApiName',
    NodeIdKey: 'RestApiId',
    DisplayType: 'ResourceOverviewItem',
    ParentNodeType: 'AwsApiGatewayVirtual',
    IconPath: GetAwsResourceIconPath(
      'cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-API-Gateway_64.png'
    ),
  },
  ApiGatewayStage: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: false,
    ParentNodeType: 'ApiGateway',
    DisplayType: 'ResourceDetailsItem',
    IconPath: GetAwsResourceIconPath('cloud/aws/aws.png'),
    ParentNameTrailPathIndex: 0,
  },
  ApiGatewayResource: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: false,
    ParentNodeType: 'ApiGateway',
    DisplayType: 'ResourceDetailsItem',
    ParentNameTrailPathIndex: 0,
    IconPath: GetAwsResourceIconPath('cloud/aws/aws.png'),
  },
  ApiGatewayResourceMethod: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: false,
    ParentNodeType: 'ApiGateway',
    DisplayType: 'ResourceDetailsItem',
    ParentNameTrailPathIndex: 0,
    IconPath: GetAwsResourceIconPath('cloud/aws/aws.png'),
    TrailPathIndexRange: [0, 2],
  },

  //
  //
  //
  //
  AwsEc2InstanceVirtual: {
    AddedItem: {},
    IsVirtual: true,
    IconPath: 'https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png',
    DisplayName: 'AWS EC2 INSTANCE',
    CloudResourceId: '766202828',
    ParentNodeType: 'AwsAccountNodeVirtual',
    DisplayType: 'VirtualGroupItem',
  },
  Ec2Instance: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: true,
    NodeDisplayNameKey: 'InstanceID',
    NodeIdKey: 'InstanceID',
    DisplayType: 'ResourceOverviewItem',
    ParentNodeType: 'AwsEc2InstanceVirtual',
    IconPath: GetAwsResourceIconPath(
      'cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.svg'
    ),
  },
  Ec2InstanceTag: {
    AddedItem: {},
    IsVirtual: false,
    IsContainsDiffDetailsChildren: false,
    ParentNodeType: 'Ec2Instance',
    DisplayType: 'ResourceDetailsItem',
    IconPath: GetAwsResourceIconPath('cloud/aws/aws.png'),
    ParentNameTrailPathIndex: 1,
    TrailPathIndexRange: [0, 1],
  },
};

const getNodesDefinition = () => {
  return AwsNodesDefinition;
};

export const GetIsNodeVirtual = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];
  if (!nodeDef) {
    console.log('GetIsNodeVirtual !nodeDef');
    return false;
  }
  console.log('GetIsNodeVirtual VALID entry');
  return nodeDef.IsVirtual;
};

export const GetNodeDisplayNameKey = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];
  if (!nodeDef) {
    console.log('GetNodeDisplayNameKey !nodeDef');
    return false;
  }
  console.log('GetNodeDisplayNameKey VALID entry');
  return nodeDef.NodeDisplayNameKey;
};

export const GetNodeIdKey = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];
  if (!nodeDef) {
    return 'TheBadKey ' + generateUniqueID();
  }
  return nodeDef.NodeIdKey;
};

export const GetNodeIsContainsDiffDetailsChildren = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];
  if (!nodeDef) {
    return false;
  }
  return nodeDef.IsContainsDiffDetailsChildren;
};

export const GetParentNodeType = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];
  if (!nodeDef) {
    return ' BAD PARENT ';
  }
  return nodeDef.ParentNodeType;
};

export const VirtualNodes = () => {};

export const GetVirtualNodeTypesDefinition = () => {
  let nodesDefinitionMap = getNodesDefinition();

  console.log(typeof nodesDefinitionMap);

  let virtualDefinitionsList = [];

  for (let key in nodesDefinitionMap) {
    let nodeDefinition = nodesDefinitionMap[key];

    if (nodeDefinition.IsVirtual === true) {
      let nodeDefinitonItem = {
        nodeType: key,
        nodeDefinition: nodeDefinition,
      };

      virtualDefinitionsList.push(nodeDefinitonItem);
    }
  }

  return virtualDefinitionsList;
};

export const GetDisplayName = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];

  if (!nodeDef) {
    console.log('GetDisplayName !nodeDef');
    return false;
  }
  console.log('GetDisplayName VALID entry');
  return nodeDef.DisplayName;
};

export const GetCloudResourceId = (nodeType) => {
  let nodeDef = getNodesDefinition()[nodeType];

  if (!nodeDef) {
    return false;
  }
  return nodeDef.CloudResourceId;
};

export const GetIconPath = (nodeType) => {
  return getNodeProperty(nodeType, 'IconPath');
};

export const GetDisplayType = (nodeType) => {
  return getNodeProperty(nodeType, 'DisplayType');
};

export const GetParentNameTrailPathIndex = (nodeType) => {
  return getNodeProperty(nodeType, 'ParentNameTrailPathIndex');
};

export const GetTrailPathIndexRange = (nodeType) => {
  return getNodeProperty(nodeType, 'TrailPathIndexRange');
};

export const getNodeProperty = (nodeType, property) => {
  let nodeDef = getNodesDefinition()[nodeType];

  if (!nodeDef) {
    console.log(`${property}  !nodeDef`);
    return false;
  }
  console.log(`${property}  VALID entry`);
  return nodeDef[property];
};
