import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftMenu from "../LeftMenu";
import { GetMenuBar } from "../MenubarComponent";
import Download from "../../images/download.png";
import "../../styles/Settings/billingPlans.css"
import ComingSoon from "../../images/comingsoon.svg";



function BillingsPlans() {

    function getComingSoon() {
        return(
            <div className="text-center">
                <img alt='' height="500" src={ComingSoon}/>
                <div className="row">
                    <h2><b>We're building this feature</b></h2>
                </div>
                <div className="row">
                    <h2>We will be launching this feature very soon. Stay tuned! for release 0.2 </h2>
                </div>
            </div>
        )
    }

  return (
    <>
      { GetMenuBar("user_logged_in") }

      <Container className="small mt-5">
          <Row>
              <Col cs lg="3">
                  <LeftMenu />
              </Col>
              <Col xs lg="9">
                  {getComingSoon()}
              </Col>
          </Row>
      </Container>

   {/*   <Container className="small  mt-5">
        <Row>
          <Col xs lg="3">
            <LeftMenu />
          </Col>
          <Col xs lg="9">
            <section className="bill-section">
              <div className="d-flex justify-content-between align-items-center">
                <span>Outstanding Bill</span>
                <Button
                  className="custom-button filled-button"
                  variant="primary"
                  type=""
                  // onClick={addKeyTolist}
                >
                  Pay $99.00
                </Button>
              </div>
              <div className="billing outstanding">
                <div className="payment-due due">
                    <div className="form-group">
                      <label>Plan : </label>
                      <span> PRO</span>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <p>Payment Due on 27 January 2022</p>
                  </div>
              </div>
            </section>
            <section className="bill-section">
              <div className="d-flex justify-content-between align-items-center">
                <span>Current Plan</span>
                <Button
                  className="custom-button white-button"
                  variant="primary"
                  type=""
                  // onClick={addKeyTolist}
                >
                  Upgrade Plan
                </Button>
              </div>
              <div className="billing">
                <div className="payment-due due">
                    <div className="form-group">
                      <label>Plan : </label>
                      <span> PRO</span>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <p>Payment Due on 27 January 2022</p>
                  </div>
              </div>
            </section>
            <section className="bill-section">
              <div className="d-flex justify-content-between align-items-center">
                <span>Last Payment </span>
                <Button
                  className="custom-button white-button"
                  variant="primary"
                  type=""
                  // onClick={addKeyTolist}
                >
                  View All Payments
                </Button>
              </div>
              <div className="billing">
                <div className="payment-due due">
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Plan : </label>
                        <span> PRO</span>
                      </div>
                      <a href="#">Need Help?</a>
                    </div>
                    <div className="form-group">
                      <label>Plan Charges :</label>
                      <span> $99.00 /month</span>
                    </div>
                    <div className="form-group">
                      <label>Payment Date :</label>
                      <span> 27 January 2022</span>
                    </div>
                    <div className="bulky d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label>Transaction ID :</label>
                        <span> 33242328687879</span>
                      </div>
                      <a href="#"><img src={Download}/>Download Incoice</a>
                    </div>
                  </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>*/}
    </>
  );
}

export default BillingsPlans;
