export const customDropdownStyles = {
    container: (provided, state) => ({
        ...provided,
        width:  '464px'
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: '48px',
        height: '48px',
        borderRadius: "4px",
        padding: "0px 16px",
        backgroundColor: "#F9FAFB"
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0px",
        height: "24px",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: "#6B7280",
        fontSize: "14px"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#6B7280",
        fontSize: "14px"
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
    }),
}