import { Input } from 'antd';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const EditableDynamicForm = ({ inputFields, prefilledValues, onSave }) => {
  const [formValues, setFormValues] = useState(prefilledValues);
  const [isEditing, setIsEditing] = useState(false);

  // console.log({ inputFields, prefilledValues });

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    toggleEdit();
    onSave(formValues);
  };

  return inputFields?.length ? (
    <div style={{ padding: '0.5vw' }}>
      {inputFields.map((eachInputField) => (
        <div>
          <div>{eachInputField.label}</div>
          <Input
            name={eachInputField.name}
            id={eachInputField.id}
            value={formValues[eachInputField.name]}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
      ))}
      <div style={{ marginTop: '1vw' }}>
        <Button onClick={!isEditing ? toggleEdit : handleSave}>{isEditing ? 'Save' : 'Edit'}</Button>
      </div>
    </div>
  ) : null;
};

export default EditableDynamicForm;
