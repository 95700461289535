import {BaseContextBarBuilder, GetSimpleKeyPairValueElement} from "./ContextBarBuilder";
import uniqid from "uniqid";
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";


export const ElasticIPContextBarHandler = () => {

    console.log("     ElasticIPContextBarHandler ========= ")
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    console.log("nodeContextInfo: ", nodeContextInfo)
    let resourceProperties = [
        "AssociationId",
        "InstanceId",
        "PublicIp",
    ]
    const title = "Elastic IP User";
    const displayFields = []

    for (let i=0 ; i<resourceProperties.length; i++) {
        let labelStr = resourceProperties[i]
        let keyStr = resourceProperties[i]
        displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr))
    }

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}

export const ElasticLoadBalancerContextBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()

    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
    let imgPath = currentContextBarNodeInfo.node_data.imgPath

    const title = nodeContextInfo.LoadBalancerName

    const displayFields = [
        GetSimpleKeyPairValueElement("LoadBalancerName", "LoadBalancerName"),
        GetSimpleKeyPairValueElement("LoadBalancerType", "LoadBalancerType"),
        GetSimpleKeyPairValueElement("Scheme", "Scheme"),
        GetSimpleKeyPairValueElement("VpcId", "VpcId"),
    ]

    let resourcePropertyBody = [];

    for (let i = 0; i < displayFields.length; i++) {
            
            let propertyRow = (
                <tr key={uniqid()}>
                    <td>{displayFields[i].label}</td>
                    <td>{displayFields[i].value}</td>
                </tr>
            )
    
            resourcePropertyBody.push(propertyRow)
    }

    return (
        <div className="context-bar">

            <div className="flex">
                <img className="context-bar-img" src={imgPath} />
            </div>
            <div>
                <h4 className="context-bar-title">{title}</h4>
            </div>

            <hr />

            <br />

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {resourcePropertyBody}

                </tbody>

            </table>

        </div>
    )
}

export const ElasticLoadBalancerV2ContextBarHandler = () => {
    
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()
    
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
    let imgPath = currentContextBarNodeInfo.node_data.imgPath
    
    const title = nodeContextInfo.LoadBalancerName
    
    const displayFields = [
        GetSimpleKeyPairValueElement("LoadBalancerName", "LoadBalancerName"),
        GetSimpleKeyPairValueElement("Scheme", "Scheme"),
        GetSimpleKeyPairValueElement("LoadBalancerArn", "LoadBalancerArn"),
    ]
    
    let resourcePropertyBody = [];
    
    for (let i = 0; i < displayFields.length; i++) {
                
        let propertyRow = (
            <tr key={uniqid()}>
                <td>{displayFields[i].label}</td>
                <td>{displayFields[i].value}</td>
            </tr>
        )
        
        resourcePropertyBody.push(propertyRow)
    }
    
    return (
       BaseContextBarBuilder(title, resourcePropertyBody)
    )
}

