
import {
    getCurrentActiveContextBarNodeInfo,
} from "../ContextBarHandlerBuilder";

import {Table} from "react-bootstrap";
import uniqid from "uniqid";
import { BaseContextBarBuilder } from "./ContextBarBuilder";


export const S3ContextShowInfoBarHandler = () => {
    const title = "S3"
    const displayFields = [
        {"label": "Bucket Name", "key":"BucketName", "element_type": "simple_key_pair_value"},
        {"label": "Creation Date", "key":"CreationDate", "element_type": "simple_key_pair_value"},
        {"label": "Bucket Policy", "key":"BucketPolicy", "element_type": "auto_json_value"},
        {"label": "Tag 1", "key":"Tag1", "element_type": "tag"},
        {"label": "Tag 2", "key":"Tag2", "element_type": "tag"},
    ]

    return (
        BaseContextBarBuilder(title, displayFields)
    )
}


export const S3ContextShowInfoBarHandler_old = () => {
    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo()

    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo
    let imgPath = currentContextBarNodeInfo.node_data.imgPath

    let resourceProperties = [
        "BucketName",
        "CreationDate",
        "BucketPolicy"
    ]


    const resourcePropertyBody = resourceProperties.map((p) =>
        <tr key={uniqid()}>
            <td>{p}</td>
            <td>{nodeContextInfo[p]}</td>
        </tr>
    );


    return (
        <div>
            <h1><img src={imgPath} width="48" height="48"/> ec2 </h1>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
                </thead>

                <tbody>
                {resourcePropertyBody}
                </tbody>

            </Table>
        </div>
    )
}

//
//
