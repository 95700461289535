import uniqid from 'uniqid';
import { getCurrentActiveContextBarNodeInfo } from '../ContextBarHandlerBuilder';
import { BaseContextBarBuilder, GetSimpleKeyPairValueElement } from './ContextBarBuilder';

export const IamRolePoliciesContextShowInfoBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    console.log("IamRolePoliciesContextShowInfoBarHandler", nodeContextInfo);

    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    const title = "IAM Role Policies";

    const displayFields = []

    displayFields.push(GetSimpleKeyPairValueElement("PolicyName", "PolicyName"))
    displayFields.push(GetSimpleKeyPairValueElement("PolicyArn", "PolicyArn"))

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}


export const IamRolesListContextShowInfoBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    console.log("IamRolesListContextShowInfoBarHandler", nodeContextInfo);
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    const title = "IAM Roles List";
    const displayFields = []

    displayFields.push(GetSimpleKeyPairValueElement("RoleId", "RoleId"))
    displayFields.push(GetSimpleKeyPairValueElement("RoleName", "RoleName"))

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}

export const IamUserAttachedPoliciesContextShowInfoBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    console.log("IamUserAttachedPoliciesContextShowInfoBarHandler", nodeContextInfo);
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    const title = "IAM User Attached Policies";
    const displayFields = []

    displayFields.push(GetSimpleKeyPairValueElement("PolicyName", "PolicyName"))
    displayFields.push(GetSimpleKeyPairValueElement("PolicyArn", "PolicyArn"))

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}


export const IamUserInlinePoliciesContextShowInfoBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    console.log("IamUserInlinePoliciesContextShowInfoBarHandler", nodeContextInfo);
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    const title = "IAM User Inline Policies";
    const displayFields = []

    displayFields.push(GetSimpleKeyPairValueElement("UserName", "UserName"))
    // displayFields.push(GetSimpleKeyPairValueElement("PolicyArn", "PolicyArn"))

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}

export const IamUsersResourcesContextShowInfoBarHandler = () => {

    let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
    let nodeContextInfo = currentContextBarNodeInfo.node_data.nodeContextInfo;

    console.log("IamUsersResourcesContextShowInfoBarHandler", nodeContextInfo);
    let imgPath = currentContextBarNodeInfo.node_data.imgPath;

    const title = "IAM Users Resources";
    const displayFields = []

    displayFields.push(GetSimpleKeyPairValueElement("UserName", "UserName"))
    displayFields.push(GetSimpleKeyPairValueElement("UserId", "UserId"))

    return (
        BaseContextBarBuilder(title, displayFields)
    )

}


