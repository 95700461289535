export const CONCERTO_HTTP_OK = 200;

export const LOGOUT_TIME_IN_MINUTES = 60;

//
//

export const WEEKDAYS_MAP = {
  M: 'mon',
  T: 'tue',
  W: 'wed',
  Th: 'thu',
  F: 'fri',
  Sa: 'sat',
  Su: 'sun',
};
